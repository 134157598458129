import React from 'react';
import { makeStyles } from '@mui/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, Button } from '@mui/material';
import { ArrowLeftIcon } from '../icons/ArrowLeftIcon';
import { ArrowRightIcon } from '../icons/ArrowRightIcon';

export interface ConfirmCustomerArrivedDialogProps {
  open: boolean;
  onClose: () => void;
  onPressConfirm: () => void;
  onPressCancel: () => void;
}

function ConfirmCustomerArrivedDialog({
  open,
  onClose,
  onPressConfirm,
  onPressCancel,
}: ConfirmCustomerArrivedDialogProps) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle id="simple-dialog-title">{`Vuoi confermare la modifica?`}</DialogTitle>
      <Box
        className={classes.footer}
        justifyContent="space-between"
        alignItems="center"
        display="flex"
      >
        <Button
          className={classes.cancelButton}
          variant="contained"
          onClick={onPressCancel}
          disableElevation
          startIcon={<ArrowLeftIcon color="#34303D" />}
        >
          Annulla
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.confirmButton}
          onClick={onPressConfirm}
          endIcon={<ArrowRightIcon />}
          disableElevation
        >
          Conferma
        </Button>
      </Box>
    </Dialog>
  );
}

export default ConfirmCustomerArrivedDialog;

const useStyles = makeStyles({
  footer: {
    width: '100%',
    padding: '16px 16px 8px 16px',
  },
  confirmButton: {
    backgroundColor: '#34303d !important',
    padding: '10px 18px !important',
    borderRadius: '25px !important',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    lineHeight: '14px !important',
    color: '#fff !important',
    border: '1px solid #34303d !important',
  },
  cancelButton: {
    backgroundColor: 'transparent !important',
    padding: '10px 18px !important',
    borderRadius: '25px !important',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    lineHeight: '14px !important',
    color: '#34303d !important',
    border: '1px solid #34303d !important',
  },
});
