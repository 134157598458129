import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Theme,
  Typography,
  Dialog,
  ImageList,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ActionDialog from '../dialog/ActionDialog';
import { Appointment, AppointmentClient, ClientEdit } from '../../api/entities';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AppointmentsActions } from '../../redux/actions';
import { RootState } from '../../redux/reducers';
import ServiceProductLabel from '../services/details/ServiceProductLabel';
import ConfirmCustomerArrivedDialog from './ConfirmCustomerArrivedDialog';
import { createStyles, makeStyles } from '@mui/styles';
import BookingCheckboxButton from './BookingCheckboxButton';
import { ArrowRightIcon } from '../icons/ArrowRightIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';
import { CloseIcon } from '../icons/CloseIcon';

interface Props {
  open: boolean;
  anchor: HTMLElement | null;
  event: Appointment | undefined | null;
  onClose: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
}

function BookingEventModal(props: Props) {
  const classes = useStyles();
  const id = props.open ? 'simple-popover' : undefined;
  const event = props.event;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [service, setService] = useState<string | undefined>('');
  const [clientArrived, setClientArrived] = useState<boolean | undefined>(
    undefined,
  );
  const [clientsNames, setClientsName] = useState('');
  const [referral, setReferral] = useState('');
  const [note, setNote] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [notifyForDelete, setNotifyForDelete] = useState(false);
  const [openClientArriveYESdDialog, setOpenClientYESArrivedDialog] =
    useState(false);
  const [openClientArriveNOdDialog, setOpenClientNOArrivedDialog] =
    useState(false);
  const currentUser = useSelector(
    (state: RootState) => state.UsersReducer.currentUser,
  );

  const handleClickDetail = () => {
    if (event && event.uuid) {
      let pathname = '/appointment/' + event.uuid;
      navigate(pathname, { state: event });
    }
  };

  const handleClientsNames = (clients: AppointmentClient[] | undefined) => {
    if (!clients) return '';
    const names = _.map(clients, function (obj) {
      return obj.name + ' ' + obj.surname;
    }).join(',');
    return names;
  };

  useEffect(() => {
    if (event?.storeService) {
      setClientArrived(event.hasArrived);
      setService(event.storeService.externalName);
      setClientsName(handleClientsNames(event.clients));
      setNote(event.notes || '');
      setReferral(event.referral ? event.referral : '');
    }
  }, [event]);

  const clientWithCrmValues = () => {
    if (event && event.clients) {
      let client = event.clients[0];
      return [
        { crmClientId: client.crmClientId, clientType: client.clientType },
      ] as ClientEdit[];
    }
    return [{ crmClientId: undefined, clientType: undefined }] as ClientEdit[];
  };

  const handleClientIsArrived = (isArrived: boolean) => {
    setOpenClientNOArrivedDialog(false);
    setOpenClientYESArrivedDialog(false);
    setClientArrived(isArrived);
    if (event?.uuid) {
      dispatch(
        AppointmentsActions.patchAppointment(event.uuid, {
          hasArrived: isArrived,
          clients: clientWithCrmValues(),
        }),
      );
    }
  };

  const handleDeleteAppointment = () => {
    if (event && event.uuid) {
      dispatch(
        AppointmentsActions.deleteAppointment(event.uuid, notifyForDelete),
      );
      setOpenDeleteDialog(false);
      props.onClose();
    }
  };

  const notificationEnabled = useMemo<boolean>(() => {
    return (
      currentUser?.businessRole === 'SHOP ASSISTANT' ||
      currentUser?.businessRole === 'CALL CENTER'
    );
  }, [currentUser]);

  const serviceColor = useMemo<string>(() => {
    if (service === 'Assistenza') return '#BCE9F8';
    if (service === 'Acquisto') return '#F8F4BC';
    if (service === 'Videocall') return '#00D1D2';
    return '#90ee90';
  }, [service]);

  return (
    <Dialog
      id={id}
      open={props.open}
      onClose={props.onClose}
      maxWidth={'sm'}
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <Box className={classes.container}>
        <Box display="flex" className={classes.headerContainer}>
          <Box display="flex" alignItems="center">
            <Box
              className={classes.serviceBox}
              style={{
                backgroundColor: serviceColor,
              }}
            >
              <Typography className={classes.service}>{service}</Typography>
            </Box>
            {Array.isArray(event?.products) && (
              <ImageList className={classes.productsContainer}>
                {(event!.products || []).map((product) => {
                  return (
                    <ServiceProductLabel
                      key={product.uuid}
                      type={product?.productName || 'IQOS'}
                    />
                  );
                })}
              </ImageList>
            )}
          </Box>
          <Box display="flex" alignSelf="center">
            <IconButton
              className={classes.headerButton}
              onClick={() => setOpenDeleteDialog(true)}
            >
              <DeleteIcon color="#34303d" />
            </IconButton>
            <IconButton className={classes.headerButton} onClick={props.onEdit}>
              <EditIcon color="#34303d" />
            </IconButton>
            <div className={classes.headerButtonBorder}>
              <IconButton
                sx={{ marginRight: 0 }}
                className={classes.headerButton}
                onClick={props.onClose}
              >
                <CloseIcon color="#34303d" />
              </IconButton>
            </div>
          </Box>
        </Box>
        <Box
          justifyContent="space-between"
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <Typography className={classes.nameLabel}>{clientsNames}</Typography>
          <Box>
            <Box className={classes.content} alignItems="center" display="flex">
              <div className={classes.contentLabel}>Data:&nbsp;</div>
              <Typography className={classes.contentValue}>
                {moment(event?.startTime).format('dddd, DD MMMM - HH:mm')}
              </Typography>
            </Box>
            <Box
              sx={{ borderWidth: note ? 1 : 0 }}
              className={classes.content}
              alignItems="center"
              display="flex"
            >
              <div className={classes.contentLabel}>Referral:&nbsp;</div>
              <Typography className={classes.contentValue}>
                {referral}
              </Typography>
            </Box>
            {note && (
              <Box
                className={classes.content}
                sx={{ borderWidth: 0 }}
                alignItems="center"
                display="flex"
              >
                <div className={classes.contentLabel}>Note:&nbsp;</div>
                <Typography className={classes.contentValue}>{note}</Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          alignItems="flex-end"
          justifyContent="space-between"
          display="flex"
          className={classes.footer}
        >
          <Box justifyContent="space-between" alignItems="center">
            <Typography className={classes.clientLabel}>
              Il cliente è arrivato?
            </Typography>
            <Box alignItems="center" display="flex">
              <BookingCheckboxButton
                name="Sì"
                minWidth="84px"
                selected={!!clientArrived}
                onSelected={() => {
                  if (!!clientArrived) return;
                  setOpenClientYESArrivedDialog(true);
                }}
              />
              <BookingCheckboxButton
                name="No"
                minWidth="84px"
                selected={!clientArrived}
                onSelected={() => {
                  if (!clientArrived) return;
                  setOpenClientNOArrivedDialog(true);
                }}
              />
            </Box>
          </Box>
          <Button
            className={classes.detailButton}
            onClick={() => handleClickDetail()}
            disableElevation
            endIcon={<ArrowRightIcon />}
          >
            Dettagli
          </Button>
        </Box>
        <ActionDialog
          title="Elimina appuntamento"
          message="Sei sicuro di eliminare questo appuntamento?"
          onAction={() => handleDeleteAppointment()}
          open={openDeleteDialog}
          hasNotificationOption={true}
          notificationEnabled={notificationEnabled}
          onCheckedNotificationEnabled={(value) => setNotifyForDelete(value)}
          onClose={() => setOpenDeleteDialog(false)}
          actionTitle="Elimina"
        />
        <ConfirmCustomerArrivedDialog
          open={openClientArriveYESdDialog}
          onPressConfirm={() => handleClientIsArrived(!clientArrived)}
          onPressCancel={() => setOpenClientYESArrivedDialog(false)}
          onClose={() => setOpenClientYESArrivedDialog(false)}
        />
        <ConfirmCustomerArrivedDialog
          open={openClientArriveNOdDialog}
          onPressConfirm={() => handleClientIsArrived(!clientArrived)}
          onPressCancel={() => setOpenClientNOArrivedDialog(false)}
          onClose={() => setOpenClientNOArrivedDialog(false)}
        />
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '16px 20px',
    },
    serviceBox: {
      padding: '8px 16px',
      borderRadius: '4px !important',
    },
    service: {
      color: '#34303D',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
    },
    productsContainer: {
      margin: '0 0 0 8px !important',
    },
    headerContainer: {
      width: '100%',
      marginBottom: 32,
      justifyContent: 'space-between',
    },
    headerButton: {
      padding: '8px !important',
      borderRadius: '50% !important',
      minWidth: 'unset !important',
      width: '32px !important',
      height: '32px !important',
      marginRight: 4,
    },
    headerButtonBorder: {
      borderLeft: '1px solid #E1E0E2 !important',
      marginLeft: 4,
      paddingLeft: 4,
    },
    nameLabel: {
      color: '#34303D',
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      marginBottom: '16px !important',
    },
    detailButton: {
      backgroundColor: '#34303d !important',
      padding: '12px 24px !important',
      borderRadius: '50px !important',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      color: '#fff !important',
      border: '1px solid #34303d !important',
    },
    content: {
      padding: '16px 0',
      borderBottom: '1px solid #E1E0E2',
      width: '100%',
    },
    contentLabel: {
      color: '#34303D',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      minWidth: '100px',
    },
    contentValue: {
      color: '#34303D',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '700 !important',
      lineHeight: '14px !important',
    },
    footer: {
      marginTop: 16,
      paddingTop: 16,
      borderTop: '1px solid #E1E0E2',
    },
    clientLabel: {
      marginBottom: '16px !important',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
    },
  }),
);

export default BookingEventModal;
