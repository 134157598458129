import React, { MouseEvent } from 'react';
import { IconButton } from '@mui/material';
import { NotificationIcon } from '../icons/NotificationIcon';
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/styles';
import { useNotifications } from './NotificationContext';

interface Props {
  active: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const NotificationButton = ({ active, onClick }: Props) => {
  const classes = useStyles();

  const { toBeReadCount } = useNotifications();

  return (
    <span className={classes.wrapper}>
      <IconButton className={classes.button} onClick={onClick}>
        <NotificationIcon color={active ? '#00D1D2' : '#ffffff'} />
        {active && <div className={classes.indicator} />}
      </IconButton>
      {toBeReadCount > 0 && (
        <div className={classes.counter}>{toBeReadCount}</div>
      )}
    </span>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    button: {
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.05) !important',
      },
    },
    indicator: {
      backgroundColor: '#00D1D2',
      height: '4px',
      borderRadius: '2px',
      width: '40px',
      position: 'absolute',
      left: 0,
      bottom: '-10px',
    },
    counter: {
      position: 'absolute',
      top: '-5px',
      right: '-5px',
      backgroundColor: '#00D1D2',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: '12px',
      textAlign: 'center',
    },
  }),
);
