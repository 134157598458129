import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import './DetailStore.scss';
import { Box, Grid, Button, Typography, IconButton } from '@mui/material';
import StoreServiceCard from '../../components/stores/details/StoreServiceCard';
import StoreHoursList from '../../components/stores/details/StoreHoursList';
import StoreDateRangeModal from '../../components/stores/details/modals/StoreDateRangeModal';
import {
  BusinessHourRequestActions,
  BusinessHoursActions,
  ModalsActions,
  StoreActions,
} from '../../redux/actions';
import { RootState } from '../../redux/reducers';
import ActionDialog from '../../components/dialog/ActionDialog';
import { StoreStoreOutputStoreRead } from '../../api/services/nbp';
import {
  HydratedBusinessHours,
  HydratedServices,
  RequestBusinessHours,
  Roles,
} from '../../api/entities';
import StoreSpecialHoursModal from '../../components/stores/details/modals/time_periods/StoreSpecialHoursModal';
import CreateSpecialTimePeriodModal from '../../components/stores/details/modals/time_periods/CreateSpecialTimePeriodModal';
import StoreHoursModal from '../../components/stores/details/modals/StoreHoursModal';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ArrowLeftIcon } from '../../components/icons/ArrowLeftIcon';
import { ArchiveIcon } from '../../components/icons/ArchiveIcon';
import { EditIcon } from '../../components/icons/EditIcon';
import { UserRoles } from '../../utils/UserRoles';
import StoreEditInfoModal from '../../components/stores/details/modals/StoreEditInfoModal';
import { AddIcon } from '../../components/icons/AddIcon';

interface DetailStoreProps {
  getSingleStore: (id: string) => void;
  storeHours?: HydratedBusinessHours;
  addNewStoreSchedule: (schedule: any) => void;
  removeStoreSchedule: (id: string) => void;
  addBusinessHours: (id: string, request: RequestBusinessHours) => void;
  storeDetail: StoreStoreOutputStoreRead | undefined;
  hydratedService?: HydratedServices;
  getBusinessHours: (id: string) => void;
  getSpecialHours: (id: string) => void;
  resetApiCallCounter: () => void;
  archiveStore: (id: string, data: any) => void;
  loadingSingleStore: boolean;
  updateBusinessHoursLoading: boolean;
  createBusinessHoursLoading: boolean;
  currentRole?: Roles;
}

type LegacyDetailStoreProps = DetailStoreProps & {
  location: Location;
  navigate: NavigateFunction;
  currentRole: Roles;
  dispatch: any;
};
interface DetailStoreState {
  dateRangeIsVisible: boolean;
  archiveDialogOpen: boolean;
  activeStoreDialogOpen: boolean;
  editDialogOpen: boolean;
  selectedBusinessHoursId?: string;
}

export function DetailStore(props: DetailStoreProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentRole = useSelector(
    (state: RootState) => state.UsersReducer.currentRole,
  );

  return (
    <LegacyDetailStore
      {...props}
      location={location}
      navigate={navigate}
      currentRole={currentRole}
      dispatch={dispatch}
    />
  );
}

class LegacyDetailStore extends React.Component<
  LegacyDetailStoreProps,
  DetailStoreState
> {
  constructor(props: LegacyDetailStoreProps) {
    super(props);
    this.state = {
      dateRangeIsVisible: false,
      archiveDialogOpen: false,
      activeStoreDialogOpen: false,
      editDialogOpen: false,
    };
  }

  componentDidMount() {
    let storeID = this.props.location.pathname.getIdFromPath();
    if (storeID) {
      this.props.getSingleStore(storeID);
      this.props.getBusinessHours(storeID);
      this.props.getSpecialHours(storeID);
    }
  }

  toggleDateRange = () => {
    this.setState({ dateRangeIsVisible: !this.state.dateRangeIsVisible });
  };

  toggleArchiveDialog = () => {
    this.setState({ archiveDialogOpen: !this.state.archiveDialogOpen });
  };

  toggleActiveDialog = () => {
    this.setState({ activeStoreDialogOpen: !this.state.activeStoreDialogOpen });
  };

  toggleEditDialog = () => {
    this.setState({ editDialogOpen: !this.state.editDialogOpen });
  };

  archiveStore = () => {
    if (this.props.storeDetail?.uuid) {
      this.props.archiveStore(this.props.storeDetail.uuid, {
        status: 'ARCHIVED',
      });
      this.toggleArchiveDialog();
    }
  };

  activateStore = () => {
    if (this.props.storeDetail?.uuid) {
      this.props.archiveStore(this.props.storeDetail.uuid, { status: null });
      this.toggleActiveDialog();
    }
  };

  onPressNewRange = () => {
    this.props.dispatch(
      BusinessHourRequestActions.setBusinessHourRequest(undefined),
    );
    this.props.dispatch(ModalsActions.openDateRangeModal());
  };

  deleteStoreSchedule = (id: string) => {
    this.props.removeStoreSchedule(id);
  };

  loading = () => {
    return this.props.loadingSingleStore;
  };

  handleBack = () => {
    this.props.navigate('/stores');
  };

  render() {
    return (
      <Box display="flex" className="main-container">
        <Grid className="grid-action-container">
          <Button
            disableElevation
            className="sidebar-button"
            onClick={() => this.handleBack()}
            startIcon={<ArrowLeftIcon color="#34303D" />}
          >
            Torna Indietro
          </Button>
          <Typography className="sidebar-label">Gestisci Store</Typography>
          <Box
            flexDirection="column"
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Button
              variant="outlined"
              className="sidebar-button"
              onClick={() => this.toggleArchiveDialog()}
            >
              {this.props.storeDetail?.status !== 'ARCHIVED' && (
                <ArchiveIcon color="#34303D" small={true} />
              )}
              {this.props.storeDetail?.status !== 'ARCHIVED'
                ? 'Archivia'
                : 'Ripristina'}
            </Button>
          </Box>
        </Grid>
        <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
          <Grid className="grid-info-container">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div className="title">Informazioni</div>
              {(this.props.currentRole === UserRoles.ADMIN ||
                this.props.currentRole === UserRoles.SUPER_STORE_MANAGER ||
                this.props.currentRole === UserRoles.STORE_MANAGER) && (
                <IconButton onClick={this.toggleEditDialog}>
                  <EditIcon color="#00D1D2" />
                </IconButton>
              )}
            </Box>
            <Box
              flexDirection="column"
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              className="key-value-box"
            >
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                className="item-box"
              >
                <Typography className="key-label">None:</Typography>
                <Typography className="value-label">
                  {this.props.storeDetail?.name}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                className="item-box"
              >
                <Typography className="key-label">Regione:</Typography>
                <Typography className="value-label">
                  {this.props.storeDetail?.region}
                </Typography>
              </Box>
              {this.props.storeDetail?.address && (
                <Box
                  display="flex"
                  width="100%"
                  alignItems="center"
                  className="item-box"
                >
                  <Typography className="key-label">Indirizzo:</Typography>
                  <Typography className="value-label">
                    {this.props.storeDetail?.address}
                  </Typography>
                </Box>
              )}
              {this.props.storeDetail?.phoneNumber && (
                <Box
                  display="flex"
                  width="100%"
                  alignItems="center"
                  className="item-box"
                >
                  <Typography className="key-label">Telefono:</Typography>
                  <Typography className="value-label">
                    {this.props.storeDetail?.phoneNumber}
                  </Typography>
                </Box>
              )}
              {this.props.storeDetail?.cellPhoneNumber && (
                <Box
                  display="flex"
                  width="100%"
                  alignItems="center"
                  className="item-box"
                >
                  <Typography className="key-label">Cellulare:</Typography>
                  <Typography className="value-label">
                    {this.props.storeDetail?.cellPhoneNumber}
                  </Typography>
                </Box>
              )}
              {this.props.storeDetail?.image && (
                <Box
                  display="flex"
                  width="100%"
                  alignItems="center"
                  className="item-box"
                >
                  <Typography className="key-label">
                    Foto dello store:
                  </Typography>
                  <img src={this.props.storeDetail?.image} className="image" />
                </Box>
              )}
              {this.props.storeDetail?.visibilityStartDate &&
                this.props.storeDetail?.visibilityEndDate && (
                  <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    className="item-box"
                  >
                    <Typography className="key-label">Visibilità:</Typography>
                    <Typography className="value-label">
                      {this.props.storeDetail?.visibilityStartDate?.formatted() +
                        ' - ' +
                        this.props.storeDetail?.visibilityEndDate?.formatted()}
                    </Typography>
                  </Box>
                )}
            </Box>
            <StoreServiceCard />
          </Grid>
          <div className="hours-wrapper">
            <div className="cards-container">
              <StoreHoursList
                data={this.props.storeHours?.['hydra:member'] ?? []}
                onDeleteItem={(id: string) => this.deleteStoreSchedule(id)}
              />
            </div>

            {(this.props.currentRole === UserRoles.ADMIN ||
              this.props.currentRole === UserRoles.SUPER_STORE_MANAGER ||
              this.props.currentRole === UserRoles.STORE_MANAGER) && (
              <Button
                variant="outlined"
                disableElevation
                className="secondary-button"
                onClick={() => this.onPressNewRange()}
                endIcon={<AddIcon color="#34303D" />}
              >
                Nuova programmazione
              </Button>
            )}
          </div>
        </Box>
        <StoreEditInfoModal
          isVisible={this.state.editDialogOpen}
          onClose={this.toggleEditDialog}
        />
        <StoreDateRangeModal />
        <ActionDialog
          title="Archivia Store"
          message="Sei sicuro di voler archiviare questo store?"
          onAction={() => this.archiveStore()}
          open={this.state.archiveDialogOpen}
          onClose={this.toggleArchiveDialog}
          actionTitle="Archivia"
        />
        <ActionDialog
          open={this.state.activeStoreDialogOpen}
          onClose={this.toggleActiveDialog}
          title="Ripristina Store"
          message="Sei sicuro di voler ripristinare questo store?"
          actionTitle="Ripristina"
          onAction={() => this.activateStore()}
        />

        <StoreSpecialHoursModal />
        <CreateSpecialTimePeriodModal />
        <StoreHoursModal />
      </Box>
    );
  }
}

export function mapStateToProps(state: RootState) {
  return {
    storeDetail: state.StoreReducer.storeDetail,
    storeHours: state.BusinessHoursReducer.hydratedBusinessHours,
    hydratedService: state.ServiceReducer.hydratedService,
    loadingSingleStore: state.StoreReducer.loadingSingleStore,
    updateBusinessHoursLoading:
      state.BusinessHoursReducer.updateBusinessHoursLoading,
    createBusinessHoursLoading:
      state.BusinessHoursReducer.createBusinessHoursLoading,
    currentRole: state.UsersReducer.currentRole,
  };
}

const mapDispatchToProps = {
  getSingleStore: (id: string) => StoreActions.getSingleStore(id),
  getBusinessHours: (id: string) =>
    BusinessHoursActions.getBusinessHoursByStore(id),
  getSpecialHours: (id: string) => BusinessHoursActions.getSpecialHours(id),
  removeStoreSchedule: (id: string) => StoreActions.removeStoreOpenSchedule(id),
  addBusinessHours: (id: string, request: RequestBusinessHours) =>
    BusinessHoursActions.createBusinessHour(id, request),
  archiveStore: (id: string, data: any) => StoreActions.archiveStore(id, data),
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailStore);
