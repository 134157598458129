import React, { useMemo } from 'react';
import _ from 'lodash';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ClickAndLending } from '../../api/entities';
import { createStyles, makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import moment from 'moment';
import Icons from '../../theme/Icons';

const CLICK_AND_LENDING_STATUS = [
  {
    status: 'CREATED',
    color: '202, 11, 17',
    label: 'Da verificare',
  },
  {
    status: 'AWAITINGPICK',
    color: '25, 89, 201',
    label: 'In attesa di ritiro',
  },
  {
    status: 'VARIANTAWAITINGPICK',
    color: '25, 89, 201',
    label: 'In attesa di ritiro',
  },
  {
    status: 'NOTAVAILABLE',
    color: '183, 183, 183',
    label: 'Prodotto non disponibile',
  },
  {
    status: 'ENDED',
    color: '108, 167, 84',
    label: 'Concluso',
  },
  {
    status: 'CANCELLED',
    color: '245, 135, 32',
    label: 'Annullato',
  },
] as const;

interface ClickAndLendingRowProps {
  row: ClickAndLending;
  onRowClick: () => void;
}

export default function ClickAndLendingRow({
  row,
  onRowClick,
}: ClickAndLendingRowProps) {
  const classes = useStyles();

  const uiDate = (date: string | undefined) => {
    const toReturn = moment(date).format('DD/MM/YYYY HH:mm');
    if (toReturn === 'Invalid date') return '-';
    return toReturn;
  };

  const status = useMemo(() => {
    let status = row.reservationStatus;
    return CLICK_AND_LENDING_STATUS.find((s) => s.status === status);
  }, [row]);

  return (
    <TableRow
      style={{ backgroundColor: `rgba(${status?.color}, 0.1)` }}
      hover={false}
      role="checkbox"
      tabIndex={-1}
      key={_.uniqueId()}
      className={classes.row}
      onClick={onRowClick}
    >
      <TableCell align="left">
        {status?.status === 'CREATED' && (
          <img src={Icons.Warning} alt="warning" />
        )}
      </TableCell>
      <TableCell align="left">
        <Typography className={classes.cellLabel}>
          {row.userName} {row.userSurname}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography className={classes.cellLabelDate}>
          {uiDate(row.createdAt)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography className={classes.cellLabelDate}>
          {uiDate(row.confirmedAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.cellLabel}>
          {row.reservationCode}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography className={classes.cellLabel}>
          {row.referral ? row.referral : '-'}
        </Typography>
      </TableCell>
      <TableCell align="left" width="16%">
        <Typography className={classes.cellLabel}>
          {row.outcome ? row.outcome : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <div
          style={{ backgroundColor: `rgba(${status?.color}, 0.2)` }}
          className={classes.statusBox}
        >
          <div
            className={classes.statusBadge}
            style={{ backgroundColor: `rgb(${status?.color})` }}
          />
          <Typography
            className={classes.statusLabel}
            style={{ color: '#34303D' }}
          >
            {status?.label}
          </Typography>
        </div>
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      cursor: 'pointer',
      '&&:hover': {
        backgroundColor: '#F9F9F9',
      },
    },
    cellLabel: {
      color: '#34303D !important',
      fontSize: '14px !important',
      borderColor: '#E1E0E2',
    },
    cellLabelDate: {
      color: '#34303D !important',
      fontSize: '14px !important',
      borderColor: '#E1E0E2',
      whiteSpace: 'nowrap',
    },
    statusBox: {
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: 4,
      padding: '4px 8px',
    },
    statusLabel: {
      fontfFamily: 'IQOS Sans',
      fontWeight: 'bold',
      fontSize: '12px !important',
      whiteSpace: 'nowrap',
      padding: '0 !important',
    },
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    verificationDialog: {
      backgroundColor: 'white',
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      borderRadius: 4,
      flexWrap: 'nowrap',
    },
    bottom: {
      marginBottom: 20,
    },
    statusBadge: {
      borderRadius: '50%',
      width: 10,
      height: 10,
      marginRight: 6,
    },
    warning: {
      color: 'red',
    },
  }),
);
