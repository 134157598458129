/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { ForgotPasswordrequest } from "../nbp";
// @ts-ignore
import { ForgotPasswordreset } from "../nbp";
/**
 * ForgotPasswordApi - axios parameter creator
 * @export
 */
export const ForgotPasswordApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Validates token
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getForgotPassword: async (
      token: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists("getForgotPassword", "token", token);
      const localVarPath = `/forgot-password/{token}`.replace(
        `{${"token"}}`,
        encodeURIComponent(String(token)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generates a token and send email
     * @param {ForgotPasswordrequest} [forgotPasswordrequest] Request a new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postForgotPassword: async (
      forgotPasswordrequest?: ForgotPasswordrequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/forgot-password/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forgotPasswordrequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resets user password from token
     * @param {string} token
     * @param {ForgotPasswordreset} [forgotPasswordreset] Reset password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postForgotPasswordToken: async (
      token: string,
      forgotPasswordreset?: ForgotPasswordreset,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists("postForgotPasswordToken", "token", token);
      const localVarPath = `/forgot-password/{token}`.replace(
        `{${"token"}}`,
        encodeURIComponent(String(token)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forgotPasswordreset,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ForgotPasswordApi - functional programming interface
 * @export
 */
export const ForgotPasswordApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ForgotPasswordApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Validates token
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getForgotPassword(
      token: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getForgotPassword(token, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Generates a token and send email
     * @param {ForgotPasswordrequest} [forgotPasswordrequest] Request a new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postForgotPassword(
      forgotPasswordrequest?: ForgotPasswordrequest,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postForgotPassword(
          forgotPasswordrequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Resets user password from token
     * @param {string} token
     * @param {ForgotPasswordreset} [forgotPasswordreset] Reset password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postForgotPasswordToken(
      token: string,
      forgotPasswordreset?: ForgotPasswordreset,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postForgotPasswordToken(
          token,
          forgotPasswordreset,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ForgotPasswordApi - factory interface
 * @export
 */
export const ForgotPasswordApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ForgotPasswordApiFp(configuration);
  return {
    /**
     *
     * @summary Validates token
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getForgotPassword(token: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .getForgotPassword(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generates a token and send email
     * @param {ForgotPasswordrequest} [forgotPasswordrequest] Request a new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postForgotPassword(
      forgotPasswordrequest?: ForgotPasswordrequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postForgotPassword(forgotPasswordrequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Resets user password from token
     * @param {string} token
     * @param {ForgotPasswordreset} [forgotPasswordreset] Reset password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postForgotPasswordToken(
      token: string,
      forgotPasswordreset?: ForgotPasswordreset,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postForgotPasswordToken(token, forgotPasswordreset, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ForgotPasswordApi - object-oriented interface
 * @export
 * @class ForgotPasswordApi
 * @extends {BaseAPI}
 */
export class ForgotPasswordApi extends BaseAPI {
  /**
   *
   * @summary Validates token
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForgotPasswordApi
   */
  public getForgotPassword(token: string, options?: any) {
    return ForgotPasswordApiFp(this.configuration)
      .getForgotPassword(token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generates a token and send email
   * @param {ForgotPasswordrequest} [forgotPasswordrequest] Request a new password
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForgotPasswordApi
   */
  public postForgotPassword(
    forgotPasswordrequest?: ForgotPasswordrequest,
    options?: any,
  ) {
    return ForgotPasswordApiFp(this.configuration)
      .postForgotPassword(forgotPasswordrequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resets user password from token
   * @param {string} token
   * @param {ForgotPasswordreset} [forgotPasswordreset] Reset password
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ForgotPasswordApi
   */
  public postForgotPasswordToken(
    token: string,
    forgotPasswordreset?: ForgotPasswordreset,
    options?: any,
  ) {
    return ForgotPasswordApiFp(this.configuration)
      .postForgotPasswordToken(token, forgotPasswordreset, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
