import React, { useMemo } from 'react';
import Mail from '../../../assets/Mail.svg';
import MailOpen from '../../../assets/MailOpen.svg';
import './NotificationCard.scss';
import { Notification } from '../../../redux/actions/NotificationsActions';
import { Box, Typography } from '@mui/material';

interface NotificationCardProps {
  notification: Notification;
  onClick: () => void;
}

export function NotificationCard({
  notification,
  onClick,
}: NotificationCardProps) {
  const humanTime = useMemo<string>(() => {
    const notyDate = new Date(notification.createdAt || new Date());
    const now = Date.now();
    const msDiff = now - notyDate.getTime();
    const secDiff = Math.round(msDiff) / 1000;

    const minAsSecs = 60;
    const hourAsSecs = minAsSecs * 60;
    const dayAsSecs = hourAsSecs * 24;

    if (secDiff < 30) {
      return 'Ora';
    }

    if (secDiff < minAsSecs) {
      return `${Math.round(secDiff)} sec fa`;
    }

    if (secDiff >= minAsSecs && secDiff < hourAsSecs) {
      return `${Math.round(secDiff / minAsSecs).toString()} min fa`;
    }

    if (secDiff >= hourAsSecs && secDiff < dayAsSecs) {
      const hours = Math.round(secDiff / hourAsSecs);
      return `${hours} ${hours === 1 ? 'ora' : 'ore'} fa`;
    }

    return `${Math.round(secDiff / dayAsSecs)} g fa`;
  }, [notification]);

  const color = useMemo<string>(() => {
    if (!notification.type) return '#90ee90';
    switch (notification.type.toLowerCase()) {
      case 'assistenza':
        return '#BCE9F8';
      case 'acquisto':
        return '#F8F4BC';
      case 'videocall':
        return '#00D1D2';
      default:
        return '#90ee90';
    }
  }, [notification]);

  return (
    <div
      key={notification.id}
      className="notification-container"
      onClick={onClick}
    >
      <div
        className={
          'notification-content' + (!notification.isRead ? ' active' : '')
        }
      >
        <Box
          className="notification-type"
          style={{
            backgroundColor: color,
          }}
        />
        <div style={{ width: 260 }}>
          <Typography sx={{ width: 260 }} fontSize={14}>
            {notification.body}
          </Typography>
        </div>
        <div className="status">
          <Typography fontSize={12}>{humanTime}</Typography>
          <img
            src={notification.isRead ? MailOpen : Mail}
            className="notification-read"
          />
        </div>
      </div>
    </div>
  );
}
