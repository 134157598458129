import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Dialog, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DialogActionFooter from '../../../dialog/DialogActionFooter';
import moment from 'moment';
import { RootState } from '../../../../redux/reducers';
import {
  BusinessHourRequestActions,
  BusinessHoursActions,
  ModalsActions,
} from '../../../../redux/actions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment';

export default function StoreDateRangeModal() {
  const classes = useStyles();
  const { showDateRangeModalSuccess } = useSelector(
    (state: RootState) => state.UiReducer,
  );

  const businessHoursRequest = useSelector(
    (state: RootState) => state.BusinessHourRequestReducer.businessHoursRequest,
  );
  const storeDetail = useSelector(
    (state: RootState) => state.StoreReducer.storeDetail,
  );

  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>(moment().add(1, 'year'));
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    if (businessHoursRequest) {
      if (businessHoursRequest.startDate && businessHoursRequest.endDate) {
        setStartDate(moment(businessHoursRequest.startDate));
        setEndDate(moment(businessHoursRequest.endDate));
        setTitle('Modifica programmazione');
      } else if (businessHoursRequest && businessHoursRequest.startDate) {
        setStartDate(moment(businessHoursRequest.startDate));
      }
    } else {
      setStartDate(moment());
      setEndDate(moment().add(1, 'year'));
      setTitle('Nuova programmazione');
    }
  }, [businessHoursRequest]);

  const dispatch = useDispatch();

  const handleStartDateChange = (value: any) => {
    let momentString = value?.toISOString();
    if (momentString) {
      setStartDate(moment(momentString));
    }
  };
  const handleEndDateChange = (value: any) => {
    let momentString = value?.toISOString();
    if (momentString) {
      setEndDate(moment(momentString));
    }
  };

  const handleClose = () => {
    dispatch(BusinessHourRequestActions.setBusinessHourRequest(undefined));
    dispatch(ModalsActions.closeModal());
  };

  const handleSave = () => {
    if (businessHoursRequest) {
      //********UPDATE  RANGE*/
      const sDate = moment(startDate).toISOString();
      const eDate = moment(endDate).toISOString();
      let request = { startDate: sDate, endDate: eDate };
      dispatch(
        BusinessHoursActions.updateBusinessHours(
          businessHoursRequest.uuid ?? '',
          request,
        ),
      );
      handleClose();
    } else {
      //**********POST NEW RANGE */
      const sDate = moment(startDate).toISOString();
      const eDate = moment(endDate).toISOString();
      let request = { startDate: sDate, endDate: eDate, isActive: true };
      dispatch(
        BusinessHoursActions.createBusinessHour(
          storeDetail?.uuid ?? '',
          request,
        ),
      );
      handleClose();
    }
  };

  const renderDatePicker = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
        <Box
          className={classes.fieldsBox}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="24px"
        >
          <DatePicker
            slotProps={{
              textField: {
                color: 'secondary',
                size: 'small',
                inputProps: {
                  style: {
                    padding: '12px 16px',
                  },
                },
              },
            }}
            value={startDate}
            onChange={(value: any) => handleStartDateChange(value)}
          />

          <DatePicker
            slotProps={{
              textField: {
                color: 'secondary',
                size: 'small',
                inputProps: {
                  style: {
                    padding: '12px 16px',
                  },
                },
              },
            }}
            value={endDate}
            onChange={(value: any) => handleEndDateChange(value)}
          />
        </Box>
      </LocalizationProvider>
    );
  };

  return (
    <Dialog
      open={showDateRangeModalSuccess}
      onClose={() => handleClose()}
      scroll="body"
      maxWidth={'sm'}
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <Box className={classes.content}>{renderDatePicker()}</Box>
      <Box className={classes.footerWrapper}>
        <DialogActionFooter
          onCancel={() => handleClose()}
          onConfirm={() => handleSave()}
        />
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      paddingTop: 8,
      paddingLeft: 16,
      paddingRight: 16,
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
    },
    fieldsBox: {
      width: '100%',
      paddingBottom: 24,
      paddingTop: 24,
      paddingLeft: 24,
      paddingRight: 24,
    },
    content: {
      borderRadius: 4,
      paddingBottom: 4,
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
  }),
);
