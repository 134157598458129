/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { HydratedStoreCalendarOutputCalendarRead } from "../nbp";
// @ts-ignore
import { HydratedStoreStoreOutputStoreRead } from "../nbp";
// @ts-ignore
import { HydratedStoreStoreOutputStoreUserHoursRead } from "../nbp";
// @ts-ignore
import { HydratedStoreStoreOutputStoreUserRead } from "../nbp";
// @ts-ignore
import { StoreStatusInputStoreWrite } from "../nbp";
// @ts-ignore
import { StoreStoreInputStoreWrite } from "../nbp";
// @ts-ignore
import { StoreStoreOutputStoreRead } from "../nbp";
// @ts-ignore
import { StoreStoreOutputStoreUserRead } from "../nbp";
/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Removes the Store resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteStoreItem", "id", id);
      const localVarPath = `/api/stores/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Archived Stores
     * @param {boolean} [all]
     * @param {string} [name]
     * @param {string} [city]
     * @param {string} [address]
     * @param {string} [region]
     * @param {string} [longitude]
     * @param {string} [latitude]
     * @param {string} [phoneNumber]
     * @param {string} [zipCode]
     * @param {string} [province]
     * @param {string} [storeType]
     * @param {string} [visibilityStartDate]
     * @param {string} [visibilityEndDate]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedStoresStoreCollection: async (
      all?: boolean,
      name?: string,
      city?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/stores/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (all !== undefined) {
        localVarQueryParameter["all"] = all;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (city !== undefined) {
        localVarQueryParameter["city"] = city;
      }

      if (address !== undefined) {
        localVarQueryParameter["address"] = address;
      }

      if (region !== undefined) {
        localVarQueryParameter["region"] = region;
      }

      if (longitude !== undefined) {
        localVarQueryParameter["longitude"] = longitude;
      }

      if (latitude !== undefined) {
        localVarQueryParameter["latitude"] = latitude;
      }

      if (phoneNumber !== undefined) {
        localVarQueryParameter["phoneNumber"] = phoneNumber;
      }

      if (zipCode !== undefined) {
        localVarQueryParameter["zipCode"] = zipCode;
      }

      if (province !== undefined) {
        localVarQueryParameter["province"] = province;
      }

      if (storeType !== undefined) {
        localVarQueryParameter["storeType"] = storeType;
      }

      if (visibilityStartDate !== undefined) {
        localVarQueryParameter["visibilityStartDate"] = visibilityStartDate;
      }

      if (visibilityEndDate !== undefined) {
        localVarQueryParameter["visibilityEndDate"] = visibilityEndDate;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves current user\'s Stores
     * @param {boolean} [all]
     * @param {string} [name]
     * @param {Array<string>} [names]
     * @param {Array<string>} [products]
     * @param {string} [city]
     * @param {string} [orderbyfield]
     * @param {string} [orderdirection]
     * @param {string} [address]
     * @param {string} [region]
     * @param {string} [longitude]
     * @param {string} [latitude]
     * @param {string} [phoneNumber]
     * @param {string} [zipCode]
     * @param {string} [province]
     * @param {string} [storeType]
     * @param {string} [visibilityStartDate]
     * @param {string} [visibilityEndDate]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [alsoArchived]
     * @param {Array<string>} [properties]
     * @param {string} [slug]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAssociatedStoresStoreCollection: async (
      all?: boolean,
      name?: string,
      names?: Array<string>,
      products?: Array<string>,
      city?: string,
      orderbyfield?: string,
      orderdirection?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      onlyArchived?: boolean,
      alsoArchived?: boolean,
      properties?: Array<string>,
      slug?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/current/stores`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (all !== undefined) {
        localVarQueryParameter["all"] = all;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (names) {
        localVarQueryParameter["names[]"] = names;
      }

      if (products) {
        localVarQueryParameter["products[]"] = products;
      }

      if (city !== undefined) {
        localVarQueryParameter["city"] = city;
      }

      if (orderbyfield !== undefined) {
        localVarQueryParameter["orderbyfield"] = orderbyfield;
      }

      if (orderdirection !== undefined) {
        localVarQueryParameter["orderdirection"] = orderdirection;
      }

      if (address !== undefined) {
        localVarQueryParameter["address"] = address;
      }

      if (region !== undefined) {
        localVarQueryParameter["region"] = region;
      }

      if (longitude !== undefined) {
        localVarQueryParameter["longitude"] = longitude;
      }

      if (latitude !== undefined) {
        localVarQueryParameter["latitude"] = latitude;
      }

      if (phoneNumber !== undefined) {
        localVarQueryParameter["phoneNumber"] = phoneNumber;
      }

      if (zipCode !== undefined) {
        localVarQueryParameter["zipCode"] = zipCode;
      }

      if (province !== undefined) {
        localVarQueryParameter["province"] = province;
      }

      if (storeType !== undefined) {
        localVarQueryParameter["storeType"] = storeType;
      }

      if (visibilityStartDate !== undefined) {
        localVarQueryParameter["visibilityStartDate"] = visibilityStartDate;
      }

      if (visibilityEndDate !== undefined) {
        localVarQueryParameter["visibilityEndDate"] = visibilityEndDate;
      }

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (alsoArchived !== undefined) {
        localVarQueryParameter["alsoArchived"] = alsoArchived;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (slug !== undefined) {
        localVarQueryParameter["slug"] = slug;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves current user\'s Stores with current week\'s hours
     * @param {boolean} [all]
     * @param {string} [name]
     * @param {Array<string>} [names]
     * @param {string} [city]
     * @param {string} [address]
     * @param {string} [region]
     * @param {string} [longitude]
     * @param {string} [latitude]
     * @param {string} [phoneNumber]
     * @param {string} [zipCode]
     * @param {string} [province]
     * @param {string} [storeType]
     * @param {string} [visibilityStartDate]
     * @param {string} [visibilityEndDate]
     * @param {boolean} [alsoArchived]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAssociatedStoresWithHoursStoreCollection: async (
      all?: boolean,
      name?: string,
      names?: Array<string>,
      city?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      alsoArchived?: boolean,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/current/stores/week`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (all !== undefined) {
        localVarQueryParameter["all"] = all;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (names) {
        localVarQueryParameter["names[]"] = names;
      }

      if (city !== undefined) {
        localVarQueryParameter["city"] = city;
      }

      if (address !== undefined) {
        localVarQueryParameter["address"] = address;
      }

      if (region !== undefined) {
        localVarQueryParameter["region"] = region;
      }

      if (longitude !== undefined) {
        localVarQueryParameter["longitude"] = longitude;
      }

      if (latitude !== undefined) {
        localVarQueryParameter["latitude"] = latitude;
      }

      if (phoneNumber !== undefined) {
        localVarQueryParameter["phoneNumber"] = phoneNumber;
      }

      if (zipCode !== undefined) {
        localVarQueryParameter["zipCode"] = zipCode;
      }

      if (province !== undefined) {
        localVarQueryParameter["province"] = province;
      }

      if (storeType !== undefined) {
        localVarQueryParameter["storeType"] = storeType;
      }

      if (visibilityStartDate !== undefined) {
        localVarQueryParameter["visibilityStartDate"] = visibilityStartDate;
      }

      if (visibilityEndDate !== undefined) {
        localVarQueryParameter["visibilityEndDate"] = visibilityEndDate;
      }

      if (alsoArchived !== undefined) {
        localVarQueryParameter["alsoArchived"] = alsoArchived;
      }

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves the collection of StoreOutput resources.
     * @param {boolean} [all] Show stores out of visibility
     * @param {string} [lat] Geographic filter - center latitude
     * @param {string} [lng] Geographic filter - center longitude
     * @param {number} [distance] Geographic filter - radius
     * @param {Array<string>} [products] Show stores associated with given product UUID
     * @param {string} [name] Show store with given name using LIKE
     * @param {Array<string>} [names] Show stores with exact names
     * @param {string} [city] Show stores with exact city name
     * @param {string} [address] Show stores with given address using LIKE
     * @param {string} [region] Show stores with given region using LIKE
     * @param {string} [longitude] Show store with exact longitude
     * @param {string} [latitude] Show store with exact latitude
     * @param {string} [phoneNumber] Show store with exact phoneNumber
     * @param {string} [zipCode] Show stores with exact CAP (ZIP code)
     * @param {string} [province] Show stores with given province using LIKE
     * @param {string} [storeType] Show stores with given storeType using LIKE
     * @param {string} [visibilityStartDate] Visibility filter - start date
     * @param {string} [visibilityEndDate] Visibility filter - end date
     * @param {boolean} [onlyArchived] Show only archived stores
     * @param {boolean} [alsoArchived] Show archived stores in addition to other conditions
     * @param {Array<string>} [properties] Show only given properties
     * @param {string} [slug] Show store with exact slug
     * @param {string} [orderbyfield]
     * @param {string} [orderdirection]
     * @param {Array<string>} [services] Show stores associated with given services\&#39; UUIDs
     * @param {string} [serviceName]
     * @param {string} [productNameForStoreService] Show stores associated with given service internalName
     * @param {string} [serviceContext] Show stores associated with given service context - Leave empty for default services
     * @param {string} [zipCodeForCity] Show stores in city corresponding to given zipcode
     * @param {string} [zipCodeList] Define the zipCodeList for zipCodeForCity. Default null.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllStoresStoreCollection: async (
      all?: boolean,
      lat?: string,
      lng?: string,
      distance?: number,
      products?: Array<string>,
      name?: string,
      names?: Array<string>,
      city?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      onlyArchived?: boolean,
      alsoArchived?: boolean,
      properties?: Array<string>,
      slug?: string,
      orderbyfield?: string,
      orderdirection?: string,
      services?: Array<string>,
      serviceName?: string,
      productNameForStoreService?: string,
      serviceContext?: string,
      zipCodeForCity?: string,
      zipCodeList?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/stores`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (all !== undefined) {
        localVarQueryParameter["all"] = all;
      }

      if (lat !== undefined) {
        localVarQueryParameter["lat"] = lat;
      }

      if (lng !== undefined) {
        localVarQueryParameter["lng"] = lng;
      }

      if (distance !== undefined) {
        localVarQueryParameter["distance"] = distance;
      }

      if (products) {
        localVarQueryParameter["products[]"] = products;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (names) {
        localVarQueryParameter["names[]"] = names;
      }

      if (city !== undefined) {
        localVarQueryParameter["city"] = city;
      }

      if (address !== undefined) {
        localVarQueryParameter["address"] = address;
      }

      if (region !== undefined) {
        localVarQueryParameter["region"] = region;
      }

      if (longitude !== undefined) {
        localVarQueryParameter["longitude"] = longitude;
      }

      if (latitude !== undefined) {
        localVarQueryParameter["latitude"] = latitude;
      }

      if (phoneNumber !== undefined) {
        localVarQueryParameter["phoneNumber"] = phoneNumber;
      }

      if (zipCode !== undefined) {
        localVarQueryParameter["zipCode"] = zipCode;
      }

      if (province !== undefined) {
        localVarQueryParameter["province"] = province;
      }

      if (storeType !== undefined) {
        localVarQueryParameter["storeType"] = storeType;
      }

      if (visibilityStartDate !== undefined) {
        localVarQueryParameter["visibilityStartDate"] = visibilityStartDate;
      }

      if (visibilityEndDate !== undefined) {
        localVarQueryParameter["visibilityEndDate"] = visibilityEndDate;
      }

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (alsoArchived !== undefined) {
        localVarQueryParameter["alsoArchived"] = alsoArchived;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (slug !== undefined) {
        localVarQueryParameter["slug"] = slug;
      }

      if (orderbyfield !== undefined) {
        localVarQueryParameter["orderbyfield"] = orderbyfield;
      }

      if (orderdirection !== undefined) {
        localVarQueryParameter["orderdirection"] = orderdirection;
      }

      if (services) {
        localVarQueryParameter["services[]"] = services;
      }

      if (serviceName !== undefined) {
        localVarQueryParameter["serviceName"] = serviceName;
      }

      if (productNameForStoreService !== undefined) {
        localVarQueryParameter["productNameForStoreService"] =
          productNameForStoreService;
      }

      if (serviceContext !== undefined) {
        localVarQueryParameter["serviceContext"] = serviceContext;
      }

      if (zipCodeForCity !== undefined) {
        localVarQueryParameter["zipCodeForCity"] = zipCodeForCity;
      }

      if (zipCodeList !== undefined) {
        localVarQueryParameter["zipCodeList"] = zipCodeList;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a StoreOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreUserReadStoreItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getStoreUserReadStoreItem", "id", id);
      const localVarPath = `/api/stores/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Stores by Service
     * @param {string} id
     * @param {boolean} [onlyArchived]
     * @param {boolean} [all]
     * @param {string} [defaultDuration]
     * @param {string} [defaultCapacity]
     * @param {string} [publicName]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoresForCurrentServiceStoreCollection: async (
      id: string,
      onlyArchived?: boolean,
      all?: boolean,
      defaultDuration?: string,
      defaultCapacity?: string,
      publicName?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getStoresForCurrentServiceStoreCollection", "id", id);
      const localVarPath = `/api/services/{id}/stores`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (all !== undefined) {
        localVarQueryParameter["all"] = all;
      }

      if (defaultDuration !== undefined) {
        localVarQueryParameter["defaultDuration"] = defaultDuration;
      }

      if (defaultCapacity !== undefined) {
        localVarQueryParameter["defaultCapacity"] = defaultCapacity;
      }

      if (publicName !== undefined) {
        localVarQueryParameter["publicName"] = publicName;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Week hours for Store
     * @param {string} id
     * @param {boolean} [onlyBusinessHours]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoresWeeklyHoursStoreCollection: async (
      id: string,
      onlyBusinessHours?: boolean,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getStoresWeeklyHoursStoreCollection", "id", id);
      const localVarPath = `/api/stores/{id}/week`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (onlyBusinessHours !== undefined) {
        localVarQueryParameter["onlyBusinessHours"] = onlyBusinessHours;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Changes status for a Store resource
     * @param {string} id
     * @param {StoreStatusInputStoreWrite} [storeStatusInputStoreWrite] The updated Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusStoreItem: async (
      id: string,
      storeStatusInputStoreWrite?: StoreStatusInputStoreWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchStatusStoreItem", "id", id);
      const localVarPath = `/api/stores/{id}/status`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeStatusInputStoreWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the Store resource.
     * @param {string} id
     * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The updated Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStoreItem: async (
      id: string,
      storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchStoreItem", "id", id);
      const localVarPath = `/api/stores/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeStoreInputStoreWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a Store resource.
     * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The new Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postStoresStoreCollection: async (
      storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/stores`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeStoreInputStoreWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Replaces the Store resource.
     * @param {string} id
     * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The updated Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStoreItem: async (
      id: string,
      storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putStoreItem", "id", id);
      const localVarPath = `/api/stores/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeStoreInputStoreWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Removes the Store resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteStoreItem(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStoreItem(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Archived Stores
     * @param {boolean} [all]
     * @param {string} [name]
     * @param {string} [city]
     * @param {string} [address]
     * @param {string} [region]
     * @param {string} [longitude]
     * @param {string} [latitude]
     * @param {string} [phoneNumber]
     * @param {string} [zipCode]
     * @param {string} [province]
     * @param {string} [storeType]
     * @param {string} [visibilityStartDate]
     * @param {string} [visibilityEndDate]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArchivedStoresStoreCollection(
      all?: boolean,
      name?: string,
      city?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreStoreOutputStoreRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllArchivedStoresStoreCollection(
          all,
          name,
          city,
          address,
          region,
          longitude,
          latitude,
          phoneNumber,
          zipCode,
          province,
          storeType,
          visibilityStartDate,
          visibilityEndDate,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves current user\'s Stores
     * @param {boolean} [all]
     * @param {string} [name]
     * @param {Array<string>} [names]
     * @param {Array<string>} [products]
     * @param {string} [city]
     * @param {string} [orderbyfield]
     * @param {string} [orderdirection]
     * @param {string} [address]
     * @param {string} [region]
     * @param {string} [longitude]
     * @param {string} [latitude]
     * @param {string} [phoneNumber]
     * @param {string} [zipCode]
     * @param {string} [province]
     * @param {string} [storeType]
     * @param {string} [visibilityStartDate]
     * @param {string} [visibilityEndDate]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [alsoArchived]
     * @param {Array<string>} [properties]
     * @param {string} [slug]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAssociatedStoresStoreCollection(
      all?: boolean,
      name?: string,
      names?: Array<string>,
      products?: Array<string>,
      city?: string,
      orderbyfield?: string,
      orderdirection?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      onlyArchived?: boolean,
      alsoArchived?: boolean,
      properties?: Array<string>,
      slug?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreStoreOutputStoreUserRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllAssociatedStoresStoreCollection(
          all,
          name,
          names,
          products,
          city,
          orderbyfield,
          orderdirection,
          address,
          region,
          longitude,
          latitude,
          phoneNumber,
          zipCode,
          province,
          storeType,
          visibilityStartDate,
          visibilityEndDate,
          onlyArchived,
          alsoArchived,
          properties,
          slug,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves current user\'s Stores with current week\'s hours
     * @param {boolean} [all]
     * @param {string} [name]
     * @param {Array<string>} [names]
     * @param {string} [city]
     * @param {string} [address]
     * @param {string} [region]
     * @param {string} [longitude]
     * @param {string} [latitude]
     * @param {string} [phoneNumber]
     * @param {string} [zipCode]
     * @param {string} [province]
     * @param {string} [storeType]
     * @param {string} [visibilityStartDate]
     * @param {string} [visibilityEndDate]
     * @param {boolean} [alsoArchived]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAssociatedStoresWithHoursStoreCollection(
      all?: boolean,
      name?: string,
      names?: Array<string>,
      city?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      alsoArchived?: boolean,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreStoreOutputStoreUserHoursRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllAssociatedStoresWithHoursStoreCollection(
          all,
          name,
          names,
          city,
          address,
          region,
          longitude,
          latitude,
          phoneNumber,
          zipCode,
          province,
          storeType,
          visibilityStartDate,
          visibilityEndDate,
          alsoArchived,
          onlyArchived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves the collection of StoreOutput resources.
     * @param {boolean} [all] Show stores out of visibility
     * @param {string} [lat] Geographic filter - center latitude
     * @param {string} [lng] Geographic filter - center longitude
     * @param {number} [distance] Geographic filter - radius
     * @param {Array<string>} [products] Show stores associated with given product UUID
     * @param {string} [name] Show store with given name using LIKE
     * @param {Array<string>} [names] Show stores with exact names
     * @param {string} [city] Show stores with exact city name
     * @param {string} [address] Show stores with given address using LIKE
     * @param {string} [region] Show stores with given region using LIKE
     * @param {string} [longitude] Show store with exact longitude
     * @param {string} [latitude] Show store with exact latitude
     * @param {string} [phoneNumber] Show store with exact phoneNumber
     * @param {string} [zipCode] Show stores with exact CAP (ZIP code)
     * @param {string} [province] Show stores with given province using LIKE
     * @param {string} [storeType] Show stores with given storeType using LIKE
     * @param {string} [visibilityStartDate] Visibility filter - start date
     * @param {string} [visibilityEndDate] Visibility filter - end date
     * @param {boolean} [onlyArchived] Show only archived stores
     * @param {boolean} [alsoArchived] Show archived stores in addition to other conditions
     * @param {Array<string>} [properties] Show only given properties
     * @param {string} [slug] Show store with exact slug
     * @param {string} [orderbyfield]
     * @param {string} [orderdirection]
     * @param {Array<string>} [services] Show stores associated with given services\&#39; UUIDs
     * @param {string} [serviceName]
     * @param {string} [productNameForStoreService] Show stores associated with given service internalName
     * @param {string} [serviceContext] Show stores associated with given service context - Leave empty for default services
     * @param {string} [zipCodeForCity] Show stores in city corresponding to given zipcode
     * @param {string} [zipCodeList] Define the zipCodeList for zipCodeForCity. Default null.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllStoresStoreCollection(
      all?: boolean,
      lat?: string,
      lng?: string,
      distance?: number,
      products?: Array<string>,
      name?: string,
      names?: Array<string>,
      city?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      onlyArchived?: boolean,
      alsoArchived?: boolean,
      properties?: Array<string>,
      slug?: string,
      orderbyfield?: string,
      orderdirection?: string,
      services?: Array<string>,
      serviceName?: string,
      productNameForStoreService?: string,
      serviceContext?: string,
      zipCodeForCity?: string,
      zipCodeList?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreStoreOutputStoreRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllStoresStoreCollection(
          all,
          lat,
          lng,
          distance,
          products,
          name,
          names,
          city,
          address,
          region,
          longitude,
          latitude,
          phoneNumber,
          zipCode,
          province,
          storeType,
          visibilityStartDate,
          visibilityEndDate,
          onlyArchived,
          alsoArchived,
          properties,
          slug,
          orderbyfield,
          orderdirection,
          services,
          serviceName,
          productNameForStoreService,
          serviceContext,
          zipCodeForCity,
          zipCodeList,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a StoreOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStoreUserReadStoreItem(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreStoreOutputStoreUserRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStoreUserReadStoreItem(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Stores by Service
     * @param {string} id
     * @param {boolean} [onlyArchived]
     * @param {boolean} [all]
     * @param {string} [defaultDuration]
     * @param {string} [defaultCapacity]
     * @param {string} [publicName]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStoresForCurrentServiceStoreCollection(
      id: string,
      onlyArchived?: boolean,
      all?: boolean,
      defaultDuration?: string,
      defaultCapacity?: string,
      publicName?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreStoreOutputStoreRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStoresForCurrentServiceStoreCollection(
          id,
          onlyArchived,
          all,
          defaultDuration,
          defaultCapacity,
          publicName,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Week hours for Store
     * @param {string} id
     * @param {boolean} [onlyBusinessHours]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStoresWeeklyHoursStoreCollection(
      id: string,
      onlyBusinessHours?: boolean,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreCalendarOutputCalendarRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStoresWeeklyHoursStoreCollection(
          id,
          onlyBusinessHours,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Changes status for a Store resource
     * @param {string} id
     * @param {StoreStatusInputStoreWrite} [storeStatusInputStoreWrite] The updated Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchStatusStoreItem(
      id: string,
      storeStatusInputStoreWrite?: StoreStatusInputStoreWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreStoreOutputStoreRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchStatusStoreItem(
          id,
          storeStatusInputStoreWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the Store resource.
     * @param {string} id
     * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The updated Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchStoreItem(
      id: string,
      storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreStoreOutputStoreRead>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchStoreItem(
        id,
        storeStoreInputStoreWrite,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a Store resource.
     * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The new Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postStoresStoreCollection(
      storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreStoreOutputStoreRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postStoresStoreCollection(
          storeStoreInputStoreWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Replaces the Store resource.
     * @param {string} id
     * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The updated Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putStoreItem(
      id: string,
      storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreStoreOutputStoreRead>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putStoreItem(
        id,
        storeStoreInputStoreWrite,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StoreApiFp(configuration);
  return {
    /**
     *
     * @summary Removes the Store resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreItem(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteStoreItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Archived Stores
     * @param {boolean} [all]
     * @param {string} [name]
     * @param {string} [city]
     * @param {string} [address]
     * @param {string} [region]
     * @param {string} [longitude]
     * @param {string} [latitude]
     * @param {string} [phoneNumber]
     * @param {string} [zipCode]
     * @param {string} [province]
     * @param {string} [storeType]
     * @param {string} [visibilityStartDate]
     * @param {string} [visibilityEndDate]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedStoresStoreCollection(
      all?: boolean,
      name?: string,
      city?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreStoreOutputStoreRead> {
      return localVarFp
        .getAllArchivedStoresStoreCollection(
          all,
          name,
          city,
          address,
          region,
          longitude,
          latitude,
          phoneNumber,
          zipCode,
          province,
          storeType,
          visibilityStartDate,
          visibilityEndDate,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves current user\'s Stores
     * @param {boolean} [all]
     * @param {string} [name]
     * @param {Array<string>} [names]
     * @param {Array<string>} [products]
     * @param {string} [city]
     * @param {string} [orderbyfield]
     * @param {string} [orderdirection]
     * @param {string} [address]
     * @param {string} [region]
     * @param {string} [longitude]
     * @param {string} [latitude]
     * @param {string} [phoneNumber]
     * @param {string} [zipCode]
     * @param {string} [province]
     * @param {string} [storeType]
     * @param {string} [visibilityStartDate]
     * @param {string} [visibilityEndDate]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [alsoArchived]
     * @param {Array<string>} [properties]
     * @param {string} [slug]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAssociatedStoresStoreCollection(
      all?: boolean,
      name?: string,
      names?: Array<string>,
      products?: Array<string>,
      city?: string,
      orderbyfield?: string,
      orderdirection?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      onlyArchived?: boolean,
      alsoArchived?: boolean,
      properties?: Array<string>,
      slug?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreStoreOutputStoreUserRead> {
      return localVarFp
        .getAllAssociatedStoresStoreCollection(
          all,
          name,
          names,
          products,
          city,
          orderbyfield,
          orderdirection,
          address,
          region,
          longitude,
          latitude,
          phoneNumber,
          zipCode,
          province,
          storeType,
          visibilityStartDate,
          visibilityEndDate,
          onlyArchived,
          alsoArchived,
          properties,
          slug,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves current user\'s Stores with current week\'s hours
     * @param {boolean} [all]
     * @param {string} [name]
     * @param {Array<string>} [names]
     * @param {string} [city]
     * @param {string} [address]
     * @param {string} [region]
     * @param {string} [longitude]
     * @param {string} [latitude]
     * @param {string} [phoneNumber]
     * @param {string} [zipCode]
     * @param {string} [province]
     * @param {string} [storeType]
     * @param {string} [visibilityStartDate]
     * @param {string} [visibilityEndDate]
     * @param {boolean} [alsoArchived]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAssociatedStoresWithHoursStoreCollection(
      all?: boolean,
      name?: string,
      names?: Array<string>,
      city?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      alsoArchived?: boolean,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreStoreOutputStoreUserHoursRead> {
      return localVarFp
        .getAllAssociatedStoresWithHoursStoreCollection(
          all,
          name,
          names,
          city,
          address,
          region,
          longitude,
          latitude,
          phoneNumber,
          zipCode,
          province,
          storeType,
          visibilityStartDate,
          visibilityEndDate,
          alsoArchived,
          onlyArchived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves the collection of StoreOutput resources.
     * @param {boolean} [all] Show stores out of visibility
     * @param {string} [lat] Geographic filter - center latitude
     * @param {string} [lng] Geographic filter - center longitude
     * @param {number} [distance] Geographic filter - radius
     * @param {Array<string>} [products] Show stores associated with given product UUID
     * @param {string} [name] Show store with given name using LIKE
     * @param {Array<string>} [names] Show stores with exact names
     * @param {string} [city] Show stores with exact city name
     * @param {string} [address] Show stores with given address using LIKE
     * @param {string} [region] Show stores with given region using LIKE
     * @param {string} [longitude] Show store with exact longitude
     * @param {string} [latitude] Show store with exact latitude
     * @param {string} [phoneNumber] Show store with exact phoneNumber
     * @param {string} [zipCode] Show stores with exact CAP (ZIP code)
     * @param {string} [province] Show stores with given province using LIKE
     * @param {string} [storeType] Show stores with given storeType using LIKE
     * @param {string} [visibilityStartDate] Visibility filter - start date
     * @param {string} [visibilityEndDate] Visibility filter - end date
     * @param {boolean} [onlyArchived] Show only archived stores
     * @param {boolean} [alsoArchived] Show archived stores in addition to other conditions
     * @param {Array<string>} [properties] Show only given properties
     * @param {string} [slug] Show store with exact slug
     * @param {string} [orderbyfield]
     * @param {string} [orderdirection]
     * @param {Array<string>} [services] Show stores associated with given services\&#39; UUIDs
     * @param {string} [serviceName]
     * @param {string} [productNameForStoreService] Show stores associated with given service internalName
     * @param {string} [serviceContext] Show stores associated with given service context - Leave empty for default services
     * @param {string} [zipCodeForCity] Show stores in city corresponding to given zipcode
     * @param {string} [zipCodeList] Define the zipCodeList for zipCodeForCity. Default null.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllStoresStoreCollection(
      all?: boolean,
      lat?: string,
      lng?: string,
      distance?: number,
      products?: Array<string>,
      name?: string,
      names?: Array<string>,
      city?: string,
      address?: string,
      region?: string,
      longitude?: string,
      latitude?: string,
      phoneNumber?: string,
      zipCode?: string,
      province?: string,
      storeType?: string,
      visibilityStartDate?: string,
      visibilityEndDate?: string,
      onlyArchived?: boolean,
      alsoArchived?: boolean,
      properties?: Array<string>,
      slug?: string,
      orderbyfield?: string,
      orderdirection?: string,
      services?: Array<string>,
      serviceName?: string,
      productNameForStoreService?: string,
      serviceContext?: string,
      zipCodeForCity?: string,
      zipCodeList?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreStoreOutputStoreRead> {
      return localVarFp
        .getAllStoresStoreCollection(
          all,
          lat,
          lng,
          distance,
          products,
          name,
          names,
          city,
          address,
          region,
          longitude,
          latitude,
          phoneNumber,
          zipCode,
          province,
          storeType,
          visibilityStartDate,
          visibilityEndDate,
          onlyArchived,
          alsoArchived,
          properties,
          slug,
          orderbyfield,
          orderdirection,
          services,
          serviceName,
          productNameForStoreService,
          serviceContext,
          zipCodeForCity,
          zipCodeList,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a StoreOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreUserReadStoreItem(
      id: string,
      options?: any,
    ): AxiosPromise<StoreStoreOutputStoreUserRead> {
      return localVarFp
        .getStoreUserReadStoreItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Stores by Service
     * @param {string} id
     * @param {boolean} [onlyArchived]
     * @param {boolean} [all]
     * @param {string} [defaultDuration]
     * @param {string} [defaultCapacity]
     * @param {string} [publicName]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoresForCurrentServiceStoreCollection(
      id: string,
      onlyArchived?: boolean,
      all?: boolean,
      defaultDuration?: string,
      defaultCapacity?: string,
      publicName?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreStoreOutputStoreRead> {
      return localVarFp
        .getStoresForCurrentServiceStoreCollection(
          id,
          onlyArchived,
          all,
          defaultDuration,
          defaultCapacity,
          publicName,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Week hours for Store
     * @param {string} id
     * @param {boolean} [onlyBusinessHours]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoresWeeklyHoursStoreCollection(
      id: string,
      onlyBusinessHours?: boolean,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreCalendarOutputCalendarRead> {
      return localVarFp
        .getStoresWeeklyHoursStoreCollection(
          id,
          onlyBusinessHours,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Changes status for a Store resource
     * @param {string} id
     * @param {StoreStatusInputStoreWrite} [storeStatusInputStoreWrite] The updated Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusStoreItem(
      id: string,
      storeStatusInputStoreWrite?: StoreStatusInputStoreWrite,
      options?: any,
    ): AxiosPromise<StoreStoreOutputStoreRead> {
      return localVarFp
        .patchStatusStoreItem(id, storeStatusInputStoreWrite, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the Store resource.
     * @param {string} id
     * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The updated Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStoreItem(
      id: string,
      storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
      options?: any,
    ): AxiosPromise<StoreStoreOutputStoreRead> {
      return localVarFp
        .patchStoreItem(id, storeStoreInputStoreWrite, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a Store resource.
     * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The new Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postStoresStoreCollection(
      storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
      options?: any,
    ): AxiosPromise<StoreStoreOutputStoreRead> {
      return localVarFp
        .postStoresStoreCollection(storeStoreInputStoreWrite, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Replaces the Store resource.
     * @param {string} id
     * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The updated Store resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStoreItem(
      id: string,
      storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
      options?: any,
    ): AxiosPromise<StoreStoreOutputStoreRead> {
      return localVarFp
        .putStoreItem(id, storeStoreInputStoreWrite, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
  /**
   *
   * @summary Removes the Store resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public deleteStoreItem(id: string, options?: any) {
    return StoreApiFp(this.configuration)
      .deleteStoreItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Archived Stores
   * @param {boolean} [all]
   * @param {string} [name]
   * @param {string} [city]
   * @param {string} [address]
   * @param {string} [region]
   * @param {string} [longitude]
   * @param {string} [latitude]
   * @param {string} [phoneNumber]
   * @param {string} [zipCode]
   * @param {string} [province]
   * @param {string} [storeType]
   * @param {string} [visibilityStartDate]
   * @param {string} [visibilityEndDate]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public getAllArchivedStoresStoreCollection(
    all?: boolean,
    name?: string,
    city?: string,
    address?: string,
    region?: string,
    longitude?: string,
    latitude?: string,
    phoneNumber?: string,
    zipCode?: string,
    province?: string,
    storeType?: string,
    visibilityStartDate?: string,
    visibilityEndDate?: string,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreApiFp(this.configuration)
      .getAllArchivedStoresStoreCollection(
        all,
        name,
        city,
        address,
        region,
        longitude,
        latitude,
        phoneNumber,
        zipCode,
        province,
        storeType,
        visibilityStartDate,
        visibilityEndDate,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves current user\'s Stores
   * @param {boolean} [all]
   * @param {string} [name]
   * @param {Array<string>} [names]
   * @param {Array<string>} [products]
   * @param {string} [city]
   * @param {string} [orderbyfield]
   * @param {string} [orderdirection]
   * @param {string} [address]
   * @param {string} [region]
   * @param {string} [longitude]
   * @param {string} [latitude]
   * @param {string} [phoneNumber]
   * @param {string} [zipCode]
   * @param {string} [province]
   * @param {string} [storeType]
   * @param {string} [visibilityStartDate]
   * @param {string} [visibilityEndDate]
   * @param {boolean} [onlyArchived]
   * @param {boolean} [alsoArchived]
   * @param {Array<string>} [properties]
   * @param {string} [slug]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public getAllAssociatedStoresStoreCollection(
    all?: boolean,
    name?: string,
    names?: Array<string>,
    products?: Array<string>,
    city?: string,
    orderbyfield?: string,
    orderdirection?: string,
    address?: string,
    region?: string,
    longitude?: string,
    latitude?: string,
    phoneNumber?: string,
    zipCode?: string,
    province?: string,
    storeType?: string,
    visibilityStartDate?: string,
    visibilityEndDate?: string,
    onlyArchived?: boolean,
    alsoArchived?: boolean,
    properties?: Array<string>,
    slug?: string,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreApiFp(this.configuration)
      .getAllAssociatedStoresStoreCollection(
        all,
        name,
        names,
        products,
        city,
        orderbyfield,
        orderdirection,
        address,
        region,
        longitude,
        latitude,
        phoneNumber,
        zipCode,
        province,
        storeType,
        visibilityStartDate,
        visibilityEndDate,
        onlyArchived,
        alsoArchived,
        properties,
        slug,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves current user\'s Stores with current week\'s hours
   * @param {boolean} [all]
   * @param {string} [name]
   * @param {Array<string>} [names]
   * @param {string} [city]
   * @param {string} [address]
   * @param {string} [region]
   * @param {string} [longitude]
   * @param {string} [latitude]
   * @param {string} [phoneNumber]
   * @param {string} [zipCode]
   * @param {string} [province]
   * @param {string} [storeType]
   * @param {string} [visibilityStartDate]
   * @param {string} [visibilityEndDate]
   * @param {boolean} [alsoArchived]
   * @param {boolean} [onlyArchived]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public getAllAssociatedStoresWithHoursStoreCollection(
    all?: boolean,
    name?: string,
    names?: Array<string>,
    city?: string,
    address?: string,
    region?: string,
    longitude?: string,
    latitude?: string,
    phoneNumber?: string,
    zipCode?: string,
    province?: string,
    storeType?: string,
    visibilityStartDate?: string,
    visibilityEndDate?: string,
    alsoArchived?: boolean,
    onlyArchived?: boolean,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreApiFp(this.configuration)
      .getAllAssociatedStoresWithHoursStoreCollection(
        all,
        name,
        names,
        city,
        address,
        region,
        longitude,
        latitude,
        phoneNumber,
        zipCode,
        province,
        storeType,
        visibilityStartDate,
        visibilityEndDate,
        alsoArchived,
        onlyArchived,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves the collection of StoreOutput resources.
   * @param {boolean} [all] Show stores out of visibility
   * @param {string} [lat] Geographic filter - center latitude
   * @param {string} [lng] Geographic filter - center longitude
   * @param {number} [distance] Geographic filter - radius
   * @param {Array<string>} [products] Show stores associated with given product UUID
   * @param {string} [name] Show store with given name using LIKE
   * @param {Array<string>} [names] Show stores with exact names
   * @param {string} [city] Show stores with exact city name
   * @param {string} [address] Show stores with given address using LIKE
   * @param {string} [region] Show stores with given region using LIKE
   * @param {string} [longitude] Show store with exact longitude
   * @param {string} [latitude] Show store with exact latitude
   * @param {string} [phoneNumber] Show store with exact phoneNumber
   * @param {string} [zipCode] Show stores with exact CAP (ZIP code)
   * @param {string} [province] Show stores with given province using LIKE
   * @param {string} [storeType] Show stores with given storeType using LIKE
   * @param {string} [visibilityStartDate] Visibility filter - start date
   * @param {string} [visibilityEndDate] Visibility filter - end date
   * @param {boolean} [onlyArchived] Show only archived stores
   * @param {boolean} [alsoArchived] Show archived stores in addition to other conditions
   * @param {Array<string>} [properties] Show only given properties
   * @param {string} [slug] Show store with exact slug
   * @param {string} [orderbyfield]
   * @param {string} [orderdirection]
   * @param {Array<string>} [services] Show stores associated with given services\&#39; UUIDs
   * @param {string} [serviceName]
   * @param {string} [productNameForStoreService] Show stores associated with given service internalName
   * @param {string} [serviceContext] Show stores associated with given service context - Leave empty for default services
   * @param {string} [zipCodeForCity] Show stores in city corresponding to given zipcode
   * @param {string} [zipCodeList] Define the zipCodeList for zipCodeForCity. Default null.
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public getAllStoresStoreCollection(
    all?: boolean,
    lat?: string,
    lng?: string,
    distance?: number,
    products?: Array<string>,
    name?: string,
    names?: Array<string>,
    city?: string,
    address?: string,
    region?: string,
    longitude?: string,
    latitude?: string,
    phoneNumber?: string,
    zipCode?: string,
    province?: string,
    storeType?: string,
    visibilityStartDate?: string,
    visibilityEndDate?: string,
    onlyArchived?: boolean,
    alsoArchived?: boolean,
    properties?: Array<string>,
    slug?: string,
    orderbyfield?: string,
    orderdirection?: string,
    services?: Array<string>,
    serviceName?: string,
    productNameForStoreService?: string,
    serviceContext?: string,
    zipCodeForCity?: string,
    zipCodeList?: string,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreApiFp(this.configuration)
      .getAllStoresStoreCollection(
        all,
        lat,
        lng,
        distance,
        products,
        name,
        names,
        city,
        address,
        region,
        longitude,
        latitude,
        phoneNumber,
        zipCode,
        province,
        storeType,
        visibilityStartDate,
        visibilityEndDate,
        onlyArchived,
        alsoArchived,
        properties,
        slug,
        orderbyfield,
        orderdirection,
        services,
        serviceName,
        productNameForStoreService,
        serviceContext,
        zipCodeForCity,
        zipCodeList,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a StoreOutput resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public getStoreUserReadStoreItem(id: string, options?: any) {
    return StoreApiFp(this.configuration)
      .getStoreUserReadStoreItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Stores by Service
   * @param {string} id
   * @param {boolean} [onlyArchived]
   * @param {boolean} [all]
   * @param {string} [defaultDuration]
   * @param {string} [defaultCapacity]
   * @param {string} [publicName]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public getStoresForCurrentServiceStoreCollection(
    id: string,
    onlyArchived?: boolean,
    all?: boolean,
    defaultDuration?: string,
    defaultCapacity?: string,
    publicName?: string,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreApiFp(this.configuration)
      .getStoresForCurrentServiceStoreCollection(
        id,
        onlyArchived,
        all,
        defaultDuration,
        defaultCapacity,
        publicName,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Week hours for Store
   * @param {string} id
   * @param {boolean} [onlyBusinessHours]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public getStoresWeeklyHoursStoreCollection(
    id: string,
    onlyBusinessHours?: boolean,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreApiFp(this.configuration)
      .getStoresWeeklyHoursStoreCollection(
        id,
        onlyBusinessHours,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Changes status for a Store resource
   * @param {string} id
   * @param {StoreStatusInputStoreWrite} [storeStatusInputStoreWrite] The updated Store resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public patchStatusStoreItem(
    id: string,
    storeStatusInputStoreWrite?: StoreStatusInputStoreWrite,
    options?: any,
  ) {
    return StoreApiFp(this.configuration)
      .patchStatusStoreItem(id, storeStatusInputStoreWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the Store resource.
   * @param {string} id
   * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The updated Store resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public patchStoreItem(
    id: string,
    storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
    options?: any,
  ) {
    return StoreApiFp(this.configuration)
      .patchStoreItem(id, storeStoreInputStoreWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a Store resource.
   * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The new Store resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public postStoresStoreCollection(
    storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
    options?: any,
  ) {
    return StoreApiFp(this.configuration)
      .postStoresStoreCollection(storeStoreInputStoreWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Replaces the Store resource.
   * @param {string} id
   * @param {StoreStoreInputStoreWrite} [storeStoreInputStoreWrite] The updated Store resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreApi
   */
  public putStoreItem(
    id: string,
    storeStoreInputStoreWrite?: StoreStoreInputStoreWrite,
    options?: any,
  ) {
    return StoreApiFp(this.configuration)
      .putStoreItem(id, storeStoreInputStoreWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
