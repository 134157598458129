import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { EditIcon } from '../../icons/EditIcon';
import UserStoresEditModal from './UserStoresEditModal';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';

export default function UserProfileStores() {
  const classes = useStyles();
  const [openEditStoresModal, setOpenEditStoresModal] = React.useState(false);
  const userDetail = useSelector(
    (state: RootState) => state.UsersReducer.userDetail,
  );
  const userStores = userDetail
    ? userDetail.stores
      ? userDetail.stores
      : []
    : [];
  return (
    <Grid className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.title}>Store in gestione</Typography>
        <IconButton onClick={() => setOpenEditStoresModal(true)}>
          <EditIcon color="#00D1D2" />
        </IconButton>
      </Box>
      {userStores.map((item, i) => (
        <Box
          key={i}
          display="flex"
          className={classes.fieldBox}
          alignItems="center"
        >
          <Typography className={classes.valueLabel}>{item.name}</Typography>
        </Box>
      ))}
      <UserStoresEditModal
        isVisible={openEditStoresModal}
        onClose={() => setOpenEditStoresModal(false)}
      />
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FFFFFF',
      marginTop: 32,
      marginLeft: 25,
    },
    title: {
      fontSize: '18px !important',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px !important',
      color: '#34303D !important',
      minWidth: '200px',
    },
    fieldBox: {
      marginTop: 16,
    },
    footerBox: {
      marginTop: 40,
    },
    valueLabel: {
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '14px !important',
      color: '#34303D !important',
    },
  }),
);
