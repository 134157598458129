/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { HydratedServiceServiceOutputServiceRead } from "../nbp";
// @ts-ignore
import { ServiceServiceInputServiceWrite } from "../nbp";
// @ts-ignore
import { ServiceServiceOutputServiceRead } from "../nbp";
// @ts-ignore
import { ServiceStatusInputServiceWrite } from "../nbp";
/**
 * ServiceApi - axios parameter creator
 * @export
 */
export const ServiceApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Removes the Service resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteServiceItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteServiceItem", "id", id);
      const localVarPath = `/api/services/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Archived Services
     * @param {string} [defaultDuration]
     * @param {string} [defaultCapacity]
     * @param {string} [publicName]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedServicesServiceCollection: async (
      defaultDuration?: string,
      defaultCapacity?: string,
      publicName?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/services/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (defaultDuration !== undefined) {
        localVarQueryParameter["defaultDuration"] = defaultDuration;
      }

      if (defaultCapacity !== undefined) {
        localVarQueryParameter["defaultCapacity"] = defaultCapacity;
      }

      if (publicName !== undefined) {
        localVarQueryParameter["publicName"] = publicName;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Archived Services
     * @param {number} [defaultDuration]
     * @param {number} [defaultCapacity]
     * @param {string} [publicName]
     * @param {string} [internalName]
     * @param {Array<string>} [productUuids]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceCollection: async (
      defaultDuration?: number,
      defaultCapacity?: number,
      publicName?: string,
      internalName?: string,
      productUuids?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/services`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (defaultDuration !== undefined) {
        localVarQueryParameter["defaultDuration"] = defaultDuration;
      }

      if (defaultCapacity !== undefined) {
        localVarQueryParameter["defaultCapacity"] = defaultCapacity;
      }

      if (publicName !== undefined) {
        localVarQueryParameter["publicName"] = publicName;
      }

      if (internalName !== undefined) {
        localVarQueryParameter["internalName"] = internalName;
      }

      if (productUuids) {
        localVarQueryParameter["productUuids[]"] = productUuids;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a ServiceOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getServiceItem", "id", id);
      const localVarPath = `/api/services/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Services by Store
     * @param {string} id
     * @param {string} [defaultDuration]
     * @param {string} [defaultCapacity]
     * @param {string} [publicName]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicesForCurrentStoreServiceCollection: async (
      id: string,
      defaultDuration?: string,
      defaultCapacity?: string,
      publicName?: string,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "getServicesForCurrentStoreServiceCollection",
        "id",
        id,
      );
      const localVarPath = `/api/stores/{id}/services`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (defaultDuration !== undefined) {
        localVarQueryParameter["defaultDuration"] = defaultDuration;
      }

      if (defaultCapacity !== undefined) {
        localVarQueryParameter["defaultCapacity"] = defaultCapacity;
      }

      if (publicName !== undefined) {
        localVarQueryParameter["publicName"] = publicName;
      }

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the Service resource.
     * @param {string} id
     * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchServiceItem: async (
      id: string,
      serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchServiceItem", "id", id);
      const localVarPath = `/api/services/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceServiceInputServiceWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Changes status for a Service resource
     * @param {string} uuid
     * @param {ServiceStatusInputServiceWrite} [serviceStatusInputServiceWrite] The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusServiceItem: async (
      uuid: string,
      serviceStatusInputServiceWrite?: ServiceStatusInputServiceWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("patchStatusServiceItem", "uuid", uuid);
      const localVarPath = `/api/services/{uuid}/status`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceStatusInputServiceWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a Service resource.
     * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The new Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postServiceServiceCollection: async (
      serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/services`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceServiceInputServiceWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Replaces the Service resource.
     * @param {string} id
     * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putServiceItem: async (
      id: string,
      serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putServiceItem", "id", id);
      const localVarPath = `/api/services/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceServiceInputServiceWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ServiceApi - functional programming interface
 * @export
 */
export const ServiceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ServiceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Removes the Service resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteServiceItem(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteServiceItem(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Archived Services
     * @param {string} [defaultDuration]
     * @param {string} [defaultCapacity]
     * @param {string} [publicName]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArchivedServicesServiceCollection(
      defaultDuration?: string,
      defaultCapacity?: string,
      publicName?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedServiceServiceOutputServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllArchivedServicesServiceCollection(
          defaultDuration,
          defaultCapacity,
          publicName,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Archived Services
     * @param {number} [defaultDuration]
     * @param {number} [defaultCapacity]
     * @param {string} [publicName]
     * @param {string} [internalName]
     * @param {Array<string>} [productUuids]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceCollection(
      defaultDuration?: number,
      defaultCapacity?: number,
      publicName?: string,
      internalName?: string,
      productUuids?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedServiceServiceOutputServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getServiceCollection(
          defaultDuration,
          defaultCapacity,
          publicName,
          internalName,
          productUuids,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a ServiceOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceItem(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ServiceServiceOutputServiceRead>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceItem(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Services by Store
     * @param {string} id
     * @param {string} [defaultDuration]
     * @param {string} [defaultCapacity]
     * @param {string} [publicName]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServicesForCurrentStoreServiceCollection(
      id: string,
      defaultDuration?: string,
      defaultCapacity?: string,
      publicName?: string,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedServiceServiceOutputServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getServicesForCurrentStoreServiceCollection(
          id,
          defaultDuration,
          defaultCapacity,
          publicName,
          onlyArchived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the Service resource.
     * @param {string} id
     * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchServiceItem(
      id: string,
      serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ServiceServiceOutputServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchServiceItem(
          id,
          serviceServiceInputServiceWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Changes status for a Service resource
     * @param {string} uuid
     * @param {ServiceStatusInputServiceWrite} [serviceStatusInputServiceWrite] The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchStatusServiceItem(
      uuid: string,
      serviceStatusInputServiceWrite?: ServiceStatusInputServiceWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ServiceServiceOutputServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchStatusServiceItem(
          uuid,
          serviceStatusInputServiceWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a Service resource.
     * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The new Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postServiceServiceCollection(
      serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ServiceServiceOutputServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postServiceServiceCollection(
          serviceServiceInputServiceWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Replaces the Service resource.
     * @param {string} id
     * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putServiceItem(
      id: string,
      serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ServiceServiceOutputServiceRead>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putServiceItem(
        id,
        serviceServiceInputServiceWrite,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ServiceApi - factory interface
 * @export
 */
export const ServiceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ServiceApiFp(configuration);
  return {
    /**
     *
     * @summary Removes the Service resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteServiceItem(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteServiceItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Archived Services
     * @param {string} [defaultDuration]
     * @param {string} [defaultCapacity]
     * @param {string} [publicName]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedServicesServiceCollection(
      defaultDuration?: string,
      defaultCapacity?: string,
      publicName?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedServiceServiceOutputServiceRead> {
      return localVarFp
        .getAllArchivedServicesServiceCollection(
          defaultDuration,
          defaultCapacity,
          publicName,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Archived Services
     * @param {number} [defaultDuration]
     * @param {number} [defaultCapacity]
     * @param {string} [publicName]
     * @param {string} [internalName]
     * @param {Array<string>} [productUuids]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceCollection(
      defaultDuration?: number,
      defaultCapacity?: number,
      publicName?: string,
      internalName?: string,
      productUuids?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedServiceServiceOutputServiceRead> {
      return localVarFp
        .getServiceCollection(
          defaultDuration,
          defaultCapacity,
          publicName,
          internalName,
          productUuids,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a ServiceOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceItem(
      id: string,
      options?: any,
    ): AxiosPromise<ServiceServiceOutputServiceRead> {
      return localVarFp
        .getServiceItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Services by Store
     * @param {string} id
     * @param {string} [defaultDuration]
     * @param {string} [defaultCapacity]
     * @param {string} [publicName]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicesForCurrentStoreServiceCollection(
      id: string,
      defaultDuration?: string,
      defaultCapacity?: string,
      publicName?: string,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedServiceServiceOutputServiceRead> {
      return localVarFp
        .getServicesForCurrentStoreServiceCollection(
          id,
          defaultDuration,
          defaultCapacity,
          publicName,
          onlyArchived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the Service resource.
     * @param {string} id
     * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchServiceItem(
      id: string,
      serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
      options?: any,
    ): AxiosPromise<ServiceServiceOutputServiceRead> {
      return localVarFp
        .patchServiceItem(id, serviceServiceInputServiceWrite, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Changes status for a Service resource
     * @param {string} uuid
     * @param {ServiceStatusInputServiceWrite} [serviceStatusInputServiceWrite] The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusServiceItem(
      uuid: string,
      serviceStatusInputServiceWrite?: ServiceStatusInputServiceWrite,
      options?: any,
    ): AxiosPromise<ServiceServiceOutputServiceRead> {
      return localVarFp
        .patchStatusServiceItem(uuid, serviceStatusInputServiceWrite, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a Service resource.
     * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The new Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postServiceServiceCollection(
      serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
      options?: any,
    ): AxiosPromise<ServiceServiceOutputServiceRead> {
      return localVarFp
        .postServiceServiceCollection(serviceServiceInputServiceWrite, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Replaces the Service resource.
     * @param {string} id
     * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The updated Service resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putServiceItem(
      id: string,
      serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
      options?: any,
    ): AxiosPromise<ServiceServiceOutputServiceRead> {
      return localVarFp
        .putServiceItem(id, serviceServiceInputServiceWrite, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
export class ServiceApi extends BaseAPI {
  /**
   *
   * @summary Removes the Service resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public deleteServiceItem(id: string, options?: any) {
    return ServiceApiFp(this.configuration)
      .deleteServiceItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Archived Services
   * @param {string} [defaultDuration]
   * @param {string} [defaultCapacity]
   * @param {string} [publicName]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public getAllArchivedServicesServiceCollection(
    defaultDuration?: string,
    defaultCapacity?: string,
    publicName?: string,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return ServiceApiFp(this.configuration)
      .getAllArchivedServicesServiceCollection(
        defaultDuration,
        defaultCapacity,
        publicName,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Archived Services
   * @param {number} [defaultDuration]
   * @param {number} [defaultCapacity]
   * @param {string} [publicName]
   * @param {string} [internalName]
   * @param {Array<string>} [productUuids]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public getServiceCollection(
    defaultDuration?: number,
    defaultCapacity?: number,
    publicName?: string,
    internalName?: string,
    productUuids?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return ServiceApiFp(this.configuration)
      .getServiceCollection(
        defaultDuration,
        defaultCapacity,
        publicName,
        internalName,
        productUuids,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a ServiceOutput resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public getServiceItem(id: string, options?: any) {
    return ServiceApiFp(this.configuration)
      .getServiceItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Services by Store
   * @param {string} id
   * @param {string} [defaultDuration]
   * @param {string} [defaultCapacity]
   * @param {string} [publicName]
   * @param {boolean} [onlyArchived]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public getServicesForCurrentStoreServiceCollection(
    id: string,
    defaultDuration?: string,
    defaultCapacity?: string,
    publicName?: string,
    onlyArchived?: boolean,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return ServiceApiFp(this.configuration)
      .getServicesForCurrentStoreServiceCollection(
        id,
        defaultDuration,
        defaultCapacity,
        publicName,
        onlyArchived,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the Service resource.
   * @param {string} id
   * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The updated Service resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public patchServiceItem(
    id: string,
    serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
    options?: any,
  ) {
    return ServiceApiFp(this.configuration)
      .patchServiceItem(id, serviceServiceInputServiceWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Changes status for a Service resource
   * @param {string} uuid
   * @param {ServiceStatusInputServiceWrite} [serviceStatusInputServiceWrite] The updated Service resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public patchStatusServiceItem(
    uuid: string,
    serviceStatusInputServiceWrite?: ServiceStatusInputServiceWrite,
    options?: any,
  ) {
    return ServiceApiFp(this.configuration)
      .patchStatusServiceItem(uuid, serviceStatusInputServiceWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a Service resource.
   * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The new Service resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public postServiceServiceCollection(
    serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
    options?: any,
  ) {
    return ServiceApiFp(this.configuration)
      .postServiceServiceCollection(serviceServiceInputServiceWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Replaces the Service resource.
   * @param {string} id
   * @param {ServiceServiceInputServiceWrite} [serviceServiceInputServiceWrite] The updated Service resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public putServiceItem(
    id: string,
    serviceServiceInputServiceWrite?: ServiceServiceInputServiceWrite,
    options?: any,
  ) {
    return ServiceApiFp(this.configuration)
      .putServiceItem(id, serviceServiceInputServiceWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
