/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { HydratedTimePeriodTimePeriodOutputTimePeriodRead } from "../nbp";
// @ts-ignore
import { TimePeriodStatusInputTimePeriodWrite } from "../nbp";
// @ts-ignore
import { TimePeriodTimePeriodInputTimePeriodWrite } from "../nbp";
// @ts-ignore
import { TimePeriodTimePeriodOutputTimePeriodRead } from "../nbp";
/**
 * TimePeriodApi - axios parameter creator
 * @export
 */
export const TimePeriodApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Removes the TimePeriod resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTimePeriodItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteTimePeriodItem", "id", id);
      const localVarPath = `/api/time_periods/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Archived TimePeriods
     * @param {string} [openDay]
     * @param {string} [closeDay]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedTimePeriodsTimePeriodCollection: async (
      openDay?: string,
      closeDay?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/time_periods/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (openDay !== undefined) {
        localVarQueryParameter["openDay"] = openDay;
      }

      if (closeDay !== undefined) {
        localVarQueryParameter["closeDay"] = closeDay;
      }

      if (openTime !== undefined) {
        localVarQueryParameter["openTime"] = openTime;
      }

      if (closeTime !== undefined) {
        localVarQueryParameter["closeTime"] = closeTime;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves the collection of TimePeriodOutput resources.
     * @param {string} [openDay]
     * @param {string} [closeDay]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTimePeriodCollection: async (
      openDay?: string,
      closeDay?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/time_periods`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (openDay !== undefined) {
        localVarQueryParameter["openDay"] = openDay;
      }

      if (closeDay !== undefined) {
        localVarQueryParameter["closeDay"] = closeDay;
      }

      if (openTime !== undefined) {
        localVarQueryParameter["openTime"] = openTime;
      }

      if (closeTime !== undefined) {
        localVarQueryParameter["closeTime"] = closeTime;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a TimePeriodOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTimePeriodItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getTimePeriodItem", "id", id);
      const localVarPath = `/api/time_periods/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves TimePeriods by BusinessHours
     * @param {string} id
     * @param {string} [openDay]
     * @param {string} [closeDay]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {boolean} [withStoreService]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTimeperiodsForCurrentStoreAndCurrentBusinessHoursTimePeriodCollection:
      async (
        id: string,
        openDay?: string,
        closeDay?: string,
        openTime?: string,
        closeTime?: string,
        withStoreService?: boolean,
        onlyArchived?: boolean,
        properties?: Array<string>,
        page?: number,
        itemsPerPage?: number,
        pagination?: boolean,
        options: any = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'id' is not null or undefined
        assertParamExists(
          "getTimeperiodsForCurrentStoreAndCurrentBusinessHoursTimePeriodCollection",
          "id",
          id,
        );
        const localVarPath = `/api/business_hours/{id}/time_periods`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "GET",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication authenticationToken required
        await setApiKeyToObject(
          localVarHeaderParameter,
          "Authorization",
          configuration,
        );

        if (openDay !== undefined) {
          localVarQueryParameter["openDay"] = openDay;
        }

        if (closeDay !== undefined) {
          localVarQueryParameter["closeDay"] = closeDay;
        }

        if (openTime !== undefined) {
          localVarQueryParameter["openTime"] = openTime;
        }

        if (closeTime !== undefined) {
          localVarQueryParameter["closeTime"] = closeTime;
        }

        if (withStoreService !== undefined) {
          localVarQueryParameter["withStoreService"] = withStoreService;
        }

        if (onlyArchived !== undefined) {
          localVarQueryParameter["onlyArchived"] = onlyArchived;
        }

        if (properties) {
          localVarQueryParameter["properties[]"] = properties;
        }

        if (page !== undefined) {
          localVarQueryParameter["page"] = page;
        }

        if (itemsPerPage !== undefined) {
          localVarQueryParameter["itemsPerPage"] = itemsPerPage;
        }

        if (pagination !== undefined) {
          localVarQueryParameter["pagination"] = pagination;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Changes status for a TimePeriod resource
     * @param {string} uuid
     * @param {TimePeriodStatusInputTimePeriodWrite} [timePeriodStatusInputTimePeriodWrite] The updated TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusTimePeriodItem: async (
      uuid: string,
      timePeriodStatusInputTimePeriodWrite?: TimePeriodStatusInputTimePeriodWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("patchStatusTimePeriodItem", "uuid", uuid);
      const localVarPath = `/api/time_periods/{uuid}/status`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        timePeriodStatusInputTimePeriodWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the TimePeriod resource.
     * @param {string} id
     * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The updated TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchTimePeriodItem: async (
      id: string,
      timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchTimePeriodItem", "id", id);
      const localVarPath = `/api/time_periods/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        timePeriodTimePeriodInputTimePeriodWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a TimePeriod resource.
     * @param {string} id
     * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The new TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTimePeriodCollection: async (
      id: string,
      timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("postTimePeriodCollection", "id", id);
      const localVarPath = `/api/business_hours/{id}/time_periods`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        timePeriodTimePeriodInputTimePeriodWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Replaces the TimePeriod resource.
     * @param {string} id
     * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The updated TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTimePeriodItem: async (
      id: string,
      timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putTimePeriodItem", "id", id);
      const localVarPath = `/api/time_periods/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        timePeriodTimePeriodInputTimePeriodWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TimePeriodApi - functional programming interface
 * @export
 */
export const TimePeriodApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TimePeriodApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Removes the TimePeriod resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTimePeriodItem(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteTimePeriodItem(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Archived TimePeriods
     * @param {string} [openDay]
     * @param {string} [closeDay]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArchivedTimePeriodsTimePeriodCollection(
      openDay?: string,
      closeDay?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedTimePeriodTimePeriodOutputTimePeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllArchivedTimePeriodsTimePeriodCollection(
          openDay,
          closeDay,
          openTime,
          closeTime,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves the collection of TimePeriodOutput resources.
     * @param {string} [openDay]
     * @param {string} [closeDay]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTimePeriodCollection(
      openDay?: string,
      closeDay?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedTimePeriodTimePeriodOutputTimePeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTimePeriodCollection(
          openDay,
          closeDay,
          openTime,
          closeTime,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a TimePeriodOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTimePeriodItem(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TimePeriodTimePeriodOutputTimePeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTimePeriodItem(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves TimePeriods by BusinessHours
     * @param {string} id
     * @param {string} [openDay]
     * @param {string} [closeDay]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {boolean} [withStoreService]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTimeperiodsForCurrentStoreAndCurrentBusinessHoursTimePeriodCollection(
      id: string,
      openDay?: string,
      closeDay?: string,
      openTime?: string,
      closeTime?: string,
      withStoreService?: boolean,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedTimePeriodTimePeriodOutputTimePeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTimeperiodsForCurrentStoreAndCurrentBusinessHoursTimePeriodCollection(
          id,
          openDay,
          closeDay,
          openTime,
          closeTime,
          withStoreService,
          onlyArchived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Changes status for a TimePeriod resource
     * @param {string} uuid
     * @param {TimePeriodStatusInputTimePeriodWrite} [timePeriodStatusInputTimePeriodWrite] The updated TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchStatusTimePeriodItem(
      uuid: string,
      timePeriodStatusInputTimePeriodWrite?: TimePeriodStatusInputTimePeriodWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TimePeriodTimePeriodOutputTimePeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchStatusTimePeriodItem(
          uuid,
          timePeriodStatusInputTimePeriodWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the TimePeriod resource.
     * @param {string} id
     * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The updated TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchTimePeriodItem(
      id: string,
      timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TimePeriodTimePeriodOutputTimePeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchTimePeriodItem(
          id,
          timePeriodTimePeriodInputTimePeriodWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a TimePeriod resource.
     * @param {string} id
     * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The new TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTimePeriodCollection(
      id: string,
      timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TimePeriodTimePeriodOutputTimePeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postTimePeriodCollection(
          id,
          timePeriodTimePeriodInputTimePeriodWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Replaces the TimePeriod resource.
     * @param {string} id
     * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The updated TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putTimePeriodItem(
      id: string,
      timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TimePeriodTimePeriodOutputTimePeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putTimePeriodItem(
          id,
          timePeriodTimePeriodInputTimePeriodWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TimePeriodApi - factory interface
 * @export
 */
export const TimePeriodApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TimePeriodApiFp(configuration);
  return {
    /**
     *
     * @summary Removes the TimePeriod resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTimePeriodItem(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteTimePeriodItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Archived TimePeriods
     * @param {string} [openDay]
     * @param {string} [closeDay]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedTimePeriodsTimePeriodCollection(
      openDay?: string,
      closeDay?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedTimePeriodTimePeriodOutputTimePeriodRead> {
      return localVarFp
        .getAllArchivedTimePeriodsTimePeriodCollection(
          openDay,
          closeDay,
          openTime,
          closeTime,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves the collection of TimePeriodOutput resources.
     * @param {string} [openDay]
     * @param {string} [closeDay]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTimePeriodCollection(
      openDay?: string,
      closeDay?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedTimePeriodTimePeriodOutputTimePeriodRead> {
      return localVarFp
        .getTimePeriodCollection(
          openDay,
          closeDay,
          openTime,
          closeTime,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a TimePeriodOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTimePeriodItem(
      id: string,
      options?: any,
    ): AxiosPromise<TimePeriodTimePeriodOutputTimePeriodRead> {
      return localVarFp
        .getTimePeriodItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves TimePeriods by BusinessHours
     * @param {string} id
     * @param {string} [openDay]
     * @param {string} [closeDay]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {boolean} [withStoreService]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTimeperiodsForCurrentStoreAndCurrentBusinessHoursTimePeriodCollection(
      id: string,
      openDay?: string,
      closeDay?: string,
      openTime?: string,
      closeTime?: string,
      withStoreService?: boolean,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedTimePeriodTimePeriodOutputTimePeriodRead> {
      return localVarFp
        .getTimeperiodsForCurrentStoreAndCurrentBusinessHoursTimePeriodCollection(
          id,
          openDay,
          closeDay,
          openTime,
          closeTime,
          withStoreService,
          onlyArchived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Changes status for a TimePeriod resource
     * @param {string} uuid
     * @param {TimePeriodStatusInputTimePeriodWrite} [timePeriodStatusInputTimePeriodWrite] The updated TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusTimePeriodItem(
      uuid: string,
      timePeriodStatusInputTimePeriodWrite?: TimePeriodStatusInputTimePeriodWrite,
      options?: any,
    ): AxiosPromise<TimePeriodTimePeriodOutputTimePeriodRead> {
      return localVarFp
        .patchStatusTimePeriodItem(
          uuid,
          timePeriodStatusInputTimePeriodWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the TimePeriod resource.
     * @param {string} id
     * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The updated TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchTimePeriodItem(
      id: string,
      timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
      options?: any,
    ): AxiosPromise<TimePeriodTimePeriodOutputTimePeriodRead> {
      return localVarFp
        .patchTimePeriodItem(
          id,
          timePeriodTimePeriodInputTimePeriodWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a TimePeriod resource.
     * @param {string} id
     * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The new TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTimePeriodCollection(
      id: string,
      timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
      options?: any,
    ): AxiosPromise<TimePeriodTimePeriodOutputTimePeriodRead> {
      return localVarFp
        .postTimePeriodCollection(
          id,
          timePeriodTimePeriodInputTimePeriodWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Replaces the TimePeriod resource.
     * @param {string} id
     * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The updated TimePeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTimePeriodItem(
      id: string,
      timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
      options?: any,
    ): AxiosPromise<TimePeriodTimePeriodOutputTimePeriodRead> {
      return localVarFp
        .putTimePeriodItem(
          id,
          timePeriodTimePeriodInputTimePeriodWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TimePeriodApi - object-oriented interface
 * @export
 * @class TimePeriodApi
 * @extends {BaseAPI}
 */
export class TimePeriodApi extends BaseAPI {
  /**
   *
   * @summary Removes the TimePeriod resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimePeriodApi
   */
  public deleteTimePeriodItem(id: string, options?: any) {
    return TimePeriodApiFp(this.configuration)
      .deleteTimePeriodItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Archived TimePeriods
   * @param {string} [openDay]
   * @param {string} [closeDay]
   * @param {string} [openTime]
   * @param {string} [closeTime]
   * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimePeriodApi
   */
  public getAllArchivedTimePeriodsTimePeriodCollection(
    openDay?: string,
    closeDay?: string,
    openTime?: string,
    closeTime?: string,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return TimePeriodApiFp(this.configuration)
      .getAllArchivedTimePeriodsTimePeriodCollection(
        openDay,
        closeDay,
        openTime,
        closeTime,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves the collection of TimePeriodOutput resources.
   * @param {string} [openDay]
   * @param {string} [closeDay]
   * @param {string} [openTime]
   * @param {string} [closeTime]
   * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimePeriodApi
   */
  public getTimePeriodCollection(
    openDay?: string,
    closeDay?: string,
    openTime?: string,
    closeTime?: string,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return TimePeriodApiFp(this.configuration)
      .getTimePeriodCollection(
        openDay,
        closeDay,
        openTime,
        closeTime,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a TimePeriodOutput resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimePeriodApi
   */
  public getTimePeriodItem(id: string, options?: any) {
    return TimePeriodApiFp(this.configuration)
      .getTimePeriodItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves TimePeriods by BusinessHours
   * @param {string} id
   * @param {string} [openDay]
   * @param {string} [closeDay]
   * @param {string} [openTime]
   * @param {string} [closeTime]
   * @param {boolean} [withStoreService]
   * @param {boolean} [onlyArchived]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimePeriodApi
   */
  public getTimeperiodsForCurrentStoreAndCurrentBusinessHoursTimePeriodCollection(
    id: string,
    openDay?: string,
    closeDay?: string,
    openTime?: string,
    closeTime?: string,
    withStoreService?: boolean,
    onlyArchived?: boolean,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return TimePeriodApiFp(this.configuration)
      .getTimeperiodsForCurrentStoreAndCurrentBusinessHoursTimePeriodCollection(
        id,
        openDay,
        closeDay,
        openTime,
        closeTime,
        withStoreService,
        onlyArchived,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Changes status for a TimePeriod resource
   * @param {string} uuid
   * @param {TimePeriodStatusInputTimePeriodWrite} [timePeriodStatusInputTimePeriodWrite] The updated TimePeriod resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimePeriodApi
   */
  public patchStatusTimePeriodItem(
    uuid: string,
    timePeriodStatusInputTimePeriodWrite?: TimePeriodStatusInputTimePeriodWrite,
    options?: any,
  ) {
    return TimePeriodApiFp(this.configuration)
      .patchStatusTimePeriodItem(
        uuid,
        timePeriodStatusInputTimePeriodWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the TimePeriod resource.
   * @param {string} id
   * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The updated TimePeriod resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimePeriodApi
   */
  public patchTimePeriodItem(
    id: string,
    timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
    options?: any,
  ) {
    return TimePeriodApiFp(this.configuration)
      .patchTimePeriodItem(
        id,
        timePeriodTimePeriodInputTimePeriodWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a TimePeriod resource.
   * @param {string} id
   * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The new TimePeriod resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimePeriodApi
   */
  public postTimePeriodCollection(
    id: string,
    timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
    options?: any,
  ) {
    return TimePeriodApiFp(this.configuration)
      .postTimePeriodCollection(
        id,
        timePeriodTimePeriodInputTimePeriodWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Replaces the TimePeriod resource.
   * @param {string} id
   * @param {TimePeriodTimePeriodInputTimePeriodWrite} [timePeriodTimePeriodInputTimePeriodWrite] The updated TimePeriod resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimePeriodApi
   */
  public putTimePeriodItem(
    id: string,
    timePeriodTimePeriodInputTimePeriodWrite?: TimePeriodTimePeriodInputTimePeriodWrite,
    options?: any,
  ) {
    return TimePeriodApiFp(this.configuration)
      .putTimePeriodItem(id, timePeriodTimePeriodInputTimePeriodWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
