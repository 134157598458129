import { Cookies } from "react-cookie";

export default class CookieService {
  private static instance: CookieService;
  private cookie: Cookies;

  private constructor() {
    this.cookie = new Cookies();
  }

  static getInstance() {
    if (!CookieService.instance) {
      CookieService.instance = new CookieService();
    }
    return CookieService.instance;
  }

  isAuthenticated(key: string): boolean {
    const value: string | undefined = this.cookie.get(key);
    return !!value;
  }

  get(key: string): any {
    const value: string | undefined = this.cookie.get(key);
    return value ? value : "";
  }
  set(key: string, value: any, options?: any): void {
    this.cookie.set(key, JSON.stringify(value), options);
  }
  remove(key: string): any {
    this.cookie.remove(key, { path: "/" });
  }
}
