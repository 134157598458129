import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Box,
  Dialog,
  DialogTitle,
  Grid,
  Autocomplete,
  Table,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { IOSSwitch as Switch } from '../../../../forms/Switch';

import DialogActionFooter from '../../../../dialog/DialogActionFooter';
import _ from 'lodash';
import moment from 'moment';
import { RootState } from '../../../../../redux/reducers';
import {
  BusinessHoursActions,
  ModalsActions,
} from '../../../../../redux/actions';
import { TimePeriod } from '../../../../../api/entities';
import ActionDialog from '../../../../dialog/ActionDialog';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteIcon } from '../../../../icons/DeleteIcon';

interface RowProps {
  item: any;
  onDeleteSpecialHourPeriod: (time: any) => void;
}

function Row(props: RowProps) {
  const classes = useStyles();
  const item = props.item;
  const dispatch = useDispatch();
  const [selectedOpenTime, setSelectedOpenTime] = useState();
  const [selectedCloseTime, setSelectedCloseTime] = useState();
  const storeServices = useSelector((state: RootState) =>
    state.StoreReducer.storeDetail?.storeServices
      ? state.StoreReducer.storeDetail.storeServices
      : [],
  );
  const [rowServices, setRowServices] = useState<any[]>([]);
  const [selectedServiceID, setSelectedServiceID] = useState<string>('all');
  const [isClosed, setOpen] = useState(item.isClosed);

  useEffect(() => {
    if (storeServices) {
      setRowServices(
        storeServices.concat({ uuid: 'all', externalName: 'Tutti i Servizi' }),
      );
    }
  }, [storeServices]);

  const handleChangeDay = (event: any) => {
    let request = {
      startDate: moment(event).toISOString(),
      endDate: moment(event).toISOString(),
    };
    dispatch(BusinessHoursActions.updateSpecialHourPeriod(item.uuid, request));
  };

  const handleOpenTime = (event: any, id: string) => {
    setSelectedOpenTime(event.target.value);
    let request = {
      openTime: event.target.value,
    };
    dispatch(BusinessHoursActions.updateSpecialHourPeriod(id, request));
  };
  const handleCloseTime = (event: any, id: string) => {
    setSelectedCloseTime(event.target.value);
    let request = {
      closeTime: event.target.value,
    };
    dispatch(BusinessHoursActions.updateSpecialHourPeriod(id, request));
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpen(event.target.checked);

    if (item.uuid) {
      dispatch(
        BusinessHoursActions.updateSpecialHourPeriod(item.uuid, {
          isClosed: !event.target.checked,
        }),
      );
    }
  };

  const handleServices = (event: any, id: string) => {
    let selectedId = event.target.value;
    let service = _.find(storeServices, { uuid: selectedId });
    if (service) {
      setSelectedServiceID(service.uuid ? service.uuid : 'all');
      let request = {
        storeServices: [service.uuid],
      };
      dispatch(BusinessHoursActions.updateSpecialHourPeriod(id, request));
    } else if (selectedId === 'all') {
      setSelectedServiceID('all');
      let request = {
        storeServices: [],
      };
      dispatch(BusinessHoursActions.updateSpecialHourPeriod(id, request));
    }
  };

  const handleServiceValue = (selectedId: any) => {
    let service = _.find(storeServices, { uuid: selectedId });
    if (service) {
      return service.externalName;
    } else if (selectedId === 'all') {
      return 'Tutti i Servizi';
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
      <Box
        key={_.uniqueId()}
        className={classes.row}
        justifyContent="flex-start"
        alignItems="center"
        display="flex"
        width="100%"
      >
        <div>
          <DatePicker
            className={classes.date}
            value={moment(item.startDate)}
            onChange={(event: any) => {
              handleChangeDay(event);
            }}
            slotProps={{
              textField: {
                color: 'secondary',
                size: 'small',
                inputProps: {
                  style: {
                    padding: '12px 16px',
                  },
                },
              },
            }}
          />
        </div>

        <Switch
          checked={!isClosed}
          onChange={handleChange}
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />

        <Typography className={classes.switchLabel}>
          {isClosed !== true ? 'APERTO' : 'CHIUSO'}
        </Typography>

        {isClosed !== true ? (
          <Box key={_.uniqueId()} display="flex" alignItems="center">
            <TextField
              id="time"
              type="time"
              defaultValue={item.openTime}
              className={classes.timeStart}
              color="secondary"
              size="small"
              sx={{ marginRight: '16px', minWidth: '110px' }}
              inputProps={{
                style: {
                  padding: '12px 16px',
                },
                step: 300, // 5 min
              }}
              InputLabelProps={{
                style: {
                  top: '4px',
                },
                shrink: true,
              }}
              value={selectedOpenTime}
              onChange={(event) => {
                handleOpenTime(event, item.uuid);
              }}
            />

            <TextField
              id="time"
              type="time"
              defaultValue={item.closeTime}
              value={selectedCloseTime}
              className={classes.timeEnd}
              color="secondary"
              size="small"
              sx={{ marginRight: '16px', minWidth: '110px' }}
              inputProps={{
                style: {
                  padding: '12px 16px',
                },
                step: 300, // 5 min
              }}
              InputLabelProps={{
                style: {
                  top: '4px',
                },
                shrink: true,
              }}
              onChange={(event) => {
                handleCloseTime(event, item.uuid);
              }}
            />

            <Autocomplete
              noOptionsText="Nessun risultato"
              fullWidth
              id="standard-select-service"
              value={
                storeServices && storeServices.length > 0
                  ? storeServices[0].uuid
                  : 'all'
              }
              color="secondary"
              size="small"
              options={rowServices || []}
              onChange={(e: any, option: any) => handleServices(e, option.uuid)}
              getOptionLabel={(option: any) => handleServiceValue(option)!}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  color="secondary"
                  label="Seleziona Servizio"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      padding: '6px 10px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      top: '4px',
                    },
                  }}
                />
              )}
              renderOption={(props: any, option: any) => (
                <li {...props} key={option.uuid}>
                  {option.externalName}
                </li>
              )}
            />
            <IconButton
              sx={{ marginLeft: '16px' }}
              onClick={() => props.onDeleteSpecialHourPeriod(item)}
            >
              <DeleteIcon color="#00D1D2" />
            </IconButton>
          </Box>
        ) : (
          <IconButton onClick={() => props.onDeleteSpecialHourPeriod(item)}>
            <DeleteIcon color="#00D1D2" />
          </IconButton>
        )}
      </Box>
    </LocalizationProvider>
  );
}

export default function StoreSpecialHoursModal() {
  const classes = useStyles();
  const storeDetail = useSelector(
    (state: RootState) => state.StoreReducer.storeDetail,
  );
  const specialTimePeriods = useSelector((state: RootState) =>
    state.BusinessHoursReducer.hydratedSpecialHours?.['hydra:member']
      ? state.BusinessHoursReducer.hydratedSpecialHours['hydra:member']
      : [],
  );
  const dispatch = useDispatch();
  const [deletedObject, setDeletedObject] = useState<any>(undefined);
  const [openActionDialog, setOpenActionDialog] = useState(false);
  const { showSpecialHoursModalSuccess } = useSelector(
    (state: RootState) => state.UiReducer,
  );

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      dispatch(ModalsActions.closeModal());
      return;
    }
    dispatch(ModalsActions.closeModal());
  };

  const openDeleteDialog = (item: any) => {
    setDeletedObject(item);
    setOpenActionDialog(true);
  };
  const deleteAction = () => {
    if (deletedObject && storeDetail && storeDetail.uuid) {
      dispatch(
        BusinessHoursActions.deleteSpecialHourPeriod(
          deletedObject.uuid,
          storeDetail.uuid,
        ),
      );
      setOpenActionDialog(false);
      setDeletedObject(undefined);
    }
  };

  const handleCloseDialog = () => {
    setOpenActionDialog(false);
    setDeletedObject(undefined);
  };

  return (
    <Dialog
      className={classes.modal}
      open={showSpecialHoursModalSuccess}
      onClose={() => handleClose()}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        Gestione altri orari
      </DialogTitle>
      <Grid className={classes.gridContainer}>
        <Table>
          {specialTimePeriods.map((item: TimePeriod) => (
            <Row
              item={item}
              key={_.uniqueId()}
              onDeleteSpecialHourPeriod={(item) => openDeleteDialog(item)}
            />
          ))}
        </Table>

        <ActionDialog
          title="Attenzione"
          message="Sei sicuro di voler eliminare questo periodo?"
          onAction={() => {
            deleteAction();
          }}
          open={openActionDialog}
          onClose={() => handleCloseDialog()}
          actionTitle="Elimina"
        />
      </Grid>
      <Box className={classes.footerWrapper}>
        <DialogActionFooter
          onCancel={() => handleClose()}
          onConfirm={() => handleClose()}
        />
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    gridContainer: {
      padding: '24px',
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
    },
    switchLabel: {
      color: '#34303D',
      fontWeight: 'bold',
      marginLeft: '16px !important',
      marginRight: '16px !important',
    },
    date: {
      width: 175,
      marginRight: '16px !important',
      fontSize: '14px',
    },
    timeStart: {
      marginLeft: 20,
      marginRight: 8,
      width: 100,
      fontSize: '14px',
    },
    timeEnd: {
      marginLeft: 8,
      marginRight: 20,
      width: 100,
      fontSize: '14px',
    },
    endRowLabel: {
      color: '#00D1D2',
      fontSize: 12,
      fontWeight: 'bold',
    },
    row: {
      borderTop: '1px solid #E1E0E2',
      paddingTop: 24,
      paddingBottom: 24,
    },
  }),
);
