import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { UsersActions } from "../actions";
import { HydratedUser, Roles, User } from "../../api/entities";

export type UsersState = {
  hydratedUsers?: HydratedUser;
  currentUser?: User;
  currentRole?: Roles;
  usersLoading: boolean;
  currentUserLoading: boolean;
  currentUserError: any;
  usersError: any;
  userDetail?: User;
  loadingSingleUser: boolean;
  singleUserError: boolean;
  patchUserError: boolean;
  patchUserLoading: boolean;
  searchUserLoading: boolean;
  deleteUserSuccess?: string;
  deleteUserLoading: boolean;
  deleteUserError: boolean;
  postUserSuccess: boolean;
  postUserLoading: boolean;
  postUserError: boolean;
};

const INITIAL_USERS_STATE: UsersState = {
  usersError: false,
  usersLoading: false,
  currentUserLoading: false,
  currentUserError: false,
  loadingSingleUser: false,
  singleUserError: false,
  patchUserError: false,
  patchUserLoading: false,
  searchUserLoading: false,
  deleteUserLoading: false,
  deleteUserError: false,
  postUserSuccess: false,
  postUserLoading: false,
  postUserError: false,
};

const addUser = (hydratedUsers: HydratedUser | undefined, newUser: User) => {
  if (hydratedUsers) {
    return {
      ...hydratedUsers,
      "hydra:member": [newUser, ...hydratedUsers?.["hydra:member"]],
    };
  }
  return hydratedUsers;
};

const UsersReducer = createReducer(INITIAL_USERS_STATE, (builder) =>
  builder
    .addCase(
      UsersActions.getUsersSuccess,
      (state: UsersState, action: PayloadAction<HydratedUser>) => {
        let hydratedUsers = action.payload;
        return {
          ...state,
          hydratedUsers,
          usersError: false,
          usersLoading: false,
        };
      },
    )
    .addCase(
      UsersActions.getUsersError,
      (state: UsersState, action: PayloadAction<any>) => {
        let usersError = action.payload;
        return { ...state, usersError, usersLoading: false };
      },
    )
    .addCase(UsersActions.getUsersLoading, (state: UsersState) => {
      return { ...state, usersError: false, usersLoading: true };
    })
    .addCase(UsersActions.getCurrentUserLoading, (state: UsersState) => {
      return { ...state, currentUserError: false, currentUserLoading: true };
    })
    .addCase(
      UsersActions.getCurrentUserSuccess,
      (state: UsersState, action: PayloadAction<User>) => {
        return {
          ...state,
          currentUserError: false,
          currentUserLoading: false,
          currentUser: action.payload,
        };
      },
    )
    .addCase(UsersActions.getCurrentUserError, (state: UsersState) => {
      return { ...state, currentUserError: true, currentUserLoading: false };
    })
    .addCase(
      UsersActions.setCurrentRole,
      (state: UsersState, action: PayloadAction<Roles>) => {
        return { ...state, currentRole: action.payload };
      },
    )
    .addCase(
      UsersActions.getSingleUserSuccess,
      (state: UsersState, action: PayloadAction<User>) => {
        let userDetail = action.payload;
        return {
          ...state,
          userDetail,
          loadingSingleUser: false,
          singleUserError: false,
        };
      },
    )
    .addCase(UsersActions.getSingleUserLoading, (state: UsersState) => {
      return { ...state, loadingSingleUser: true, singleUserError: false };
    })
    .addCase(
      UsersActions.getSingleUserError,
      (state: UsersState, action: PayloadAction<any>) => {
        let singleUserError = action.payload;
        return { ...state, loadingSingleUser: false, singleUserError };
      },
    )
    .addCase(
      UsersActions.patchUserSuccess,
      (state: UsersState, action: PayloadAction<User>) => {
        let user = action.payload;
        return {
          ...state,
          patchUserError: false,
          patchUserLoading: false,
          userDetail: user,
        };
      },
    )
    .addCase(UsersActions.patchUserLoading, (state: UsersState) => {
      return { ...state, patchUserLoading: true, patchUserError: false };
    })
    .addCase(
      UsersActions.patchUserError,
      (state: UsersState, action: PayloadAction<any>) => {
        let error = action.payload;
        return { ...state, patchUserLoading: false, patchUserError: error };
      },
    )
    .addCase(UsersActions.searchUsersLoading, (state: UsersState) => {
      return { ...state, searchUserLoading: true };
    })
    .addCase(
      UsersActions.searchUsersSuccess,
      (state: UsersState, action: PayloadAction<HydratedUser>) => {
        return {
          ...state,
          hydratedUsers: action.payload,
          searchUserLoading: false,
        };
      },
    )
    .addCase(UsersActions.searchUsersError, (state: UsersState) => {
      return { ...state, searchUserLoading: false };
    })
    //delete user
    .addCase(
      UsersActions.deleteUserSuccess,
      (state: UsersState, action: PayloadAction<string>) => {
        let deletedID = action.payload;

        return {
          ...state,
          deleteUserError: false,
          deleteUserLoading: false,
          deleteUserSuccess: deletedID,
        };
      },
    )
    .addCase(UsersActions.deleteUserLoading, (state: UsersState) => {
      return { ...state, deleteUserLoading: true, deleteUserError: false };
    })
    .addCase(
      UsersActions.deleteUserError,
      (state: UsersState, action: PayloadAction<any>) => {
        let deleteUserError = action.payload;
        return {
          ...state,
          deleteUserLoading: false,
          deleteUserError,
          deleteUserSuccess: undefined,
        };
      },
    )
    //post user
    .addCase(UsersActions.postUserLoading, (state: UsersState) => {
      return { ...state, postUserLoading: true, postUserSuccess: false };
    })
    .addCase(UsersActions.postUserError, (state: UsersState, action: any) => {
      return {
        ...state,
        postUserError: action.payload,
        postUserLoading: false,
      };
    })
    .addCase(
      UsersActions.postUserSuccess,
      (state: UsersState, action: PayloadAction<User>) => {
        let newUser = action.payload;
        return {
          ...state,
          hydratedUsers: addUser(state.hydratedUsers, newUser),
          postUserLoading: false,
          postUserError: false,
        };
      },
    ),
);

export default UsersReducer;
