import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Dialog, Grid, TextareaAutosize, TextField } from '@mui/material';
import ModalHeader from '../../ModalHeader';
import DialogActionFooter from '../../dialog/DialogActionFooter';

interface UserProfileMessageModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSave: () => void;
}

export default function UserProfileMessageModal(
  props: UserProfileMessageModalProps,
) {
  const classes = useStyles();

  const handleClose = () => {
    props.onClose();
  };
  const handleSave = () => {
    props.onSave();
  };

  return (
    <Dialog
      className={classes.modal}
      open={props.isVisible}
      onClose={() => handleClose()}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <Grid className={classes.gridContainer}>
        <ModalHeader
          title={'Modifica informazioni'}
          onClose={() => handleClose()}
        />
        <Box className={classes.contentBox}>
          <TextField
            className={classes.contentField}
            fullWidth
            label="Mittente"
            id="mittente"
            value={''}
            onChange={(event) => {}}
          />
          <TextField
            className={classes.contentField}
            fullWidth
            label="Inserisci un oggetto"
            id="oggetto"
            value={''}
            onChange={(event) => {}}
          />
          <TextareaAutosize
            className={classes.textArea}
            minRows={10}
            maxRows={10}
            aria-label="maximum height"
            defaultValue="Inserisci qui l’html dell’email"
          />
        </Box>

        <Box
          className={classes.footerView}
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <DialogActionFooter
            onCancel={() => handleClose()}
            onConfirm={() => handleSave()}
            actionTitle="Modifica"
          />
        </Box>
      </Grid>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
      //display: 'flex',
    },
    gridContainer: {
      //width: '50%',
      //height: '80%',
      backgroundColor: 'white',
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      borderRadius: 4,
      position: 'relative',
      overflow: 'hidden',
    },
    textArea: {
      width: '100%',
      borderWidth: 1,
      borderRadius: 4,
      borderColor: '#00D1D2',
      marginTop: 40,
      padding: 16,
    },
    input: {
      display: 'none',
    },
    uploadRoot: {
      '& > *': {
        //margin: theme.spacing(1),
      },
      alignItems: 'center',
    },
    contentBox: {
      //padding: 16,
      overflow: 'scroll',
    },
    footerView: {
      width: '100%',
      height: 50,
      marginTop: 20,
      //paddingBottom: 40,
      //position: 'absolute',
      backgroundColor: 'white',
      // paddingLeft: 16,
      // paddingRight: 16,
      // left: 0,
      // right: 0,
      // bottom: 0,
    },
    uploadButton: {
      marginTop: 6,
      fontWeight: 'bold',
      fontFamily: 'IQOS Sans',
    },
    title: {
      fontSize: 20,
      color: '#34303D',
    },
    label: {
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
    contentField: {
      width: '100%',
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
    uploadBox: {},
    switchBase: {
      '&$checked': {
        color: '#00D1D2',
      },
      '&$checked + $track': {
        backgroundColor: '#F4E9DC',
      },
    },
    img: { marginLeft: 16 },
  }),
);
