import {
  List,
  Box,
  TextField,
  Theme,
  Autocomplete,
  IconButton,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BusinessHour } from '../../../../../api/entities';
import { BusinessHourRequestActions } from '../../../../../redux/actions';
import { RootState } from '../../../../../redux/reducers';
import ActionDialog from '../../../../dialog/ActionDialog';
import { createStyles, makeStyles } from '@mui/styles';
import { DeleteIcon } from '../../../../icons/DeleteIcon';
import { AddIcon } from '../../../../icons/AddIcon';

interface TimePeriodRowHoursProps {
  item: any;
  hide: boolean;
}

export default function TimePeriodRowHours(props: TimePeriodRowHoursProps) {
  const hide = props.hide;
  const classes = useStyles();
  const dispatch = useDispatch();

  const storeServices = useSelector((state: RootState) =>
    state.StoreReducer.storeDetail?.storeServices
      ? state.StoreReducer.storeDetail.storeServices
      : [],
  );

  const businessHoursRequest = useSelector(
    (state: RootState) => state.BusinessHourRequestReducer.businessHoursRequest,
  );

  const [rowServices, setRowServices] = useState<any[]>([]);

  const item = props.item;

  const [openActionDialog, setOpenActionDialog] = useState(false);

  const [deletedObject, setDeletedObject] = useState<any>();

  useEffect(() => {
    if (storeServices) {
      setRowServices(
        storeServices.concat({ uuid: 'all', externalName: 'Tutti i Servizi' }),
      );
    }
  }, [storeServices]);

  const handleServices = (option: any, timePeriod: BusinessHour) => {
    const storeService = storeServices.find((s) => s.uuid === option?.uuid);
    if (storeService?.service) {
      const storeServices = [
        {
          ['@id']: '/api/store_services/' + storeService.uuid,
          uuid: storeService.uuid,
        },
      ];
      const updatedPeriod = { ...timePeriod, storeServices };
      dispatch(BusinessHourRequestActions.updateTimePeriod(updatedPeriod));
    } else {
      const updatedPeriod = { ...timePeriod, storeServices: [] };
      dispatch(BusinessHourRequestActions.updateTimePeriod(updatedPeriod));
    }
  };

  const handleCloseTime = (event: any, timePeriod: BusinessHour) => {
    let updatedPeriod = { ...timePeriod, closeTime: event.target.value };
    dispatch(BusinessHourRequestActions.updateTimePeriod(updatedPeriod));
  };

  const handleOpenTime = (event: any, timePeriod: BusinessHour) => {
    let updatedPeriod = { ...timePeriod, openTime: event.target.value };
    dispatch(BusinessHourRequestActions.updateTimePeriod(updatedPeriod));
  };

  const renderServiceValue = (serviceUuid: string) => {
    const storeService = storeServices.find((s) => s.uuid === serviceUuid);
    if (storeService?.externalName) return storeService.externalName;
    return 'Tutti i Servizi';
  };

  const handleDeletePeriod = (deletedPeriod: any) => {
    setDeletedObject(deletedPeriod);
    setOpenActionDialog(true);
  };

  const handleCloseDialog = () => {
    setDeletedObject(undefined);
    setOpenActionDialog(false);
  };

  const deleteAction = () => {
    if (deletedObject) {
      dispatch(BusinessHourRequestActions.deleteTimePeriod(deletedObject));
      setOpenActionDialog(false);
    }
  };

  const handleAddTimePeriod = () => {
    let newTimePeriod = {
      uuid: _.uniqueId(),
      openDay: item.openDay,
      closeDay: item.openDay,
      openTime: '09:00',
      closeTime: '13:00',
      storeServices: [],
    };
    dispatch(BusinessHourRequestActions.addTimePeriod(newTimePeriod));
  };

  return hide ? (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <List className={classes.listBox}>
          {item.times.map((time: BusinessHour, i: number) => {
            return (
              <Box
                key={i}
                sx={{
                  marginBottom:
                    item.times?.length < 1 || i === item.times?.length - 1
                      ? 0
                      : '12px',
                }}
                display="flex"
                alignItems="center"
              >
                <TextField
                  id="time"
                  type="time"
                  defaultValue={time.openTime}
                  className={classes.timeStart}
                  color="secondary"
                  size="small"
                  sx={{ marginRight: '16px', minWidth: '110px' }}
                  inputProps={{
                    style: {
                      padding: '12px 16px',
                    },
                    step: 300, // 5 min
                  }}
                  InputLabelProps={{
                    style: {
                      top: '4px',
                    },
                    shrink: true,
                  }}
                  onChange={(event) => {
                    handleOpenTime(event, time);
                  }}
                />

                <TextField
                  id="time"
                  type="time"
                  defaultValue={time.closeTime}
                  className={classes.timeEnd}
                  color="secondary"
                  size="small"
                  sx={{ marginRight: '16px', minWidth: '110px' }}
                  inputProps={{
                    style: {
                      padding: '12px 16px',
                    },
                    step: 300, // 5 min
                  }}
                  InputLabelProps={{
                    style: {
                      top: '4px',
                    },
                    shrink: true,
                  }}
                  onChange={(event) => handleCloseTime(event, time)}
                />

                <Autocomplete
                  noOptionsText="Nessun risultato"
                  fullWidth
                  id="standard-select-service"
                  value={
                    time.storeServices && time.storeServices.length > 0
                      ? time.storeServices[0].uuid
                      : 'all'
                  }
                  color="secondary"
                  size="small"
                  options={rowServices || []}
                  onChange={(_: any, option: any) =>
                    handleServices(option, time)
                  }
                  getOptionLabel={(option: any) =>
                    renderServiceValue(option?.uuid || option)
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      color="secondary"
                      label="Seleziona Servizio"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          padding: '6px 10px',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          top: '4px',
                        },
                      }}
                    />
                  )}
                  renderOption={(props: any, option: any) => (
                    <li {...props} key={option.value}>
                      {option.externalName}
                    </li>
                  )}
                />

                {item.times.length > 1 ? (
                  <IconButton
                    sx={{ marginLeft: '16px' }}
                    onClick={() => {
                      if (businessHoursRequest?.uuid) {
                        handleDeletePeriod(time);
                      }
                    }}
                  >
                    <DeleteIcon color="#00D1D2" />
                  </IconButton>
                ) : (
                  <Box style={{ width: 16 }} className={classes.left} />
                )}
              </Box>
            );
          })}
        </List>
        <IconButton onClick={() => handleAddTimePeriod()}>
          <AddIcon color="#00D1D2" />
        </IconButton>
      </Box>
      <ActionDialog
        title="Attenzione"
        message="Sei sicuro di voler eliminare questo periodo?"
        onAction={() => {
          deleteAction();
        }}
        open={openActionDialog}
        onClose={() => handleCloseDialog()}
        actionTitle="Elimina"
      />
    </>
  ) : (
    <Box />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listBox: {
      width: '100%',
      padding: '0px !important',
    },

    title: {
      fontSize: 20,
      color: '#34303D',
    },
    left: {
      marginLeft: 20,
    },
    timeStart: {
      marginLeft: 20,
      marginRight: 8,
      width: 120,
      fontSize: '14px',
    },
    timeEnd: {
      marginLeft: 8,
      marginRight: 20,
      width: 120,
      fontSize: '14px',
    },
    endRowLabel: {
      color: '#00D1D2',
      fontSize: 12,
      fontWeight: 'bold',
    },
  }),
);
