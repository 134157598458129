import Axios from "axios";
import CookieService from "./CookieService";
import { AuthActions } from "../redux/actions";
import store from "../redux/store";
export default class ApiInterceptor {
  constructor() {
    this.setRequestInterceptor();
    this.setResponseInterceptor();
  }

  private setRequestInterceptor = () => {
    Axios.interceptors.request.use(
      async (config) => {
        let token = CookieService.getInstance().get("token");
        if (token) {
          // config.headers['Authorization'] = `Bearer ${token.token}`
          // config.headers['Content-Type'] = "application/json"
          config.headers = {
            Authorization: `Bearer ${token.token}`,
            "Content-Type": "application/json",
            //'Cache-Control': 'no-cache'
          };
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  };

  private setResponseInterceptor = () => {
    Axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          store.dispatch(AuthActions.setLogin(false));
          return Axios(originalRequest);
        }
        return Promise.reject(error);
      },
    );
  };
}
