import { SvgIcon } from '@mui/material';
import React, { useMemo } from 'react';
import { useIconColor } from './IconColor';
import { IconProps } from './iconProps';

export const SearchIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  const size = useMemo<number>(() => (small ? 24 : 18), [small]);
  const position = useMemo<string>(() => (small ? '-1 -2' : '0 0'), [small]);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={`${position} ${size} ${size}`}
        fill="none"
      >
        <path
          d="M13.2444 3.1008C16.0454 5.90187 16.0454 10.4433 13.2444 13.2444C10.4433 16.0454 5.90187 16.0454 3.1008 13.2444C0.299732 10.4433 0.299732 5.90187 3.1008 3.1008C5.90187 0.299732 10.4433 0.299732 13.2444 3.1008"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.0002 17L13.2402 13.24"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
