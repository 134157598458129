import {
  Button,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Popover,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationList from '../NotificationList/NotificationList';
import { CloseIcon } from '../../icons/CloseIcon';
import { ArrowRightIcon } from '../../icons/ArrowRightIcon';

interface NotificationOverlayProps {
  anchor: HTMLElement;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function NotificationOverlay({
  anchor,
  onClose,
}: NotificationOverlayProps) {
  const navigate = useNavigate();

  return (
    <Popover
      id="notifications-overlay"
      open={true}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card sx={{ width: 400 }}>
        <CardHeader
          action={
            <IconButton onClick={onClose}>
              <CloseIcon color="#34303d" />
            </IconButton>
          }
          title="Notifiche"
        />
        <NotificationList />
        <CardActions sx={{ justifyContent: 'end' }}>
          <Button
            sx={{ fontSize: 16 }}
            color="secondary"
            endIcon={<ArrowRightIcon color="#00D1D2" />}
            onClick={() => {
              navigate('/notifications');
              onClose(null as any);
            }}
          >
            Mostra tutte
          </Button>
        </CardActions>
      </Card>
    </Popover>
  );
}
