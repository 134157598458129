import React from 'react';
import { UsersActions } from '../../redux/actions';
import { connect } from 'react-redux';
import './UsersScreen.scss';
import UsersTable from '../../components/users/table/UsersTable';

interface UsersScreenProps {
  getUsers: () => void;
}
interface UsersScreenState {}
export class UserScreen extends React.Component<
  UsersScreenProps,
  UsersScreenState
> {
  componentDidMount() {
    this.props.getUsers();
  }
  render() {
    return (
      // <Fade timeout={1000} unmountOnExit>
      <div className="users-container">
        <UsersTable />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUsers: () => dispatch(UsersActions.getUsers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserScreen);
