import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { SnackBarActions, ModalsActions } from "../../actions";
import { SnackBarProps, SnackBarType } from "../../actions/UI/SnackBarActions";

export type UI_STATE = {
  snackbarMessage: string;
  snackbarOpen: boolean;
  snackbarType: SnackBarType;
  showSpecialHoursModalSuccess: boolean;
  showCreateSpecialHoursModalSuccess: boolean;
  showTimePeriodsModalSuccess: boolean;
  closeAllModals: boolean;
  showDateRangeModalSuccess: boolean;
  showLogOutDialogSuccess: boolean;
};
const INITIAL_UI_STATE: UI_STATE = {
  snackbarMessage: "",
  snackbarOpen: false,
  snackbarType: "warning",
  showSpecialHoursModalSuccess: false,
  showCreateSpecialHoursModalSuccess: false,
  showTimePeriodsModalSuccess: false,
  closeAllModals: false,
  showDateRangeModalSuccess: false,
  showLogOutDialogSuccess: false,
};

const UIReducer = createReducer(INITIAL_UI_STATE, (builder) =>
  builder
    .addCase(
      SnackBarActions.showSnackbar,
      (state: UI_STATE, action: PayloadAction<SnackBarProps>) => {
        return {
          ...state,
          snackbarOpen: true,
          snackbarMessage: action.payload.message,
          snackbarType: action.payload.type,
        };
      },
    )
    .addCase(
      SnackBarActions.clearSnackBar,
      (state: UI_STATE, action: PayloadAction) => {
        return {
          ...state,
          snackbarOpen: false,
          snackbarMessage: "",
        };
      },
    )
    .addCase(
      ModalsActions.showSpecialHoursModalSuccess,
      (state: UI_STATE, action: PayloadAction) => {
        return {
          ...state,
          showSpecialHoursModalSuccess: true,
        };
      },
    )
    .addCase(
      ModalsActions.showCreateSpecialHoursModalSuccess,
      (state: UI_STATE, action: PayloadAction) => {
        return {
          ...state,
          showCreateSpecialHoursModalSuccess: true,
        };
      },
    )
    .addCase(
      ModalsActions.showTimePeriodsModalSuccess,
      (state: UI_STATE, action: PayloadAction) => {
        return {
          ...state,
          showTimePeriodsModalSuccess: true,
        };
      },
    )
    .addCase(
      ModalsActions.showDateRangeModalSuccess,
      (state: UI_STATE, action: PayloadAction) => {
        return {
          ...state,
          showDateRangeModalSuccess: true,
        };
      },
    )
    .addCase(
      ModalsActions.showLogOutDialogSuccess,
      (state: UI_STATE, action: PayloadAction) => {
        return {
          ...state,
          showLogOutDialogSuccess: true,
        };
      },
    )
    .addCase(
      ModalsActions.closeAllModals,
      (state: UI_STATE, action: PayloadAction) => {
        return {
          ...state,
          showSpecialHoursModalSuccess: false,
          showCreateSpecialHoursModalSuccess: false,
          showTimePeriodsModalSuccess: false,
          showDateRangeModalSuccess: false,
          showLogOutDialogSuccess: false,
        };
      },
    ),
);

export default UIReducer;
