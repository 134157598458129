import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UsersActions } from '../../../redux/actions';
import { RootState } from '../../../redux/reducers';
import UsersTableRow from './UsersTableRow';
import UsersTableToolbar from './UsersTableToolbar';
import { User } from '../../../api/entities';
import { createStyles, makeStyles } from '@mui/styles';

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'username', numeric: false, disablePadding: false, label: 'USERNAME' },
  { id: 'email', numeric: false, disablePadding: false, label: 'EMAIL' },
  { id: 'store', numeric: false, disablePadding: false, label: 'STORE' },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'TIPO DI UTENTE',
  },
  { id: 'actions', numeric: false, disablePadding: false, label: '' },
];

interface UsersTableHeadProps {
  classes: ReturnType<typeof useStyles>;
}

function UsersTableHead({ classes }: UsersTableHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.headTableCell}
            key={headCell.id}
            align={headCell.label === 'icon' ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <Typography className={classes.headCellLabel}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function UsersTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [query, setQuery] = useState<string>('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const isSearching = useSelector(
    (state: RootState) => state.UsersReducer.searchUserLoading,
  );
  const isLoading = useSelector(
    (state: RootState) => state.UsersReducer.usersLoading,
  );
  const rowsPerPageOptions = [10, 30, 100];
  const totalItems =
    useSelector((state: RootState) =>
      state.UsersReducer.hydratedUsers
        ? state.UsersReducer.hydratedUsers['hydra:totalItems']
        : 0,
    ) ?? 0;

  const hydratedUsers = useSelector(
    (state: RootState) => state.UsersReducer.hydratedUsers,
  );

  const users = hydratedUsers?.['hydra:member']
    ? hydratedUsers['hydra:member']
    : [];

  const currentUser = useSelector(
    (state: RootState) => state.UsersReducer.currentUser,
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(UsersActions.getUsers(newPage + 1, rowsPerPage, query));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPerPage = parseInt(event.target.value, 10);
    dispatch(UsersActions.getUsers(1, newPerPage, query));
    setRowsPerPage(newPerPage);
    setPage(0);
  };

  const handleSelectRow = (row: User) => {
    let userId = row.uuid;
    if (userId) {
      let pathname = '/users/' + userId;
      navigate(pathname);
    }
  };

  function searchUsers(query: string) {
    dispatch(UsersActions.getUsers(1, rowsPerPage, query));
    setPage(0);
    setQuery(query);
  }

  const usersWithoutMe = users.filter((u) => u.uuid !== currentUser?.uuid);

  return (
    <div className={classes.table_wrapper}>
      <Paper className={classes.paper}>
        <UsersTableToolbar onSearch={(query: string) => searchUsers(query)} />
        <TableContainer>
          <Table className={classes.table}>
            <UsersTableHead classes={classes} />
            <TableBody>
              {usersWithoutMe.map((row, index) => (
                <UsersTableRow
                  key={index}
                  row={row}
                  labelId={`enhanced-table-checkbox-${index}`}
                  onClickRow={(row) => handleSelectRow(row)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          SelectProps={{
            disabled: isLoading || isSearching,
          }}
          backIconButtonProps={
            isLoading || isSearching
              ? {
                  disabled: isLoading || isSearching,
                }
              : undefined
          }
          nextIconButtonProps={
            isLoading || isSearching
              ? {
                  disabled: isLoading || isSearching,
                }
              : undefined
          }
          className={classes.pagination}
          count={totalItems}
          page={page}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Righe per pagina"
          component="div"
          onPageChange={handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table_wrapper: {
      paddingBottom: 20,
      height: '100vh',
    },
    headTableCell: {
      backgroundColor: '#F9F9F9',
      padding: '4px 16px !important',
      textWrap: 'nowrap',
    },
    headTableCellCheckbox: {
      backgroundColor: '#F9F9F9',
      padding: '0 !important',
    },
    headCellLabel: {
      color: '#7D7982 !important',
      fontSize: '12px !important',
      letterSpacing: '0.24px',
    },
    paper: {
      width: '100%',
      boxShadow: 'none !important',
    },
    table: {
      minWidth: 750,
    },
    selectedToolbarTitle: {
      fontSize: 15,
      fontWeight: 'bold',
    },
    toolbarBox: {
      width: '100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    collapsedBoxItem: {
      padding: 8,
      width: '100%',
    },
    dayLabel: {
      color: '#34303D',
      fontSize: 12,
    },
    hoursLabel: {
      color: '#34303D',
      fontSize: 9,
    },
    pagination: {
      backgroundColor: '#F9F9F9',
      color: '#34303D !important',
      fontStyle: 'normal',
      fontWeight: '400',
    },
  }),
);
