import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { Theme } from '@mui/material';
import { SnackBarActions } from '../../redux/actions';
import { RootState } from '../../redux/reducers';
import React from 'react';
import { Alert as MuiAlert, AlertProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SnackBar() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { snackbarMessage, snackbarOpen, snackbarType } = useSelector(
    (state: RootState) => state.UiReducer,
  );

  const handleClose = (
    event?: Event | React.SyntheticEvent<any, any>,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      dispatch(SnackBarActions.clearSnacks());
      return;
    }
    dispatch(SnackBarActions.clearSnacks());
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={(e) => handleClose(e)}
      >
        <Alert onClose={(e) => handleClose(e)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
