import { SvgIcon } from '@mui/material';
import React, { useMemo } from 'react';
import { useIconColor } from './IconColor';
import { IconProps } from './iconProps';

export const ArchiveIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  const size = useMemo<number>(() => (small ? 18 : 16), [small]);
  const position = useMemo<string>(() => (small ? '-2 -3' : '0 0'), [small]);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={`${position} ${size} ${size}`}
        fill="none"
      >
        <path
          d="M7.77796 5.40736L6.59277 4.22217L7.77796 3.03699"
          stroke={iconColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.3337 6.00002V10.1482C11.3337 10.803 10.8033 11.3334 10.1485 11.3334H1.85218C1.19736 11.3334 0.666992 10.803 0.666992 10.1482V1.85187C0.666992 1.19706 1.19736 0.666687 1.85218 0.666687H9.55588C10.5378 0.666687 11.3337 1.46254 11.3337 2.44446V2.44446C11.3337 3.42639 10.5378 4.22224 9.55588 4.22224H6.59292"
          stroke={iconColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.666992 7.77765H3.26373C3.48833 7.77765 3.69336 7.90446 3.79351 8.10535L4.0584 8.63513C4.15914 8.83602 4.36418 8.96283 4.58877 8.96283H7.41188C7.63647 8.96283 7.84151 8.83602 7.94166 8.63513L8.20655 8.10535C8.30729 7.90446 8.51232 7.77765 8.73692 7.77765H11.3337"
          stroke={iconColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
