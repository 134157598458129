import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

interface BookingConfirmedPageProps {
  startDate?: string;
  startTime?: string;
  isActive: boolean;
}

export default function BookingConfirmedPage({
  startDate,
  startTime,
  isActive,
}: BookingConfirmedPageProps) {
  const classes = useStyles();
  const error = useSelector(
    (state: RootState) => state.AppointmentsReducer.postAppointmentError,
  );

  return (
    <Grid
      className={isActive ? classes.confirmedContainer : classes.hidden}
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {error || !(startDate && startTime) ? (
        <Typography className={classes.title}>
          Qualcosa è andato storto. Riprova più tardi.
        </Typography>
      ) : (
        <div>
          <Typography className={classes.title}>Grazie!</Typography>
          <Typography className={classes.subtitle}>
            La tua prenotazione è stata salvata
          </Typography>
          <Typography className={classes.dateLabel}>
            {`L’appuntamento è stato fissato per il ${startDate} alle ${startTime}`}
          </Typography>
        </div>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmedContainer: {
      width: '100%',
    },
    hidden: {
      display: 'none !important',
    },
    title: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '700 !important',
      lineHeight: 'normal !important',
      textAlign: 'center',
      color: '#34303D',
    },
    subtitle: {
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: 'normal !important',
      textAlign: 'center',
      color: '#34303D',
      marginTop: '16px !important',
    },
    dateLabel: {
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '700 !important',
      lineHeight: 'normal !important',
      textAlign: 'center',
      color: '#34303D',
      marginBottom: '80px !important',
    },
  }),
);
