import React from 'react';
import { ServiceActions } from '../../redux/actions';
import { connect } from 'react-redux';
import './ServicesScreen.scss';
import ServicesTable from '../../components/services/table/ServicesTable';

interface ServicesScreenProps {
  getServices: () => void;
}
interface ServicesScreenState {}
export class ServicesScreen extends React.Component<
  ServicesScreenProps,
  ServicesScreenState
> {
  componentDidMount() {
    this.props.getServices();
  }
  render() {
    return (
      <div className="services-container">
        <ServicesTable />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getServices: () => dispatch(ServiceActions.getServices()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServicesScreen);
