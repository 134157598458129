import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ServiceActions } from "../actions";
import {
  HydratedServices,
  Service,
  TransNotifications,
  TransNotification,
} from "../../api/entities";

export interface ServiceState {
  hydratedService?: HydratedServices;
  loadingServices: boolean;
  servicesError: boolean;
  singleServiceLoading: boolean;
  singleServiceError: boolean;
  serviceDetail?: Service;
  postCatServicesError: boolean;
  postCatServicesLoading: boolean;
  transactionalNotifications?: TransNotifications;
  isLoadingTransNotifications: boolean;
  isPatchingTransNotifications: boolean;
}

const INITIAL_SERVICE_STATE: ServiceState = {
  loadingServices: false,
  servicesError: false,
  singleServiceError: false,
  singleServiceLoading: false,
  postCatServicesError: false,
  postCatServicesLoading: false,
  isLoadingTransNotifications: false,
  isPatchingTransNotifications: false,
};

const ServiceReducer = createReducer(INITIAL_SERVICE_STATE, (builder) => {
  builder
    .addCase(
      ServiceActions.getServicesSuccess,
      (state: ServiceState, action: PayloadAction<HydratedServices>) => {
        let hydratedService = action.payload;
        return {
          ...state,
          hydratedService,
          loadingServices: false,
          servicesError: false,
        };
      },
    )
    .addCase(ServiceActions.getServicesLoading, (state: ServiceState) => {
      return { ...state, loadingServices: true, servicesError: false };
    })
    .addCase(
      ServiceActions.getServicesError,
      (state: ServiceState, action: PayloadAction<any>) => {
        return {
          ...state,
          loadingServices: false,
          servicesError: action.payload,
        };
      },
    )
    .addCase(ServiceActions.getSingleServiceLoading, (state: ServiceState) => {
      return {
        ...state,
        singleServiceLoading: true,
        singleServiceError: false,
      };
    })
    .addCase(
      ServiceActions.getSingleServiceError,
      (state: ServiceState, action: PayloadAction<any>) => {
        return {
          ...state,
          singleServiceLoading: false,
          singleServiceError: action.payload,
        };
      },
    )
    .addCase(
      ServiceActions.getSingleServiceSuccess,
      (state: ServiceState, action: PayloadAction<Service>) => {
        let serviceDetail = action.payload;
        return {
          ...state,
          singleServiceLoading: false,
          singleServiceError: false,
          serviceDetail,
        };
      },
    )
    .addCase(
      ServiceActions.editServiceSuccess,
      (state: ServiceState, action: PayloadAction<Service>) => {
        let serviceDetail = action.payload;
        return {
          ...state,
          singleServiceLoading: false,
          singleServiceError: false,
          serviceDetail,
        };
      },
    )
    .addCase(
      ServiceActions.postCatServicesSuccess,
      (state: ServiceState, action: PayloadAction<Service>) => {
        let service = action.payload;
        return {
          ...state,
          postCatServicesLoading: false,
          postCatServicesError: false,
        };
      },
    )
    .addCase(
      ServiceActions.getTransactionalNotificationsLoading,
      (state: ServiceState) => {
        return { ...state, isLoadingTransNotifications: true };
      },
    )
    .addCase(
      ServiceActions.getTransactionalNotificationsSuccess,
      (state: ServiceState, action: PayloadAction<TransNotifications>) => {
        return {
          ...state,
          isLoadingTransNotifications: false,
          transactionalNotifications: action.payload,
        };
      },
    )
    .addCase(
      ServiceActions.getTransactionalNotificationsError,
      (state: ServiceState, action: PayloadAction<any>) => {
        return { ...state, isLoadingTransNotifications: false };
      },
    )
    .addCase(
      ServiceActions.patchTransactionalNotificationsLoading,
      (state: ServiceState) => {
        return { ...state, isPatchingTransNotifications: true };
      },
    )
    .addCase(
      ServiceActions.patchTransactionalNotificationsSuccess,
      (state: ServiceState, action: PayloadAction<TransNotification>) => {
        return { ...state, isPatchingTransNotifications: false };
      },
    )
    .addCase(
      ServiceActions.patchTransactionalNotificationsError,
      (state: ServiceState, action: PayloadAction<any>) => {
        return { ...state, isPatchingTransNotifications: false };
      },
    );
});

export default ServiceReducer;
