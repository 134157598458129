import { SvgIcon } from '@mui/material';
import { useIconColor } from './IconColor';
import React from 'react';
import { IconProps } from './iconProps';

export const StoreIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          d="M18.9369 11.74V18.961C18.9369 19.2366 18.8274 19.5008 18.6326 19.6957C18.4377 19.8905 18.1734 20 17.8979 20H6.25092C5.97536 20 5.71109 19.8905 5.51624 19.6957C5.32139 19.5008 5.21191 19.2366 5.21191 18.961V11.74"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.91609 4L8.62109 6.968"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.076 10.309C12.0871 10.7829 12.2831 11.2336 12.6222 11.5648C12.9613 11.896 13.4165 12.0814 13.8904 12.0814C14.3644 12.0814 14.8196 11.896 15.1587 11.5648C15.4978 11.2336 15.6938 10.7829 15.705 10.309C15.7651 10.8095 16.0067 11.2704 16.384 11.6047C16.7612 11.939 17.2479 12.1234 17.752 12.123C18.2949 12.123 18.8155 11.9073 19.1994 11.5234C19.5833 11.1396 19.799 10.6189 19.799 10.076C19.7995 9.26287 19.6799 8.45416 19.444 7.67603L18.775 5.47601C18.6452 5.04956 18.3819 4.6761 18.0238 4.41071C17.6657 4.14531 17.2317 4.00202 16.7859 4.00201H7.37C6.92425 4.00202 6.49027 4.14531 6.13214 4.41071C5.77402 4.6761 5.5107 5.04956 5.38098 5.47601L4.71198 7.67603C4.47571 8.45412 4.35573 9.26282 4.35596 10.076C4.35622 10.619 4.57213 11.1396 4.95618 11.5235C5.34022 11.9074 5.861 12.123 6.40399 12.123C6.90804 12.1234 7.39474 11.939 7.77197 11.6047C8.14921 11.2704 8.3908 10.8095 8.45099 10.309C8.45099 10.7901 8.64211 11.2515 8.9823 11.5917C9.32249 11.9319 9.78385 12.123 10.265 12.123C10.7461 12.123 11.2075 11.9319 11.5477 11.5917C11.8879 11.2515 12.079 10.7901 12.079 10.309H12.076Z"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.2368 4L15.5318 6.968"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.0762 4V6.968"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </svg>
    </SvgIcon>
  );
};
