import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Autocomplete, Box } from '@mui/material';

const capacities: string[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      width: '100%',
    },
    box: {
      width: '100%',
      marginBottom: '24px',
    },
  }),
);
interface CapacityTextFieldProps {
  onSelectValue: (value: string) => void;
  capacity?: string;
}
export default function CapacityTextField({
  onSelectValue,
  capacity,
}: CapacityTextFieldProps) {
  const classes = useStyles();

  const handleChange = (value: string | null) => {
    if (value) onSelectValue(value);
  };

  return (
    <Box className={classes.box}>
      <Autocomplete
        noOptionsText="Nessun risultato"
        fullWidth
        id="standard-select-capacity"
        value={capacity || '1'}
        color="secondary"
        size="small"
        options={capacities || []}
        onChange={(e: any, option: string | null) => handleChange(option)}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            color="secondary"
            label="Seleziona Capacità"
            size="small"
            inputProps={{
              ...params.inputProps,
              style: {
                padding: '6px 10px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
          />
        )}
      />
    </Box>
  );
}
