import { compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";
import { configureStore } from "@reduxjs/toolkit";

const middleware = [thunk]; // logger
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const store = configureStore({
  reducer: rootReducer,
  middleware,
});
export type AppDispatch = typeof store.dispatch;
export default store;
