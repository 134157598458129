import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

const REGION_OPTIONS: string[] = [
  'Abruzzo',
  'Basilicata',
  'Calabria',
  'Campania',
  'Emilia-Romagna',
  'Friuli-Venezia Giulia',
  'Lazio',
  'Liguria',
  'Lombardia',
  'Marche',
  'Molise',
  'Piemonte',
  'Puglia',
  'Sardegna',
  'Sicilia',
  'Toscana',
  'Trentino-Alto Adige ',
  'Umbria',
  `Valle d'Aosta`,
  'Veneto',
];
interface RegionTextFieldProps {
  regionName?: string;
  onSelect: (region: string) => void;
}

export default function RegionTextField({
  regionName,
  onSelect,
}: RegionTextFieldProps) {
  const handleChange = (value: string | null) => {
    if (value) onSelect(value);
  };

  return (
    <Autocomplete
      noOptionsText="Nessuna regione"
      fullWidth
      id="standard-select-region"
      value={regionName}
      color="secondary"
      size="small"
      options={REGION_OPTIONS || []}
      onChange={(e: any, option: string | null) => handleChange(option)}
      renderInput={(params: any) => (
        <TextField
          {...params}
          fullWidth
          id="standard-select"
          label="Regione"
          size="small"
          color="secondary"
          style={{ marginBottom: '24px' }}
          inputProps={{
            ...params.inputProps,
            style: {
              padding: '6px 16px',
            },
          }}
          InputLabelProps={{
            style: {
              top: '4px',
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          {option}
        </li>
      )}
    />
  );
}
