import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { RootState } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import BookingCheckboxButton from './BookingCheckboxButton';
import { PRODUCT_VEEV } from '../../utils/validators';

interface AppointmentProductFieldProps {
  select: (product: string) => void;
  selectedProduct: string;
}

export default function AppointmentProductField({
  select,
  selectedProduct,
}: AppointmentProductFieldProps) {
  const classes = useStyles();

  const products = useSelector((state: RootState) =>
    state.StoreReducer.currentSelectedStore?.products
      ? state.StoreReducer.currentSelectedStore.products.map((obj) => {
          return obj.productName;
        })
      : [],
  );

  return (
    <Box className={classes.box}>
      {(products || [])
        .filter((p) => !!p && p !== PRODUCT_VEEV)
        .map((product) => (
          <BookingCheckboxButton
            key={product!}
            noCheckmark
            name={product!}
            selected={selectedProduct === product}
            onSelected={() => select(product!)}
          />
        ))}
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      width: '100%',
    },
  }),
);
