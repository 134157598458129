import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { AxiosResponse } from "axios";
import { AppointmentApi } from "../../api/services/nbp/appointment-api";
import {
  HydratedAppointment,
  Appointment,
  AppointmentRequest,
} from "../../api/entities";
import SnackBarActions from "../actions/UI/SnackBarActions";

//Api Service instance from open api generator
let api = new AppointmentApi();

//Appointments
//Appointment

//GET APPOINTMENTS
const getAppointmentsSuccess = createAction<HydratedAppointment>(
  "appointment/getAppointmentsSuccess",
);
const getAppointmentsLoading = createAction(
  "appointment/getAppointmentsLoading",
);
const getAppointmentsError = createAction<any>(
  "appointment/getAppointmentsError",
);

const getAppointments =
  (_id: string, _startDay: string, _endDay: string) =>
  (dispatch: AppDispatch) => {
    let id = _id;
    let notes = undefined;
    let startDay = _startDay;
    let endDay = _endDay;
    let onlyArchived = undefined;
    let hasArrived = undefined;
    let properties = undefined;
    let stores = undefined;
    let page = undefined;
    let itemsPerPage = undefined;
    let pagination = false;
    let options = undefined;

    dispatch(getAppointmentsLoading());
    api
      .getAllAppointmentsFromStoreAppointmentCollection(
        id,
        notes,
        startDay,
        endDay,
        onlyArchived,
        hasArrived,
        properties,
        stores,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((response: AxiosResponse<HydratedAppointment>) => {
        dispatch(getAppointmentsSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(getAppointmentsError(error));
      });
  };

//GET SINGLE APPOINTMENT
const getSingleAppointmentSuccess = createAction<Appointment>(
  "appointment/getSingleAppointmentSuccess",
);
const getSingleAppointmentError = createAction<any>(
  "appointment/getSingleAppointmentError",
);
const getSingleAppointmentLoading = createAction(
  "appointment/getSingleAppointmentLoading",
);

const getSingleAppointment = (id: string) => (dispatch: AppDispatch) => {
  dispatch(getSingleAppointmentLoading());
  api
    .getAppointmentItem(id)
    .then((response: AxiosResponse<Appointment>) => {
      dispatch(getSingleAppointmentSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(getSingleAppointmentError(error));
    });
};

//POST APPOINTMENT
const postAppointmentSuccess = createAction<Appointment>(
  "appointment/postAppointmentSuccess",
);
const postAppointmentError = createAction<any>(
  "appointment/postAppoitntmentError",
);
const postAppointmentLoading = createAction(
  "appointment/postAppoitntmentLoading",
);

const postAppointment =
  (storeServiceId: string, data: AppointmentRequest) =>
  (dispatch: AppDispatch) => {
    dispatch(postAppointmentLoading());
    api
      .postAppointmentByStoreServiceIDAppointmentCollection(
        storeServiceId,
        data,
      )
      .then((response: AxiosResponse<any>) => {
        dispatch(postAppointmentSuccess(response.data));
        dispatch(
          SnackBarActions.snackbar(
            "L’appuntamento è stato creato con successo",
            "success",
          ),
        );
      })
      .catch((error: any) => {
        dispatch(postAppointmentError(error));
      });
  };

//PATCH APPOINTMENT
const patchAppointmentSuccess = createAction<Appointment>(
  "appointment/patchAppointmentSuccess",
);
const patchAppointmentError = createAction<any>(
  "appointment/patchAppoitntmentError",
);
const patchAppointmentLoading = createAction(
  "appointment/patchAppoitntmentLoading",
);

const patchAppointment =
  (id: string, data: AppointmentRequest) => (dispatch: AppDispatch) => {
    // console.log('PUT APPOINTMENT', data)
    dispatch(patchAppointmentLoading());
    api
      .patchAppointmentItem(id, data)
      .then((response: AxiosResponse<Appointment>) => {
        dispatch(patchAppointmentSuccess(response.data));
        dispatch(
          SnackBarActions.snackbar("Modifica appuntamento riuscita", "success"),
        );
      })
      .catch((error: any) => {
        dispatch(patchAppointmentError(error));
        dispatch(
          SnackBarActions.snackbar(
            error.response.data["hydra:description"]
              ? error.response.data["hydra:description"]
              : "Qualcosa è andato storto",
            "error",
          ),
        );
      });
  };

//DELETE APPOINTMENT
const deleteAppointmentSuccess = createAction<string>(
  "appointment/deleteAppointmentSuccess",
);
const deleteAppointmentError = createAction<any>(
  "appointment/deleteAppoitntmentError",
);
const deleteAppointmentLoading = createAction(
  "appointment/deleteAppoitntmentLoading",
);

const deleteAppointment =
  (id: string, notifEnabled: boolean) => (dispatch: AppDispatch) => {
    dispatch(deleteAppointmentLoading());
    api
      .deleteAppointmentItem(id, notifEnabled)
      .then((response: AxiosResponse<any>) => {
        dispatch(deleteAppointmentSuccess(id));
        dispatch(
          SnackBarActions.snackbar("Appuntamento cancellato", "success"),
        );
      })
      .catch((error: any) => {
        dispatch(deleteAppointmentError(error));
      });
  };

export default {
  getAppointments,
  getAppointmentsLoading,
  getAppointmentsError,
  getAppointmentsSuccess,
  getSingleAppointment,
  getSingleAppointmentError,
  getSingleAppointmentLoading,
  getSingleAppointmentSuccess,
  patchAppointment,
  patchAppointmentSuccess,
  patchAppointmentError,
  patchAppointmentLoading,
  deleteAppointment,
  deleteAppointmentSuccess,
  deleteAppointmentError,
  deleteAppointmentLoading,
  postAppointment,
  postAppointmentLoading,
  postAppointmentSuccess,
  postAppointmentError,
};
