import React from 'react';
import { TextField } from '@mui/material';

interface FormInputPasswordProps {
  onChange: (text: string) => void;
  onSubmit: () => void;
}

export class FormInputPassword extends React.Component<FormInputPasswordProps> {
  render() {
    return (
      <TextField
        fullWidth
        margin="dense"
        id="outlined-password-input"
        variant="outlined"
        label={'Password '}
        size="small"
        style={{ marginBottom: '12px', marginTop: '12px' }}
        inputProps={{
          style: {
            padding: '12px 16px',
          },
        }}
        InputLabelProps={{
          style: {
            top: '4px',
          },
        }}
        type="password"
        color="secondary"
        autoComplete="current-password"
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            this.props.onSubmit();
          }
        }}
        onChange={(event) => this.props.onChange(event.target.value)}
      />
    );
  }
}
