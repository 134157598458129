import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Box, IconButton, Drawer, List } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import {
  AuthActions,
  ModalsActions,
  StoreActions,
  UsersActions,
} from '../../redux/actions';
import ActionDialog from '../dialog/ActionDialog';
import UserRoleLabel from '../users/table/UserRoleLabel';
import NotificationOverlay from '../notifications/NotificationOverlay/NotificationOverlay';
import { CloseSidebarIcon } from '../icons/CloseSidebarIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { NotificationProvider } from '../notifications/NotificationContext';
import { SideNavigation } from './SideNavigation';
import { NotificationButton } from '../notifications/NotificationButton';

const drawerWidth = 240;

interface Props {
  children: React.ReactNode;
}

export default function Scaffold({ children }: Props) {
  // @ts-ignore
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const currentRole = useSelector(
    (state: RootState) => state.UsersReducer.currentRole,
  );
  const isLoggedIn = useSelector(
    (state: RootState) => state.AuthReducer.loggedIn,
  );
  const showLogOutDialogSuccess = useSelector(
    (state: RootState) => state.UiReducer.showLogOutDialogSuccess,
  );
  const dispatch = useDispatch();

  const [openNotifications, setOpenNotifications] = useState(false);
  const [notyPopoverAnchorEl, setNotyPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const toggleNotifications = () => {
    setOpenNotifications(!openNotifications);
  };

  const handleNotificationClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const newValue = !openNotifications;

    toggleNotifications();
    setNotyPopoverAnchorEl(newValue ? event.currentTarget : null);
  };

  const isActive = useCallback(
    (path: string) => location.pathname.includes(path),
    [location],
  );

  return (
    <div className={classes.root}>
      {isLoggedIn && (
        <NotificationProvider>
          <AppBar
            sx={{
              backgroundColor: '#34303D',
              zIndex: 1201,
            }}
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar
              sx={{
                width: 'fill-available',
                justifyContent: 'space-between',
              }}
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <IconButton
                  aria-label={`${open ? 'close' : 'open'} drawer`}
                  onClick={() => setOpen(!open)}
                  edge="start"
                  className={clsx(
                    classes.menuButton,
                    open ? classes.menuButtonOpen : classes.menuButtonClose,
                  )}
                >
                  {open ? <CloseSidebarIcon /> : <MenuIcon />}
                </IconButton>
                <h1 className={classes.appName}>BOOKING PLATFORM</h1>
              </Box>
              <Box
                display="flex"
                className={classes.rightItemBox}
                alignItems="center"
              >
                <div style={{ marginRight: '10px' }}>
                  <NotificationButton
                    active={isActive('/notifications')}
                    onClick={handleNotificationClick}
                  />
                </div>
                {openNotifications && (
                  <NotificationOverlay
                    anchor={notyPopoverAnchorEl!}
                    onClose={handleNotificationClick}
                  />
                )}
                <UserRoleLabel role={currentRole} menu />
              </Box>
            </Toolbar>
          </AppBar>

          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerContent]: true,
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <Box
              sx={{
                backgroundColor: '#34303D',
              }}
            >
              <List>
                <SideNavigation closeDrawer={() => setOpen(false)} />
              </List>
            </Box>
          </Drawer>

          <main className={classes.content}>
            <div className={classes.toolbar} />
            {children}
          </main>

          <ActionDialog
            open={showLogOutDialogSuccess}
            title="Log out"
            message="Sei sicuro di voler uscire?"
            onAction={() => {
              dispatch(StoreActions.setCurrentSelectedStore(undefined));
              dispatch(AuthActions.setLogin(false));
              dispatch(UsersActions.setCurrentRole(undefined));
              dispatch(ModalsActions.closeModal());
            }}
            onClose={() => dispatch(ModalsActions.closeModal())}
            actionTitle="Sì"
          />
        </NotificationProvider>
      )}

      {!isLoggedIn && <main className={classes.content}>{children}</main>}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appName: {
      color: '#fff !important',
      fontSize: '16px !important',
      fontWeight: '400 !important',
      margin: ' 0 0 0 20px',
      letterSpacing: '0.25px',
    },
    appBar: {
      backgroundColor: '#34303D',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: 'none !important',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 0,
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.05) !important',
      },
    },
    menuButtonOpen: {
      marginLeft: `calc(${drawerWidth}px - 88px) !important`,
    },
    menuButtonClose: {
      marginLeft: '-7px !important',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerContent: {
      top: '64px !important',
      backgroundColor: '#34303D !important',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(8),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(10),
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
    },
    rightItemBox: {
      height: 40,
    },
  }),
);
