import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { BusinessHoursActions } from "../actions";
import _ from "lodash";
import {
  BusinessHour,
  BusinessHours,
  HydratedBusinessHours,
  HydratedSpecialHoursPeriods,
  SpecialHourPeriod,
} from "../../api/entities";

export type GroupedTimePeriods = {
  openDay: string;
  isClosed: boolean;
  times: BusinessHour[];
};

export type BusinessHoursState = {
  //HYDRA
  hydratedBusinessHours?: HydratedBusinessHours;
  hydratedSpecialHours?: HydratedSpecialHoursPeriods;

  //SPECIAL HOURS
  patchSpecialHourPeriodError: boolean;
  patchSpecialHourPeriodLoading: boolean;
  deleteSpecialHourPeriodError: boolean;
  deleteSpecialHourPeriodLoading: boolean;
  postSpecialHourPeriodError: boolean;
  postSpecialHourPeriodLoading: boolean;
  specialHoursLoading: boolean;
  specialHoursError: any;
  //BUSINESS HOURS
  businessHoursLoading: boolean;
  businessHoursError: any;
  createBusinessHoursLoading: boolean;
  createBusinessHoursError: any;
  deleteBusinessHoursLoading: boolean;
  deleteBusinessHoursError: any;
  updateBusinessHoursLoading: boolean;
  updateBusinessHoursError: any;
  // TIME PERIODS
  createTimePeriodSuccess: boolean;
  createTimePeriodLoading: boolean;
  createTimePeriodError: any;
  deleteTimePeriodSuccess: boolean;
  deleteTimePeriodLoading: boolean;
  deleteTimePeriodError: any;
  updateTimePeriodSuccess: boolean;
  updateTimePeriodLoading: boolean;
  updateTimePeriodError: any;
};

const INITIAL_BUSINESS_HOURS_STATE: BusinessHoursState = {
  //SPECIAL HOURS
  patchSpecialHourPeriodError: false,
  patchSpecialHourPeriodLoading: false,
  deleteSpecialHourPeriodError: false,
  deleteSpecialHourPeriodLoading: false,
  postSpecialHourPeriodError: false,
  postSpecialHourPeriodLoading: false,
  specialHoursError: false,
  specialHoursLoading: false,
  //BUSINESS HOURS
  businessHoursError: false,
  businessHoursLoading: false,
  createBusinessHoursLoading: false,
  createBusinessHoursError: false,
  deleteBusinessHoursLoading: false,
  deleteBusinessHoursError: false,
  updateBusinessHoursError: false,
  updateBusinessHoursLoading: false,
  // TIME PERIODS
  createTimePeriodSuccess: false,
  createTimePeriodLoading: false,
  createTimePeriodError: false,
  deleteTimePeriodSuccess: false,
  deleteTimePeriodLoading: false,
  deleteTimePeriodError: false,
  updateTimePeriodSuccess: false,
  updateTimePeriodLoading: false,
  updateTimePeriodError: false,
};

const addBusinessHours = (
  hydratedBusinessHours: HydratedBusinessHours | undefined,
  newHours: BusinessHours,
) => {
  if (hydratedBusinessHours) {
    let array = {
      ...hydratedBusinessHours,
      "hydra:member": [newHours, ...hydratedBusinessHours?.["hydra:member"]],
    };
    let grouped = groupTimePeriods(array);
    return grouped;
  }
  let grouped = groupTimePeriods(hydratedBusinessHours);
  return grouped;
};

const addSpecialHourPeriod = (
  hydratedSpecialHours: HydratedSpecialHoursPeriods | undefined,
  newPeriod: SpecialHourPeriod,
) => {
  if (hydratedSpecialHours) {
    return {
      ...hydratedSpecialHours,
      "hydra:member": [newPeriod, ...hydratedSpecialHours?.["hydra:member"]],
    };
  }
  return hydratedSpecialHours;
};

const updateSpecialHourPeriod = (
  hydratedSpecialHours: HydratedSpecialHoursPeriods | undefined,
  newPeriod: SpecialHourPeriod,
) => {
  if (hydratedSpecialHours) {
    let index = _.findIndex(
      hydratedSpecialHours["hydra:member"],
      (member) => member.uuid === newPeriod.uuid,
    );
    let newArray = [
      ...hydratedSpecialHours["hydra:member"].slice(0, index),
      newPeriod,
      ...hydratedSpecialHours["hydra:member"].slice(index + 1),
    ];
    return { ...hydratedSpecialHours, "hydra:member": newArray };
  }
  return hydratedSpecialHours;
};

const deleteSpecialHourPeriod = (
  hydratedSpecialHours: HydratedSpecialHoursPeriods | undefined,
  deletedID: string,
) => {
  // console.log('spec hours before delete', hydratedSpecialHours)
  if (hydratedSpecialHours) {
    let filteredMembers = hydratedSpecialHours?.["hydra:member"].filter(
      (member) => member.uuid !== deletedID,
    );
    // console.log('filtered hours delete', hydratedSpecialHours)
    return { ...hydratedSpecialHours, "hydra:member": filteredMembers };
  }
  return hydratedSpecialHours;
};

const deleteBusinessHour = (
  hydratedBusinessHours: HydratedBusinessHours | undefined,
  deleteId: string,
) => {
  if (hydratedBusinessHours) {
    let filteredMembers = hydratedBusinessHours?.["hydra:member"].filter(
      (member) => member.uuid !== deleteId,
    );
    return { ...hydratedBusinessHours, "hydra:member": filteredMembers };
  }
  return hydratedBusinessHours;
};

const groupTimePeriods = (hydratedBusinessHours?: HydratedBusinessHours) => {
  if (hydratedBusinessHours) {
    let newBusinessHoursArray = hydratedBusinessHours["hydra:member"].map(
      (businessHour) => {
        const groupedTimePeriods = _.groupBy(
          businessHour.timePeriods,
          "openDay",
        );
        let newTimePeriods: GroupedTimePeriods[] = [];
        // console.log('grouped time periods', groupTimePeriods)
        _.forOwn(groupedTimePeriods, (value, key) => {
          newTimePeriods.push({
            openDay: key,
            isClosed:
              value.length > 0
                ? value[0].isClosed
                  ? value[0].isClosed
                  : false
                : false, //if first is closed all day is closed
            times: value.map((object) => {
              return object;
            }),
          });
        });

        return {
          ...businessHour,
          timePeriods: newTimePeriods,
        };
      },
    );
    return { ...hydratedBusinessHours, "hydra:member": newBusinessHoursArray };
  }
  return hydratedBusinessHours;
};

const addTimePeriod = (
  newTimePeriod: any,
  hydratedBusinessHours?: HydratedBusinessHours,
) => {
  if (hydratedBusinessHours) {
    let index = hydratedBusinessHours["hydra:member"].findIndex(
      (member) => member.uuid === newTimePeriod.businessHours.uuid,
    );
    let businessHour: any = hydratedBusinessHours["hydra:member"][index];
    let timePeriodIndex = businessHour.timePeriods?.findIndex(
      (timePeriod: any) => timePeriod.openDay === newTimePeriod.openDay,
    );
    let timePeriod = businessHour.timePeriods[timePeriodIndex];
    let newTimesArray = [...timePeriod.times, newTimePeriod];
    let newTimePeriodsArray = [
      ...businessHour.timePeriods?.slice(0, timePeriodIndex),
      { ...timePeriod, times: newTimesArray },
      ...businessHour.timePeriods?.slice(timePeriodIndex + 1),
    ];
    let newBusinessHoursArray = [
      ...hydratedBusinessHours["hydra:member"].slice(0, index),
      {
        ...hydratedBusinessHours["hydra:member"][index],
        timePeriods: newTimePeriodsArray,
      },
      ...hydratedBusinessHours["hydra:member"].slice(index + 1),
    ];
    return { ...hydratedBusinessHours, "hydra:member": newBusinessHoursArray };
  }
  return hydratedBusinessHours;
};

const deleteTimePeriod = (
  timePeriodId: string,
  businessHourId: string,
  day: string,
  hydratedBusinessHours?: HydratedBusinessHours,
) => {
  if (hydratedBusinessHours) {
    let index = hydratedBusinessHours["hydra:member"].findIndex(
      (member) => member.uuid === businessHourId,
    );
    let businessHour: any = hydratedBusinessHours["hydra:member"][index];
    let timePeriodIndex = businessHour.timePeriods?.findIndex(
      (timePeriod: any) => timePeriod.openDay === day,
    );
    let timePeriod = businessHour.timePeriods[timePeriodIndex];
    let newTimesArray = timePeriod.times.filter(
      (time: any) => time.uuid !== timePeriodId,
    );
    let newTimePeriodsArray = [];
    if (newTimesArray.length > 0) {
      // delete only one time period
      newTimePeriodsArray = [
        ...businessHour.timePeriods?.slice(0, timePeriodIndex),
        { ...timePeriod, times: newTimesArray },
        ...businessHour.timePeriods?.slice(timePeriodIndex + 1),
      ];
    } else {
      // delete whole day if times.length === 0
      newTimePeriodsArray = businessHour.timePeriods.filter(
        (timePeriod: any) => timePeriod.openDay !== day,
      );
    }

    let newBusinessHoursArray = [
      ...hydratedBusinessHours["hydra:member"].slice(0, index),
      {
        ...hydratedBusinessHours["hydra:member"][index],
        timePeriods: newTimePeriodsArray,
      },
      ...hydratedBusinessHours["hydra:member"].slice(index + 1),
    ];
    return { ...hydratedBusinessHours, "hydra:member": newBusinessHoursArray };
  }
  return hydratedBusinessHours;
};

const updateTimePeriod = (
  updatedTimePeriod: any,
  hydratedBusinessHours?: HydratedBusinessHours,
) => {
  if (hydratedBusinessHours) {
    let index = hydratedBusinessHours["hydra:member"].findIndex(
      (member) => member.uuid === updatedTimePeriod.businessHours.uuid,
    );
    let businessHour: any = hydratedBusinessHours["hydra:member"][index];
    let timePeriodIndex = businessHour.timePeriods?.findIndex(
      (timePeriod: any) => timePeriod.openDay === updatedTimePeriod.openDay,
    );
    let timePeriod = businessHour.timePeriods[timePeriodIndex];
    let timeSlotIndex = timePeriod.times.findIndex(
      (time: any) => time.uuid === updatedTimePeriod.uuid,
    );

    let newTimesArray = [
      ...timePeriod.times.slice(0, timeSlotIndex),
      updatedTimePeriod,
      ...timePeriod.times.slice(timeSlotIndex + 1),
    ];
    let newTimePeriodsArray = [
      ...businessHour.timePeriods?.slice(0, timePeriodIndex),
      { ...timePeriod, times: newTimesArray },
      ...businessHour.timePeriods?.slice(timePeriodIndex + 1),
    ];
    let newBusinessHoursArray = [
      ...hydratedBusinessHours["hydra:member"].slice(0, index),
      {
        ...hydratedBusinessHours["hydra:member"][index],
        timePeriods: newTimePeriodsArray,
      },
      ...hydratedBusinessHours["hydra:member"].slice(index + 1),
    ];
    return { ...hydratedBusinessHours, "hydra:member": newBusinessHoursArray };
  }
  return hydratedBusinessHours;
};

const BusinessHoursReducer = createReducer(
  INITIAL_BUSINESS_HOURS_STATE,
  (builder) =>
    builder

      // GET BUSINESS HOURS
      .addCase(
        BusinessHoursActions.getBusinessHoursSuccess,
        (
          state: BusinessHoursState,
          action: PayloadAction<HydratedBusinessHours>,
        ) => {
          let hydratedBusinessHours = action.payload;
          let grouped = groupTimePeriods(hydratedBusinessHours);
          return {
            ...state,
            hydratedBusinessHours: grouped,
            businessHoursError: false,
            businessHoursLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.getBusinessHoursError,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let businessHoursError = action.payload;
          return { ...state, businessHoursError, businessHoursLoading: false };
        },
      )
      .addCase(
        BusinessHoursActions.getBusinessHoursLoading,
        (state: BusinessHoursState) => {
          return {
            ...state,
            businessHoursError: false,
            businessHoursLoading: true,
          };
        },
      )

      //POST SPECIAL HOUR PERIOD
      .addCase(
        BusinessHoursActions.postSpecialHourPeriodSuccess,
        (
          state: BusinessHoursState,
          action: PayloadAction<SpecialHourPeriod>,
        ) => {
          let specialHourPeriod = action.payload;

          return {
            ...state,
            hydratedSpecialHours: addSpecialHourPeriod(
              state.hydratedSpecialHours,
              specialHourPeriod,
            ),
            postSpecialHourPeriodError: false,
            postSpecialHourPeriodLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.postSpecialHourPeriodLoading,
        (state: BusinessHoursState) => {
          return {
            ...state,
            postSpecialHourPeriodError: false,
            postSpecialHourPeriodLoading: true,
          };
        },
      )
      .addCase(
        BusinessHoursActions.postSpecialHourPeriodError,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let error = action.payload;
          return {
            ...state,
            postSpecialHourPeriodError: error,
            postSpecialHourPeriodLoading: false,
          };
        },
      )

      //PATCH SPECIAL HOUR PERIOD
      .addCase(
        BusinessHoursActions.patchSpecialHourPeriodSuccess,
        (
          state: BusinessHoursState,
          action: PayloadAction<SpecialHourPeriod>,
        ) => {
          let specialHourPeriod = action.payload;

          return {
            ...state,
            hydratedSpecialHours: updateSpecialHourPeriod(
              state.hydratedSpecialHours,
              specialHourPeriod,
            ),
            patchSpecialHourPeriodError: false,
            patchSpecialHourPeriodLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.patchSpecialHourPeriodLoading,
        (state: BusinessHoursState) => {
          return {
            ...state,
            patchSpecialHourPeriodError: false,
            patchSpecialHourPeriodLoading: true,
          };
        },
      )
      .addCase(
        BusinessHoursActions.patchSpecialHourPeriodError,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let error = action.payload;
          return {
            ...state,
            patchSpecialHourPeriodError: error,
            patchSpecialHourPeriodLoading: false,
          };
        },
      )

      //DELETE SPECIAL HOUR
      .addCase(
        BusinessHoursActions.deleteSpecialHourPeriodSuccess,
        (state: BusinessHoursState, action: PayloadAction<string>) => {
          let id = action.payload;

          return {
            ...state,
            hydratedSpecialHours: deleteSpecialHourPeriod(
              state.hydratedSpecialHours,
              id,
            ),
            deleteSpecialHourPeriodError: false,
            deleteSpecialHourPeriodLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.deleteSpecialHourPeriodLoading,
        (state: BusinessHoursState) => {
          return {
            ...state,
            deleteSpecialHourPeriodError: false,
            deleteSpecialHourPeriodLoading: true,
          };
        },
      )
      .addCase(
        BusinessHoursActions.deleteSpecialHourPeriodError,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let error = action.payload;
          return {
            ...state,
            deleteSpecialHourPeriodError: error,
            deleteSpecialHourPeriodLoading: false,
          };
        },
      )

      // GET SPECIAL HOUR
      .addCase(
        BusinessHoursActions.getSpecialHoursSuccess,
        (
          state: BusinessHoursState,
          action: PayloadAction<HydratedSpecialHoursPeriods>,
        ) => {
          let hydratedSpecialHours = action.payload;
          return {
            ...state,
            hydratedSpecialHours,
            specialHoursError: false,
            specialHoursLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.getSpecialHoursError,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let specialHoursError = action.payload;
          return { ...state, specialHoursError, specialHoursLoading: false };
        },
      )
      .addCase(
        BusinessHoursActions.getSpecialHoursLoading,
        (state: BusinessHoursState) => {
          return {
            ...state,
            specialHoursError: false,
            specialHoursLoading: true,
          };
        },
      )

      // ADD BUSINESS HOUR
      .addCase(
        BusinessHoursActions.createBusinessHoursSuccess,
        (state: BusinessHoursState, action: PayloadAction<BusinessHours>) => {
          let newBusinessHours = action.payload;
          return {
            ...state,
            hydratedBusinessHours: addBusinessHours(
              state.hydratedBusinessHours,
              newBusinessHours,
            ),
            createBusinessHoursError: false,
            createBusinessHoursLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.createBusinessHoursLoading,
        (state: BusinessHoursState) => {
          return { ...state, createBusinessHoursLoading: true };
        },
      )
      .addCase(
        BusinessHoursActions.createBusinessHoursError,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let createBusinessHoursError = action.payload;
          return {
            ...state,
            createBusinessHoursError,
            createBusinessHoursLoading: false,
          };
        },
      )

      // UPDATE BUSINESS HOUR
      .addCase(
        BusinessHoursActions.updateBusinessHoursSuccess,
        (state: BusinessHoursState, action: PayloadAction<BusinessHours>) => {
          // not call above , tmp remove it manual update redux state
          return {
            ...state,
            updateBusinessHoursError: false,
            updateBusinessHoursLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.updateBusinessHoursLoading,
        (state: BusinessHoursState) => {
          return {
            ...state,
            updateBusinessHoursError: false,
            updateBusinessHoursLoading: true,
          };
        },
      )
      .addCase(
        BusinessHoursActions.updateBusinessHoursError,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let updateBusinessHoursError = action.payload;
          return {
            ...state,
            updateBusinessHoursError,
            updateBusinessHoursLoading: false,
          };
        },
      )

      // DELETE BUSINESS HOUR
      .addCase(
        BusinessHoursActions.deleteBusinessHoursSuccess,
        (state: BusinessHoursState, action: PayloadAction<string>) => {
          let deleteId = action.payload;
          return {
            ...state,
            hydratedBusinessHours: deleteBusinessHour(
              state.hydratedBusinessHours,
              deleteId,
            ),
            deleteBusinessHoursError: false,
            deleteBusinessHoursLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.deleteBusinessHoursLoading,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let deleteBusinessHoursError = action.payload;
          return {
            ...state,
            deleteBusinessHoursError,
            deleteBusinessHoursLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.deleteBusinessHoursError,
        (state: BusinessHoursState) => {
          return {
            ...state,
            deleteBusinessHoursError: false,
            deleteBusinessHoursLoading: true,
          };
        },
      )

      // ADD TIME PERIOD
      .addCase(
        BusinessHoursActions.createTimePeriodSuccess,
        (state: BusinessHoursState, action: PayloadAction<BusinessHours>) => {
          let newTimePeriod = action.payload;
          return {
            ...state,
            hydratedBusinessHours: addTimePeriod(
              newTimePeriod,
              state.hydratedBusinessHours,
            ),
            createTimePeriodSuccess: true,
            createTimePeriodError: false,
            createTimePeriodLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.createTimePeriodError,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let createTimePeriodError = action.payload;
          return {
            ...state,
            createTimePeriodError,
            createTimePeriodLoading: false,
            createTimePeriodSuccess: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.createTimePeriodLoading,
        (state: BusinessHoursState) => {
          return {
            ...state,
            createTimePeriodError: false,
            createTimePeriodLoading: true,
            createTimePeriodSuccess: false,
          };
        },
      )

      // DELETE TIME PERIOD
      .addCase(
        BusinessHoursActions.deleteTimePeriodSuccess,
        (
          state: BusinessHoursState,
          action: PayloadAction<{
            timePeriodId: string;
            businessHourId: string;
            day: string;
          }>,
        ) => {
          const businessHourId = action.payload.businessHourId;
          const day = action.payload.day;
          const timePeriodId = action.payload.timePeriodId;
          return {
            ...state,
            hydratedBusinessHours: deleteTimePeriod(
              timePeriodId,
              businessHourId,
              day,
              state.hydratedBusinessHours,
            ),
            deleteTimePeriodSuccess: true,
            deleteTimePeriodError: false,
            deleteTimePeriodLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.deleteTimePeriodError,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let deleteTimePeriodError = action.payload;
          return {
            ...state,
            deleteTimePeriodError,
            deleteTimePeriodLoading: false,
            deleteTimePeriodSuccess: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.deleteTimePeriodLoading,
        (state: BusinessHoursState) => {
          return {
            ...state,
            deleteTimePeriodError: false,
            deleteTimePeriodLoading: true,
            deleteTimePeriodSuccess: false,
          };
        },
      )

      // UPDATE TIME PERIOD (OPEN-CLOSE) TIME
      .addCase(
        BusinessHoursActions.updateTimePeriodSuccess,
        (
          state: BusinessHoursState,
          action: PayloadAction<{
            timePeriodId: string;
            businessHourId: string;
            day: string;
          }>,
        ) => {
          const updatedTimePeriod = action.payload;
          return {
            ...state,
            hydratedBusinessHours: updateTimePeriod(
              updatedTimePeriod,
              state.hydratedBusinessHours,
            ),
            updateTimePeriodSuccess: true,
            updateTimePeriodError: false,
            updateTimePeriodLoading: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.updateTimePeriodError,
        (state: BusinessHoursState, action: PayloadAction<any>) => {
          let updateTimePeriodError = action.payload;
          return {
            ...state,
            updateTimePeriodError,
            updateTimePeriodLoading: false,
            updateTimePeriodSuccess: false,
          };
        },
      )
      .addCase(
        BusinessHoursActions.updateTimePeriodLoading,
        (state: BusinessHoursState) => {
          return {
            ...state,
            updateTimePeriodError: false,
            updateTimePeriodLoading: true,
            updateTimePeriodSuccess: false,
          };
        },
      ),
);

export default BusinessHoursReducer;
