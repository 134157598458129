import { PushNotificationHistoryApi } from "../../api/services";

let api = new PushNotificationHistoryApi();

export interface Notification {
  id?: string;
  title?: string;
  body?: string;
  link?: string;
  type?: string;
  isRead?: boolean;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}

const getNotifications = async (
  _options: {
    type?: string;
    onlyRead?: boolean;
    onlyNotRead?: boolean;
    page?: number;
    itemsPerPage?: number;
    pagination?: boolean;
  } = {
    page: 1,
    itemsPerPage: 30,
    pagination: true,
  },
) => {
  const allUsers: boolean | undefined = undefined;
  const usersIds: string[] | undefined = undefined;

  try {
    const resp = await api.getPushNotificationHistoryCollection(
      allUsers,
      usersIds,
      _options.type,
      _options.onlyRead,
      _options.onlyNotRead,
      _options.page,
      _options.itemsPerPage,
      _options.pagination,
    );

    const hydraList = resp.data["hydra:member"];

    const notyList: Notification[] = hydraList.map((value) => {
      return {
        id: value.uuid,
        title: value.title,
        body: value.body,
        link: value.link,
        type: value["@type"],
        isRead: value.isRead,
        createdAt: value.createdAt ? new Date(value.createdAt) : null,
        updatedAt: value.updatedAt ? new Date(value.updatedAt) : null,
      };
    });

    return notyList;
  } catch (ex) {
    console.error(`getNotifications error: ${ex}`);
    return [];
  }
};

const markAsRead = async (id: string) => {
  try {
    const resp = await api.patchPushNotificationHistoryItem(id, {
      isRead: true,
    });

    return true;
  } catch (ex) {
    console.error(`getNotifications error: ${ex}`);
    return;
  }
};

export default {
  getNotifications,
  markAsRead,
};
