import React, { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';

const DEFAULT_REDIRECT_ROUTE: string = '/';

interface GuardedRouteProps {
  redirectTo?: string;
  authRoute?: boolean;
}

export const GuardedRoute = ({ redirectTo, authRoute }: GuardedRouteProps) => {
  const loggedIn: boolean = useSelector(
    (state: RootState) => state.AuthReducer.loggedIn,
  );

  const loadingUser: boolean = useSelector(
    (state: RootState) => state.UsersReducer.currentUserLoading,
  );

  const hasUser: boolean = useSelector(
    (state: RootState) => !!state.UsersReducer.currentUser,
  );

  const shouldRedirect: boolean = useMemo<boolean>(
    () => (authRoute ? !!loggedIn : !loggedIn),
    [authRoute, loggedIn],
  );

  return !loadingUser && (hasUser || !authRoute) && shouldRedirect ? (
    <Navigate to={redirectTo || DEFAULT_REDIRECT_ROUTE} />
  ) : (
    <Outlet />
  );
};
