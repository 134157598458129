import React from 'react';
import { connect } from 'react-redux';
import './UserProfile.scss';
import ActionDialog from '../../components/dialog/ActionDialog';
import { User } from '../../api/entities';
import SnackBarActions, {
  SnackBarType,
} from '../../redux/actions/UI/SnackBarActions';
import { StoreActions, UsersActions } from '../../redux/actions';
import UserProfileMessageModal from '../../components/users/profile/UserProfileMessageModal';
import UserEditInfoModal from '../../components/users/profile/UserEditModal';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { ArrowLeftIcon } from '../../components/icons/ArrowLeftIcon';
import Icons from '../../theme/Icons';
import { DeleteIcon } from '../../components/icons/DeleteIcon';
import { EditIcon } from '../../components/icons/EditIcon';
import UserRoleLabel from '../../components/users/table/UserRoleLabel';
import UserProfileStores from '../../components/users/profile/UserProfileStores';

interface UserProfileProps {
  getSingleUser: (id: string) => void;
  getUserCurrentStores: () => void;
  patchUserStatus: (userId: string, statsu?: string) => void;
  deleteUser: (userId: string) => void;
  userDetail: User;
  loadingSingleUser: boolean;
}
interface UserProfileState {
  deleteDialogOpen: boolean;
  suspendDialogOpen: boolean;
  messageModalOpen: boolean;
  activateModalOpen: boolean;
  openEditModal: boolean;
  userDetail: User | undefined;
}

type LegacyUserProfileProps = UserProfileProps & {
  navigate: NavigateFunction;
  location: Location;
};

export function UserProfile(props: UserProfileProps) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <LeagcyUserProfile {...props} navigate={navigate} location={location} />
  );
}

class LeagcyUserProfile extends React.Component<
  LegacyUserProfileProps,
  UserProfileState
> {
  constructor(props: LegacyUserProfileProps) {
    super(props);
    this.state = {
      deleteDialogOpen: false,
      suspendDialogOpen: false,
      messageModalOpen: false,
      activateModalOpen: false,
      openEditModal: false,
      userDetail: undefined,
    };
  }
  componentDidMount() {
    this.props.getUserCurrentStores();
    let userID = this.props.location.pathname.getIdFromPath();
    if (userID) {
      this.props.getSingleUser(userID);
    }
  }

  toggleMessageModal = () => {
    this.setState({ messageModalOpen: !this.state.messageModalOpen });
  };

  toggleDeleteDialog = () => {
    this.setState({ deleteDialogOpen: !this.state.deleteDialogOpen });
  };

  toggleSuspendDialog = () => {
    this.setState({ suspendDialogOpen: !this.state.suspendDialogOpen });
  };

  toggleActivateDialog = () => {
    this.setState({ activateModalOpen: !this.state.activateModalOpen });
  };

  toggleEditModal = () => {
    this.setState({ openEditModal: !this.state.openEditModal });
  };

  handleSuspendUser = () => {
    this.toggleSuspendDialog();
    let userID = this.props.location.pathname.getIdFromPath();
    if (userID) {
      this.props.patchUserStatus(userID, 'ARCHIVED');
    }
  };

  handleActivateUser = () => {
    this.toggleActivateDialog();
    let userID = this.props.location.pathname.getIdFromPath();
    if (userID) {
      this.props.patchUserStatus(userID);
    }
  };

  handleDeleteUser = () => {
    this.toggleDeleteDialog();
    if (this.props.userDetail.uuid) {
      this.props.deleteUser(this.props.userDetail.uuid);
      this.props.navigate(-1);
    }
  };

  handleBack = () => {
    this.props.navigate('/users');
  };

  render() {
    return (
      <Box display="flex" className="main-container">
        <div className="grid-action-container">
          <Button
            disableElevation
            className="sidebar-button"
            onClick={() => this.handleBack()}
            startIcon={<ArrowLeftIcon color="#34303D" />}
            sx={{ padding: '8px !important' }}
          >
            Torna Indietro
          </Button>
          <Typography className="sidebar-label">Gestisci Utente</Typography>
          <Button
            variant="outlined"
            className="sidebar-button"
            onClick={() =>
              !this.props.userDetail?.status
                ? this.toggleSuspendDialog()
                : this.toggleActivateDialog()
            }
          >
            {this.props.userDetail?.status && (
              <img src={Icons.Suspend} alt="suspend" />
            )}
            {!this.props.userDetail?.status ? 'Sospendi' : ' Attiva utente'}
          </Button>
          <Button
            variant="outlined"
            className="sidebar-button"
            onClick={() => this.toggleDeleteDialog()}
            startIcon={<DeleteIcon color="#34303D" />}
            sx={{ marginTop: '16px', padding: '8px !important' }}
          >
            Elimina
          </Button>
        </div>
        <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="grid-info-container"
            flexDirection="column"
          >
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <div className="title">{this.props.userDetail?.username}</div>
                <UserRoleLabel role={this.props.userDetail?.businessRole} />
              </Box>

              <IconButton
                sx={{ marginLeft: '12px' }}
                onClick={this.toggleEditModal}
              >
                <EditIcon color="#00D1D2" />
              </IconButton>
            </Box>
            <Box
              flexDirection="column"
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              className="key-value-box"
            >
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                className="item-box"
              >
                <Typography className="key-label">Nome:</Typography>
                <Typography className="value-label">
                  {this.props.userDetail?.name}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                className="item-box"
              >
                <Typography className="key-label">Cognome:</Typography>
                <Typography className="value-label">
                  {this.props.userDetail?.surname}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                className="item-box"
              >
                <Typography className="key-label">Email:</Typography>
                <Typography className="value-label">
                  {this.props.userDetail?.email}
                </Typography>
              </Box>
            </Box>
          </Box>
          <UserProfileStores />
        </Box>
        <UserProfileMessageModal
          isVisible={this.state.messageModalOpen}
          onClose={() => this.toggleMessageModal()}
          onSave={() => this.toggleMessageModal()}
        />
        <ActionDialog
          title="Elimina utente"
          message="Sei sicuro di voler eliminare questo utente?"
          onAction={() => this.handleDeleteUser()}
          open={this.state.deleteDialogOpen}
          onClose={this.toggleDeleteDialog}
          actionTitle="Elimina"
        />
        <ActionDialog
          title="Sospendi utente"
          message="Sei sicuro di voler sospendere questo utente?"
          onAction={() => this.handleSuspendUser()}
          open={this.state.suspendDialogOpen}
          onClose={this.toggleSuspendDialog}
          actionTitle="Sospendi"
        />
        <ActionDialog
          title="Attiva utente"
          message="Sei sicuro di voler attivare questo utente?"
          onAction={() => this.handleActivateUser()}
          open={this.state.activateModalOpen}
          onClose={this.toggleActivateDialog}
          actionTitle="Attiva"
        />
        <UserEditInfoModal
          user={this.props.userDetail}
          isVisible={this.state.openEditModal}
          onClose={() => this.toggleEditModal()}
        />
      </Box>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    userDetail: state.UsersReducer.userDetail,
    loadingSingleUser: state.UsersReducer.loadingSingleUser,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showSnackBar: (message: string, type: SnackBarType) =>
      dispatch(SnackBarActions.snackbar(message, type)),
    getSingleUser: (id: string) => dispatch(UsersActions.getSingleUser(id)),
    getUserCurrentStores: () => dispatch(StoreActions.getUserCurrentStores()),
    patchUserStatus: (userId: string, status?: string) =>
      dispatch(UsersActions.patchUserStatus(userId, status)),
    deleteUser: (userId: string) => dispatch(UsersActions.deleteUser(userId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
