/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { Credentials } from "../nbp";
// @ts-ignore
import { Token } from "../nbp";
/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get JWT token to login.
     * @param {Credentials} [credentials] Create new JWT Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postCredentialsItem: async (
      credentials?: Credentials,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/authentication_token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        credentials,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get JWT token to login.
     * @param {Credentials} [credentials] Create new JWT Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postCredentialsItem(
      credentials?: Credentials,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postCredentialsItem(
          credentials,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TokenApiFp(configuration);
  return {
    /**
     *
     * @summary Get JWT token to login.
     * @param {Credentials} [credentials] Create new JWT Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postCredentialsItem(
      credentials?: Credentials,
      options?: any,
    ): AxiosPromise<Token> {
      return localVarFp
        .postCredentialsItem(credentials, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
  /**
   *
   * @summary Get JWT token to login.
   * @param {Credentials} [credentials] Create new JWT Token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenApi
   */
  public postCredentialsItem(credentials?: Credentials, options?: any) {
    return TokenApiFp(this.configuration)
      .postCredentialsItem(credentials, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
