import { SvgIcon } from '@mui/material';
import { useIconColor } from './IconColor';
import React from 'react';

import { IconProps } from './iconProps';

export const PeopleIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 17C4 15.8954 4.89543 15 6 15H14C15.1046 15 16 15.8954 16 17V19C16 19.5523 15.5523 20 15 20H5C4.44772 20 4 19.5523 4 19V17Z"
          stroke={iconColor}
          stroke-width="1.5"
        />
        <path
          d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
          stroke={iconColor}
          stroke-width="1.5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.25 11C10.25 8.1005 12.6005 5.75 15.5 5.75C18.3995 5.75 20.75 8.1005 20.75 11C20.75 12.2519 20.3118 13.4014 19.5805 14.3036C21.1451 14.9308 22.25 16.4613 22.25 18.25V19C22.25 20.7949 20.7949 22.25 19 22.25H12C10.2051 22.25 8.75 20.7949 8.75 19V18.25C8.75 16.4613 9.85495 14.9308 11.4195 14.3036C10.6882 13.4014 10.25 12.2519 10.25 11ZM13.2496 14C12.339 13.3158 11.75 12.2267 11.75 11C11.75 8.92893 13.4289 7.25 15.5 7.25C17.5711 7.25 19.25 8.92893 19.25 11C19.25 12.2267 18.661 13.3158 17.7504 14C17.1236 14.4709 16.3444 14.75 15.5 14.75C14.6556 14.75 13.8764 14.4709 13.2496 14ZM12.8056 15.5068C11.3775 15.6065 10.25 16.7966 10.25 18.25V19C10.25 19.9665 11.0335 20.75 12 20.75H19C19.9665 20.75 20.75 19.9665 20.75 19V18.25C20.75 16.7966 19.6225 15.6065 18.1944 15.5068C18.1302 15.5023 18.0654 15.5 18 15.5H13C12.9346 15.5 12.8698 15.5023 12.8056 15.5068Z"
          fill="#34303D"
        />
        <path
          d="M11 18.25C11 17.1454 11.8954 16.25 13 16.25H18C19.1046 16.25 20 17.1454 20 18.25V19C20 19.5523 19.5523 20 19 20H12C11.4477 20 11 19.5523 11 19V18.25Z"
          fill="#34303D"
          stroke={iconColor}
          stroke-width="1.5"
        />
        <path
          d="M15.5 14C17.1569 14 18.5 12.6569 18.5 11C18.5 9.34315 17.1569 8 15.5 8C13.8431 8 12.5 9.34315 12.5 11C12.5 12.6569 13.8431 14 15.5 14Z"
          fill="#34303D"
          stroke={iconColor}
          stroke-width="1.5"
        />
      </svg>
    </SvgIcon>
  );
};
