import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import DialogActionFooter from '../../../dialog/DialogActionFooter';
import Icons from '../../../../theme/Icons';
import { useDispatch } from 'react-redux';
import { Service } from '../../../../api/entities';
import { ServiceActions, SnackBarActions } from '../../../../redux/actions';
import _ from 'lodash';
import CapacityTextField from '../../CapacityTextField';
import DurationTextField from '../../DurationTextField';
import { CloudUploadIcon } from '../../../icons/CloudUploadIcon';

interface EditServiceModalProps {
  isVisible: boolean;
  onClose: () => void;
  service: Service | undefined;
}

export default function EditServiceModal(props: EditServiceModalProps) {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const dispatch = useDispatch();
  const service = props.service;
  const [products, setProducts] = useState<any[]>([]);

  const [duration, setDuration] = useState(
    service?.defaultDuration ? service.defaultDuration.toString() : '15',
  );
  const [capacity, setCapacity] = useState(
    service?.defaultCapacity ? service.defaultCapacity.toString() : '10',
  );

  useEffect(() => {
    if (service) {
      setName(service.publicName ? service.publicName : '');
      if (service.defaultCapacity) {
        setCapacity(service.defaultCapacity?.toString());
      }
      if (service.defaultDuration) {
        setDuration(service.defaultDuration?.toString());
      }

      setProducts(
        _.map(service.products, function (o) {
          return o.uuid;
        }),
      );
    }
  }, [service]);

  const handleClose = () => {
    props.onClose();
  };
  const handleSave = () => {
    let request = {
      publicName: name,
      defaultCapacity: parseInt(capacity, 10),
      defaultDuration: parseInt(duration, 10),
    };
    if (service && service.uuid && name !== '' && products.length > 0) {
      dispatch(ServiceActions.editService(service.uuid, request));
      props.onClose();
    } else {
      dispatch(
        SnackBarActions.snackbar('Controlla i campi del modulo.', 'warning'),
      );
    }
  };

  return (
    <Dialog
      className={classes.modal}
      open={props.isVisible}
      onClose={() => handleClose()}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        Modifica Servizio
      </DialogTitle>
      <Grid className={classes.gridContainer}>
        <Box justifyContent="space-between">
          <TextField
            className={classes.contentField}
            autoFocus
            label="Nome del servizio"
            id="name"
            color="secondary"
            size="small"
            style={{ marginBottom: '24px' }}
            inputProps={{
              style: {
                padding: '12px 16px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <CapacityTextField
            capacity={capacity}
            onSelectValue={(value: string) => setCapacity(value)}
          />
          <DurationTextField
            defaultValue={duration}
            onSelectValue={(value: string) => setDuration(value)}
          />
          <Typography className={classes.label}>Icona del servizio</Typography>
          <Box display="flex" alignItems="center">
            <div className={classes.uploadRoot}>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  disableElevation
                  color="inherit"
                  className={classes.uploadButton}
                  startIcon={<CloudUploadIcon color="#34303D" />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
              <input
                accept="image/*"
                className={classes.input}
                id="icon-button-file"
                type="file"
              />
            </div>
            <img src={Icons.EditService} className={classes.img} />
          </Box>
        </Box>
      </Grid>
      <Box className={classes.footerWrapper}>
        <DialogActionFooter
          onCancel={() => handleClose()}
          onConfirm={() => handleSave()}
          actionTitle="Modifica"
        />
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '16px !important',
    },
    gridContainer: {
      backgroundColor: 'white',
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 4,
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
    input: {
      display: 'none',
    },
    uploadRoot: {
      alignItems: 'center',
    },
    uploadButton: {
      marginTop: 6,
      fontWeight: 'bold',
      fontFamily: 'IQOS Sans',
      borderRadius: '40px !important',
    },
    label: {
      fontWeight: 'bold',
      fontSize: '14px !important',
      marginTop: '10px !important',
    },
    contentField: {
      width: '100%',
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
    img: { marginLeft: 16 },
  }),
);
