import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Step,
  StepLabel,
  Stepper,
  StepIconProps,
  StepConnector,
} from '@mui/material';

import { stepConnectorClasses } from '@mui/material/StepConnector';
interface DirectionStepsProps {
  activeStep: number;
}

const BOOKING_STEPS: string[] = [
  'Tipologia di servizio, data e ora',
  'Dati del cliente',
  'Appuntamento confermato',
];

const StepIconRoot = styled('div')<{
  ownerState: {
    active?: boolean;
    completed?: boolean;
  };
}>(({ theme, ownerState }) => ({
  backgroundColor: '#fff',
  zIndex: 1,
  color: '#99979E',
  border: '2px solid #99979E',
  width: 32,
  height: 32,
  display: 'flex',
  fontSize: 14,
  fontWeight: 700,
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#34303D',
    borderColor: '#34303D',
    color: '#fff',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#34303D',
    borderColor: '#34303D',
    color: '#fff',
  }),
}));

function StepIcon({
  active,
  classes,
  completed,
  error,
  icon,
  className,
  sx,
}: StepIconProps) {
  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icon}
    </StepIconRoot>
  );
}

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#99979E',
    margin: '0 16px',
  },
}));

export default function DirectionSteps({ activeStep }: DirectionStepsProps) {
  return (
    <Stepper connector={<Connector />} activeStep={activeStep} alternativeLabel>
      {BOOKING_STEPS.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
