import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import './DetailServiceScreen.scss';
import ServiceInfoCard from '../../components/services/details/ServiceInfoCard';
import ServiceTabs from '../../components/services/details/ServiceTabs';
import SnackBarActions, {
  SnackBarType,
} from '../../redux/actions/UI/SnackBarActions';
import { ServiceActions } from '../../redux/actions';
import { Button, Typography, Box } from '@mui/material';
import { ArrowLeftIcon } from '../../components/icons/ArrowLeftIcon';
import { DeleteIcon } from '../../components/icons/DeleteIcon';
import ActionDialog from '../../components/dialog/ActionDialog';

interface DetailServiceProps {
  getSingleService: (id: string) => void;
  getTransactionalNotifications: (serviceId: string, productId: string) => void;
}

interface DetailServiceState {
  deleteDialogOpen: boolean;
}

export function DetailService(props: DetailServiceProps) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <LegacyDetailService {...props} location={location} navigate={navigate} />
  );
}

type LegacyDetailServiceProps = DetailServiceProps & {
  location: any;
  navigate: any;
};

class LegacyDetailService extends React.Component<
  LegacyDetailServiceProps,
  DetailServiceState
> {
  constructor(props: LegacyDetailServiceProps) {
    super(props);
    this.state = {
      deleteDialogOpen: false,
    };
  }

  componentDidMount() {
    let serviceId = this.props.location.pathname.getIdFromPath();
    if (!serviceId) return;
    this.props.getSingleService(serviceId);
    this.props.getTransactionalNotifications(
      serviceId,
      '56d51468-464a-4dd8-a692-5c9bd61ec26d', // HERE FROM THREE YEARS
    );
  }

  handleBack = () => {
    this.props.navigate('/services');
  };

  toggleDeleteDialog = () => {
    this.setState({ deleteDialogOpen: !this.state.deleteDialogOpen });
  };

  render() {
    return (
      <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
        <Box display="flex" className="main-container">
          <div className="grid-action-container">
            <Button
              disableElevation
              className="sidebar-button"
              onClick={() => this.handleBack()}
              startIcon={<ArrowLeftIcon color="#34303D" />}
              sx={{ padding: '8px !important' }}
            >
              Torna Indietro
            </Button>
            <Typography className="sidebar-label">Gestisci Servizio</Typography>
            <Button
              variant="outlined"
              className="sidebar-button"
              onClick={() => this.toggleDeleteDialog()}
              startIcon={<DeleteIcon color="#34303D" />}
              sx={{ padding: '8px !important' }}
            >
              Elimina
            </Button>
          </div>
          <ServiceInfoCard />
          <ServiceTabs
            serviceId={this.props.location.pathname.getIdFromPath()}
          />
        </Box>
        <ActionDialog
          title="Elimina servizio"
          message="Sei sicuro di elimina questo servizio?"
          onAction={() => {}}
          open={this.state.deleteDialogOpen}
          onClose={this.toggleDeleteDialog}
          actionTitle="Elimina"
        />
      </Box>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showSnackBar: (message: string, type: SnackBarType) =>
      dispatch(SnackBarActions.snackbar(message, type)),
    getSingleService: (id: string) =>
      dispatch(ServiceActions.getSingleService(id)),
    getTransactionalNotifications: (serviceId: string, productId: string) =>
      dispatch(
        ServiceActions.getTransactionalNotifications(serviceId, productId),
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailService);
