import React from 'react';
import { connect, useSelector } from 'react-redux';
import LoginForm from '../../components/forms/login_form/LoginForm';
import { RootState } from '../../redux/reducers';
import {
  AuthActions,
  ProductsActions,
  ServiceActions,
  UsersActions,
} from '../../redux/actions';
import { Credentials } from '../../api/services/nbp';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface LoginProps {
  loggedIn: boolean;
  loginError: any;
  loginLoading: boolean;
  getUser: () => void;
  getServices: () => void;
  getProducts: () => void;
  setLogin: (loggedIn: boolean) => void;
  login: (credentials: Credentials) => void;
}

type LegacyLoginProps = LoginProps & {
  navigate: NavigateFunction;
  loading: boolean;
};
interface LoginState {
  openErrorDialog: boolean;
}

export function Login(props: LoginProps) {
  const navigate = useNavigate();
  const loading = useSelector(
    (state: RootState) => state.AuthReducer.loginLoading,
  );
  return <LegacyLogin {...props} loading={loading} navigate={navigate} />;
}

class LegacyLogin extends React.Component<LegacyLoginProps, LoginState> {
  constructor(props: LegacyLoginProps) {
    super(props);
    this.state = { openErrorDialog: false };
  }
  componentDidUpdate(prevProps: LoginProps) {
    if (prevProps.loginLoading && this.props.loggedIn) {
      this.props.setLogin(true);
      this.props.getUser();
      this.props.getServices();
      this.props.getProducts();
    }
  }
  onSubmit(credentials: Credentials) {
    this.props.login(credentials);
  }

  render() {
    return (
      <LoginForm
        loading={this.props.loginLoading}
        onSubmit={(credentials) => this.onSubmit(credentials)}
        onForgotPasswordPress={() => this.props.navigate('/forgot-password')}
      />
    );
  }
}

export function mapStateToProps(state: RootState) {
  return {
    loggedIn: state.AuthReducer.loggedIn,
    loginError: state.AuthReducer.loginError,
    loginLoading: state.AuthReducer.loginLoading,
  };
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    setLogin: (loggedIn: boolean) => dispatch(AuthActions.setLogin(loggedIn)),
    login: (credentials: Credentials) =>
      dispatch(AuthActions.login(credentials)),
    getUser: () => dispatch(UsersActions.getCurrent()),
    getServices: () => dispatch(ServiceActions.getServices()),
    getProducts: () => dispatch(ProductsActions.getProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
