/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { AppointmentAppointmentInputAppointmentEdit } from "../nbp";
// @ts-ignore
import { AppointmentAppointmentInputAppointmentWrite } from "../nbp";
// @ts-ignore
import { AppointmentAppointmentOutputAppointmentRead } from "../nbp";
// @ts-ignore
import { AppointmentStatusInputAppointmentWrite } from "../nbp";
// @ts-ignore
import { HydratedAppointmentAppointmentOutputAppointmentRead } from "../nbp";
/**
 * AppointmentApi - axios parameter creator
 * @export
 */
export const AppointmentApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete an Appointment resource
     * @param {string} id
     * @param {boolean} [notificationEnabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAppointmentItem: async (
      id: string,
      notificationEnabled?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteAppointmentItem", "id", id);
      const localVarPath = `/api/appointments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (notificationEnabled !== undefined) {
        localVarQueryParameter["notificationEnabled"] = notificationEnabled;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Appointments by Store
     * @param {string} id
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {Array<string>} [stores]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAppointmentsFromStoreAppointmentCollection: async (
      id: string,
      notes?: string,
      startDay?: string,
      endDay?: string,
      onlyArchived?: boolean,
      hasArrived?: boolean,
      properties?: Array<string>,
      stores?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "getAllAppointmentsFromStoreAppointmentCollection",
        "id",
        id,
      );
      const localVarPath = `/api/stores/{id}/appointments`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (notes !== undefined) {
        localVarQueryParameter["notes"] = notes;
      }

      if (startDay !== undefined) {
        localVarQueryParameter["startDay"] = startDay;
      }

      if (endDay !== undefined) {
        localVarQueryParameter["endDay"] = endDay;
      }

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (hasArrived !== undefined) {
        localVarQueryParameter["hasArrived"] = hasArrived;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (stores) {
        localVarQueryParameter["stores[]"] = stores;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Archived Appointments
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedAppointmentsAppointmentCollection: async (
      notes?: string,
      startDay?: string,
      endDay?: string,
      hasArrived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/appointments/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (notes !== undefined) {
        localVarQueryParameter["notes"] = notes;
      }

      if (startDay !== undefined) {
        localVarQueryParameter["startDay"] = startDay;
      }

      if (endDay !== undefined) {
        localVarQueryParameter["endDay"] = endDay;
      }

      if (hasArrived !== undefined) {
        localVarQueryParameter["hasArrived"] = hasArrived;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves All Appointments
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [stores]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppointmentCollection: async (
      notes?: string,
      startDay?: string,
      endDay?: string,
      hasArrived?: boolean,
      stores?: Array<string>,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/appointments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (notes !== undefined) {
        localVarQueryParameter["notes"] = notes;
      }

      if (startDay !== undefined) {
        localVarQueryParameter["startDay"] = startDay;
      }

      if (endDay !== undefined) {
        localVarQueryParameter["endDay"] = endDay;
      }

      if (hasArrived !== undefined) {
        localVarQueryParameter["hasArrived"] = hasArrived;
      }

      if (stores) {
        localVarQueryParameter["stores[]"] = stores;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a AppointmentOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppointmentItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getAppointmentItem", "id", id);
      const localVarPath = `/api/appointments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Appointments by StoreService
     * @param {string} id
     * @param {string} productID
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppointmentsForSingleStoreAndSingleStoreServiceAppointmentCollection:
      async (
        id: string,
        productID: string,
        notes?: string,
        startDay?: string,
        endDay?: string,
        onlyArchived?: boolean,
        hasArrived?: boolean,
        properties?: Array<string>,
        page?: number,
        itemsPerPage?: number,
        pagination?: boolean,
        options: any = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'id' is not null or undefined
        assertParamExists(
          "getAppointmentsForSingleStoreAndSingleStoreServiceAppointmentCollection",
          "id",
          id,
        );
        // verify required parameter 'productID' is not null or undefined
        assertParamExists(
          "getAppointmentsForSingleStoreAndSingleStoreServiceAppointmentCollection",
          "productID",
          productID,
        );
        const localVarPath =
          `/api/store_services/{id}/product/{productID}/appointments`
            .replace(`{${"id"}}`, encodeURIComponent(String(id)))
            .replace(`{${"productID"}}`, encodeURIComponent(String(productID)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: "GET",
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication authenticationToken required
        await setApiKeyToObject(
          localVarHeaderParameter,
          "Authorization",
          configuration,
        );

        if (notes !== undefined) {
          localVarQueryParameter["notes"] = notes;
        }

        if (startDay !== undefined) {
          localVarQueryParameter["startDay"] = startDay;
        }

        if (endDay !== undefined) {
          localVarQueryParameter["endDay"] = endDay;
        }

        if (onlyArchived !== undefined) {
          localVarQueryParameter["onlyArchived"] = onlyArchived;
        }

        if (hasArrived !== undefined) {
          localVarQueryParameter["hasArrived"] = hasArrived;
        }

        if (properties) {
          localVarQueryParameter["properties[]"] = properties;
        }

        if (page !== undefined) {
          localVarQueryParameter["page"] = page;
        }

        if (itemsPerPage !== undefined) {
          localVarQueryParameter["itemsPerPage"] = itemsPerPage;
        }

        if (pagination !== undefined) {
          localVarQueryParameter["pagination"] = pagination;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Retrieves Appointments by Store and Service
     * @param {string} id
     * @param {boolean} [onlyArchived]
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppointmentsFromStoreServiceAppointmentCollection: async (
      id: string,
      onlyArchived?: boolean,
      notes?: string,
      startDay?: string,
      endDay?: string,
      hasArrived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "getAppointmentsFromStoreServiceAppointmentCollection",
        "id",
        id,
      );
      const localVarPath = `/api/store_services/{id}/appointments`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (notes !== undefined) {
        localVarQueryParameter["notes"] = notes;
      }

      if (startDay !== undefined) {
        localVarQueryParameter["startDay"] = startDay;
      }

      if (endDay !== undefined) {
        localVarQueryParameter["endDay"] = endDay;
      }

      if (hasArrived !== undefined) {
        localVarQueryParameter["hasArrived"] = hasArrived;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves AppointmentCalendars by Store
     * @param {string} id
     * @param {string} startDay
     * @param {string} endDay
     * @param {string} [notes]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [numberOfResultsPerDay]
     * @param {Array<string>} [stores]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFirstsAppointmentsFromStoreAppointmentCollection: async (
      id: string,
      startDay: string,
      endDay: string,
      notes?: string,
      onlyArchived?: boolean,
      hasArrived?: boolean,
      properties?: Array<string>,
      numberOfResultsPerDay?: number,
      stores?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "getFirstsAppointmentsFromStoreAppointmentCollection",
        "id",
        id,
      );
      // verify required parameter 'startDay' is not null or undefined
      assertParamExists(
        "getFirstsAppointmentsFromStoreAppointmentCollection",
        "startDay",
        startDay,
      );
      // verify required parameter 'endDay' is not null or undefined
      assertParamExists(
        "getFirstsAppointmentsFromStoreAppointmentCollection",
        "endDay",
        endDay,
      );
      const localVarPath = `/api/stores/{id}/appointments/calendar`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (notes !== undefined) {
        localVarQueryParameter["notes"] = notes;
      }

      if (startDay !== undefined) {
        localVarQueryParameter["startDay"] = startDay;
      }

      if (endDay !== undefined) {
        localVarQueryParameter["endDay"] = endDay;
      }

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (hasArrived !== undefined) {
        localVarQueryParameter["hasArrived"] = hasArrived;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (numberOfResultsPerDay !== undefined) {
        localVarQueryParameter["numberOfResultsPerDay"] = numberOfResultsPerDay;
      }

      if (stores) {
        localVarQueryParameter["stores[]"] = stores;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the Appointment resource.
     * @param {string} id
     * @param {AppointmentAppointmentInputAppointmentEdit} [appointmentAppointmentInputAppointmentEdit] The updated Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchAppointmentItem: async (
      id: string,
      appointmentAppointmentInputAppointmentEdit?: AppointmentAppointmentInputAppointmentEdit,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchAppointmentItem", "id", id);
      const localVarPath = `/api/appointments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appointmentAppointmentInputAppointmentEdit,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Changes status for an Appointment resource
     * @param {string} uuid
     * @param {AppointmentStatusInputAppointmentWrite} [appointmentStatusInputAppointmentWrite] The updated Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusAppointmentItem: async (
      uuid: string,
      appointmentStatusInputAppointmentWrite?: AppointmentStatusInputAppointmentWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("patchStatusAppointmentItem", "uuid", uuid);
      const localVarPath = `/api/appointments/{uuid}/status`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appointmentStatusInputAppointmentWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates an Appointment resource
     * @param {string} id
     * @param {AppointmentAppointmentInputAppointmentWrite} [appointmentAppointmentInputAppointmentWrite] The new Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAppointmentByStoreServiceIDAppointmentCollection: async (
      id: string,
      appointmentAppointmentInputAppointmentWrite?: AppointmentAppointmentInputAppointmentWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "postAppointmentByStoreServiceIDAppointmentCollection",
        "id",
        id,
      );
      const localVarPath = `/api/store_services/{id}/appointments`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appointmentAppointmentInputAppointmentWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Replaces the Appointment resource.
     * @param {string} id
     * @param {AppointmentAppointmentInputAppointmentEdit} [appointmentAppointmentInputAppointmentEdit] The updated Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAppointmentItem: async (
      id: string,
      appointmentAppointmentInputAppointmentEdit?: AppointmentAppointmentInputAppointmentEdit,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putAppointmentItem", "id", id);
      const localVarPath = `/api/appointments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appointmentAppointmentInputAppointmentEdit,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AppointmentApi - functional programming interface
 * @export
 */
export const AppointmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AppointmentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete an Appointment resource
     * @param {string} id
     * @param {boolean} [notificationEnabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAppointmentItem(
      id: string,
      notificationEnabled?: boolean,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAppointmentItem(
          id,
          notificationEnabled,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Appointments by Store
     * @param {string} id
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {Array<string>} [stores]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAppointmentsFromStoreAppointmentCollection(
      id: string,
      notes?: string,
      startDay?: string,
      endDay?: string,
      onlyArchived?: boolean,
      hasArrived?: boolean,
      properties?: Array<string>,
      stores?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllAppointmentsFromStoreAppointmentCollection(
          id,
          notes,
          startDay,
          endDay,
          onlyArchived,
          hasArrived,
          properties,
          stores,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Archived Appointments
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArchivedAppointmentsAppointmentCollection(
      notes?: string,
      startDay?: string,
      endDay?: string,
      hasArrived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllArchivedAppointmentsAppointmentCollection(
          notes,
          startDay,
          endDay,
          hasArrived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves All Appointments
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [stores]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppointmentCollection(
      notes?: string,
      startDay?: string,
      endDay?: string,
      hasArrived?: boolean,
      stores?: Array<string>,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppointmentCollection(
          notes,
          startDay,
          endDay,
          hasArrived,
          stores,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a AppointmentOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppointmentItem(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppointmentItem(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Appointments by StoreService
     * @param {string} id
     * @param {string} productID
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppointmentsForSingleStoreAndSingleStoreServiceAppointmentCollection(
      id: string,
      productID: string,
      notes?: string,
      startDay?: string,
      endDay?: string,
      onlyArchived?: boolean,
      hasArrived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppointmentsForSingleStoreAndSingleStoreServiceAppointmentCollection(
          id,
          productID,
          notes,
          startDay,
          endDay,
          onlyArchived,
          hasArrived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Appointments by Store and Service
     * @param {string} id
     * @param {boolean} [onlyArchived]
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppointmentsFromStoreServiceAppointmentCollection(
      id: string,
      onlyArchived?: boolean,
      notes?: string,
      startDay?: string,
      endDay?: string,
      hasArrived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppointmentsFromStoreServiceAppointmentCollection(
          id,
          onlyArchived,
          notes,
          startDay,
          endDay,
          hasArrived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves AppointmentCalendars by Store
     * @param {string} id
     * @param {string} startDay
     * @param {string} endDay
     * @param {string} [notes]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [numberOfResultsPerDay]
     * @param {Array<string>} [stores]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFirstsAppointmentsFromStoreAppointmentCollection(
      id: string,
      startDay: string,
      endDay: string,
      notes?: string,
      onlyArchived?: boolean,
      hasArrived?: boolean,
      properties?: Array<string>,
      numberOfResultsPerDay?: number,
      stores?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFirstsAppointmentsFromStoreAppointmentCollection(
          id,
          startDay,
          endDay,
          notes,
          onlyArchived,
          hasArrived,
          properties,
          numberOfResultsPerDay,
          stores,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the Appointment resource.
     * @param {string} id
     * @param {AppointmentAppointmentInputAppointmentEdit} [appointmentAppointmentInputAppointmentEdit] The updated Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchAppointmentItem(
      id: string,
      appointmentAppointmentInputAppointmentEdit?: AppointmentAppointmentInputAppointmentEdit,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchAppointmentItem(
          id,
          appointmentAppointmentInputAppointmentEdit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Changes status for an Appointment resource
     * @param {string} uuid
     * @param {AppointmentStatusInputAppointmentWrite} [appointmentStatusInputAppointmentWrite] The updated Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchStatusAppointmentItem(
      uuid: string,
      appointmentStatusInputAppointmentWrite?: AppointmentStatusInputAppointmentWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchStatusAppointmentItem(
          uuid,
          appointmentStatusInputAppointmentWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates an Appointment resource
     * @param {string} id
     * @param {AppointmentAppointmentInputAppointmentWrite} [appointmentAppointmentInputAppointmentWrite] The new Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAppointmentByStoreServiceIDAppointmentCollection(
      id: string,
      appointmentAppointmentInputAppointmentWrite?: AppointmentAppointmentInputAppointmentWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAppointmentByStoreServiceIDAppointmentCollection(
          id,
          appointmentAppointmentInputAppointmentWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Replaces the Appointment resource.
     * @param {string} id
     * @param {AppointmentAppointmentInputAppointmentEdit} [appointmentAppointmentInputAppointmentEdit] The updated Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putAppointmentItem(
      id: string,
      appointmentAppointmentInputAppointmentEdit?: AppointmentAppointmentInputAppointmentEdit,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppointmentAppointmentOutputAppointmentRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putAppointmentItem(
          id,
          appointmentAppointmentInputAppointmentEdit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AppointmentApi - factory interface
 * @export
 */
export const AppointmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AppointmentApiFp(configuration);
  return {
    /**
     *
     * @summary Delete an Appointment resource
     * @param {string} id
     * @param {boolean} [notificationEnabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAppointmentItem(
      id: string,
      notificationEnabled?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteAppointmentItem(id, notificationEnabled, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Appointments by Store
     * @param {string} id
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {Array<string>} [stores]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAppointmentsFromStoreAppointmentCollection(
      id: string,
      notes?: string,
      startDay?: string,
      endDay?: string,
      onlyArchived?: boolean,
      hasArrived?: boolean,
      properties?: Array<string>,
      stores?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .getAllAppointmentsFromStoreAppointmentCollection(
          id,
          notes,
          startDay,
          endDay,
          onlyArchived,
          hasArrived,
          properties,
          stores,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Archived Appointments
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedAppointmentsAppointmentCollection(
      notes?: string,
      startDay?: string,
      endDay?: string,
      hasArrived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .getAllArchivedAppointmentsAppointmentCollection(
          notes,
          startDay,
          endDay,
          hasArrived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves All Appointments
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [stores]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppointmentCollection(
      notes?: string,
      startDay?: string,
      endDay?: string,
      hasArrived?: boolean,
      stores?: Array<string>,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .getAppointmentCollection(
          notes,
          startDay,
          endDay,
          hasArrived,
          stores,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a AppointmentOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppointmentItem(
      id: string,
      options?: any,
    ): AxiosPromise<AppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .getAppointmentItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Appointments by StoreService
     * @param {string} id
     * @param {string} productID
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppointmentsForSingleStoreAndSingleStoreServiceAppointmentCollection(
      id: string,
      productID: string,
      notes?: string,
      startDay?: string,
      endDay?: string,
      onlyArchived?: boolean,
      hasArrived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .getAppointmentsForSingleStoreAndSingleStoreServiceAppointmentCollection(
          id,
          productID,
          notes,
          startDay,
          endDay,
          onlyArchived,
          hasArrived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Appointments by Store and Service
     * @param {string} id
     * @param {boolean} [onlyArchived]
     * @param {string} [notes]
     * @param {string} [startDay]
     * @param {string} [endDay]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppointmentsFromStoreServiceAppointmentCollection(
      id: string,
      onlyArchived?: boolean,
      notes?: string,
      startDay?: string,
      endDay?: string,
      hasArrived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .getAppointmentsFromStoreServiceAppointmentCollection(
          id,
          onlyArchived,
          notes,
          startDay,
          endDay,
          hasArrived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves AppointmentCalendars by Store
     * @param {string} id
     * @param {string} startDay
     * @param {string} endDay
     * @param {string} [notes]
     * @param {boolean} [onlyArchived]
     * @param {boolean} [hasArrived]
     * @param {Array<string>} [properties]
     * @param {number} [numberOfResultsPerDay]
     * @param {Array<string>} [stores]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFirstsAppointmentsFromStoreAppointmentCollection(
      id: string,
      startDay: string,
      endDay: string,
      notes?: string,
      onlyArchived?: boolean,
      hasArrived?: boolean,
      properties?: Array<string>,
      numberOfResultsPerDay?: number,
      stores?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedAppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .getFirstsAppointmentsFromStoreAppointmentCollection(
          id,
          startDay,
          endDay,
          notes,
          onlyArchived,
          hasArrived,
          properties,
          numberOfResultsPerDay,
          stores,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the Appointment resource.
     * @param {string} id
     * @param {AppointmentAppointmentInputAppointmentEdit} [appointmentAppointmentInputAppointmentEdit] The updated Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchAppointmentItem(
      id: string,
      appointmentAppointmentInputAppointmentEdit?: AppointmentAppointmentInputAppointmentEdit,
      options?: any,
    ): AxiosPromise<AppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .patchAppointmentItem(
          id,
          appointmentAppointmentInputAppointmentEdit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Changes status for an Appointment resource
     * @param {string} uuid
     * @param {AppointmentStatusInputAppointmentWrite} [appointmentStatusInputAppointmentWrite] The updated Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusAppointmentItem(
      uuid: string,
      appointmentStatusInputAppointmentWrite?: AppointmentStatusInputAppointmentWrite,
      options?: any,
    ): AxiosPromise<AppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .patchStatusAppointmentItem(
          uuid,
          appointmentStatusInputAppointmentWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates an Appointment resource
     * @param {string} id
     * @param {AppointmentAppointmentInputAppointmentWrite} [appointmentAppointmentInputAppointmentWrite] The new Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAppointmentByStoreServiceIDAppointmentCollection(
      id: string,
      appointmentAppointmentInputAppointmentWrite?: AppointmentAppointmentInputAppointmentWrite,
      options?: any,
    ): AxiosPromise<AppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .postAppointmentByStoreServiceIDAppointmentCollection(
          id,
          appointmentAppointmentInputAppointmentWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Replaces the Appointment resource.
     * @param {string} id
     * @param {AppointmentAppointmentInputAppointmentEdit} [appointmentAppointmentInputAppointmentEdit] The updated Appointment resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAppointmentItem(
      id: string,
      appointmentAppointmentInputAppointmentEdit?: AppointmentAppointmentInputAppointmentEdit,
      options?: any,
    ): AxiosPromise<AppointmentAppointmentOutputAppointmentRead> {
      return localVarFp
        .putAppointmentItem(
          id,
          appointmentAppointmentInputAppointmentEdit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AppointmentApi - object-oriented interface
 * @export
 * @class AppointmentApi
 * @extends {BaseAPI}
 */
export class AppointmentApi extends BaseAPI {
  /**
   *
   * @summary Delete an Appointment resource
   * @param {string} id
   * @param {boolean} [notificationEnabled]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public deleteAppointmentItem(
    id: string,
    notificationEnabled?: boolean,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .deleteAppointmentItem(id, notificationEnabled, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Appointments by Store
   * @param {string} id
   * @param {string} [notes]
   * @param {string} [startDay]
   * @param {string} [endDay]
   * @param {boolean} [onlyArchived]
   * @param {boolean} [hasArrived]
   * @param {Array<string>} [properties]
   * @param {Array<string>} [stores]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public getAllAppointmentsFromStoreAppointmentCollection(
    id: string,
    notes?: string,
    startDay?: string,
    endDay?: string,
    onlyArchived?: boolean,
    hasArrived?: boolean,
    properties?: Array<string>,
    stores?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .getAllAppointmentsFromStoreAppointmentCollection(
        id,
        notes,
        startDay,
        endDay,
        onlyArchived,
        hasArrived,
        properties,
        stores,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Archived Appointments
   * @param {string} [notes]
   * @param {string} [startDay]
   * @param {string} [endDay]
   * @param {boolean} [hasArrived]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public getAllArchivedAppointmentsAppointmentCollection(
    notes?: string,
    startDay?: string,
    endDay?: string,
    hasArrived?: boolean,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .getAllArchivedAppointmentsAppointmentCollection(
        notes,
        startDay,
        endDay,
        hasArrived,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves All Appointments
   * @param {string} [notes]
   * @param {string} [startDay]
   * @param {string} [endDay]
   * @param {boolean} [hasArrived]
   * @param {Array<string>} [stores]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public getAppointmentCollection(
    notes?: string,
    startDay?: string,
    endDay?: string,
    hasArrived?: boolean,
    stores?: Array<string>,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .getAppointmentCollection(
        notes,
        startDay,
        endDay,
        hasArrived,
        stores,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a AppointmentOutput resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public getAppointmentItem(id: string, options?: any) {
    return AppointmentApiFp(this.configuration)
      .getAppointmentItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Appointments by StoreService
   * @param {string} id
   * @param {string} productID
   * @param {string} [notes]
   * @param {string} [startDay]
   * @param {string} [endDay]
   * @param {boolean} [onlyArchived]
   * @param {boolean} [hasArrived]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public getAppointmentsForSingleStoreAndSingleStoreServiceAppointmentCollection(
    id: string,
    productID: string,
    notes?: string,
    startDay?: string,
    endDay?: string,
    onlyArchived?: boolean,
    hasArrived?: boolean,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .getAppointmentsForSingleStoreAndSingleStoreServiceAppointmentCollection(
        id,
        productID,
        notes,
        startDay,
        endDay,
        onlyArchived,
        hasArrived,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Appointments by Store and Service
   * @param {string} id
   * @param {boolean} [onlyArchived]
   * @param {string} [notes]
   * @param {string} [startDay]
   * @param {string} [endDay]
   * @param {boolean} [hasArrived]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public getAppointmentsFromStoreServiceAppointmentCollection(
    id: string,
    onlyArchived?: boolean,
    notes?: string,
    startDay?: string,
    endDay?: string,
    hasArrived?: boolean,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .getAppointmentsFromStoreServiceAppointmentCollection(
        id,
        onlyArchived,
        notes,
        startDay,
        endDay,
        hasArrived,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves AppointmentCalendars by Store
   * @param {string} id
   * @param {string} startDay
   * @param {string} endDay
   * @param {string} [notes]
   * @param {boolean} [onlyArchived]
   * @param {boolean} [hasArrived]
   * @param {Array<string>} [properties]
   * @param {number} [numberOfResultsPerDay]
   * @param {Array<string>} [stores]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public getFirstsAppointmentsFromStoreAppointmentCollection(
    id: string,
    startDay: string,
    endDay: string,
    notes?: string,
    onlyArchived?: boolean,
    hasArrived?: boolean,
    properties?: Array<string>,
    numberOfResultsPerDay?: number,
    stores?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .getFirstsAppointmentsFromStoreAppointmentCollection(
        id,
        startDay,
        endDay,
        notes,
        onlyArchived,
        hasArrived,
        properties,
        numberOfResultsPerDay,
        stores,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the Appointment resource.
   * @param {string} id
   * @param {AppointmentAppointmentInputAppointmentEdit} [appointmentAppointmentInputAppointmentEdit] The updated Appointment resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public patchAppointmentItem(
    id: string,
    appointmentAppointmentInputAppointmentEdit?: AppointmentAppointmentInputAppointmentEdit,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .patchAppointmentItem(
        id,
        appointmentAppointmentInputAppointmentEdit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Changes status for an Appointment resource
   * @param {string} uuid
   * @param {AppointmentStatusInputAppointmentWrite} [appointmentStatusInputAppointmentWrite] The updated Appointment resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public patchStatusAppointmentItem(
    uuid: string,
    appointmentStatusInputAppointmentWrite?: AppointmentStatusInputAppointmentWrite,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .patchStatusAppointmentItem(
        uuid,
        appointmentStatusInputAppointmentWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates an Appointment resource
   * @param {string} id
   * @param {AppointmentAppointmentInputAppointmentWrite} [appointmentAppointmentInputAppointmentWrite] The new Appointment resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public postAppointmentByStoreServiceIDAppointmentCollection(
    id: string,
    appointmentAppointmentInputAppointmentWrite?: AppointmentAppointmentInputAppointmentWrite,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .postAppointmentByStoreServiceIDAppointmentCollection(
        id,
        appointmentAppointmentInputAppointmentWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Replaces the Appointment resource.
   * @param {string} id
   * @param {AppointmentAppointmentInputAppointmentEdit} [appointmentAppointmentInputAppointmentEdit] The updated Appointment resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppointmentApi
   */
  public putAppointmentItem(
    id: string,
    appointmentAppointmentInputAppointmentEdit?: AppointmentAppointmentInputAppointmentEdit,
    options?: any,
  ) {
    return AppointmentApiFp(this.configuration)
      .putAppointmentItem(
        id,
        appointmentAppointmentInputAppointmentEdit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
