import { SvgIcon } from '@mui/material';
import React from 'react';
import { useIconColor } from './IconColor';
import { IconProps } from './iconProps';

export const ClockIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="1 1 20 20"
        fill="none"
      >
        <path
          d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
          stroke={iconColor}
          stroke-width="1.5"
        />
        <path
          d="M12 8V12.5L14.5 10"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
