import React, { useMemo } from 'react';
import { NotificationGroup } from '../notificationGroup';
import { Typography } from '@mui/material';
import './NotificationSection.scss';

interface NotificationSectionProps {
  group: NotificationGroup;
}

export function NotificationSection({ group }: NotificationSectionProps) {
  const title = useMemo<string>(() => {
    if (group === 'TODAY') return 'OGGI';
    if (group === 'YESTERDAY') return 'IERI';
    if (group === 'OLDER') return 'PRECEDENTI';
    return 'OGGI';
  }, [group]);

  return (
    <div className="notifications-section">
      <Typography fontSize={12}>{title}</Typography>
    </div>
  );
}
