import { CardContent } from '@mui/material';
import React from 'react';
import { NotificationSection } from '../NotificationSection/NotificationSection';
import { NotificationCard } from '../NotificationCard/NotificationCard';
import { useNotifications } from '../NotificationContext';

export default function NotificationList() {
  const { today, yesterday, older, setAsRead } = useNotifications();

  return (
    <>
      {!today.length && !yesterday.length && !older.length && (
        <CardContent sx={{ padding: '0.875rem' }}>Nessuna notifica</CardContent>
      )}
      {!!today.length && (
        <>
          <NotificationSection group="TODAY" />
          <CardContent sx={{ padding: '0.875rem' }}>
            {today.map((noty) => (
              <NotificationCard
                notification={noty}
                onClick={() => setAsRead(noty, 'TODAY')}
                key={noty.id}
              />
            ))}
          </CardContent>
        </>
      )}

      {!!yesterday.length && (
        <>
          <NotificationSection group="YESTERDAY" />
          <CardContent sx={{ padding: '0.875rem' }}>
            {yesterday.map((noty) => (
              <NotificationCard
                notification={noty}
                onClick={() => setAsRead(noty, 'YESTERDAY')}
                key={noty.id}
              />
            ))}
          </CardContent>
        </>
      )}

      {!!older.length && (
        <>
          <NotificationSection group="OLDER" />
          <CardContent sx={{ padding: '0.875rem' }}>
            {older.map((noty) => (
              <NotificationCard
                notification={noty}
                onClick={() => setAsRead(noty, 'OLDER')}
                key={noty.id}
              />
            ))}
          </CardContent>
        </>
      )}
    </>
  );
}
