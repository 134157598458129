import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { BusinessHourRequestActions } from "../actions";
import { BusinessHours } from "../../api/entities";

export type BUSINESS_HOUR_REQUEST_STATE = {
  businessHoursRequest?: BusinessHours;
};
const INITIAL_UI_STATE: BUSINESS_HOUR_REQUEST_STATE = {
  businessHoursRequest: undefined,
};

const addTimePeriod = (
  businessHoursRequest: BusinessHours | undefined,
  newPeriod: any,
) => {
  if (businessHoursRequest && businessHoursRequest?.timePeriods) {
    let timePeriodIndex = businessHoursRequest.timePeriods?.findIndex(
      (timePeriod: any) => timePeriod.openDay === newPeriod.openDay,
    );
    let timePeriod: any = businessHoursRequest.timePeriods[timePeriodIndex];
    let newTimesArray = [...timePeriod.times, newPeriod];
    let newTimePeriodsArray = [
      ...businessHoursRequest.timePeriods?.slice(0, timePeriodIndex),
      { ...timePeriod, times: newTimesArray },
      ...businessHoursRequest.timePeriods?.slice(timePeriodIndex + 1),
    ];
    return { ...businessHoursRequest, timePeriods: newTimePeriodsArray };
  }
  return businessHoursRequest;
};

const deleteTimePeriod = (
  businessHoursRequest: BusinessHours | undefined,
  deletedPeriod: any,
) => {
  if (businessHoursRequest && businessHoursRequest?.timePeriods) {
    let index = businessHoursRequest.timePeriods.findIndex(
      (member) => member.openDay === deletedPeriod.openDay,
    );
    let timePeriod: any = businessHoursRequest.timePeriods[index];
    let timeIndex = timePeriod.times.findIndex(
      (timePeriod: any) => timePeriod.uuid === deletedPeriod.uuid,
    );
    let timeObj: any = timePeriod.times[timeIndex];
    let newTimesArray = timePeriod.times.filter(
      (time: any) => time.uuid !== timeObj.uuid,
    );
    let _timesArray = [];
    if (newTimesArray.length > 0) {
      // delete only one time period
      _timesArray = [
        ...businessHoursRequest.timePeriods?.slice(0, index),
        { ...timePeriod, times: newTimesArray },
        ...businessHoursRequest.timePeriods?.slice(index + 1),
      ];
    } else {
      // delete whole day if times.length === 0
      _timesArray = businessHoursRequest.timePeriods.filter(
        (timePeriod: any) => timePeriod.openDay !== deletedPeriod.openDay,
      );
    }

    let newTimePeriodsArray = [
      ...businessHoursRequest.timePeriods?.slice(0, index),
      { ...timePeriod, times: newTimesArray },
      ...businessHoursRequest.timePeriods?.slice(index + 1),
    ];
    return { ...businessHoursRequest, timePeriods: newTimePeriodsArray };
  }
  return businessHoursRequest;
};

const updateTimePeriod = (
  businessHoursRequest: BusinessHours | undefined,
  newPeriod: any,
) => {
  if (businessHoursRequest && businessHoursRequest.timePeriods) {
    let index = businessHoursRequest.timePeriods.findIndex(
      (member) => member.openDay === newPeriod.openDay,
    );
    let timePeriod: any = businessHoursRequest.timePeriods[index];
    let timeIndex = timePeriod.times.findIndex(
      (time: any) => time.uuid === newPeriod.uuid,
    );
    //let timeObj = timePeriod.times[timeIndex]

    let newTimesArray = [
      ...timePeriod.times.slice(0, timeIndex),
      newPeriod,
      ...timePeriod.times.slice(timeIndex + 1),
    ];
    let newTimePeriodsArray = [
      ...businessHoursRequest.timePeriods?.slice(0, index),
      { ...timePeriod, times: newTimesArray, isClosed: newPeriod.isClosed },
      ...businessHoursRequest.timePeriods?.slice(index + 1),
    ];

    return { ...businessHoursRequest, timePeriods: newTimePeriodsArray };
  }
  return businessHoursRequest;
};

const BusinessHourRequestReducer = createReducer(INITIAL_UI_STATE, (builder) =>
  builder
    .addCase(
      BusinessHourRequestActions.setBusinessHourRequestSuccess,
      (
        state: BUSINESS_HOUR_REQUEST_STATE,
        action: PayloadAction<BusinessHours | undefined>,
      ) => {
        let businessHoursRequest = action.payload;
        return {
          ...state,
          businessHoursRequest: businessHoursRequest,
        };
      },
    )
    .addCase(
      BusinessHourRequestActions.addTimePeriodSuccess,
      (state: BUSINESS_HOUR_REQUEST_STATE, action: PayloadAction<any>) => {
        let timePeriod = action.payload;
        return {
          ...state,
          businessHoursRequest: addTimePeriod(
            state.businessHoursRequest,
            timePeriod,
          ),
        };
      },
    )
    .addCase(
      BusinessHourRequestActions.deleteTimePeriodSuccess,
      (state: BUSINESS_HOUR_REQUEST_STATE, action: PayloadAction<any>) => {
        let deletedPeriod = action.payload;
        return {
          ...state,
          businessHoursRequest: deleteTimePeriod(
            state.businessHoursRequest,
            deletedPeriod,
          ),
        };
      },
    )
    .addCase(
      BusinessHourRequestActions.updateTimePeriodSuccess,
      (state: BUSINESS_HOUR_REQUEST_STATE, action: PayloadAction<any>) => {
        let timePeriod = action.payload;
        return {
          ...state,
          businessHoursRequest: updateTimePeriod(
            state.businessHoursRequest,
            timePeriod,
          ),
        };
      },
    ),
);

export default BusinessHourRequestReducer;
