/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { BusinessHoursBusinessHoursInputBusinessHoursEdit } from "../nbp";
// @ts-ignore
import { BusinessHoursBusinessHoursInputBusinessHoursWrite } from "../nbp";
// @ts-ignore
import { BusinessHoursBusinessHoursOutputBusinessHoursRead } from "../nbp";
// @ts-ignore
import { BusinessHoursStatusInputBusinessHoursWrite } from "../nbp";
// @ts-ignore
import { HydratedBusinessHoursBusinessHoursOutputBusinessHoursRead } from "../nbp";
/**
 * BusinessHoursApi - axios parameter creator
 * @export
 */
export const BusinessHoursApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Removes the BusinessHours resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessHoursItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteBusinessHoursItem", "id", id);
      const localVarPath = `/api/business_hours/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Archived BusinessHours
     * @param {boolean} [isActive]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedBusinessHoursBusinessHoursCollection: async (
      isActive?: boolean,
      startDate?: string,
      endDate?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/business_hours/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (isActive !== undefined) {
        localVarQueryParameter["isActive"] = isActive;
      }

      if (startDate !== undefined) {
        localVarQueryParameter["startDate"] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter["endDate"] = endDate;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves the collection of BusinessHoursOutput resources.
     * @param {boolean} [isActive]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessHoursCollection: async (
      isActive?: boolean,
      startDate?: string,
      endDate?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/business_hours`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (isActive !== undefined) {
        localVarQueryParameter["isActive"] = isActive;
      }

      if (startDate !== undefined) {
        localVarQueryParameter["startDate"] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter["endDate"] = endDate;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves BusinessHours by Store
     * @param {string} id
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [isActive]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessHoursForCurrentStoreBusinessHoursCollection: async (
      id: string,
      startDate?: string,
      endDate?: string,
      isActive?: boolean,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "getBusinessHoursForCurrentStoreBusinessHoursCollection",
        "id",
        id,
      );
      const localVarPath = `/api/stores/{id}/business_hours`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (startDate !== undefined) {
        localVarQueryParameter["startDate"] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter["endDate"] = endDate;
      }

      if (isActive !== undefined) {
        localVarQueryParameter["isActive"] = isActive;
      }

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a BusinessHoursOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessHoursItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getBusinessHoursItem", "id", id);
      const localVarPath = `/api/business_hours/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the BusinessHours resource.
     * @param {string} id
     * @param {BusinessHoursBusinessHoursInputBusinessHoursEdit} [businessHoursBusinessHoursInputBusinessHoursEdit] The updated BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchBusinessHoursItem: async (
      id: string,
      businessHoursBusinessHoursInputBusinessHoursEdit?: BusinessHoursBusinessHoursInputBusinessHoursEdit,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchBusinessHoursItem", "id", id);
      const localVarPath = `/api/business_hours/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessHoursBusinessHoursInputBusinessHoursEdit,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Changes status for an BusinessHours resource
     * @param {string} uuid
     * @param {BusinessHoursStatusInputBusinessHoursWrite} [businessHoursStatusInputBusinessHoursWrite] The updated BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusBusinessHoursItem: async (
      uuid: string,
      businessHoursStatusInputBusinessHoursWrite?: BusinessHoursStatusInputBusinessHoursWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("patchStatusBusinessHoursItem", "uuid", uuid);
      const localVarPath = `/api/businessHours/{uuid}/status`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessHoursStatusInputBusinessHoursWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a BusinessHours resource.
     * @param {string} id
     * @param {BusinessHoursBusinessHoursInputBusinessHoursWrite} [businessHoursBusinessHoursInputBusinessHoursWrite] The new BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBusinessHoursForCurrentStoreBusinessHoursCollection: async (
      id: string,
      businessHoursBusinessHoursInputBusinessHoursWrite?: BusinessHoursBusinessHoursInputBusinessHoursWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "postBusinessHoursForCurrentStoreBusinessHoursCollection",
        "id",
        id,
      );
      const localVarPath = `/api/stores/{id}/business_hours`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessHoursBusinessHoursInputBusinessHoursWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Replaces the BusinessHours resource.
     * @param {string} id
     * @param {BusinessHoursBusinessHoursInputBusinessHoursEdit} [businessHoursBusinessHoursInputBusinessHoursEdit] The updated BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putBusinessHoursItem: async (
      id: string,
      businessHoursBusinessHoursInputBusinessHoursEdit?: BusinessHoursBusinessHoursInputBusinessHoursEdit,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putBusinessHoursItem", "id", id);
      const localVarPath = `/api/business_hours/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        businessHoursBusinessHoursInputBusinessHoursEdit,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BusinessHoursApi - functional programming interface
 * @export
 */
export const BusinessHoursApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    BusinessHoursApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Removes the BusinessHours resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBusinessHoursItem(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteBusinessHoursItem(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Archived BusinessHours
     * @param {boolean} [isActive]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArchivedBusinessHoursBusinessHoursCollection(
      isActive?: boolean,
      startDate?: string,
      endDate?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedBusinessHoursBusinessHoursOutputBusinessHoursRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllArchivedBusinessHoursBusinessHoursCollection(
          isActive,
          startDate,
          endDate,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves the collection of BusinessHoursOutput resources.
     * @param {boolean} [isActive]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessHoursCollection(
      isActive?: boolean,
      startDate?: string,
      endDate?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedBusinessHoursBusinessHoursOutputBusinessHoursRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBusinessHoursCollection(
          isActive,
          startDate,
          endDate,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves BusinessHours by Store
     * @param {string} id
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [isActive]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessHoursForCurrentStoreBusinessHoursCollection(
      id: string,
      startDate?: string,
      endDate?: string,
      isActive?: boolean,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedBusinessHoursBusinessHoursOutputBusinessHoursRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBusinessHoursForCurrentStoreBusinessHoursCollection(
          id,
          startDate,
          endDate,
          isActive,
          onlyArchived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a BusinessHoursOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessHoursItem(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BusinessHoursBusinessHoursOutputBusinessHoursRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBusinessHoursItem(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the BusinessHours resource.
     * @param {string} id
     * @param {BusinessHoursBusinessHoursInputBusinessHoursEdit} [businessHoursBusinessHoursInputBusinessHoursEdit] The updated BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchBusinessHoursItem(
      id: string,
      businessHoursBusinessHoursInputBusinessHoursEdit?: BusinessHoursBusinessHoursInputBusinessHoursEdit,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BusinessHoursBusinessHoursOutputBusinessHoursRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchBusinessHoursItem(
          id,
          businessHoursBusinessHoursInputBusinessHoursEdit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Changes status for an BusinessHours resource
     * @param {string} uuid
     * @param {BusinessHoursStatusInputBusinessHoursWrite} [businessHoursStatusInputBusinessHoursWrite] The updated BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchStatusBusinessHoursItem(
      uuid: string,
      businessHoursStatusInputBusinessHoursWrite?: BusinessHoursStatusInputBusinessHoursWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BusinessHoursBusinessHoursOutputBusinessHoursRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchStatusBusinessHoursItem(
          uuid,
          businessHoursStatusInputBusinessHoursWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a BusinessHours resource.
     * @param {string} id
     * @param {BusinessHoursBusinessHoursInputBusinessHoursWrite} [businessHoursBusinessHoursInputBusinessHoursWrite] The new BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postBusinessHoursForCurrentStoreBusinessHoursCollection(
      id: string,
      businessHoursBusinessHoursInputBusinessHoursWrite?: BusinessHoursBusinessHoursInputBusinessHoursWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BusinessHoursBusinessHoursOutputBusinessHoursRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postBusinessHoursForCurrentStoreBusinessHoursCollection(
          id,
          businessHoursBusinessHoursInputBusinessHoursWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Replaces the BusinessHours resource.
     * @param {string} id
     * @param {BusinessHoursBusinessHoursInputBusinessHoursEdit} [businessHoursBusinessHoursInputBusinessHoursEdit] The updated BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putBusinessHoursItem(
      id: string,
      businessHoursBusinessHoursInputBusinessHoursEdit?: BusinessHoursBusinessHoursInputBusinessHoursEdit,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BusinessHoursBusinessHoursOutputBusinessHoursRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putBusinessHoursItem(
          id,
          businessHoursBusinessHoursInputBusinessHoursEdit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * BusinessHoursApi - factory interface
 * @export
 */
export const BusinessHoursApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BusinessHoursApiFp(configuration);
  return {
    /**
     *
     * @summary Removes the BusinessHours resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBusinessHoursItem(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteBusinessHoursItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Archived BusinessHours
     * @param {boolean} [isActive]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedBusinessHoursBusinessHoursCollection(
      isActive?: boolean,
      startDate?: string,
      endDate?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedBusinessHoursBusinessHoursOutputBusinessHoursRead> {
      return localVarFp
        .getAllArchivedBusinessHoursBusinessHoursCollection(
          isActive,
          startDate,
          endDate,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves the collection of BusinessHoursOutput resources.
     * @param {boolean} [isActive]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessHoursCollection(
      isActive?: boolean,
      startDate?: string,
      endDate?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedBusinessHoursBusinessHoursOutputBusinessHoursRead> {
      return localVarFp
        .getBusinessHoursCollection(
          isActive,
          startDate,
          endDate,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves BusinessHours by Store
     * @param {string} id
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [isActive]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessHoursForCurrentStoreBusinessHoursCollection(
      id: string,
      startDate?: string,
      endDate?: string,
      isActive?: boolean,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedBusinessHoursBusinessHoursOutputBusinessHoursRead> {
      return localVarFp
        .getBusinessHoursForCurrentStoreBusinessHoursCollection(
          id,
          startDate,
          endDate,
          isActive,
          onlyArchived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a BusinessHoursOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessHoursItem(
      id: string,
      options?: any,
    ): AxiosPromise<BusinessHoursBusinessHoursOutputBusinessHoursRead> {
      return localVarFp
        .getBusinessHoursItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the BusinessHours resource.
     * @param {string} id
     * @param {BusinessHoursBusinessHoursInputBusinessHoursEdit} [businessHoursBusinessHoursInputBusinessHoursEdit] The updated BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchBusinessHoursItem(
      id: string,
      businessHoursBusinessHoursInputBusinessHoursEdit?: BusinessHoursBusinessHoursInputBusinessHoursEdit,
      options?: any,
    ): AxiosPromise<BusinessHoursBusinessHoursOutputBusinessHoursRead> {
      return localVarFp
        .patchBusinessHoursItem(
          id,
          businessHoursBusinessHoursInputBusinessHoursEdit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Changes status for an BusinessHours resource
     * @param {string} uuid
     * @param {BusinessHoursStatusInputBusinessHoursWrite} [businessHoursStatusInputBusinessHoursWrite] The updated BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusBusinessHoursItem(
      uuid: string,
      businessHoursStatusInputBusinessHoursWrite?: BusinessHoursStatusInputBusinessHoursWrite,
      options?: any,
    ): AxiosPromise<BusinessHoursBusinessHoursOutputBusinessHoursRead> {
      return localVarFp
        .patchStatusBusinessHoursItem(
          uuid,
          businessHoursStatusInputBusinessHoursWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a BusinessHours resource.
     * @param {string} id
     * @param {BusinessHoursBusinessHoursInputBusinessHoursWrite} [businessHoursBusinessHoursInputBusinessHoursWrite] The new BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBusinessHoursForCurrentStoreBusinessHoursCollection(
      id: string,
      businessHoursBusinessHoursInputBusinessHoursWrite?: BusinessHoursBusinessHoursInputBusinessHoursWrite,
      options?: any,
    ): AxiosPromise<BusinessHoursBusinessHoursOutputBusinessHoursRead> {
      return localVarFp
        .postBusinessHoursForCurrentStoreBusinessHoursCollection(
          id,
          businessHoursBusinessHoursInputBusinessHoursWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Replaces the BusinessHours resource.
     * @param {string} id
     * @param {BusinessHoursBusinessHoursInputBusinessHoursEdit} [businessHoursBusinessHoursInputBusinessHoursEdit] The updated BusinessHours resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putBusinessHoursItem(
      id: string,
      businessHoursBusinessHoursInputBusinessHoursEdit?: BusinessHoursBusinessHoursInputBusinessHoursEdit,
      options?: any,
    ): AxiosPromise<BusinessHoursBusinessHoursOutputBusinessHoursRead> {
      return localVarFp
        .putBusinessHoursItem(
          id,
          businessHoursBusinessHoursInputBusinessHoursEdit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BusinessHoursApi - object-oriented interface
 * @export
 * @class BusinessHoursApi
 * @extends {BaseAPI}
 */
export class BusinessHoursApi extends BaseAPI {
  /**
   *
   * @summary Removes the BusinessHours resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessHoursApi
   */
  public deleteBusinessHoursItem(id: string, options?: any) {
    return BusinessHoursApiFp(this.configuration)
      .deleteBusinessHoursItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Archived BusinessHours
   * @param {boolean} [isActive]
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessHoursApi
   */
  public getAllArchivedBusinessHoursBusinessHoursCollection(
    isActive?: boolean,
    startDate?: string,
    endDate?: string,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return BusinessHoursApiFp(this.configuration)
      .getAllArchivedBusinessHoursBusinessHoursCollection(
        isActive,
        startDate,
        endDate,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves the collection of BusinessHoursOutput resources.
   * @param {boolean} [isActive]
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessHoursApi
   */
  public getBusinessHoursCollection(
    isActive?: boolean,
    startDate?: string,
    endDate?: string,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return BusinessHoursApiFp(this.configuration)
      .getBusinessHoursCollection(
        isActive,
        startDate,
        endDate,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves BusinessHours by Store
   * @param {string} id
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {boolean} [isActive]
   * @param {boolean} [onlyArchived]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessHoursApi
   */
  public getBusinessHoursForCurrentStoreBusinessHoursCollection(
    id: string,
    startDate?: string,
    endDate?: string,
    isActive?: boolean,
    onlyArchived?: boolean,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return BusinessHoursApiFp(this.configuration)
      .getBusinessHoursForCurrentStoreBusinessHoursCollection(
        id,
        startDate,
        endDate,
        isActive,
        onlyArchived,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a BusinessHoursOutput resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessHoursApi
   */
  public getBusinessHoursItem(id: string, options?: any) {
    return BusinessHoursApiFp(this.configuration)
      .getBusinessHoursItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the BusinessHours resource.
   * @param {string} id
   * @param {BusinessHoursBusinessHoursInputBusinessHoursEdit} [businessHoursBusinessHoursInputBusinessHoursEdit] The updated BusinessHours resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessHoursApi
   */
  public patchBusinessHoursItem(
    id: string,
    businessHoursBusinessHoursInputBusinessHoursEdit?: BusinessHoursBusinessHoursInputBusinessHoursEdit,
    options?: any,
  ) {
    return BusinessHoursApiFp(this.configuration)
      .patchBusinessHoursItem(
        id,
        businessHoursBusinessHoursInputBusinessHoursEdit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Changes status for an BusinessHours resource
   * @param {string} uuid
   * @param {BusinessHoursStatusInputBusinessHoursWrite} [businessHoursStatusInputBusinessHoursWrite] The updated BusinessHours resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessHoursApi
   */
  public patchStatusBusinessHoursItem(
    uuid: string,
    businessHoursStatusInputBusinessHoursWrite?: BusinessHoursStatusInputBusinessHoursWrite,
    options?: any,
  ) {
    return BusinessHoursApiFp(this.configuration)
      .patchStatusBusinessHoursItem(
        uuid,
        businessHoursStatusInputBusinessHoursWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a BusinessHours resource.
   * @param {string} id
   * @param {BusinessHoursBusinessHoursInputBusinessHoursWrite} [businessHoursBusinessHoursInputBusinessHoursWrite] The new BusinessHours resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessHoursApi
   */
  public postBusinessHoursForCurrentStoreBusinessHoursCollection(
    id: string,
    businessHoursBusinessHoursInputBusinessHoursWrite?: BusinessHoursBusinessHoursInputBusinessHoursWrite,
    options?: any,
  ) {
    return BusinessHoursApiFp(this.configuration)
      .postBusinessHoursForCurrentStoreBusinessHoursCollection(
        id,
        businessHoursBusinessHoursInputBusinessHoursWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Replaces the BusinessHours resource.
   * @param {string} id
   * @param {BusinessHoursBusinessHoursInputBusinessHoursEdit} [businessHoursBusinessHoursInputBusinessHoursEdit] The updated BusinessHours resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessHoursApi
   */
  public putBusinessHoursItem(
    id: string,
    businessHoursBusinessHoursInputBusinessHoursEdit?: BusinessHoursBusinessHoursInputBusinessHoursEdit,
    options?: any,
  ) {
    return BusinessHoursApiFp(this.configuration)
      .putBusinessHoursItem(
        id,
        businessHoursBusinessHoursInputBusinessHoursEdit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
