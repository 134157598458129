import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/styles';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  centered: boolean;
}

export const CustomTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '3px !important',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#00D1D2',
  },
});

export const CustomTab = styled((props: { label: string }) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: '700 !important',
  fontSize: '16px',
  color: '#7D7982',
  '&.Mui-selected': {
    color: '#34303D',
  },
}));
