import { SvgIcon } from '@mui/material';
import { useIconColor } from './IconColor';
import React from 'react';
import { IconProps } from './iconProps';

export const LogoutIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="-.5 0 21.5 21.5"
        fill="none"
      >
        <path
          d="M12 15.0012L15.0013 12L12 8.99875"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.99609 12H15.0011"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4 9V7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V15"
          stroke={iconColor}
          stroke-width="1.5"
        />
      </svg>
    </SvgIcon>
  );
};
