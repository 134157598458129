import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ClickAndLendingActions } from "../actions";
import _ from "lodash";
import {
  ClickAndLending,
  HydratedClickAndLending,
  Store,
} from "../../api/entities";
import LocalStorageService from "../../services/LocalStorageService";

export const rowsPerPageOptions = [10, 30, 100];

export type ClickAndLendingState = {
  hydratedClickAndLendingReservations?: HydratedClickAndLending;
  loadingReservations: boolean;
  reservationsError: boolean;
  patchInProgress: boolean;
  rowsPerPage: number;
  currentSelectedStore: Store;
  products: Array<any>;
};

const INITIAL_CLICK_AND_LENDING_STATE: ClickAndLendingState = {
  loadingReservations: false,
  reservationsError: false,
  patchInProgress: false,
  rowsPerPage: rowsPerPageOptions[1],
  currentSelectedStore: {},
  products: [],
};

const updateClickAndLending = (
  hydratedClickAndLendingReservations: HydratedClickAndLending | undefined,
  request: ClickAndLending,
) => {
  if (hydratedClickAndLendingReservations) {
    let index = _.findIndex(
      hydratedClickAndLendingReservations["hydra:member"],
      (member) => member.uuid === request.uuid,
    );
    let newArray = [
      ...hydratedClickAndLendingReservations["hydra:member"].slice(0, index),
      request,
      ...hydratedClickAndLendingReservations["hydra:member"].slice(index + 1),
    ];
    return { ...hydratedClickAndLendingReservations, "hydra:member": newArray };
  }
  return hydratedClickAndLendingReservations;
};

const ClickAndLendingReducer = createReducer(
  INITIAL_CLICK_AND_LENDING_STATE,
  (builder) =>
    builder
      //get book and collect reservations
      .addCase(
        ClickAndLendingActions.getReservationsLoading,
        (state: ClickAndLendingState) => {
          return { ...state, loadingReservations: true };
        },
      )
      .addCase(
        ClickAndLendingActions.getReservationsSuccess,
        (
          state: ClickAndLendingState,
          action: PayloadAction<HydratedClickAndLending>,
        ) => {
          let hydratedClickAndLendingReservations = action.payload;
          return {
            ...state,
            hydratedClickAndLendingReservations,
            loadingReservations: false,
            reservationsError: false,
          };
        },
      )
      .addCase(
        ClickAndLendingActions.getReservationsError,
        (state: ClickAndLendingState, action: any) => {
          return {
            ...state,
            reservationsError: action.payload,
            loadingReservations: false,
          };
        },
      )

      // set current selected store
      .addCase(
        ClickAndLendingActions.currentSelectedStore,
        (state: ClickAndLendingState, action: PayloadAction<Store>) => {
          let currentSelectedStore = action.payload;
          LocalStorageService.getInstance().set(
            LocalStorageService.CURRENT_STORE,
            currentSelectedStore,
          );
          return { ...state, currentSelectedStore };
        },
      )

      // set store per page
      .addCase(
        ClickAndLendingActions.setRowsPerPageAction,
        (state: ClickAndLendingState, action: PayloadAction<number>) => {
          return { ...state, rowsPerPage: action.payload };
        },
      )

      // patch one book and collect
      .addCase(
        ClickAndLendingActions.patchLoading,
        (state: ClickAndLendingState) => {
          return { ...state, patchInProgress: true };
        },
      )
      .addCase(
        ClickAndLendingActions.patchSuccess,
        (
          state: ClickAndLendingState,
          action: PayloadAction<ClickAndLending>,
        ) => {
          const patchedBookAndCollect = action.payload;
          return {
            ...state,
            patchInProgress: false,
            hydratedClickAndLendingReservations: updateClickAndLending(
              state.hydratedClickAndLendingReservations,
              patchedBookAndCollect,
            ),
          };
        },
      )
      .addCase(
        ClickAndLendingActions.patchError,
        (state: ClickAndLendingState, action: any) => {
          return { ...state, patchInProgress: false };
        },
      )
      .addCase(
        ClickAndLendingActions.productsSuccess,
        (state: ClickAndLendingState, action: any) => {
          return { ...state, products: action.payload };
        },
      ),
);

export default ClickAndLendingReducer;
