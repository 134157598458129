import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  Grow,
  Theme,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import BookingInfoPage from './BookingInfoPage';
import BookingAppointmentPage from './BookingAppointmentPage';
import { RootState } from '../../redux/reducers';
import { Appointment, AppointmentRequest } from '../../api/entities';
import { useDispatch, useSelector } from 'react-redux';
import { SnackBarActions, AppointmentsActions } from '../../redux/actions';
import { validateEmail, validatePhone } from '../../utils/validators';
import { createStyles, makeStyles } from '@mui/styles';
import { CustomTab, CustomTabs } from '../CustomTabs';
import { ArrowLeftIcon } from '../icons/ArrowLeftIcon';
import { ArrowRightIcon } from '../icons/ArrowRightIcon';

interface EditBookingModalProps {
  onClose: () => void;
  open: boolean;
  event: Appointment | null | undefined;
}

export default function EditBookingModal({
  onClose,
  open,
  event,
}: EditBookingModalProps) {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [appointment, setAppointment] = useState<AppointmentRequest | null>(
    null,
  );
  const [checkedNotification, setCheckedNotification] = useState(true);
  const dispatch = useDispatch();
  const patchAppointmentLoading = useSelector(
    (state: RootState) => state.AppointmentsReducer.patchAppointmentLoading,
  );

  const handleClose = () => {
    onClose();
    setIndex(0);
  };

  const handleChangeNotifCheckbox = (event: any) => {
    setCheckedNotification(event.target.checked);
  };

  const handleContinue = () => {
    setIndex(index === 0 ? 1 : 0);
  };

  const checkEmailAndPhoneFields = () => {
    if (
      appointment?.clients &&
      appointment.clients[0].email &&
      !validateEmail(appointment?.clients[0].email) &&
      appointment?.clients[0].email !== ''
    ) {
      dispatch(SnackBarActions.snackbar('Email non valida', 'warning'));
      return false;
    }
    if (
      appointment?.clients &&
      appointment.clients[0].phoneNumber &&
      !validatePhone(appointment?.clients[0].phoneNumber) &&
      appointment?.clients[0].phoneNumber !== ''
    ) {
      dispatch(
        SnackBarActions.snackbar('Numero di telefono non valido', 'warning'),
      );
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (
      appointment &&
      appointment.id &&
      appointment.storeService &&
      appointment.startTime &&
      appointment.endTime &&
      appointment.clients &&
      appointment.products &&
      appointment.products.length > 0
    ) {
      appointment.notificationEnabled = checkedNotification;
      dispatch(
        AppointmentsActions.patchAppointment(appointment.id, appointment),
      );
      handleClose();
    } else {
      dispatch(
        SnackBarActions.snackbar(
          'È fondamentale compilare tutti i campi obbligatori',
          'warning',
        ),
      );
    }
  };

  const checkAndSave = () => {
    if (checkEmailAndPhoneFields()) {
      handleSave();
    }
  };

  const handleBack = () => {
    setIndex(0);
  };

  const handleOnChangeTimes = (appointment: AppointmentRequest | null) => {
    setAppointment((prevState) => {
      return { ...prevState, ...appointment };
    });
  };

  const handleOnChangeInfos = (appointment: AppointmentRequest) => {
    setAppointment((prevState) => {
      return { ...prevState, ...appointment };
    });
  };

  const renderNotificationCheckbox = () => {
    return (
      <Grow in={index === 0}>
        <Box display="flex" alignItems="center">
          <Checkbox
            color="secondary"
            checked={checkedNotification}
            onChange={handleChangeNotifCheckbox}
          />
          <Typography>Inviare notifica</Typography>
        </Box>
      </Grow>
    );
  };

  const renderLoading = () => {
    return (
      patchAppointmentLoading && (
        <Box display="flex" className={classes.loadingBackground}>
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={40}
            thickness={4}
          />
        </Box>
      )
    );
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={classes.modal}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      {renderLoading()}
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        Modifica Appuntamento
      </DialogTitle>
      <Grid className={classes.gridContainer}>
        <CustomTabs
          className={classes.tabs}
          value={index}
          onChange={handleContinue}
          centered
        >
          <CustomTab label="Appuntamento" />
          <CustomTab label="Cliente" />
        </CustomTabs>

        {index === 0 && (
          <BookingAppointmentPage
            event={event}
            isActive={index === 0}
            onChangeAppointment={(data) => handleOnChangeTimes(data)}
          />
        )}

        {index === 1 && (
          <BookingInfoPage
            event={event}
            isActive={index === 1}
            onChangedInfoData={(data) => handleOnChangeInfos(data)}
          />
        )}
      </Grid>
      <Box
        className={classes.footerWrapper}
        justifyContent="space-between"
        alignItems="center"
        display="flex"
      >
        {index === 0 ? (
          renderNotificationCheckbox()
        ) : (
          <Grow in={index === 1}>
            <Button
              disableElevation
              className={classes.cancelButton}
              onClick={() => handleBack()}
              startIcon={<ArrowLeftIcon color="#34303D" />}
            >
              Indietro
            </Button>
          </Grow>
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.continueButton}
          onClick={() => checkAndSave()}
          endIcon={<ArrowRightIcon />}
        >
          Modifica
        </Button>
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
    },
    gridContainer: {
      backgroundColor: 'white',
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 4,
    },
    tabs: {
      marginBottom: 40,
      borderBottom: '1px solid #E0E0E0',
    },
    stepperContainer: {
      margin: '0 auto 40px auto',
      width: '85%',
    },
    footerWrapper: {
      padding: '16px 24px 16px 13px',
    },
    footer: {
      width: '100%',
      padding: '16px 16px 8px 16px',
    },
    continueButton: {
      backgroundColor: '#34303d !important',
      padding: '12px 24px !important',
      borderRadius: '50px !important',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      color: '#fff !important',
      border: '1px solid #34303d !important',
    },
    cancelButton: {
      backgroundColor: 'transparent !important',
      padding: '12px 24px !important',
      borderRadius: '50px !important',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      color: '#34303d !important',
      border: '1px solid #34303d !important',
    },
    loadingBackground: {
      backgroundColor: '#34303D7f',
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 999,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
