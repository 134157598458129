import React from 'react';
import { TextField } from '@mui/material';
import { validateEmail } from '../../../utils/validators';

interface FormInputProps {
  onChange: (text: string) => void;
  onSubmit: () => void;
}

interface FomrInputState {
  username: string;
}

export class FormInput extends React.Component<FormInputProps, FomrInputState> {
  constructor(props: FormInputProps) {
    super(props);
    this.state = { username: '' };
  }

  errorText = () => {
    const { username } = this.state;
    return username !== '' && !validateEmail(username) && 'email non valid';
  };

  handleOnChange = (value: string) => {
    this.setState({ username: value }, () => {
      this.props.onChange(value);
    });
  };

  render() {
    return (
      <TextField
        fullWidth
        margin="dense"
        id="outlined-required"
        color="secondary"
        label={'Email'}
        variant="outlined"
        size="small"
        style={{ marginBottom: '12px', marginTop: '12px' }}
        inputProps={{
          style: {
            padding: '12px 16px',
            borderColor: '#E1E0E2',
          },
        }}
        InputLabelProps={{
          style: {
            top: '4px',
          },
        }}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            this.props.onSubmit();
          }
        }}
        onChange={(event) => {
          this.handleOnChange(event.target.value);
        }}
      />
    );
  }
}

export default FormInput;
