import React, { useEffect, useMemo, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { Autocomplete } from '@mui/material';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

interface PlaceTextFieldPorps {
  value: string;
  onSelect: (placeType: PlaceType | null) => void;
}

export default function PlaceTextField({
  value,
  onSelect,
}: PlaceTextFieldPorps) {
  const [placeType, setPlaceType] = useState<PlaceType | null>({
    description: value,
    place_id: '',
    structured_formatting: {
      main_text: '',
      secondary_text: '',
      main_text_matched_substrings: [
        {
          offset: 0,
          length: 0,
        },
      ],
    },
  });
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<PlaceType[]>([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyCgHbEyU7C1wS6O0sOrzBG8swoF86o3-us&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        200,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) return;

    if (inputValue === '' && placeType?.place_id) {
      setOptions(placeType ? [placeType] : []);
      return;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (!active) return;
      let newOptions: PlaceType[] = [];

      if (placeType?.place_id) {
        newOptions = [placeType];
      }

      if (results) {
        newOptions = [...newOptions, ...results];
      }

      newOptions = newOptions.reduce((options: PlaceType[], o) => {
        if (!o.place_id) return options;
        if (options.find((option) => option.place_id === o.place_id))
          return options;
        return [...options, o];
      }, []);

      setOptions(newOptions);
    });

    return () => {
      active = false;
    };
  }, [placeType, inputValue, fetch]);

  return (
    <Autocomplete
      noOptionsText="Nessun risultato"
      id="google-map-demo"
      fullWidth
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      options={options}
      autoComplete
      includeInputInList
      value={placeType}
      onChange={(event: any, newValue: PlaceType | null) => {
        if (newValue?.place_id) {
          setOptions([newValue, ...options]);
        }
        setPlaceType(newValue);
        onSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          size="small"
          style={{ marginBottom: '24px' }}
          inputProps={{
            ...params.inputProps,
            style: {
              padding: '6px 16px',
            },
          }}
          InputLabelProps={{
            style: {
              top: '4px',
            },
          }}
          label="Indirizzo"
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ]),
        );
        return (
          <li {...props} key={option.place_id}>
            <Grid container alignItems="center">
              <Grid item>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
