import React from 'react';
import { SvgIcon } from '@mui/material';
import { useIconColor } from './IconColor';
import { IconProps } from './iconProps';

export const CalendarIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="18"
        viewBox="-.5 0 17 19"
        fill="none"
      >
        <path
          d="M0.892578 6.923H15.1166"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.5518 10.048H11.5608"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.00293 10.048H8.01193"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.4458 10.048H4.4548"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.5518 13.157H11.5608"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.00293 13.157H8.01193"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.4458 13.157H4.4548"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.2349 1V4.291"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.77295 1V4.291"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.3908 2.57899H4.6168C2.2668 2.57899 0.799805 3.85999 0.799805 6.21399V13.3C0.799805 15.69 2.2668 17 4.6168 17H11.3828C13.7398 17 15.1998 15.712 15.1998 13.358V6.21399C15.2068 3.85999 13.7468 2.57899 11.3908 2.57899Z"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
