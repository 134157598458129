import React from 'react';
import './StoreTableToolbar.scss';
import {
  Typography,
  Box,
  Button,
  TextField,
  FormControlLabel,
  Toolbar,
} from '@mui/material';
import { IOSSwitch as Switch } from '../../forms/Switch';
import { RootState } from '../../../redux/reducers';
import { connect } from 'react-redux';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { UserRoles } from '../../../utils/UserRoles';
import { StoreActions } from '../../../redux/actions';
import Autocomplete from '@mui/material/Autocomplete';
import { AddIcon } from '../../icons/AddIcon';
import { Store } from '../../../api/entities';

interface StoresBarProps {
  onPressCreateStore: () => void;
  onPressCancelSelection: () => void;
  numSelected: number;
  getUserCurrentStores: () => void;
  onStoresSelected: (stores: Store[]) => void;
  userCurrentStores: Store[] | undefined;
  currentRole?: string;
  onSwitchArchivedOnly: (value: boolean) => void;
}

interface StoresBarState {
  onlyArchived: boolean;
}

class StoreTableToolbar extends React.Component<
  StoresBarProps,
  StoresBarState
> {
  constructor(props: StoresBarProps) {
    super(props);
    this.state = {
      onlyArchived: false,
    };
  }

  componentDidMount() {
    this.props.getUserCurrentStores();
  }

  onSelectStores = (stores: Store[]) => {
    this.props.onStoresSelected(stores);
  };

  onSwitchOnlyArchived = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ onlyArchived: event.target.checked }, () => {
      this.props.onSwitchArchivedOnly(this.state.onlyArchived);
    });
  };

  render() {
    const {
      numSelected,
      currentRole,
      userCurrentStores,
      onPressCancelSelection,
    } = this.props;
    return (
      <Toolbar className="toolbar">
        <Box
          className="bar-wrapper"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <Typography className="title">Store</Typography>
            <FormControlLabel
              className="control-label"
              control={
                <Switch
                  className="control-switch"
                  checked={this.state.onlyArchived}
                  onChange={this.onSwitchOnlyArchived}
                  name="checkedB"
                  color="secondary"
                />
              }
              label="Mostra archiviati"
              labelPlacement="start"
            />
          </Box>
          {numSelected > 0 ? (
            <Box
              display="flex"
              className="selected-content"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                className="delete-button"
                onClick={() => onPressCancelSelection()}
                variant="outlined"
              >
                <Typography className="delete-button-title">
                  {`Annulla selezione (${numSelected})`}
                </Typography>
              </Button>
            </Box>
          ) : (
            <Box
              display="flex"
              className="content"
              justifyContent="space-between"
              alignItems="center"
            >
              <Autocomplete
                noOptionsText="Nessun risultato"
                multiple
                limitTags={2}
                size="small"
                id="checkboxes-tags-demo"
                options={userCurrentStores || []}
                disableCloseOnSelect
                getOptionLabel={(option: any) =>
                  option.name ? option.name : ''
                }
                onChange={(e, options) => this.onSelectStores(options as any[])}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.uuid}>
                    <Checkbox
                      color="secondary"
                      style={{ marginRight: 4 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{ minWidth: 275, maxWidth: 500, marginRight: 10 }}
                color="secondary"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Seleziona Store"
                    size="small"
                    color="secondary"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        padding: '6px 10px',
                        borderColor: '#E1E0E2',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        top: '4px',
                      },
                    }}
                  />
                )}
              />
              {(currentRole === UserRoles.ADMIN ||
                currentRole === UserRoles.SUPER_STORE_MANAGER) && (
                <Button
                  className="button"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  onClick={() => this.props.onPressCreateStore()}
                  startIcon={<AddIcon color="#34303D" />}
                >
                  <Typography className="button-title">
                    Aggiungi Store
                  </Typography>
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Toolbar>
    );
  }
}

export function mapStateToProps(state: RootState) {
  return {
    userCurrentStores: state.StoreReducer.userCurrentStores,
    currentRole: state.UsersReducer.currentRole,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserCurrentStores: () => dispatch(StoreActions.getUserCurrentStores()),
    getStores: (
      sortField?: string,
      sortDir?: string,
      page?: number,
      itemsPerPage?: number,
      onlyArchived?: boolean,
      names?: string[],
    ) =>
      dispatch(
        StoreActions.getStores(
          sortField,
          sortDir,
          page,
          itemsPerPage,
          onlyArchived,
          names,
        ),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreTableToolbar);
