import Trash from "../assets/Trash.svg";
import Close from "../assets/Close.svg";
import Pencil from "../assets/Pencil.svg";
import Note from "../assets/Note.svg";
import Message from "../assets/Message.svg";
import Suspend from "../assets/Suspend.svg";
import Trash_m from "../assets/Trash_m.svg";
import Archive from "../assets/Archive.svg";
import TrashYellow from "../assets/TrashYellow.svg";
import Plus from "../assets/Plus.svg";
import Warning from "../assets/Warning.svg";
import WheelWhite from "../assets/WheelWhite.svg";
import WheelBlack from "../assets/WheelBlack.svg";
import Services from "../assets/Services.svg";
import EditService from "../assets/EditService.svg";

export default {
  Trash,
  Close,
  Pencil,
  Note,
  Message,
  Suspend,
  Trash_m,
  Archive,
  Plus,
  TrashYellow,
  Warning,
  WheelWhite,
  WheelBlack,
  Services,
  EditService,
};
