import React, { useCallback, useMemo } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { RootState } from '../../redux/reducers';
import { TimeSlot } from '../../api/entities';

interface ServiceButtonProps {
  slot?: (TimeSlot & { selected: boolean }) | null;
  onSelect: (slot: TimeSlot) => void;
}

export default function TimeSlotButton({ slot, onSelect }: ServiceButtonProps) {
  const classes = useStyles();

  const loading = useSelector(
    (state: RootState) => state.StoreServiceReducer.getTimeSlotsLoading,
  );

  const setSelection = useCallback(() => {
    if (slot) onSelect(slot);
  }, [slot, onSelect]);

  const occupiedSlots = useMemo<number>(
    () => (slot && slot.occupiedSlots ? slot.occupiedSlots : 0),
    [slot],
  );

  const totalSlots = useMemo<number>(
    () => (slot && slot.totalSlots ? slot.totalSlots : 0),
    [slot],
  );

  const allOccupied = useMemo<boolean>(
    () => occupiedSlots >= totalSlots,
    [totalSlots, occupiedSlots],
  );

  const dotStyle =
    occupiedSlots === 0 || allOccupied ? classes.noDots : classes.hasDots;

  return loading ? (
    <Skeleton className={classes.skeleton} height={36} animation="wave" />
  ) : (
    <Button
      className={`checkbox-button ${!!slot?.selected && 'selected-checkbox'}`}
      // className={!!slot?.selected ? selectedStyle : rootStyle}
      onClick={() => setSelection()}
    >
      <Grid>
        <Typography
          className={
            !!slot?.selected
              ? classes.selectedTitle
              : allOccupied
              ? classes.occupiedTitle
              : classes.title
          }
        >
          {slot ? slot.slot : ''}
        </Typography>
      </Grid>

      <Box className={dotStyle} display="flex" flexDirection="column">
        {[...Array(occupiedSlots)].map((e, i) => (
          <Box className={classes.activeDot} key={i} />
        ))}
      </Box>
    </Button>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hasDots: {
      position: 'absolute',
      right: 2,
    },
    noDots: {
      transform: 'rotate(90deg)',
      position: 'absolute',
      right: -2,
    },
    activeDot: {
      width: 6,
      height: 6,
      backgroundColor: '#00D1D2 !important',
      borderRadius: 3,
      marginBottom: 2,
    },
    title: {
      fontSize: 12,
      color: '#34303D',
      fontWeight: 'bold',
    },
    occupiedTitle: {
      fontSize: 12,
      color: '#34303D',
      fontWeight: 'bold',
    },
    selectedTitle: {
      fontSize: 12,
      color: '#34303D',
      fontWeight: 'bold',
    },
    skeleton: {
      borderRadius: 6,
    },
  }),
);
