import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme, Box, Checkbox, Typography, Breakpoint } from '@mui/material';
import DialogActionFooter from './DialogActionFooter';
import { createStyles, makeStyles } from '@mui/styles';
interface DialogProps {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
  title: string;
  message: string;
  actionTitle: string;
  hasNotificationOption?: boolean;
  notificationEnabled?: boolean;
  onCheckedNotificationEnabled?: (value: boolean) => void;
  maxWidth?: Breakpoint;
}

export default function ActionDialog({
  open,
  onClose,
  onAction,
  title,
  message,
  actionTitle,
  hasNotificationOption,
  notificationEnabled,
  onCheckedNotificationEnabled,
  maxWidth,
}: DialogProps) {
  const classes = useStyles();
  const [checkedNotification, setCheckedNotification] = useState(
    notificationEnabled ? notificationEnabled : false,
  );
  const handleClose = () => {
    hasNotificationOption &&
      setCheckedNotification(notificationEnabled ? notificationEnabled : false);
    onClose();
  };
  const handleAction = () => {
    onAction();
  };

  useEffect(() => {
    onCheckedNotificationEnabled &&
      onCheckedNotificationEnabled(checkedNotification);
  }, [checkedNotification, onCheckedNotificationEnabled]);

  const handleChangeNotifCheckbox = (event: any) => {
    setCheckedNotification(event.target.checked);
  };

  const renderNotificationCheckbox = () => {
    return (
      <Box display="flex" alignItems="center" className={classes.checkBox}>
        <Checkbox
          color="secondary"
          checked={checkedNotification}
          onChange={handleChangeNotifCheckbox}
        />
        <Typography className={classes.checkBoxLabel}>
          Inviare notifica
        </Typography>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth || 'sm'}
      fullWidth={true}
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '12px' }}>
        <DialogContentText
          className={classes.contentText}
          id="alert-dialog-description"
        >
          {message}
        </DialogContentText>
      </DialogContent>
      {hasNotificationOption && renderNotificationCheckbox()}
      <DialogActions className={classes.footerBox}>
        <DialogActionFooter
          onCancel={() => handleClose()}
          onConfirm={handleAction}
          actionTitle={actionTitle}
        />
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      width: 122,
      height: 35,
    },
    footerBox: {
      backgroundColor: '#ffffff',
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16,
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '8px !important',
    },
    contentText: {
      fontFamily: 'IQOS Sans',
      fontSize: '14px !important',
    },
    checkBox: {
      paddingLeft: 18,
      marginBottom: -8,
      marginLeft: -6,
      zIndex: 1000,
    },
    checkBoxLabel: {
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      color: '#34303d',
    },
  }),
);
