import React from 'react';
import Button from '@mui/material/Button';
import { Theme, Box } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';
import { ArrowLeftIcon } from '../icons/ArrowLeftIcon';
import { ArrowRightIcon } from '../icons/ArrowRightIcon';

interface DialogProps {
  onCancel: () => void;
  onConfirm: () => void;
  actionTitle?: string;
  undoTitle?: string;
  disableConfirm?: boolean;
}

export default function DialogActionFooter({
  onCancel,
  onConfirm,
  actionTitle,
  undoTitle,
  disableConfirm,
}: DialogProps) {
  const classes = useStyles();

  return (
    <Box
      className={classes.footer}
      justifyContent="space-between"
      alignItems="center"
      display="flex"
    >
      <Button
        className={classes.cancelButton}
        variant="contained"
        onClick={onCancel}
        disableElevation
        startIcon={<ArrowLeftIcon color="#34303D" />}
      >
        {undoTitle || 'Annulla'}
      </Button>
      <Button
        disabled={!!disableConfirm}
        variant="contained"
        color="primary"
        className={classes.saveButton}
        onClick={onConfirm}
        endIcon={<ArrowRightIcon />}
        disableElevation
      >
        {actionTitle || 'Salva'}
      </Button>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      width: '100%',
      padding: '16px 16px 8px 16px',
    },
    saveButton: {
      backgroundColor: '#34303d !important',
      padding: '12px 24px !important',
      borderRadius: '50px !important',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      color: '#fff !important',
      border: '1px solid #34303d !important',
      '&&:hover': {
        backgroundColor: '#504d58 !important',
      },
    },
    cancelButton: {
      backgroundColor: 'transparent !important',
      padding: '12px 24px !important',
      borderRadius: '50px !important',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      color: '#34303d !important',
      border: '1px solid #34303d !important',
      '&&:hover': {
        backgroundColor: '#e1e0e2 !important',
      },
    },
  }),
);
