import React, { useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Dialog, Grid, TextareaAutosize, TextField } from '@mui/material';
import ModalHeader from '../../../ModalHeader';
import DialogActionFooter from '../../../dialog/DialogActionFooter';
import { useDispatch } from 'react-redux';
import { ServiceActions } from '../../../../redux/actions';
import * as buffer from 'buffer';
window.Buffer = buffer.Buffer;

interface EditNotificationDialogProps {
  isVisible: boolean;
  onClose: () => void;
  onSave: () => void;
  object: any;
  type: string;
}

export default function EditNotificationDialog({
  isVisible,
  onClose,
  onSave,
  object,
  type,
}: EditNotificationDialogProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tmpContent, setTempContent] = useState('');

  const renderValue = useMemo<string>(() => {
    if (object && type === 'email') {
      return object.emailNotification.fromAddress;
    }

    if (object && type === 'sms') {
      return object.smsNotification.externalName;
    }

    return '';
  }, [object, type]);

  useEffect(() => {
    const getContentString = () => {
      if (object && type === 'email') {
        const htmlString = Buffer.from(
          object.emailNotification.templateSource,
          'base64',
        ).toString('ascii');
        return htmlString;
      }

      if (object && type === 'sms') {
        const htmlString = Buffer.from(
          object.smsNotification.content,
          'base64',
        ).toString('ascii');
        return htmlString;
      }
      return '';
    };

    if (object) setTempContent(getContentString());
  }, [object, type]);

  const handleSave = () => {
    if (object && type === 'email') {
      var emailRequest = {
        emailNotification: {
          templateSource: Buffer.from(tmpContent).toString('base64'),
        },
      };
      dispatch(
        ServiceActions.editTransactionalNotifications(
          'email',
          object.uuid,
          emailRequest,
        ),
      );
      onSave();
    }
    if (object && type === 'sms') {
      var smsRequest = {
        smsNotification: {
          content: Buffer.from(tmpContent).toString('base64'),
        },
      };
      dispatch(
        ServiceActions.editTransactionalNotifications(
          'sms',
          object.uuid,
          smsRequest,
        ),
      );
      onSave();
    }
  };

  return (
    <Dialog
      className={classes.modal}
      open={isVisible}
      onClose={onClose}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <Grid className={classes.gridContainer}>
        <ModalHeader title={'Modifica notifica'} onClose={onClose} />
        <Box className={classes.contentBox}>
          <TextField
            className={classes.contentField}
            fullWidth
            label="Mittente"
            id="mittente"
            value={renderValue}
            contentEditable={false}
          />
          <TextareaAutosize
            className={classes.textArea}
            minRows={10}
            maxRows={10}
            aria-label="maximum height"
            placeholder="Messaggio"
            defaultValue=""
            value={tmpContent}
            onChange={(event) => setTempContent(event.target.value)}
          />
        </Box>

        <Box
          className={classes.footerView}
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <DialogActionFooter
            onCancel={onClose}
            onConfirm={() => handleSave()}
            actionTitle="Modifica"
          />
        </Box>
      </Grid>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    gridContainer: {
      backgroundColor: 'white',
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      borderRadius: 4,
      position: 'relative',
      overflow: 'hidden',
    },
    textArea: {
      width: '100%',
      borderWidth: 1,
      borderRadius: 4,
      borderColor: '#00D1D2',
      marginTop: 40,
      padding: 16,
    },
    input: {
      display: 'none',
    },
    uploadRoot: {
      alignItems: 'center',
    },
    contentBox: {
      overflow: 'scroll',
    },
    footerView: {
      width: '100%',
      height: 50,
      marginTop: 20,
      backgroundColor: 'white',
    },
    uploadButton: {
      marginTop: 6,
      fontWeight: 'bold',
      fontFamily: 'IQOS Sans',
    },
    title: {
      fontSize: 20,
      color: '#34303D',
    },
    label: {
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
    contentField: {
      width: '100%',
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
    img: { marginLeft: 16 },
  }),
);
