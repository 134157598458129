import React from 'react';
import { SvgIcon } from '@mui/material';
import { useIconColor } from './IconColor';
import { IconProps } from './iconProps';

export const CheckIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          d="M4.75 8.33342L7.08333 10.6667L13.0833 4.66675"
          stroke={iconColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
