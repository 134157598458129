import { Box, Button, Theme, Toolbar } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import React, { useState } from 'react';
import CreateServiceModal from '../details/modals/CreateServiceModal';
import { AddIcon } from '../../icons/AddIcon';

export default function ServiceTableToolbar() {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Toolbar className={classes.toolbar}>
      <Box
        className={classes.barWrapper}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div className={classes.toolbarTitle}>Servizi</div>
        <Box alignItems="center" display="flex">
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            className={classes.toolBarButton}
            onClick={() => setModalOpen(true)}
            startIcon={<AddIcon color="#34303D" />}
          >
            Nuovo Servizio
          </Button>
        </Box>
      </Box>
      <CreateServiceModal
        isVisible={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Toolbar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      width: '100%',
      padding: '20px 20px 0 20px !important',
      marginBottom: '32px',
    },
    barWrapper: {
      width: '100%',
    },
    toolBarButton: {
      padding: '14px 24px !important',
      borderRadius: '50px !important',
      color: '#34303d !important',
      lineHeight: '16px !important',
      fontSize: '16px !important',
    },
    toolbarTitle: {
      color: '#34303D',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    toolbarBox: {
      width: '100%',
    },
  }),
);
