/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { HydratedTransactionalNotificationTransactionalNotificationOutputTransactionalNotificationRead } from "../nbp";
// @ts-ignore
import { TransactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite } from "../nbp";
// @ts-ignore
import { TransactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite } from "../nbp";
// @ts-ignore
import { TransactionalNotificationTransactionalNotificationOutputTransactionalNotificationRead } from "../nbp";
/**
 * TransactionalNotificationApi - axios parameter creator
 * @export
 */
export const TransactionalNotificationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Retrieves the collection of TransactionalNotificationOutput resources.
     * @param {string} [serviceUuid]
     * @param {string} [productUuid]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionalNotificationCollection: async (
      serviceUuid?: string,
      productUuid?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/transactional_notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (serviceUuid !== undefined) {
        localVarQueryParameter["serviceUuid"] = serviceUuid;
      }

      if (productUuid !== undefined) {
        localVarQueryParameter["productUuid"] = productUuid;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a TransactionalNotificationOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionalNotificationItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getTransactionalNotificationItem", "id", id);
      const localVarPath = `/api/transactional_notifications/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the TransactionalNotification resource.
     * @param {string} id
     * @param {TransactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite} [transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite] The updated TransactionalNotification resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchForEmailNotificationTransactionalNotificationItem: async (
      id: string,
      transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite?: TransactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "patchForEmailNotificationTransactionalNotificationItem",
        "id",
        id,
      );
      const localVarPath =
        `/api/transactional_notification/{id}/email_notification`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the TransactionalNotification resource.
     * @param {string} id
     * @param {TransactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite} [transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite] The updated TransactionalNotification resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchForSmsNotificationTransactionalNotificationItem: async (
      id: string,
      transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite?: TransactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "patchForSmsNotificationTransactionalNotificationItem",
        "id",
        id,
      );
      const localVarPath =
        `/api/transactional_notification/{id}/sms_notification`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransactionalNotificationApi - functional programming interface
 * @export
 */
export const TransactionalNotificationApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    TransactionalNotificationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Retrieves the collection of TransactionalNotificationOutput resources.
     * @param {string} [serviceUuid]
     * @param {string} [productUuid]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionalNotificationCollection(
      serviceUuid?: string,
      productUuid?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedTransactionalNotificationTransactionalNotificationOutputTransactionalNotificationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTransactionalNotificationCollection(
          serviceUuid,
          productUuid,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a TransactionalNotificationOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionalNotificationItem(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransactionalNotificationTransactionalNotificationOutputTransactionalNotificationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTransactionalNotificationItem(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the TransactionalNotification resource.
     * @param {string} id
     * @param {TransactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite} [transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite] The updated TransactionalNotification resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchForEmailNotificationTransactionalNotificationItem(
      id: string,
      transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite?: TransactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransactionalNotificationTransactionalNotificationOutputTransactionalNotificationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchForEmailNotificationTransactionalNotificationItem(
          id,
          transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the TransactionalNotification resource.
     * @param {string} id
     * @param {TransactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite} [transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite] The updated TransactionalNotification resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchForSmsNotificationTransactionalNotificationItem(
      id: string,
      transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite?: TransactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransactionalNotificationTransactionalNotificationOutputTransactionalNotificationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchForSmsNotificationTransactionalNotificationItem(
          id,
          transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TransactionalNotificationApi - factory interface
 * @export
 */
export const TransactionalNotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TransactionalNotificationApiFp(configuration);
  return {
    /**
     *
     * @summary Retrieves the collection of TransactionalNotificationOutput resources.
     * @param {string} [serviceUuid]
     * @param {string} [productUuid]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionalNotificationCollection(
      serviceUuid?: string,
      productUuid?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedTransactionalNotificationTransactionalNotificationOutputTransactionalNotificationRead> {
      return localVarFp
        .getTransactionalNotificationCollection(
          serviceUuid,
          productUuid,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a TransactionalNotificationOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionalNotificationItem(
      id: string,
      options?: any,
    ): AxiosPromise<TransactionalNotificationTransactionalNotificationOutputTransactionalNotificationRead> {
      return localVarFp
        .getTransactionalNotificationItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the TransactionalNotification resource.
     * @param {string} id
     * @param {TransactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite} [transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite] The updated TransactionalNotification resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchForEmailNotificationTransactionalNotificationItem(
      id: string,
      transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite?: TransactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite,
      options?: any,
    ): AxiosPromise<TransactionalNotificationTransactionalNotificationOutputTransactionalNotificationRead> {
      return localVarFp
        .patchForEmailNotificationTransactionalNotificationItem(
          id,
          transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the TransactionalNotification resource.
     * @param {string} id
     * @param {TransactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite} [transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite] The updated TransactionalNotification resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchForSmsNotificationTransactionalNotificationItem(
      id: string,
      transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite?: TransactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite,
      options?: any,
    ): AxiosPromise<TransactionalNotificationTransactionalNotificationOutputTransactionalNotificationRead> {
      return localVarFp
        .patchForSmsNotificationTransactionalNotificationItem(
          id,
          transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TransactionalNotificationApi - object-oriented interface
 * @export
 * @class TransactionalNotificationApi
 * @extends {BaseAPI}
 */
export class TransactionalNotificationApi extends BaseAPI {
  /**
   *
   * @summary Retrieves the collection of TransactionalNotificationOutput resources.
   * @param {string} [serviceUuid]
   * @param {string} [productUuid]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionalNotificationApi
   */
  public getTransactionalNotificationCollection(
    serviceUuid?: string,
    productUuid?: string,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return TransactionalNotificationApiFp(this.configuration)
      .getTransactionalNotificationCollection(
        serviceUuid,
        productUuid,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a TransactionalNotificationOutput resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionalNotificationApi
   */
  public getTransactionalNotificationItem(id: string, options?: any) {
    return TransactionalNotificationApiFp(this.configuration)
      .getTransactionalNotificationItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the TransactionalNotification resource.
   * @param {string} id
   * @param {TransactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite} [transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite] The updated TransactionalNotification resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionalNotificationApi
   */
  public patchForEmailNotificationTransactionalNotificationItem(
    id: string,
    transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite?: TransactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite,
    options?: any,
  ) {
    return TransactionalNotificationApiFp(this.configuration)
      .patchForEmailNotificationTransactionalNotificationItem(
        id,
        transactionalNotificationTransactionalNotificationInputTransactionalNotificationEmailNotificationWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the TransactionalNotification resource.
   * @param {string} id
   * @param {TransactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite} [transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite] The updated TransactionalNotification resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionalNotificationApi
   */
  public patchForSmsNotificationTransactionalNotificationItem(
    id: string,
    transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite?: TransactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite,
    options?: any,
  ) {
    return TransactionalNotificationApiFp(this.configuration)
      .patchForSmsNotificationTransactionalNotificationItem(
        id,
        transactionalNotificationTransactionalNotificationInputTransactionalNotificationSmsNotificationWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
