import React from 'react';
import { List } from '@mui/material';
import StoreHoursCard from './StoreHoursCard';
import { BusinessHours } from '../../../api/entities';
import _ from 'lodash';
import moment from 'moment';
interface StoreHoursListProps {
  data: BusinessHours[];
  onDeleteItem: (id: string) => void;
}

export default function StoreHoursList(props: StoreHoursListProps) {
  let isSetCurrentWorkingHours = false;

  const removeCard = (id: string) => {
    props.onDeleteItem(id);
  };

  const isActive = (object: BusinessHours) => {
    if (!isSetCurrentWorkingHours) {
      if (object.endDate) {
        isSetCurrentWorkingHours =
          moment().isAfter(moment(object.startDate)) &&
          moment().isBefore(moment(object.endDate));
        return isSetCurrentWorkingHours;
      } else if (
        moment().diff(moment(object.startDate), 'days') === 0 ||
        moment().diff(moment(object.endDate), 'days') === 0
      ) {
        isSetCurrentWorkingHours = true;
        return isSetCurrentWorkingHours;
      } else {
        isSetCurrentWorkingHours = moment().isAfter(moment(object.startDate));
        return isSetCurrentWorkingHours;
      }
    } else {
      return false;
    }
  };

  let sortedData = _.sortBy(props.data, (obj) => obj.endDate);
  let findCurrent = _.find(sortedData, function (o) {
    return isActive(o);
  });
  var prepackData = _.map(sortedData, function (o) {
    return { ...o, isSelected: o.uuid === findCurrent?.uuid };
  });
  let findSelected = _.find(prepackData, function (o) {
    return o.isSelected === true;
  });
  if (!findSelected) {
    prepackData = _.map(prepackData, function (o) {
      if (o.isDefault === true) {
        return { ...o, isSelected: true };
      } else {
        return o;
      }
    });
  }
  let sortedPrepacked = _.sortBy(prepackData, (obj) => obj.startDate);
  let sortedByDefault = _.sortBy(
    sortedPrepacked,
    (obj) => obj.isDefault !== true,
  );

  return (
    <List disablePadding>
      {sortedByDefault.map((obj: any) => {
        return (
          <StoreHoursCard
            key={obj.uuid}
            storeOpenSchedule={obj}
            activeWorkingTime={obj.isSelected}
            onDeleteCard={(id) => removeCard(id)}
          />
        );
      })}
    </List>
  );
}
