import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StoreActions } from '../../../redux/actions';
import { RootState } from '../../../redux/reducers';
import { Store } from '../../../api/entities';
import '../../../utils/extensions';
import StoreRow from './StoreRow';
import { rowsPerPageOptions } from '../../../redux/reducers/StoreReducer';
import StoreTableToolbar from './StoreTableToolbar';

export type Order = 'asc' | 'desc';

interface HeadCell {
  id: string;
  label?: string;
}

const headCells: HeadCell[] = [
  { id: 'name', label: 'NOME' },
  { id: 'address', label: 'INDIRIZZO' },
  { id: 'region', label: 'REGIONE' },
  {
    id: 'updatedAt',
    label: 'ULTIME MODIFICHE',
  },
  { id: 'options' },
  { id: 'expandable' },
];

interface StoreTableHeadProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Store,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderRegion: Order;
  orderBy: string;
  orderByRegion: string;
  rowCount: number;
  currentOrderField: string;
}

function StoreTableHead({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  orderByRegion,
  orderRegion,
  numSelected,
  rowCount,
  onRequestSort,
  currentOrderField,
}: StoreTableHeadProps) {
  const createSortHandler =
    (property: keyof Store) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const tableSortItem = (headCell: any) => {
    switch (headCell.id) {
      case 'name':
        return (
          <TableSortLabel
            active={orderBy === headCell.id && currentOrderField === 'name'}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={createSortHandler(headCell.id)}
            className={classes.headCellLabel}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        );
      case 'region':
        return (
          <TableSortLabel
            active={
              orderByRegion === headCell.id && currentOrderField === 'region'
            }
            direction={orderByRegion === headCell.id ? orderRegion : 'asc'}
            onClick={createSortHandler(headCell.id)}
            className={classes.headCellLabel}
          >
            {headCell.label}
            {orderByRegion === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {orderRegion === 'desc'
                  ? 'sorted descending'
                  : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        );
      default:
        return <div className={classes.headCellLabel}>{headCell.label}</div>;
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.headTableCell} padding="checkbox">
          <Checkbox
            color="secondary"
            style={{ padding: '4px' }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.headTableCell}
            key={headCell.id}
            align={headCell.label === 'icon' ? 'right' : 'left'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {tableSortItem(headCell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface StoreTableProps {
  onCreateStore: () => void;
  onArchiveSelected: (value: boolean) => void;
}

// MARK: Main Table
export default function StoreTable({
  onCreateStore,
  onArchiveSelected,
}: StoreTableProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Store>('name');
  const [orderRegion, setOrderRegion] = useState<Order>('asc');
  const [orderByRegion, setOrderByRegion] = useState<keyof Store>('region');
  const [selected, setSelected] = useState<string[]>([]);
  const [currentOrderField, setCurrentOrder] = useState('name');
  const [filteredStoreNames, setFilteredStoreNames] = useState<string[]>([]);
  const [archivedOnly, setArchivedOnly] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stores = useSelector((state: RootState) =>
    state.StoreReducer.hydratedStore
      ? state.StoreReducer.hydratedStore['hydra:member']
      : [],
  );

  const isLoading = useSelector(
    (state: RootState) => state.StoreReducer.loadingStores,
  );

  const perPage = useSelector(
    (state: RootState) => state.StoreReducer.rowsPerPage,
  );

  const page = useSelector((state: RootState) => state.StoreReducer.pageIndex);

  const setRowsPerPage = (value: number) => {
    dispatch(StoreActions.setRowsPerPage(value));
  };

  const setPageIndex = (index: number) => {
    dispatch(StoreActions.setPageIndex(index));
  };

  const totalItems =
    useSelector((state: RootState) =>
      state.StoreReducer.hydratedStore
        ? state.StoreReducer.hydratedStore['hydra:totalItems']
        : 0,
    ) ?? 0;

  useEffect(() => {
    setTimeout(
      () => dispatch(StoreActions.sortStoresByField('name', order)),
      300,
    );
  }, [order, dispatch]);

  useEffect(() => {
    dispatch(StoreActions.sortStoresByField('region', orderRegion));
  }, [orderRegion, dispatch]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Store,
  ) => {
    setCurrentOrder(property);
    if (property === orderBy) {
      const isAsc = order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
    }
    if (property === orderByRegion) {
      const isAscRegion = orderRegion === 'asc';
      setOrderRegion(isAscRegion ? 'desc' : 'asc');
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    const currentOrder = currentOrderField === 'name' ? order : orderRegion;
    dispatch(
      StoreActions.getStores(
        currentOrderField,
        currentOrder,
        newPage + 1,
        perPage,
        archivedOnly,
        filteredStoreNames.length > 0 ? filteredStoreNames : undefined,
      ),
    );
    setPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const currentOrder = currentOrderField === 'name' ? order : orderRegion;
    const newPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newPerPage);
    setPageIndex(0);
    dispatch(
      StoreActions.getStores(
        currentOrderField,
        currentOrder,
        1,
        newPerPage,
        archivedOnly,
        filteredStoreNames.length > 0 ? filteredStoreNames : undefined,
      ),
    );
  };

  const handleCheckItem = (row: Store) => {
    const selectedIndex = selected.indexOf(row.name ? row.name : '');
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.name ? row.name : '');
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = stores.map((n) => (n.name ? n.name : ''));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectRow = (row: Store) => {
    let storeID = row.uuid;
    if (!storeID || isLoading) return;
    let pathname = '/stores/' + storeID;
    navigate(pathname);
  };

  const handleOnArchiveStore = () => {
    setTimeout(() => {
      dispatch(StoreActions.getStores('name', 'asc', 1, perPage));
    }, 2000);
  };

  function onSelectedStores(selectedStores: Store[]) {
    const names: string[] = selectedStores
      .filter((s) => !!s.name)
      .map((s) => s.name!);

    // ! do no chnage setters and dispatch order

    dispatch(
      StoreActions.getStores(
        'name',
        'asc',
        1,
        perPage,
        archivedOnly,
        names.length > 0 ? names : undefined,
      ),
    );

    setFilteredStoreNames(names);
    setPageIndex(0);
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.table_wrapper}>
      <Paper className={classes.paper}>
        <StoreTableToolbar
          onStoresSelected={(selectedStores: Store[]) =>
            onSelectedStores(selectedStores)
          }
          numSelected={selected.length}
          onPressCreateStore={() => onCreateStore()}
          onPressCancelSelection={() => setSelected([])}
          onSwitchArchivedOnly={(value: boolean) => {
            setArchivedOnly(value);
            onArchiveSelected(value);
          }}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <StoreTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderRegion={orderRegion}
              orderBy={orderBy}
              orderByRegion={orderByRegion}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={stores.length}
              currentOrderField={currentOrderField}
            />
            <TableBody>
              {stores.map((row, index) => {
                const isItemSelected = isSelected(row.name ? row.name : '');
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <StoreRow
                    key={row.id}
                    row={row}
                    labelId={labelId}
                    isItemSelected={isItemSelected}
                    onCheckRow={(row) => {
                      handleCheckItem(row);
                    }}
                    onClickRow={(row) => {
                      handleSelectRow(row);
                    }}
                    onArchiveStore={() => handleOnArchiveStore()}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          SelectProps={{
            disabled: isLoading,
          }}
          backIconButtonProps={
            isLoading
              ? {
                  disabled: isLoading,
                }
              : undefined
          }
          nextIconButtonProps={
            isLoading
              ? {
                  disabled: isLoading,
                }
              : undefined
          }
          className={classes.pagination}
          count={totalItems}
          page={page}
          rowsPerPage={perPage}
          labelRowsPerPage="Righe per pagina"
          component="div"
          onPageChange={handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table_wrapper: {
      paddingBottom: 20,
      height: '100vh',
    },
    headTableCell: {
      backgroundColor: '#F9F9F9',
      padding: '0 16px !important',
      textWrap: 'nowrap',
    },
    headTableCellCheckbox: {
      backgroundColor: '#F9F9F9',
      padding: '0 !important',
    },
    headCellLabel: {
      color: '#7D7982 !important',
      fontSize: '12px !important',
      letterSpacing: '0.24px',
    },
    paper: {
      width: '100%',
      boxShadow: 'none !important',
    },
    table: {
      minWidth: 750,
    },
    selectedToolbarTitle: {
      fontSize: 15,
      fontWeight: 'bold',
    },
    toolbarBox: {
      width: '100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    collapsedBoxItem: {
      padding: 8,
      width: '100%',
    },
    dayLabel: {
      color: '#34303D',
      fontSize: 12,
    },
    hoursLabel: {
      color: '#34303D',
      fontSize: 9,
    },
    pagination: {
      backgroundColor: '#F9F9F9',
      color: '#34303D !important',
      fontStyle: 'normal',
      fontWeight: '400',
    },
  }),
);
