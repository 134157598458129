import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import BookingInfoPage from './BookingInfoPage';
import BookingAppointmentPage from './BookingAppointmentPage';
import BookingConfirmedPage from './BookingConfirmedPage';
import DirectionSteps from './DirectionSteps';
import { AppointmentRequest } from '../../api/entities';
import { AppointmentsActions, SnackBarActions } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { validateEmail, validatePhone } from '../../utils/validators';
import { createStyles, makeStyles } from '@mui/styles';
import { ArrowLeftIcon } from '../icons/ArrowLeftIcon';
import { ArrowRightIcon } from '../icons/ArrowRightIcon';

interface BookingModalProps {
  onClose: () => void;
  open: boolean;
}

export default function BookingModal({ open, onClose }: BookingModalProps) {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [appointment, setAppointment] = useState<AppointmentRequest | null>({});

  const dispatch = useDispatch();
  const [checkedNotification, setCheckedNotification] = useState(true);

  const handleClose = () => {
    onClose();
    setIndex(0);
  };

  const handleChangeNotifCheckbox = (event: any) => {
    setCheckedNotification(event.target.checked);
  };

  const checkEmailAndPhoneFields = () => {
    if (
      appointment?.clients &&
      appointment.clients[0].email &&
      !validateEmail(appointment?.clients[0].email) &&
      appointment?.clients[0].email !== ''
    ) {
      dispatch(SnackBarActions.snackbar('Email non valida', 'warning'));
      return false;
    }
    if (
      appointment?.clients &&
      appointment.clients[0].phoneNumber &&
      !validatePhone(appointment?.clients[0].phoneNumber) &&
      appointment?.clients[0].phoneNumber !== ''
    ) {
      dispatch(
        SnackBarActions.snackbar('Numero di telefono non valido', 'warning'),
      );
      return false;
    }
    return true;
  };

  const handleContinue = () => {
    if (index === 0) {
      if (
        appointment?.startTime &&
        appointment?.endTime &&
        appointment?.products?.length
      ) {
        setIndex(1);
      } else {
        dispatch(
          SnackBarActions.snackbar(
            'È fondamentale compilare tutti i campi obbligatori',
            'warning',
          ),
        );
      }
    }
    if (index === 1) {
      const customer = appointment?.clients && appointment.clients[0];
      if (
        appointment?.storeService &&
        appointment?.startTime &&
        appointment?.endTime &&
        customer &&
        customer.name &&
        customer.surname &&
        ((customer.email !== '' && validateEmail(customer.email!)) ||
          (customer.phoneNumber !== '' &&
            validatePhone(customer.phoneNumber!))) &&
        appointment?.products?.length
      ) {
        appointment.notificationEnabled = checkedNotification;
        checkAndSave(appointment);
      } else {
        dispatch(
          SnackBarActions.snackbar(
            'È fondamentale compilare tutti i campi obbligatori',
            'warning',
          ),
        );
      }
    }
    if (index === 2) {
      handleClose();
    }
  };

  const checkAndSave = (appointment: AppointmentRequest) => {
    if (checkEmailAndPhoneFields()) {
      if (appointment && appointment.storeService) {
        dispatch(
          AppointmentsActions.postAppointment(
            appointment.storeService,
            appointment,
          ),
        );
        setIndex(2);
      }
    }
  };

  const handleBack = () => {
    setIndex((index) => (index === 0 ? 0 : index - 1));
  };

  const continueButtonLabel = useMemo<string>(() => {
    if (index < 2) return 'Salva';
    return 'Ok';
  }, [index]);

  const handleOnChangeAppointment = useCallback(
    (appointment: AppointmentRequest) => {
      setAppointment((prevState) => {
        return { ...prevState, ...appointment };
      });
    },
    [],
  );

  const handleOnChangeInfos = useCallback((appointment: AppointmentRequest) => {
    setAppointment((prevState) => {
      return { ...prevState, ...appointment };
    });
  }, []);

  const renderNotificationCheckbox = () => {
    return (
      <Box display="flex" alignItems="center">
        <Checkbox
          color="secondary"
          checked={checkedNotification}
          onChange={handleChangeNotifCheckbox}
        />
        <Typography>Inviare notifica</Typography>
      </Box>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={classes.modal}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        Nuovo Appuntamento
      </DialogTitle>
      <Grid className={classes.gridContainer}>
        <div className={classes.stepperContainer}>
          <DirectionSteps activeStep={index} />
        </div>

        {index === 0 && (
          <BookingAppointmentPage
            isActive={index === 0}
            event={null}
            onChangeAppointment={handleOnChangeAppointment}
          />
        )}
        {index === 1 && (
          <BookingInfoPage
            isActive={index === 1}
            event={null}
            onChangedInfoData={handleOnChangeInfos}
          />
        )}
        {index === 2 && (
          <BookingConfirmedPage
            isActive={index === 2}
            startDate={appointment?.startTime?.formatted()}
            startTime={appointment?.startTime?.getFormatedTime()}
          />
        )}
      </Grid>
      <Box
        className={
          index === 0 ? classes.footerWrapperCheckbox : classes.footerWrapper
        }
        justifyContent={index === 2 ? 'flex-end' : 'space-between'}
        alignItems="center"
        display="flex"
      >
        {index === 0 && renderNotificationCheckbox()}
        {index === 1 && (
          <Button
            disableElevation
            className={classes.cancelButton}
            onClick={() => handleBack()}
            startIcon={<ArrowLeftIcon color="#34303D" />}
          >
            Indietro
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.continueButton}
          onClick={() => handleContinue()}
          endIcon={<ArrowRightIcon />}
        >
          {continueButtonLabel}
        </Button>
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '24px !important',
    },
    gridContainer: {
      backgroundColor: 'white',
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 4,
    },
    stepperContainer: {
      margin: '0 auto 40px auto',
      width: '85%',
    },
    footerWrapper: {
      padding: '16px 24px',
    },
    footerWrapperCheckbox: {
      padding: '16px 24px 16px 13px',
    },
    footer: {
      width: '100%',
      padding: '16px 16px 8px 16px',
    },
    continueButton: {
      backgroundColor: '#34303d !important',
      padding: '12px 24px !important',
      borderRadius: '50px !important',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      color: '#fff !important',
      border: '1px solid #34303d !important',
    },
    cancelButton: {
      backgroundColor: 'transparent !important',
      padding: '12px 24px !important',
      borderRadius: '50px !important',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      color: '#34303d !important',
      border: '1px solid #34303d !important',
    },
  }),
);
