/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { HydratedPushNotificationHistoryPushNotificationHistoryOutputPushNotificationHistoryRead } from "../nbp";
// @ts-ignore
import { PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit } from "../nbp";
// @ts-ignore
import { PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite } from "../nbp";
// @ts-ignore
import { PushNotificationHistoryPushNotificationHistoryOutputPushNotificationHistoryRead } from "../nbp";
/**
 * PushNotificationHistoryApi - axios parameter creator
 * @export
 */
export const PushNotificationHistoryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Retrieves All Push Notification History Resources for that user
     * @param {boolean} [allUsers]
     * @param {Array<string>} [userUuids]
     * @param {string} [type]
     * @param {boolean} [onlyRead]
     * @param {boolean} [onlyNotRead]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPushNotificationHistoryCollection: async (
      allUsers?: boolean,
      userUuids?: Array<string>,
      type?: string,
      onlyRead?: boolean,
      onlyNotRead?: boolean,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/push_notification_history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (allUsers !== undefined) {
        localVarQueryParameter["allUsers"] = allUsers;
      }

      if (userUuids) {
        localVarQueryParameter["userUuids[]"] = userUuids;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (onlyRead !== undefined) {
        localVarQueryParameter["onlyRead"] = onlyRead;
      }

      if (onlyNotRead !== undefined) {
        localVarQueryParameter["onlyNotRead"] = onlyNotRead;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a Push Notification History Resource
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPushNotificationHistoryItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getPushNotificationHistoryItem", "id", id);
      const localVarPath = `/api/push_notification_history/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the PushNotificationHistory resource.
     * @param {string} id
     * @param {PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit} [pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit] The updated PushNotificationHistory resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchPushNotificationHistoryItem: async (
      id: string,
      pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit?: PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchPushNotificationHistoryItem", "id", id);
      const localVarPath = `/api/push_notification_history/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a Push Notification History Resource
     * @param {PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite} [pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite] The new PushNotificationHistory resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPushNotificationHistoryCollection: async (
      pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite?: PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/push_notification_history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PushNotificationHistoryApi - functional programming interface
 * @export
 */
export const PushNotificationHistoryApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PushNotificationHistoryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Retrieves All Push Notification History Resources for that user
     * @param {boolean} [allUsers]
     * @param {Array<string>} [userUuids]
     * @param {string} [type]
     * @param {boolean} [onlyRead]
     * @param {boolean} [onlyNotRead]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPushNotificationHistoryCollection(
      allUsers?: boolean,
      userUuids?: Array<string>,
      type?: string,
      onlyRead?: boolean,
      onlyNotRead?: boolean,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedPushNotificationHistoryPushNotificationHistoryOutputPushNotificationHistoryRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPushNotificationHistoryCollection(
          allUsers,
          userUuids,
          type,
          onlyRead,
          onlyNotRead,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a Push Notification History Resource
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPushNotificationHistoryItem(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PushNotificationHistoryPushNotificationHistoryOutputPushNotificationHistoryRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPushNotificationHistoryItem(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the PushNotificationHistory resource.
     * @param {string} id
     * @param {PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit} [pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit] The updated PushNotificationHistory resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchPushNotificationHistoryItem(
      id: string,
      pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit?: PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PushNotificationHistoryPushNotificationHistoryOutputPushNotificationHistoryRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchPushNotificationHistoryItem(
          id,
          pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a Push Notification History Resource
     * @param {PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite} [pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite] The new PushNotificationHistory resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPushNotificationHistoryCollection(
      pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite?: PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PushNotificationHistoryPushNotificationHistoryOutputPushNotificationHistoryRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postPushNotificationHistoryCollection(
          pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PushNotificationHistoryApi - factory interface
 * @export
 */
export const PushNotificationHistoryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PushNotificationHistoryApiFp(configuration);
  return {
    /**
     *
     * @summary Retrieves All Push Notification History Resources for that user
     * @param {boolean} [allUsers]
     * @param {Array<string>} [userUuids]
     * @param {string} [type]
     * @param {boolean} [onlyRead]
     * @param {boolean} [onlyNotRead]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPushNotificationHistoryCollection(
      allUsers?: boolean,
      userUuids?: Array<string>,
      type?: string,
      onlyRead?: boolean,
      onlyNotRead?: boolean,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedPushNotificationHistoryPushNotificationHistoryOutputPushNotificationHistoryRead> {
      return localVarFp
        .getPushNotificationHistoryCollection(
          allUsers,
          userUuids,
          type,
          onlyRead,
          onlyNotRead,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a Push Notification History Resource
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPushNotificationHistoryItem(
      id: string,
      options?: any,
    ): AxiosPromise<PushNotificationHistoryPushNotificationHistoryOutputPushNotificationHistoryRead> {
      return localVarFp
        .getPushNotificationHistoryItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the PushNotificationHistory resource.
     * @param {string} id
     * @param {PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit} [pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit] The updated PushNotificationHistory resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchPushNotificationHistoryItem(
      id: string,
      pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit?: PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit,
      options?: any,
    ): AxiosPromise<PushNotificationHistoryPushNotificationHistoryOutputPushNotificationHistoryRead> {
      return localVarFp
        .patchPushNotificationHistoryItem(
          id,
          pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a Push Notification History Resource
     * @param {PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite} [pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite] The new PushNotificationHistory resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPushNotificationHistoryCollection(
      pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite?: PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite,
      options?: any,
    ): AxiosPromise<PushNotificationHistoryPushNotificationHistoryOutputPushNotificationHistoryRead> {
      return localVarFp
        .postPushNotificationHistoryCollection(
          pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PushNotificationHistoryApi - object-oriented interface
 * @export
 * @class PushNotificationHistoryApi
 * @extends {BaseAPI}
 */
export class PushNotificationHistoryApi extends BaseAPI {
  /**
   *
   * @summary Retrieves All Push Notification History Resources for that user
   * @param {boolean} [allUsers]
   * @param {Array<string>} [userUuids]
   * @param {string} [type]
   * @param {boolean} [onlyRead]
   * @param {boolean} [onlyNotRead]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PushNotificationHistoryApi
   */
  public getPushNotificationHistoryCollection(
    allUsers?: boolean,
    userUuids?: Array<string>,
    type?: string,
    onlyRead?: boolean,
    onlyNotRead?: boolean,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return PushNotificationHistoryApiFp(this.configuration)
      .getPushNotificationHistoryCollection(
        allUsers,
        userUuids,
        type,
        onlyRead,
        onlyNotRead,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a Push Notification History Resource
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PushNotificationHistoryApi
   */
  public getPushNotificationHistoryItem(id: string, options?: any) {
    return PushNotificationHistoryApiFp(this.configuration)
      .getPushNotificationHistoryItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the PushNotificationHistory resource.
   * @param {string} id
   * @param {PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit} [pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit] The updated PushNotificationHistory resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PushNotificationHistoryApi
   */
  public patchPushNotificationHistoryItem(
    id: string,
    pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit?: PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit,
    options?: any,
  ) {
    return PushNotificationHistoryApiFp(this.configuration)
      .patchPushNotificationHistoryItem(
        id,
        pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryEdit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a Push Notification History Resource
   * @param {PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite} [pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite] The new PushNotificationHistory resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PushNotificationHistoryApi
   */
  public postPushNotificationHistoryCollection(
    pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite?: PushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite,
    options?: any,
  ) {
    return PushNotificationHistoryApiFp(this.configuration)
      .postPushNotificationHistoryCollection(
        pushNotificationHistoryPushNotificationHistoryInputPushNotificationHistoryWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
