import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import store from './redux/store';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  // @ts-ignore
  <Provider store={store}>
    <App />
  </Provider>,
);
