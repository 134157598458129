import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import ServiceTableRow from './ServiceTableRow';
import ServiceTableToolbar from './ServiceTableToolbar';
import { Service } from '../../../api/entities';
import _ from 'lodash';
import { createStyles, makeStyles } from '@mui/styles';

type Order = 'asc' | 'desc';
interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, disablePadding: false, label: 'NOME' },
  {
    id: 'createDate',
    numeric: false,
    disablePadding: false,
    label: 'DATA CREAZIONE',
  },
  {
    id: 'modifyDate',
    numeric: false,
    disablePadding: false,
    label: 'DATA MODIFICA',
  },
  { id: 'ations', numeric: false, disablePadding: false, label: '' },
];

interface ServicesTableHeadProps {
  classes: ReturnType<typeof useStyles>;
  order: Order;
  orderBy: string;
}

function ServicesTableHead(props: ServicesTableHeadProps) {
  const { classes, order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.headTableCell}
            key={headCell.id}
            align={headCell.id === 'actions' ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <div className={classes.headCellLabel}>{headCell.label}</div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ServicesTable() {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Service>('publicName');
  const navigate = useNavigate();

  const services = useSelector((state: RootState) =>
    state.ServiceReducer.hydratedService?.['hydra:member']
      ? state.ServiceReducer.hydratedService?.['hydra:member']
      : [],
  );

  const handleSelectRow = (row: Service) => {
    let storeID = row.uuid;
    if (storeID) {
      let pathname = '/services/' + storeID;
      navigate(pathname);
    }
  };

  return (
    <div className={classes.table_wrapper}>
      <Paper className={classes.paper}>
        <ServiceTableToolbar />
        <TableContainer>
          <Table className={classes.table}>
            <ServicesTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {services.map((row: Service, index) => {
                const labelId = `service-table-${index}`;
                return (
                  <ServiceTableRow
                    key={_.uniqueId()}
                    row={row}
                    labelId={labelId}
                    onClickRow={(row) => {
                      handleSelectRow(row);
                    }}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table_wrapper: {
      paddingBottom: 20,
      height: '100vh',
    },
    headTableCell: {
      backgroundColor: '#F9F9F9',
      padding: '4px 16px !important',
      textWrap: 'nowrap',
    },
    headCellLabel: {
      color: '#7D7982 !important',
      fontSize: '12px !important',
      letterSpacing: '0.24px',
    },
    paper: {
      width: '100%',
      boxShadow: 'none !important',
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);
