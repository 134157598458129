/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { HydratedUserUserOutputUserRead } from "../nbp";
// @ts-ignore
import { UserStatusInputUserWrite } from "../nbp";
// @ts-ignore
import { UserUserInputUserWrite } from "../nbp";
// @ts-ignore
import { UserUserOutputUserRead } from "../nbp";
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Removes the User resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteUserItem", "id", id);
      const localVarPath = `/api/users/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves the collection of UserOutput resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedUsersUserCollection: async (
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves the current user\'s resource.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUserUserCollection: async (
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/current`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves the collection of UserOutput resources.
     * @param {string} [searchText]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserCollection: async (
      searchText?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (searchText !== undefined) {
        localVarQueryParameter["searchText"] = searchText;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a UserOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getUserItem", "id", id);
      const localVarPath = `/api/users/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Changes status for an User resource
     * @param {string} uuid
     * @param {UserStatusInputUserWrite} [userStatusInputUserWrite] The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusUserItem: async (
      uuid: string,
      userStatusInputUserWrite?: UserStatusInputUserWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("patchStatusUserItem", "uuid", uuid);
      const localVarPath = `/api/users/{uuid}/status`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userStatusInputUserWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the User resource.
     * @param {string} id
     * @param {UserUserInputUserWrite} [userUserInputUserWrite] The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchUserItem: async (
      id: string,
      userUserInputUserWrite?: UserUserInputUserWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchUserItem", "id", id);
      const localVarPath = `/api/users/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userUserInputUserWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a User resource.
     * @param {UserUserInputUserWrite} [userUserInputUserWrite] The new User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postUserCollection: async (
      userUserInputUserWrite?: UserUserInputUserWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userUserInputUserWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Replaces the User resource.
     * @param {string} id
     * @param {UserUserInputUserWrite} [userUserInputUserWrite] The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putUserItem: async (
      id: string,
      userUserInputUserWrite?: UserUserInputUserWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putUserItem", "id", id);
      const localVarPath = `/api/users/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userUserInputUserWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Removes the User resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserItem(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserItem(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves the collection of UserOutput resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArchivedUsersUserCollection(
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedUserUserOutputUserRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllArchivedUsersUserCollection(
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves the current user\'s resource.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentUserUserCollection(
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedUserUserOutputUserRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentUserUserCollection(
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves the collection of UserOutput resources.
     * @param {string} [searchText]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserCollection(
      searchText?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedUserUserOutputUserRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserCollection(
          searchText,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a UserOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserItem(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserUserOutputUserRead>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserItem(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Changes status for an User resource
     * @param {string} uuid
     * @param {UserStatusInputUserWrite} [userStatusInputUserWrite] The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchStatusUserItem(
      uuid: string,
      userStatusInputUserWrite?: UserStatusInputUserWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserUserOutputUserRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchStatusUserItem(
          uuid,
          userStatusInputUserWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the User resource.
     * @param {string} id
     * @param {UserUserInputUserWrite} [userUserInputUserWrite] The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchUserItem(
      id: string,
      userUserInputUserWrite?: UserUserInputUserWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserUserOutputUserRead>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserItem(
        id,
        userUserInputUserWrite,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a User resource.
     * @param {UserUserInputUserWrite} [userUserInputUserWrite] The new User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postUserCollection(
      userUserInputUserWrite?: UserUserInputUserWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserUserOutputUserRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postUserCollection(
          userUserInputUserWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Replaces the User resource.
     * @param {string} id
     * @param {UserUserInputUserWrite} [userUserInputUserWrite] The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putUserItem(
      id: string,
      userUserInputUserWrite?: UserUserInputUserWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserUserOutputUserRead>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putUserItem(
        id,
        userUserInputUserWrite,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @summary Removes the User resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserItem(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteUserItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves the collection of UserOutput resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedUsersUserCollection(
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedUserUserOutputUserRead> {
      return localVarFp
        .getAllArchivedUsersUserCollection(
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves the current user\'s resource.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUserUserCollection(
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedUserUserOutputUserRead> {
      return localVarFp
        .getCurrentUserUserCollection(page, itemsPerPage, pagination, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves the collection of UserOutput resources.
     * @param {string} [searchText]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserCollection(
      searchText?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedUserUserOutputUserRead> {
      return localVarFp
        .getUserCollection(searchText, page, itemsPerPage, pagination, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a UserOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserItem(
      id: string,
      options?: any,
    ): AxiosPromise<UserUserOutputUserRead> {
      return localVarFp
        .getUserItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Changes status for an User resource
     * @param {string} uuid
     * @param {UserStatusInputUserWrite} [userStatusInputUserWrite] The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusUserItem(
      uuid: string,
      userStatusInputUserWrite?: UserStatusInputUserWrite,
      options?: any,
    ): AxiosPromise<UserUserOutputUserRead> {
      return localVarFp
        .patchStatusUserItem(uuid, userStatusInputUserWrite, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the User resource.
     * @param {string} id
     * @param {UserUserInputUserWrite} [userUserInputUserWrite] The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchUserItem(
      id: string,
      userUserInputUserWrite?: UserUserInputUserWrite,
      options?: any,
    ): AxiosPromise<UserUserOutputUserRead> {
      return localVarFp
        .patchUserItem(id, userUserInputUserWrite, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a User resource.
     * @param {UserUserInputUserWrite} [userUserInputUserWrite] The new User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postUserCollection(
      userUserInputUserWrite?: UserUserInputUserWrite,
      options?: any,
    ): AxiosPromise<UserUserOutputUserRead> {
      return localVarFp
        .postUserCollection(userUserInputUserWrite, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Replaces the User resource.
     * @param {string} id
     * @param {UserUserInputUserWrite} [userUserInputUserWrite] The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putUserItem(
      id: string,
      userUserInputUserWrite?: UserUserInputUserWrite,
      options?: any,
    ): AxiosPromise<UserUserOutputUserRead> {
      return localVarFp
        .putUserItem(id, userUserInputUserWrite, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary Removes the User resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public deleteUserItem(id: string, options?: any) {
    return UserApiFp(this.configuration)
      .deleteUserItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves the collection of UserOutput resources.
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getAllArchivedUsersUserCollection(
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return UserApiFp(this.configuration)
      .getAllArchivedUsersUserCollection(
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves the current user\'s resource.
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getCurrentUserUserCollection(
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return UserApiFp(this.configuration)
      .getCurrentUserUserCollection(page, itemsPerPage, pagination, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves the collection of UserOutput resources.
   * @param {string} [searchText]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserCollection(
    searchText?: string,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return UserApiFp(this.configuration)
      .getUserCollection(searchText, page, itemsPerPage, pagination, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a UserOutput resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserItem(id: string, options?: any) {
    return UserApiFp(this.configuration)
      .getUserItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Changes status for an User resource
   * @param {string} uuid
   * @param {UserStatusInputUserWrite} [userStatusInputUserWrite] The updated User resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public patchStatusUserItem(
    uuid: string,
    userStatusInputUserWrite?: UserStatusInputUserWrite,
    options?: any,
  ) {
    return UserApiFp(this.configuration)
      .patchStatusUserItem(uuid, userStatusInputUserWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the User resource.
   * @param {string} id
   * @param {UserUserInputUserWrite} [userUserInputUserWrite] The updated User resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public patchUserItem(
    id: string,
    userUserInputUserWrite?: UserUserInputUserWrite,
    options?: any,
  ) {
    return UserApiFp(this.configuration)
      .patchUserItem(id, userUserInputUserWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a User resource.
   * @param {UserUserInputUserWrite} [userUserInputUserWrite] The new User resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public postUserCollection(
    userUserInputUserWrite?: UserUserInputUserWrite,
    options?: any,
  ) {
    return UserApiFp(this.configuration)
      .postUserCollection(userUserInputUserWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Replaces the User resource.
   * @param {string} id
   * @param {UserUserInputUserWrite} [userUserInputUserWrite] The updated User resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public putUserItem(
    id: string,
    userUserInputUserWrite?: UserUserInputUserWrite,
    options?: any,
  ) {
    return UserApiFp(this.configuration)
      .putUserItem(id, userUserInputUserWrite, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
