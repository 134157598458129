import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Dialog, Grid } from '@mui/material';
import ModalHeader from '../../../ModalHeader';
import DialogActionFooter from '../../../dialog/DialogActionFooter';
import { StoreService } from '../../../../api/entities';
import CapacityTextField from '../../../services/CapacityTextField';
import DurationTextField from '../../../services/DurationTextField';

interface StoreServiceSettingsDialogProps {
  isVisible: boolean;
  onClose: () => void;
  onSave: (request: any) => void;
  service: StoreService | undefined;
}

export default function StoreServiceSettingsDialog(
  props: StoreServiceSettingsDialogProps,
) {
  const classes = useStyles();
  const service = props.service;

  const [duration, setDuration] = useState(
    service?.duration ? service.duration.toString() : '15',
  );
  const [capacity, setCapacity] = useState(
    service?.capacity ? service.capacity.toString() : '1',
  );

  const handleClose = () => {
    props.onClose();
  };
  const handleSave = () => {
    let request = {
      capacity: parseInt(capacity, 10),
      duration: parseInt(duration, 10),
    };
    props.onSave(request);
  };

  return (
    <Dialog
      className={classes.modal}
      open={props.isVisible}
      onClose={() => handleClose()}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <Grid className={classes.gridContainer}>
        <ModalHeader
          title={
            props.service?.externalName
              ? props.service.externalName
              : 'Modifica informazioni'
          }
          onClose={() => handleClose()}
        />
        <Box className={classes.contentBox} justifyContent="space-between">
          <Box style={{ marginTop: 10 }}>
            <CapacityTextField
              capacity={capacity}
              onSelectValue={(value: string) => setCapacity(value)}
            />
          </Box>
          <DurationTextField
            defaultValue={duration}
            onSelectValue={(value: string) => setDuration(value)}
          />
        </Box>

        <Box
          className={classes.footerView}
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <DialogActionFooter
            onCancel={() => handleClose()}
            onConfirm={() => handleSave()}
            actionTitle="Modifica"
          />
        </Box>
      </Grid>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
      //display: 'flex',
    },
    gridContainer: {
      //width: '50%',
      backgroundColor: 'white',
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      borderRadius: 4,
      //overflow: 'hidden',
    },
    input: {
      display: 'none',
    },
    uploadRoot: {
      '& > *': {
        //margin: theme.spacing(1),
      },
      alignItems: 'center',
    },
    contentBox: {
      //padding: 16,
    },
    serviceTypesBox: {
      width: 140,
      marginTop: 8,
    },
    headerView: {
      width: '100%',
      height: 40,
      backgroundColor: 'white',
      paddingLeft: 16,
      paddingRight: 10,
      paddingTop: 16,
    },
    footerView: {
      width: '100%',
      height: 50,
      // paddingLeft: 12,
      // paddingRight: 16,
      paddingBottom: 10,
      marginTop: 20,
    },
    uploadButton: {
      marginTop: 6,
      fontWeight: 'bold',
      fontFamily: 'IQOS Sans',
    },
    title: {
      fontSize: 20,
      color: '#34303D',
    },
    label: {
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
    contentField: {
      width: '100%',
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
    uploadBox: {},
    switchBase: {
      '&$checked': {
        color: '#00D1D2',
      },
      '&$checked + $track': {
        backgroundColor: '#F4E9DC',
      },
    },
    img: { marginLeft: 16 },
  }),
);
