import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  ListItemText,
} from '@mui/material';
import DialogActionFooter from '../../dialog/DialogActionFooter';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { UsersActions } from '../../../redux/actions';
import { StoreStoreOutputStoreUserRead } from '../../../api/services';

interface UserStoresEditModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export default function UserStoresEditModal(props: UserStoresEditModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userCurrentStores = useSelector((state: RootState) =>
    state.StoreReducer.userCurrentStores
      ? state.StoreReducer.userCurrentStores
      : [],
  );
  const userDetail = useSelector(
    (state: RootState) => state.UsersReducer.userDetail,
  );

  const [selectedStores, setSelectedStores] = useState<
    StoreStoreOutputStoreUserRead[]
  >([]);

  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    if (!userDetail) return;

    setSelectedStores(userDetail.stores || []);
  }, [userDetail, userCurrentStores]);

  const handleClose = () => {
    props.onClose();
  };
  const handleSave = () => {
    let userId = userDetail?.uuid;
    let businessRole = userDetail?.businessRole;
    let stores = selectedStores;
    if (userId && stores && businessRole) {
      const storeUuids = stores.map((store) => store.uuid);
      let request = { stores: storeUuids, businessRole };
      dispatch(UsersActions.patchUser(userId, request));
      handleClose();
    }
  };

  const handleChange = (objects: any[]) => {
    setSelectedStores(objects);
  };

  const handleChangeAll = (event: any) => {
    const isChecked: boolean = !!event.target.checked;
    setAllSelected(isChecked);
    if (isChecked) {
      setSelectedStores(userCurrentStores);
      return;
    }
    setSelectedStores(userDetail?.stores || []);
  };

  return (
    <Dialog
      className={classes.modal}
      open={props.isVisible}
      onClose={() => handleClose()}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        Aggiungi Store
      </DialogTitle>
      <Grid className={classes.gridContainer}>
        <Box className={classes.box}>
          <Autocomplete
            noOptionsText="Nessun risultato"
            multiple
            fullWidth
            id="demo-mutiple-checkbox"
            size="small"
            value={selectedStores}
            onChange={(e, options) => handleChange(options as any[])}
            getOptionLabel={(option) => (option.name ? option.name : '')}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Seleziona Store"
                size="small"
                color="secondary"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    padding: '6px 10px',
                    borderColor: '#E1E0E2',
                  },
                }}
                InputLabelProps={{
                  style: {
                    top: '4px',
                  },
                }}
              />
            )}
            options={userCurrentStores || []}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option?.uuid}>
                <Checkbox
                  color="secondary"
                  style={{ marginRight: 4 }}
                  checked={selected}
                />
                {option?.name}
              </li>
            )}
          />
        </Box>
        <Box
          style={{
            marginTop: '8px',
          }}
          display="flex"
          alignItems="center"
        >
          <Checkbox
            style={{
              padding: '0',
              marginRight: '8px',
            }}
            color="secondary"
            onChange={handleChangeAll}
            checked={allSelected}
          />
          <ListItemText primary={'Seleziona Tutto'} />
        </Box>
      </Grid>
      <Box className={classes.footerWrapper}>
        <DialogActionFooter
          onCancel={() => handleClose()}
          onConfirm={() => handleSave()}
        />
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '16px !important',
    },
    gridContainer: {
      backgroundColor: 'white',
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 4,
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
    box: {
      width: '100%',
      marginTop: 10,
    },
    contentField: {
      width: '100%',
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
  }),
);
