import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { AxiosResponse } from "axios";
import { BusinessHoursApi } from "../../api/services/nbp/business-hours-api";
import {
  HydratedBusinessHours,
  HydratedSpecialHoursPeriods,
  BusinessHours,
  RequestBusinessHours,
  RequestTimePeriod,
  TimePeriod,
  SpecialHourPeriod,
} from "../../api/entities";
import { SpecialHourPeriodApi, TimePeriodApi } from "../../api/services";
import SnackBarActions from "./UI/SnackBarActions";
import { time } from "console";

export let DAYS = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

//Api Service instance from open api generator
let api = new BusinessHoursApi();
let apiSpecHours = new SpecialHourPeriodApi();
let timePeriodsApi = new TimePeriodApi();

/********************************  BUSINESS HOURS  ******************************************************/

//GET BUSINESS HOURS BY STORE
const getBusinessHoursSuccess = createAction<HydratedBusinessHours>(
  "business_hours/getBusinessHoursSuccess",
);
const getBusinessHoursLoading = createAction(
  "business_hours/getBusinessHoursLoading",
);
const getBusinessHoursError = createAction<any>(
  "business_hours/getBusinessHoursError",
);

const getBusinessHoursByStore = (id: string) => (dispatch: AppDispatch) => {
  let startDate = undefined;
  let endDate = undefined;
  let isActive = undefined;
  let onlyArchived = undefined;
  let properties = undefined;
  let page = undefined;
  let itemsPerPage = undefined;
  let pagination = undefined;

  dispatch(getBusinessHoursLoading());
  api
    .getBusinessHoursForCurrentStoreBusinessHoursCollection(id)
    .then((response: AxiosResponse<HydratedBusinessHours>) => {
      dispatch(getBusinessHoursSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(getBusinessHoursError(error));
    });
};

/********************************  SPECIAL HOURS  ******************************************************/
//GET SPECIAL HOURS BY STORE
const getSpecialHoursSuccess = createAction<HydratedSpecialHoursPeriods>(
  "special_hours/getSpecialHoursSuccess",
);
const getSpecialHoursLoading = createAction(
  "special_hours/getSpecialHoursLoading",
);
const getSpecialHoursError = createAction<any>(
  "special_hours/getSpecialHoursError",
);

const getSpecialHours = (id: string) => (dispatch: AppDispatch) => {
  dispatch(getSpecialHoursLoading());
  apiSpecHours
    .getSpecialHourPeriodsForCurrentStoreSpecialHourPeriodCollection(id)
    .then((response: AxiosResponse<HydratedSpecialHoursPeriods>) => {
      dispatch(getSpecialHoursSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(getSpecialHoursError(error));
    });
};

//POST SPECIAL HOUR PERIOD
const postSpecialHourPeriodSuccess = createAction<SpecialHourPeriod>(
  "special_hours/postSpecialHourPeriodSuccess",
);
const postSpecialHourPeriodError = createAction<any>(
  "special_hours/postSpecialHourPeriodError",
);
const postSpecialHourPeriodLoading = createAction(
  "special_hours/postSpecialHourPeriodLoading",
);

const createSpecialHourPeriod =
  (storeSpecialHoursID: string, request: any) => (dispatch: AppDispatch) => {
    dispatch(postSpecialHourPeriodLoading());
    apiSpecHours
      .postSpecialHourPeriodCollection(storeSpecialHoursID, request)
      .then((response: AxiosResponse<SpecialHourPeriod>) => {
        dispatch(postSpecialHourPeriodSuccess(response.data));
        dispatch(
          SnackBarActions.snackbar(
            "Special hour period are created",
            "success",
          ),
        );
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.snackbar(
            error.response.data["hydra:description"]
              ? error.response.data["hydra:description"]
              : "Invalid date",
            "error",
          ),
        );
        dispatch(postSpecialHourPeriodError(error));
      });
  };

//PATCH SPECIAL HOUR PERIOD
const patchSpecialHourPeriodSuccess = createAction<SpecialHourPeriod>(
  "special_hours/patchSpecialHourPeriodSuccess",
);
const patchSpecialHourPeriodError = createAction<any>(
  "special_hours/patchSpecialHourPeriodError",
);
const patchSpecialHourPeriodLoading = createAction(
  "special_hours/patchSpecialHourPeriodLoading",
);

const updateSpecialHourPeriod =
  (id: string, request: any) => (dispatch: AppDispatch) => {
    dispatch(patchSpecialHourPeriodLoading());
    apiSpecHours
      .patchSpecialHourPeriodItem(id, request)
      .then((response: AxiosResponse<SpecialHourPeriod>) => {
        dispatch(patchSpecialHourPeriodSuccess(response.data));
        dispatch(
          SnackBarActions.snackbar(
            "Special hour period are updated",
            "success",
          ),
        );
      })
      .catch((error) => {
        dispatch(patchSpecialHourPeriodError(error));
      });
  };

//DELETE SPECIAL HOUR PERIOD
const deleteSpecialHourPeriodSuccess = createAction<string>(
  "special_hours/deleteSpecialHourPeriodSuccess",
);
const deleteSpecialHourPeriodError = createAction<any>(
  "special_hours/deleteSpecialHourPeriodError",
);
const deleteSpecialHourPeriodLoading = createAction(
  "special_hours/deleteSpecialHourPeriodLoading",
);

const deleteSpecialHourPeriod =
  (id: string, storeID: string) => (dispatch: AppDispatch) => {
    dispatch(deleteSpecialHourPeriodLoading());
    apiSpecHours
      .deleteSpecialHourPeriodItem(id)
      .then((response) => {
        dispatch(deleteSpecialHourPeriodSuccess(id));
        dispatch(getSpecialHours(storeID));
      })
      .catch((error: any) => {
        dispatch(deleteSpecialHourPeriodError(error));
      });
  };

// CREATE BUSINESS HOURS FOR STORE
const createBusinessHoursSuccess = createAction<BusinessHours>(
  "business_hours/createBusinessHoursSuccess",
);
const createBusinessHoursLoading = createAction(
  "business_hours/createBusinessHoursLoading",
);
const createBusinessHoursError = createAction<any>(
  "business_hours/createBusinessHoursError",
);

const createBusinessHour =
  (id: string, request: RequestBusinessHours) => (dispatch: AppDispatch) => {
    dispatch(createBusinessHoursLoading());
    api
      .postBusinessHoursForCurrentStoreBusinessHoursCollection(id, request)
      .then((response: AxiosResponse<BusinessHours>) => {
        dispatch(createBusinessHoursSuccess(response.data));
        dispatch(
          getBusinessHoursByStore(
            response.data.store?.uuid ? response.data.store.uuid : "",
          ),
        );
        dispatch(SnackBarActions.snackbar("Orario aggiunto.", "success"));
      })
      .catch((error: any) => {
        dispatch(
          SnackBarActions.snackbar(
            error.response.data["hydra:description"]
              ? error.response.data["hydra:description"]
              : "Invalid date range",
            "error",
          ),
        );
        dispatch(createBusinessHoursError(error));
      });
  };

// UPDATE BUSINESS HOURS FOR STORE
const updateBusinessHoursSuccess = createAction<BusinessHours>(
  "business_hours/updateBusinessHoursSuccess",
);
const updateBusinessHoursLoading = createAction(
  "business_hours/updateBusinessHoursLoading",
);
const updateBusinessHoursError = createAction<any>(
  "business_hours/updateBusinessHoursError",
);

const updateBusinessHours =
  (id: string, request: any) => (dispatch: AppDispatch) => {
    dispatch(updateBusinessHoursLoading());
    api
      .patchBusinessHoursItem(id, request)
      .then((response: AxiosResponse<BusinessHours>) => {
        dispatch(updateBusinessHoursSuccess(response.data));
        dispatch(
          getBusinessHoursByStore(
            response.data.store?.uuid ? response.data.store.uuid : "",
          ),
        );
        dispatch(SnackBarActions.snackbar("Orari aggiornati", "success"));
      })
      .catch((error: any) => {
        dispatch(
          SnackBarActions.snackbar(
            error.response.data["hydra:description"]
              ? error.response.data["hydra:description"]
              : "Invalid date range",
            "error",
          ),
        );
        dispatch(updateBusinessHoursError(error));
      });
  };

// DELETE BUSINESS HOURS FOR STORE
const deleteBusinessHoursSuccess = createAction<string>(
  "business_hours/deleteBusinessHoursSuccess",
);
const deleteBusinessHoursLoading = createAction(
  "business_hours/deleteBusinessHoursLoading",
);
const deleteBusinessHoursError = createAction<any>(
  "business_hours/deleteBusinessHoursError",
);

const deleteBusinessHour =
  (id: string, storeId: string | undefined) => (dispatch: AppDispatch) => {
    dispatch(deleteBusinessHoursLoading());
    api
      .deleteBusinessHoursItem(id)
      .then((response) => {
        dispatch(deleteBusinessHoursSuccess(id));
        if (storeId) {
          dispatch(getBusinessHoursByStore(storeId));
        }
        dispatch(SnackBarActions.snackbar("Fatto!", "success"));
      })
      .catch((error: any) => {
        dispatch(deleteBusinessHoursError(error));
      });
  };

/********************************  TIME PERIODS  ******************************************************/

// CREATE TIME PERIOD FOR BUSINESS HOUR SLOT
const createTimePeriodSuccess = createAction<any>(
  "time_periods/createTimePeriodSuccess",
);
const createTimePeriodLoading = createAction(
  "time_periods/createTimePeriodLoading",
);
const createTimePeriodError = createAction<any>(
  "time_periods/createTimePeriodError",
);

const createOneTimePeriod =
  (businessHoursId: string, request: RequestTimePeriod) =>
  (dispatch: AppDispatch) => {
    dispatch(createTimePeriodLoading());
    timePeriodsApi
      .postTimePeriodCollection(businessHoursId, request)
      .then((response: AxiosResponse<TimePeriod>) => {
        dispatch(createTimePeriodSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(createTimePeriodError(error));
      });
  };

// PATCH TIME PERIOD FOR BUSINESS HOUR SLOT
const updateTimePeriodSuccess = createAction<any>(
  "time_periods/updateTimePeriodSuccess",
);
const updateTimePeriodLoading = createAction(
  "time_periods/updateTimePeriodLoading",
);
const updateTimePeriodError = createAction<any>(
  "time_periods/updateTimePeriodError",
);

const updateTimePeriod =
  (id: string, request: RequestTimePeriod) => (dispatch: AppDispatch) => {
    dispatch(updateTimePeriodLoading());
    timePeriodsApi
      .patchTimePeriodItem(id, request)
      .then((response: AxiosResponse<TimePeriod>) => {
        dispatch(updateTimePeriodSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(updateTimePeriodError(error));
      });
  };

// DELETE TIME PERIOD FOR BUSINESS HOUR SLOT
const deleteTimePeriodSuccess = createAction<any>(
  "time_periods/deleteTimePeriodSuccess",
);
const deleteTimePeriodLoading = createAction(
  "time_periods/deleteTimePeriodLoading",
);
const deleteTimePeriodError = createAction<any>(
  "time_periods/deleteTimePeriodError",
);

const deleteTimePeriod =
  (timePeriodId: string, businessHourId: string, day: string) =>
  (dispatch: AppDispatch) => {
    dispatch(deleteTimePeriodLoading());
    timePeriodsApi
      .deleteTimePeriodItem(timePeriodId)
      .then((response: AxiosResponse<any>) => {
        dispatch(
          deleteTimePeriodSuccess({ timePeriodId, businessHourId, day }),
        );
      })
      .catch((error: any) => {
        dispatch(deleteTimePeriodError(error));
      });
  };

export default {
  getBusinessHoursByStore,
  getBusinessHoursError,
  getBusinessHoursLoading,
  getBusinessHoursSuccess,
  getSpecialHours,
  getSpecialHoursLoading,
  getSpecialHoursSuccess,
  getSpecialHoursError,
  createBusinessHour,
  createBusinessHoursSuccess,
  createBusinessHoursLoading,
  createBusinessHoursError,
  deleteBusinessHour,
  deleteBusinessHoursSuccess,
  deleteBusinessHoursLoading,
  deleteBusinessHoursError,
  createOneTimePeriod,
  createTimePeriodError,
  createTimePeriodLoading,
  createTimePeriodSuccess,
  updateBusinessHours,
  updateBusinessHoursSuccess,
  updateBusinessHoursLoading,
  updateBusinessHoursError,
  deleteTimePeriod,
  deleteTimePeriodSuccess,
  deleteTimePeriodLoading,
  deleteTimePeriodError,
  updateTimePeriod,
  updateTimePeriodSuccess,
  updateTimePeriodLoading,
  updateTimePeriodError,
  createSpecialHourPeriod,
  postSpecialHourPeriodError,
  postSpecialHourPeriodLoading,
  postSpecialHourPeriodSuccess,
  deleteSpecialHourPeriod,
  deleteSpecialHourPeriodError,
  deleteSpecialHourPeriodLoading,
  deleteSpecialHourPeriodSuccess,
  updateSpecialHourPeriod,
  patchSpecialHourPeriodError,
  patchSpecialHourPeriodLoading,
  patchSpecialHourPeriodSuccess,
};
