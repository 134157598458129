import { createAction } from "@reduxjs/toolkit";
import { BusinessHour, BusinessHours } from "../../api/entities";
import { GroupedTimePeriods } from "../reducers/BusinessHoursReducer";
import { AppDispatch } from "../store";

const setBusinessHourRequestSuccess = createAction<BusinessHours | undefined>(
  "bh_request/setBusinessHourRequestSuccess",
);
const addTimePeriodSuccess = createAction<any>(
  "bh_request/addTimePeriodSuccess",
);
const deleteTimePeriodSuccess = createAction<any>(
  "bh_request/deleteTimePeriodSuccess",
);
const updateTimePeriodSuccess = createAction<any>(
  "bh_request/updateTimePeriodSuccess",
);

const setBusinessHourRequest =
  (groupedTimePeriod: BusinessHours | undefined) => (dispatch: AppDispatch) => {
    const payload = groupedTimePeriod;
    dispatch(setBusinessHourRequestSuccess(payload));
  };

const addTimePeriod = (timePeriod: any) => (dispatch: AppDispatch) => {
  const payload = timePeriod;
  dispatch(addTimePeriodSuccess(payload));
};

const deleteTimePeriod = (timePeriod: any) => (dispatch: AppDispatch) => {
  const payload = timePeriod;
  dispatch(deleteTimePeriodSuccess(payload));
};

const updateTimePeriod = (timePeriod: any) => (dispatch: AppDispatch) => {
  const payload = timePeriod;
  dispatch(updateTimePeriodSuccess(timePeriod));
};

export default {
  setBusinessHourRequest,
  setBusinessHourRequestSuccess,
  addTimePeriodSuccess,
  addTimePeriod,
  deleteTimePeriod,
  deleteTimePeriodSuccess,
  updateTimePeriodSuccess,
  updateTimePeriod,
};
