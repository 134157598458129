import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { AppointmentsActions } from "../actions";
import _ from "lodash";
import { HydratedAppointment, Appointment } from "../../api/entities";
import moment from "moment";

export interface AppointmentsState {
  hydratedAppointment?: HydratedAppointment;
  calendarAppointments: any[];
  loadingAppointments: boolean;
  appointmentsError: boolean;
  singleAppointmentLoading: boolean;
  singleAppointmentError: boolean;
  appointmentDetail?: Appointment;
  patchAppointmentSuccess?: Appointment;
  patchAppointmentError: boolean;
  patchAppointmentLoading: boolean;
  deleteAppointmentSuccess?: string;
  deleteAppointmentError: boolean;
  deleteAppointmentLoading: boolean;
  postAppointmentSuccess?: Appointment;
  postAppointmentError: boolean;
  postAppointmentLoading: boolean;
}

const INITIAL_APPOINTMENT_STATE: AppointmentsState = {
  loadingAppointments: false,
  appointmentsError: false,
  singleAppointmentError: false,
  singleAppointmentLoading: false,
  calendarAppointments: [],
  patchAppointmentLoading: false,
  patchAppointmentError: false,
  deleteAppointmentLoading: false,
  deleteAppointmentError: false,
  postAppointmentError: false,
  postAppointmentLoading: false,
};

function getClientName(clients: any[] | undefined) {
  if (clients) {
    return clients[0].name + " " + clients[0].surname;
  } else {
    return " ";
  }
}

function getServiceColor(serviceName: string | undefined) {
  switch (serviceName) {
    case "Assistenza":
      return "#BCE9F8";
    case "Acquisto":
      return "#F8F4BC";
    case "Videocall":
      return "#00D1D2";
    default:
      return "#90ee90";
  }
}

function arrivedInfolabel(hasArrived: boolean | undefined) {
  if (hasArrived === true) {
    return "   \u2713";
  }
  if (hasArrived === false) {
    return "    \u2717";
  }
  return "    \u2015";
}

function getClassNames(serviceName: string | undefined, isNew: boolean) {
  switch (serviceName) {
    case "Assistenza":
      return ["my-event-assistenza", isNew && "my-event-highlight"];
    case "Acquisto":
      return ["my-event-aquisto", isNew && "my-event-highlight"];
    case "Videocall":
      return ["my-event-videocall", isNew && "my-event-highlight"];
    default:
      return ["my-event-lumen", isNew && "my-event-highlight"];
  }
}

function createCalendarEvent(obj: Appointment) {
  let before2hours = moment().subtract(2, "hours");
  let isNew = obj.updatedAt
    ? moment(obj.updatedAt).isAfter(before2hours)
    : false;
  let calendarObj = {
    id: obj.uuid,
    title: getClientName(obj.clients),
    start: obj.startTime,
    end: obj.endTime,
    hasArrived: obj.hasArrived,
    products: obj.products,
    storeService: obj.storeService,
    clients: obj.clients,
    color: getServiceColor(obj.storeService?.externalName),
    backgroundColor: getServiceColor(obj.storeService?.externalName),
    textColor: "#000",
    classNames: getClassNames(obj.storeService?.externalName, isNew),
  };
  return calendarObj;
}

const addAppointment = (
  hydratedAppointment: HydratedAppointment | undefined,
  newAppointment: Appointment,
) => {
  if (hydratedAppointment) {
    return {
      ...hydratedAppointment,
      "hydra:member": [
        newAppointment,
        ...hydratedAppointment?.["hydra:member"],
      ],
    };
  }
  return hydratedAppointment;
};

const AppointmentsReducer = createReducer(
  INITIAL_APPOINTMENT_STATE,
  (builder) => {
    builder

      //get appointments
      .addCase(
        AppointmentsActions.getAppointmentsSuccess,
        (
          state: AppointmentsState,
          action: PayloadAction<HydratedAppointment>,
        ) => {
          let hydratedAppointment = action.payload;
          let calendarAppointments = _.flatMap(
            hydratedAppointment["hydra:member"],
            createCalendarEvent,
          );
          // console.log('MARE CALENDAR EVENTS', calendarAppointments)
          return {
            ...state,
            hydratedAppointment,
            calendarAppointments,
            loadingAppointments: false,
            appointmentsError: false,
          };
        },
      )
      .addCase(
        AppointmentsActions.getAppointmentsLoading,
        (state: AppointmentsState) => {
          return {
            ...state,
            loadingAppointments: true,
            appointmentsError: false,
          };
        },
      )
      .addCase(
        AppointmentsActions.getAppointmentsError,
        (state: AppointmentsState, action: PayloadAction<any>) => {
          return {
            ...state,
            loadingAppointments: false,
            appointmentsError: action.payload,
          };
        },
      )

      //get single appointments
      .addCase(
        AppointmentsActions.getSingleAppointmentLoading,
        (state: AppointmentsState) => {
          return {
            ...state,
            singleAppointmentLoading: true,
            singleAppointmentError: false,
          };
        },
      )
      .addCase(
        AppointmentsActions.getSingleAppointmentError,
        (state: AppointmentsState, action: PayloadAction<any>) => {
          return {
            ...state,
            singleAppointmentLoading: false,
            singleAppointmentError: action.payload,
          };
        },
      )
      .addCase(
        AppointmentsActions.getSingleAppointmentSuccess,
        (state: AppointmentsState, action: PayloadAction<Appointment>) => {
          let appointmentDetail = action.payload;
          return {
            ...state,
            singleAppointmentLoading: false,
            singleAppointmentError: false,
            appointmentDetail,
          };
        },
      )

      //patch appointment
      .addCase(
        AppointmentsActions.patchAppointmentSuccess,
        (state: AppointmentsState, action: PayloadAction<Appointment>) => {
          let patchAppointmentSuccess = action.payload;
          let updatedCalendarAppointments = _.map(
            state.calendarAppointments,
            function (e) {
              return e.id === patchAppointmentSuccess.uuid
                ? createCalendarEvent(patchAppointmentSuccess)
                : e;
            },
          );
          return {
            ...state,
            patchAppointmentError: false,
            patchAppointmentLoading: false,
            patchAppointmentSuccess,
            calendarAppointments: updatedCalendarAppointments,
            appointmentDetail: patchAppointmentSuccess,
            hydratedAppointment: addAppointment(
              state.hydratedAppointment,
              patchAppointmentSuccess,
            ),
          };
        },
      )
      .addCase(
        AppointmentsActions.patchAppointmentError,
        (state: AppointmentsState, action: PayloadAction<any>) => {
          let patchAppointmentError = action.payload;
          return {
            ...state,
            patchAppointmentError,
            patchAppointmentLoading: false,
          };
        },
      )
      .addCase(
        AppointmentsActions.patchAppointmentLoading,
        (state: AppointmentsState) => {
          return {
            ...state,
            patchAppointmentError: false,
            patchAppointmentLoading: true,
          };
        },
      )

      //delete appointment
      .addCase(
        AppointmentsActions.deleteAppointmentSuccess,
        (state: AppointmentsState, action: PayloadAction<string>) => {
          let deletedID = action.payload;
          let calendarAppointments = _.filter(
            state.calendarAppointments,
            function (obj) {
              return obj.id !== deletedID;
            },
          );
          return {
            ...state,
            deleteAppointmentLoading: false,
            deleteAppointmentError: false,
            calendarAppointments,
            deleteAppointmentSuccess: deletedID,
          };
        },
      )
      .addCase(
        AppointmentsActions.deleteAppointmentLoading,
        (state: AppointmentsState) => {
          return {
            ...state,
            deleteAppointmentLoading: true,
            deleteAppointmentError: false,
          };
        },
      )
      .addCase(
        AppointmentsActions.deleteAppointmentError,
        (state: AppointmentsState, action: PayloadAction<any>) => {
          let deleteAppointmentError = action.payload;
          return {
            ...state,
            deleteAppointmentLoading: false,
            deleteAppointmentError,
          };
        },
      )

      //create appointment
      .addCase(
        AppointmentsActions.postAppointmentSuccess,
        (state: AppointmentsState, action: PayloadAction<Appointment>) => {
          // add new appointment into hydra object and prepack for calendar
          let postAppointmentSuccess = action.payload;
          let calObjArrays = [createCalendarEvent(postAppointmentSuccess)];

          let updatedCalendarAppointments = _.concat(
            state.calendarAppointments,
            calObjArrays,
          );
          return {
            ...state,
            postAppointmentError: false,
            postAppointmentLoading: false,
            postAppointmentSuccess,
            calendarAppointments: updatedCalendarAppointments,
            appointmentDetail: postAppointmentSuccess,
            hydratedAppointment: addAppointment(
              state.hydratedAppointment,
              action.payload,
            ),
          };
        },
      )
      .addCase(
        AppointmentsActions.postAppointmentError,
        (state: AppointmentsState, action: PayloadAction<any>) => {
          let postAppointmentError = action.payload;
          return {
            ...state,
            postAppointmentError,
            postAppointmentLoading: false,
          };
        },
      )
      .addCase(
        AppointmentsActions.postAppointmentLoading,
        (state: AppointmentsState) => {
          return {
            ...state,
            postAppointmentError: false,
            postAppointmentLoading: true,
          };
        },
      );
  },
);

export default AppointmentsReducer;
