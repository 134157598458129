import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../../store";

const showSpecialHoursModalSuccess = createAction(
  "ui/showSpecialHoursModalSuccess",
);
const showCreateSpecialHoursModalSuccess = createAction(
  "ui/showCreateSpecialHoursModalSuccess",
);
const showTimePeriodsModalSuccess = createAction(
  "ui/showTimePeriodsModalSuccess",
);
const showDateRangeModalSuccess = createAction("ui/showDateRangeModalSuccess");
const showLogOutDialogSuccess = createAction("ui/showLogOutDialogSuccess");
const closeAllModals = createAction("ui/closeModal");

//special hours
const openSpecialHoursModal = () => (dispatch: AppDispatch) => {
  dispatch(showSpecialHoursModalSuccess());
};

//create special hours
const openCreateSpecialHoursModal = () => (dispatch: AppDispatch) => {
  dispatch(showCreateSpecialHoursModalSuccess());
};

//edit time periods
const openTimePeriodsModal = () => (dispatch: AppDispatch) => {
  dispatch(showTimePeriodsModalSuccess());
};

//date range modal
const openDateRangeModal = () => (dispatch: AppDispatch) => {
  dispatch(showDateRangeModalSuccess());
};

//logout
const openLogoutDialog = () => (dispatch: AppDispatch) => {
  dispatch(showLogOutDialogSuccess());
};

const closeModal = () => (dispatch: AppDispatch) => {
  dispatch(closeAllModals());
};

export default {
  showSpecialHoursModalSuccess,
  closeAllModals,
  openSpecialHoursModal,
  closeModal,
  openCreateSpecialHoursModal,
  showCreateSpecialHoursModalSuccess,
  showTimePeriodsModalSuccess,
  openTimePeriodsModal,
  openDateRangeModal,
  showDateRangeModalSuccess,
  showLogOutDialogSuccess,
  openLogoutDialog,
};
