/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { HydratedStoreServiceCalendarOutputCalendarRead } from "../nbp";
// @ts-ignore
import { HydratedStoreServiceStoreServiceOutputStoreServiceRead } from "../nbp";
// @ts-ignore
import { HydratedStoreServiceTimeSlotOutputTimeslotRead } from "../nbp";
// @ts-ignore
import { StoreServiceStatusInputStoreServiceWrite } from "../nbp";
// @ts-ignore
import { StoreServiceStoreServiceInputStoreServiceWrite } from "../nbp";
// @ts-ignore
import { StoreServiceStoreServiceOutputStoreServiceRead } from "../nbp";
/**
 * StoreServiceApi - axios parameter creator
 * @export
 */
export const StoreServiceApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates the StoreService if missing.
     * @summary Add a Product for a new or existing StoreService.
     * @param {string} id
     * @param {string} serviceID
     * @param {string} productID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductWithServiceStoreServiceItem: async (
      id: string,
      serviceID: string,
      productID: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("createProductWithServiceStoreServiceItem", "id", id);
      // verify required parameter 'serviceID' is not null or undefined
      assertParamExists(
        "createProductWithServiceStoreServiceItem",
        "serviceID",
        serviceID,
      );
      // verify required parameter 'productID' is not null or undefined
      assertParamExists(
        "createProductWithServiceStoreServiceItem",
        "productID",
        productID,
      );
      const localVarPath =
        `/api/store/{id}/store_services/services/{serviceID}/products/{productID}`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)))
          .replace(`{${"serviceID"}}`, encodeURIComponent(String(serviceID)))
          .replace(`{${"productID"}}`, encodeURIComponent(String(productID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Removes the StoreService resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreServiceItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteStoreServiceItem", "id", id);
      const localVarPath = `/api/store_services/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Archived StoreServices
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedStoreServicesStoreServiceCollection: async (
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/store_services/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (externalName !== undefined) {
        localVarQueryParameter["externalName"] = externalName;
      }

      if (internalName !== undefined) {
        localVarQueryParameter["internalName"] = internalName;
      }

      if (duration !== undefined) {
        localVarQueryParameter["duration"] = duration;
      }

      if (capacity !== undefined) {
        localVarQueryParameter["capacity"] = capacity;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves calendar by StoreService
     * @param {string} id
     * @param {boolean} [withHours]
     * @param {string} [date]
     * @param {number} [days]
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalendarStoreServiceCollection: async (
      id: string,
      withHours?: boolean,
      date?: string,
      days?: number,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getCalendarStoreServiceCollection", "id", id);
      const localVarPath = `/api/store_services/{id}/calendar`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (withHours !== undefined) {
        localVarQueryParameter["withHours"] = withHours;
      }

      if (date !== undefined) {
        localVarQueryParameter["date"] = date;
      }

      if (days !== undefined) {
        localVarQueryParameter["days"] = days;
      }

      if (externalName !== undefined) {
        localVarQueryParameter["externalName"] = externalName;
      }

      if (internalName !== undefined) {
        localVarQueryParameter["internalName"] = internalName;
      }

      if (duration !== undefined) {
        localVarQueryParameter["duration"] = duration;
      }

      if (capacity !== undefined) {
        localVarQueryParameter["capacity"] = capacity;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves calendar by Store
     * @param {string} id
     * @param {boolean} [withHours]
     * @param {string} [date]
     * @param {number} [days]
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreCalendarStoreServiceCollection: async (
      id: string,
      withHours?: boolean,
      date?: string,
      days?: number,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getStoreCalendarStoreServiceCollection", "id", id);
      const localVarPath = `/api/stores/{id}/calendar`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (withHours !== undefined) {
        localVarQueryParameter["withHours"] = withHours;
      }

      if (date !== undefined) {
        localVarQueryParameter["date"] = date;
      }

      if (days !== undefined) {
        localVarQueryParameter["days"] = days;
      }

      if (externalName !== undefined) {
        localVarQueryParameter["externalName"] = externalName;
      }

      if (internalName !== undefined) {
        localVarQueryParameter["internalName"] = internalName;
      }

      if (duration !== undefined) {
        localVarQueryParameter["duration"] = duration;
      }

      if (capacity !== undefined) {
        localVarQueryParameter["capacity"] = capacity;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves the collection of StoreServiceOutput resources.
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {Array<number>} [duration2]
     * @param {number} [capacity]
     * @param {Array<number>} [capacity2]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreServiceCollection: async (
      externalName?: string,
      internalName?: string,
      duration?: number,
      duration2?: Array<number>,
      capacity?: number,
      capacity2?: Array<number>,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/store_services`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (externalName !== undefined) {
        localVarQueryParameter["externalName"] = externalName;
      }

      if (internalName !== undefined) {
        localVarQueryParameter["internalName"] = internalName;
      }

      if (duration !== undefined) {
        localVarQueryParameter["duration"] = duration;
      }

      if (duration2) {
        localVarQueryParameter["duration[]"] = duration2;
      }

      if (capacity !== undefined) {
        localVarQueryParameter["capacity"] = capacity;
      }

      if (capacity2) {
        localVarQueryParameter["capacity[]"] = capacity2;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a StoreServiceOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreServiceItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getStoreServiceItem", "id", id);
      const localVarPath = `/api/store_services/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves StoreServices by Store
     * @param {string} id
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreservicesForCurrentStoreStoreServiceCollection: async (
      id: string,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "getStoreservicesForCurrentStoreStoreServiceCollection",
        "id",
        id,
      );
      const localVarPath = `/api/stores/{id}/store_services`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (externalName !== undefined) {
        localVarQueryParameter["externalName"] = externalName;
      }

      if (internalName !== undefined) {
        localVarQueryParameter["internalName"] = internalName;
      }

      if (duration !== undefined) {
        localVarQueryParameter["duration"] = duration;
      }

      if (capacity !== undefined) {
        localVarQueryParameter["capacity"] = capacity;
      }

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Timeslots by StoreService
     * @param {string} id
     * @param {string} [date]
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTimeslotsStoreServiceCollection: async (
      id: string,
      date?: string,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getTimeslotsStoreServiceCollection", "id", id);
      const localVarPath = `/api/store_services/{id}/timeslots`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (date !== undefined) {
        localVarQueryParameter["date"] = date;
      }

      if (externalName !== undefined) {
        localVarQueryParameter["externalName"] = externalName;
      }

      if (internalName !== undefined) {
        localVarQueryParameter["internalName"] = internalName;
      }

      if (duration !== undefined) {
        localVarQueryParameter["duration"] = duration;
      }

      if (capacity !== undefined) {
        localVarQueryParameter["capacity"] = capacity;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Changes status for a Store Service resource
     * @param {string} uuid
     * @param {StoreServiceStatusInputStoreServiceWrite} [storeServiceStatusInputStoreServiceWrite] The updated StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusStoreServiceItem: async (
      uuid: string,
      storeServiceStatusInputStoreServiceWrite?: StoreServiceStatusInputStoreServiceWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("patchStatusStoreServiceItem", "uuid", uuid);
      const localVarPath = `/api/store_services/{uuid}/status`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeServiceStatusInputStoreServiceWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the StoreService resource.
     * @param {string} id
     * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The updated StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStoreServiceItem: async (
      id: string,
      storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchStoreServiceItem", "id", id);
      const localVarPath = `/api/store_services/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeServiceStoreServiceInputStoreServiceWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a StoreService resource.
     * @param {string} id
     * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The new StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postStoreServiceCollection: async (
      id: string,
      storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("postStoreServiceCollection", "id", id);
      const localVarPath = `/api/store/{id}/store_services`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeServiceStoreServiceInputStoreServiceWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Replaces the StoreService resource.
     * @param {string} id
     * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The updated StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStoreServiceItem: async (
      id: string,
      storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putStoreServiceItem", "id", id);
      const localVarPath = `/api/store_services/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeServiceStoreServiceInputStoreServiceWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Removes the StoreService if no Products are left.
     * @summary Remove a Product from an existing StoreService.
     * @param {string} id
     * @param {string} serviceID
     * @param {string} productID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProductFromServiceStoreServiceItem: async (
      id: string,
      serviceID: string,
      productID: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("removeProductFromServiceStoreServiceItem", "id", id);
      // verify required parameter 'serviceID' is not null or undefined
      assertParamExists(
        "removeProductFromServiceStoreServiceItem",
        "serviceID",
        serviceID,
      );
      // verify required parameter 'productID' is not null or undefined
      assertParamExists(
        "removeProductFromServiceStoreServiceItem",
        "productID",
        productID,
      );
      const localVarPath =
        `/api/store/{id}/store_services/services/{serviceID}/products/{productID}`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)))
          .replace(`{${"serviceID"}}`, encodeURIComponent(String(serviceID)))
          .replace(`{${"productID"}}`, encodeURIComponent(String(productID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StoreServiceApi - functional programming interface
 * @export
 */
export const StoreServiceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    StoreServiceApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates the StoreService if missing.
     * @summary Add a Product for a new or existing StoreService.
     * @param {string} id
     * @param {string} serviceID
     * @param {string} productID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createProductWithServiceStoreServiceItem(
      id: string,
      serviceID: string,
      productID: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createProductWithServiceStoreServiceItem(
          id,
          serviceID,
          productID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Removes the StoreService resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteStoreServiceItem(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteStoreServiceItem(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Archived StoreServices
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArchivedStoreServicesStoreServiceCollection(
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreServiceStoreServiceOutputStoreServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllArchivedStoreServicesStoreServiceCollection(
          externalName,
          internalName,
          duration,
          capacity,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves calendar by StoreService
     * @param {string} id
     * @param {boolean} [withHours]
     * @param {string} [date]
     * @param {number} [days]
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCalendarStoreServiceCollection(
      id: string,
      withHours?: boolean,
      date?: string,
      days?: number,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreServiceCalendarOutputCalendarRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCalendarStoreServiceCollection(
          id,
          withHours,
          date,
          days,
          externalName,
          internalName,
          duration,
          capacity,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves calendar by Store
     * @param {string} id
     * @param {boolean} [withHours]
     * @param {string} [date]
     * @param {number} [days]
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStoreCalendarStoreServiceCollection(
      id: string,
      withHours?: boolean,
      date?: string,
      days?: number,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreServiceCalendarOutputCalendarRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStoreCalendarStoreServiceCollection(
          id,
          withHours,
          date,
          days,
          externalName,
          internalName,
          duration,
          capacity,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves the collection of StoreServiceOutput resources.
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {Array<number>} [duration2]
     * @param {number} [capacity]
     * @param {Array<number>} [capacity2]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStoreServiceCollection(
      externalName?: string,
      internalName?: string,
      duration?: number,
      duration2?: Array<number>,
      capacity?: number,
      capacity2?: Array<number>,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreServiceStoreServiceOutputStoreServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStoreServiceCollection(
          externalName,
          internalName,
          duration,
          duration2,
          capacity,
          capacity2,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a StoreServiceOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStoreServiceItem(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStoreServiceItem(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves StoreServices by Store
     * @param {string} id
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStoreservicesForCurrentStoreStoreServiceCollection(
      id: string,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreServiceStoreServiceOutputStoreServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStoreservicesForCurrentStoreStoreServiceCollection(
          id,
          externalName,
          internalName,
          duration,
          capacity,
          onlyArchived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Timeslots by StoreService
     * @param {string} id
     * @param {string} [date]
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTimeslotsStoreServiceCollection(
      id: string,
      date?: string,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedStoreServiceTimeSlotOutputTimeslotRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTimeslotsStoreServiceCollection(
          id,
          date,
          externalName,
          internalName,
          duration,
          capacity,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Changes status for a Store Service resource
     * @param {string} uuid
     * @param {StoreServiceStatusInputStoreServiceWrite} [storeServiceStatusInputStoreServiceWrite] The updated StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchStatusStoreServiceItem(
      uuid: string,
      storeServiceStatusInputStoreServiceWrite?: StoreServiceStatusInputStoreServiceWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchStatusStoreServiceItem(
          uuid,
          storeServiceStatusInputStoreServiceWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the StoreService resource.
     * @param {string} id
     * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The updated StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchStoreServiceItem(
      id: string,
      storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchStoreServiceItem(
          id,
          storeServiceStoreServiceInputStoreServiceWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a StoreService resource.
     * @param {string} id
     * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The new StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postStoreServiceCollection(
      id: string,
      storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postStoreServiceCollection(
          id,
          storeServiceStoreServiceInputStoreServiceWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Replaces the StoreService resource.
     * @param {string} id
     * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The updated StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putStoreServiceItem(
      id: string,
      storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putStoreServiceItem(
          id,
          storeServiceStoreServiceInputStoreServiceWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Removes the StoreService if no Products are left.
     * @summary Remove a Product from an existing StoreService.
     * @param {string} id
     * @param {string} serviceID
     * @param {string} productID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeProductFromServiceStoreServiceItem(
      id: string,
      serviceID: string,
      productID: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeProductFromServiceStoreServiceItem(
          id,
          serviceID,
          productID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * StoreServiceApi - factory interface
 * @export
 */
export const StoreServiceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StoreServiceApiFp(configuration);
  return {
    /**
     * Creates the StoreService if missing.
     * @summary Add a Product for a new or existing StoreService.
     * @param {string} id
     * @param {string} serviceID
     * @param {string} productID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductWithServiceStoreServiceItem(
      id: string,
      serviceID: string,
      productID: string,
      options?: any,
    ): AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead> {
      return localVarFp
        .createProductWithServiceStoreServiceItem(
          id,
          serviceID,
          productID,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Removes the StoreService resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreServiceItem(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteStoreServiceItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Archived StoreServices
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedStoreServicesStoreServiceCollection(
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreServiceStoreServiceOutputStoreServiceRead> {
      return localVarFp
        .getAllArchivedStoreServicesStoreServiceCollection(
          externalName,
          internalName,
          duration,
          capacity,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves calendar by StoreService
     * @param {string} id
     * @param {boolean} [withHours]
     * @param {string} [date]
     * @param {number} [days]
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalendarStoreServiceCollection(
      id: string,
      withHours?: boolean,
      date?: string,
      days?: number,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreServiceCalendarOutputCalendarRead> {
      return localVarFp
        .getCalendarStoreServiceCollection(
          id,
          withHours,
          date,
          days,
          externalName,
          internalName,
          duration,
          capacity,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves calendar by Store
     * @param {string} id
     * @param {boolean} [withHours]
     * @param {string} [date]
     * @param {number} [days]
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreCalendarStoreServiceCollection(
      id: string,
      withHours?: boolean,
      date?: string,
      days?: number,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreServiceCalendarOutputCalendarRead> {
      return localVarFp
        .getStoreCalendarStoreServiceCollection(
          id,
          withHours,
          date,
          days,
          externalName,
          internalName,
          duration,
          capacity,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves the collection of StoreServiceOutput resources.
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {Array<number>} [duration2]
     * @param {number} [capacity]
     * @param {Array<number>} [capacity2]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreServiceCollection(
      externalName?: string,
      internalName?: string,
      duration?: number,
      duration2?: Array<number>,
      capacity?: number,
      capacity2?: Array<number>,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreServiceStoreServiceOutputStoreServiceRead> {
      return localVarFp
        .getStoreServiceCollection(
          externalName,
          internalName,
          duration,
          duration2,
          capacity,
          capacity2,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a StoreServiceOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreServiceItem(
      id: string,
      options?: any,
    ): AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead> {
      return localVarFp
        .getStoreServiceItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves StoreServices by Store
     * @param {string} id
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {boolean} [onlyArchived]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreservicesForCurrentStoreStoreServiceCollection(
      id: string,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      onlyArchived?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreServiceStoreServiceOutputStoreServiceRead> {
      return localVarFp
        .getStoreservicesForCurrentStoreStoreServiceCollection(
          id,
          externalName,
          internalName,
          duration,
          capacity,
          onlyArchived,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Timeslots by StoreService
     * @param {string} id
     * @param {string} [date]
     * @param {string} [externalName]
     * @param {string} [internalName]
     * @param {number} [duration]
     * @param {number} [capacity]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTimeslotsStoreServiceCollection(
      id: string,
      date?: string,
      externalName?: string,
      internalName?: string,
      duration?: number,
      capacity?: number,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedStoreServiceTimeSlotOutputTimeslotRead> {
      return localVarFp
        .getTimeslotsStoreServiceCollection(
          id,
          date,
          externalName,
          internalName,
          duration,
          capacity,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Changes status for a Store Service resource
     * @param {string} uuid
     * @param {StoreServiceStatusInputStoreServiceWrite} [storeServiceStatusInputStoreServiceWrite] The updated StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusStoreServiceItem(
      uuid: string,
      storeServiceStatusInputStoreServiceWrite?: StoreServiceStatusInputStoreServiceWrite,
      options?: any,
    ): AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead> {
      return localVarFp
        .patchStatusStoreServiceItem(
          uuid,
          storeServiceStatusInputStoreServiceWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the StoreService resource.
     * @param {string} id
     * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The updated StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStoreServiceItem(
      id: string,
      storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
      options?: any,
    ): AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead> {
      return localVarFp
        .patchStoreServiceItem(
          id,
          storeServiceStoreServiceInputStoreServiceWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a StoreService resource.
     * @param {string} id
     * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The new StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postStoreServiceCollection(
      id: string,
      storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
      options?: any,
    ): AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead> {
      return localVarFp
        .postStoreServiceCollection(
          id,
          storeServiceStoreServiceInputStoreServiceWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Replaces the StoreService resource.
     * @param {string} id
     * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The updated StoreService resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStoreServiceItem(
      id: string,
      storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
      options?: any,
    ): AxiosPromise<StoreServiceStoreServiceOutputStoreServiceRead> {
      return localVarFp
        .putStoreServiceItem(
          id,
          storeServiceStoreServiceInputStoreServiceWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Removes the StoreService if no Products are left.
     * @summary Remove a Product from an existing StoreService.
     * @param {string} id
     * @param {string} serviceID
     * @param {string} productID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProductFromServiceStoreServiceItem(
      id: string,
      serviceID: string,
      productID: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .removeProductFromServiceStoreServiceItem(
          id,
          serviceID,
          productID,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StoreServiceApi - object-oriented interface
 * @export
 * @class StoreServiceApi
 * @extends {BaseAPI}
 */
export class StoreServiceApi extends BaseAPI {
  /**
   * Creates the StoreService if missing.
   * @summary Add a Product for a new or existing StoreService.
   * @param {string} id
   * @param {string} serviceID
   * @param {string} productID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public createProductWithServiceStoreServiceItem(
    id: string,
    serviceID: string,
    productID: string,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .createProductWithServiceStoreServiceItem(
        id,
        serviceID,
        productID,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Removes the StoreService resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public deleteStoreServiceItem(id: string, options?: any) {
    return StoreServiceApiFp(this.configuration)
      .deleteStoreServiceItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Archived StoreServices
   * @param {string} [externalName]
   * @param {string} [internalName]
   * @param {number} [duration]
   * @param {number} [capacity]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public getAllArchivedStoreServicesStoreServiceCollection(
    externalName?: string,
    internalName?: string,
    duration?: number,
    capacity?: number,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .getAllArchivedStoreServicesStoreServiceCollection(
        externalName,
        internalName,
        duration,
        capacity,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves calendar by StoreService
   * @param {string} id
   * @param {boolean} [withHours]
   * @param {string} [date]
   * @param {number} [days]
   * @param {string} [externalName]
   * @param {string} [internalName]
   * @param {number} [duration]
   * @param {number} [capacity]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public getCalendarStoreServiceCollection(
    id: string,
    withHours?: boolean,
    date?: string,
    days?: number,
    externalName?: string,
    internalName?: string,
    duration?: number,
    capacity?: number,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .getCalendarStoreServiceCollection(
        id,
        withHours,
        date,
        days,
        externalName,
        internalName,
        duration,
        capacity,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves calendar by Store
   * @param {string} id
   * @param {boolean} [withHours]
   * @param {string} [date]
   * @param {number} [days]
   * @param {string} [externalName]
   * @param {string} [internalName]
   * @param {number} [duration]
   * @param {number} [capacity]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public getStoreCalendarStoreServiceCollection(
    id: string,
    withHours?: boolean,
    date?: string,
    days?: number,
    externalName?: string,
    internalName?: string,
    duration?: number,
    capacity?: number,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .getStoreCalendarStoreServiceCollection(
        id,
        withHours,
        date,
        days,
        externalName,
        internalName,
        duration,
        capacity,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves the collection of StoreServiceOutput resources.
   * @param {string} [externalName]
   * @param {string} [internalName]
   * @param {number} [duration]
   * @param {Array<number>} [duration2]
   * @param {number} [capacity]
   * @param {Array<number>} [capacity2]
   * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public getStoreServiceCollection(
    externalName?: string,
    internalName?: string,
    duration?: number,
    duration2?: Array<number>,
    capacity?: number,
    capacity2?: Array<number>,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .getStoreServiceCollection(
        externalName,
        internalName,
        duration,
        duration2,
        capacity,
        capacity2,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a StoreServiceOutput resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public getStoreServiceItem(id: string, options?: any) {
    return StoreServiceApiFp(this.configuration)
      .getStoreServiceItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves StoreServices by Store
   * @param {string} id
   * @param {string} [externalName]
   * @param {string} [internalName]
   * @param {number} [duration]
   * @param {number} [capacity]
   * @param {boolean} [onlyArchived]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public getStoreservicesForCurrentStoreStoreServiceCollection(
    id: string,
    externalName?: string,
    internalName?: string,
    duration?: number,
    capacity?: number,
    onlyArchived?: boolean,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .getStoreservicesForCurrentStoreStoreServiceCollection(
        id,
        externalName,
        internalName,
        duration,
        capacity,
        onlyArchived,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Timeslots by StoreService
   * @param {string} id
   * @param {string} [date]
   * @param {string} [externalName]
   * @param {string} [internalName]
   * @param {number} [duration]
   * @param {number} [capacity]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public getTimeslotsStoreServiceCollection(
    id: string,
    date?: string,
    externalName?: string,
    internalName?: string,
    duration?: number,
    capacity?: number,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .getTimeslotsStoreServiceCollection(
        id,
        date,
        externalName,
        internalName,
        duration,
        capacity,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Changes status for a Store Service resource
   * @param {string} uuid
   * @param {StoreServiceStatusInputStoreServiceWrite} [storeServiceStatusInputStoreServiceWrite] The updated StoreService resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public patchStatusStoreServiceItem(
    uuid: string,
    storeServiceStatusInputStoreServiceWrite?: StoreServiceStatusInputStoreServiceWrite,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .patchStatusStoreServiceItem(
        uuid,
        storeServiceStatusInputStoreServiceWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the StoreService resource.
   * @param {string} id
   * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The updated StoreService resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public patchStoreServiceItem(
    id: string,
    storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .patchStoreServiceItem(
        id,
        storeServiceStoreServiceInputStoreServiceWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a StoreService resource.
   * @param {string} id
   * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The new StoreService resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public postStoreServiceCollection(
    id: string,
    storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .postStoreServiceCollection(
        id,
        storeServiceStoreServiceInputStoreServiceWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Replaces the StoreService resource.
   * @param {string} id
   * @param {StoreServiceStoreServiceInputStoreServiceWrite} [storeServiceStoreServiceInputStoreServiceWrite] The updated StoreService resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public putStoreServiceItem(
    id: string,
    storeServiceStoreServiceInputStoreServiceWrite?: StoreServiceStoreServiceInputStoreServiceWrite,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .putStoreServiceItem(
        id,
        storeServiceStoreServiceInputStoreServiceWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Removes the StoreService if no Products are left.
   * @summary Remove a Product from an existing StoreService.
   * @param {string} id
   * @param {string} serviceID
   * @param {string} productID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StoreServiceApi
   */
  public removeProductFromServiceStoreServiceItem(
    id: string,
    serviceID: string,
    productID: string,
    options?: any,
  ) {
    return StoreServiceApiFp(this.configuration)
      .removeProductFromServiceStoreServiceItem(
        id,
        serviceID,
        productID,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
