import { Box, Grid, ImageList, Theme, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TimeSlotButton from './TimeSlotButton';
import BookingCheckboxButton from './BookingCheckboxButton';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { StoreServiceActions } from '../../redux/actions';
import {
  Appointment,
  AppointmentRequest,
  StoreService,
  TimeSlot,
} from '../../api/entities';
import _ from 'lodash';
import 'moment/locale/it';
import AppointmentProductField from './AppointmentProductField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { pickersLayoutClasses } from '@mui/x-date-pickers';
import { addTimeForDate } from '../../utils/extensions';

moment.locale('it');

interface BookingAppointmentPageProps {
  event: Appointment | null | undefined;
  isActive: boolean;
  onChangeAppointment: (data: any) => void;
}

export default function BookingAppointmentPage({
  event,
  isActive,
  onChangeAppointment,
}: BookingAppointmentPageProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useSelector(
    (state: RootState) => state.StoreReducer.currentSelectedStore,
  );
  const storeServices = useSelector(
    (state: RootState) =>
      state.StoreReducer.currentSelectedStore?.storeServices,
  );
  const [date, changeDate] = useState<Moment | null>(moment());
  const [service, setService] = useState<StoreService>();
  const [selectedSlot, setSelectedSlot] = useState<TimeSlot | null>();
  const [selectedTime, setSelectedTime] = useState('');
  const [times, setTimes] = useState<any[]>([]);
  const [showWarningSlotLabel, setShowWarningSlotLabel] = useState(false);

  const [product, setProduct] = useState<string | undefined>('');

  const timeSlots = useSelector((state: RootState) =>
    state.StoreServiceReducer.hydratedTimeSlots
      ? state.StoreServiceReducer.hydratedTimeSlots['hydra:member']
      : [],
  );

  useEffect(() => {
    if (storeServices?.[0]) setService(storeServices[0]);
  }, [storeServices]);

  useEffect(() => {
    if (event?.products?.[0]) {
      setProduct(event.products[0].productName);
      return;
    }

    if (store?.products) {
      if (store.products.length > 1) {
        setProduct('IQOS');
        return;
      }
      if (store.products[0]) {
        setProduct(store.products[0].productName);
        return;
      }
    }

    setProduct('');
  }, [event, store]);

  useEffect(() => {
    // * Here is another performance issue. Check this hook and productServices

    let selectedSlot = _.find(timeSlots, ['slot', selectedTime]);
    setSelectedSlot(selectedSlot);

    const extendedTimeSlots = timeSlots.map((slot: TimeSlot) => ({
      ...slot,
      selected: slot.slot ? slot.slot === selectedTime : false,
    }));

    setTimes(extendedTimeSlots);
  }, [selectedTime, timeSlots]);

  useEffect(() => {
    if (service?.uuid && date) {
      dispatch(
        StoreServiceActions.getTimeSlots(
          service.uuid,
          date.format('DD-MM-YYYY'),
        ),
      );
    }
  }, [service, date, dispatch]);

  const productServices = useCallback(
    (tmpProduct: string | undefined) => {
      let currentSelectedProduct = tmpProduct;
      let servicesByProduct: StoreService[] = [];
      storeServices?.forEach((item) => {
        item.products?.forEach((product) => {
          if (product.productName === currentSelectedProduct) {
            servicesByProduct.push(item);
          }
        });
      });
      return servicesByProduct;
    },
    [storeServices],
  );

  useEffect(() => {
    if (!storeServices || !event) return;

    if (event.storeService) {
      const editableStoreService = _.find(storeServices, {
        uuid: event.storeService.uuid,
      });
      setService(editableStoreService);
      changeDate(moment(event?.startTime));
      setSelectedTime(moment(event.startTime).format('HH:mm'));
      return;
    }
    changeDate(moment());
    setService(productServices(product)[0]);
  }, [event, store, storeServices, product, productServices]);

  useEffect(() => {
    if (!store) return;
    let duration = selectedSlot?.duration;

    const productUUID: string | undefined = store.products?.find(
      (p) => p.productName === product,
    )?.uuid;

    const appointmentRequest: AppointmentRequest = {
      id: event ? event.uuid : undefined,
      storeService: service?.uuid,
      startTime: addTimeForDate(date, selectedSlot)?.toISOString(),
      endTime: addTimeForDate(date, selectedSlot)
        ?.add(duration, 'minute')
        .toISOString(),
      products: productUUID ? [productUUID] : undefined,
    };

    onChangeAppointment(appointmentRequest);
  }, [service, selectedSlot, date, event, onChangeAppointment, product, store]);

  const handleSelectService = useCallback((storeService: StoreService) => {
    setService(storeService);
    setSelectedSlot(null);
  }, []);

  const handleTimes = useCallback(
    (_slot: TimeSlot) => {
      if (_slot.status === 'closed') {
        setShowWarningSlotLabel(true);
        let newTimes = times.map((slot) => {
          return { ...slot, selected: _slot.slot === slot.slot };
        });
        setTimes(newTimes);
        setSelectedSlot(_slot);
      } else {
        setShowWarningSlotLabel(false);
        let newTimes = times.map((slot) => {
          return { ...slot, selected: _slot.slot === slot.slot };
        });
        setTimes(newTimes);
        setSelectedSlot(_slot);
      }
    },
    [times],
  );

  const handleOnChangeDate = useCallback(
    (_date: any | null) => {
      changeDate(_date);
      setShowWarningSlotLabel(false);
      if (service?.uuid && _date) {
        dispatch(
          StoreServiceActions.getTimeSlots(
            service.uuid,
            moment(_date).format('DD-MM-YYYY'),
          ),
        );
      }
    },
    [service, dispatch],
  );

  const handleOnChangeProducts = useCallback(
    (value: string) => {
      setProduct(value);
      const productService = productServices(value)[0];
      if (service?.uuid !== productService?.uuid) {
        setService(productService);
      }
    },
    [service, productServices],
  );

  const pastArray: any = useMemo(
    () =>
      times.map((timeSlot) => {
        const date1 = date?.set({
          h: timeSlot.slot.split(':')[0],
          m: timeSlot.slot.split(':')[1],
        });
        return moment().isSameOrAfter(date1);
      }),
    [times, date],
  );

  const allDatePassed = useMemo(() => _.every(pastArray), [pastArray]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
      <Grid className={isActive ? classes.gridContainer : classes.hidden}>
        <Box className={classes.container}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" width="100%">
              <Box>
                <Box className={classes.productsInnerBox}>
                  <Typography className={classes.fieldLabel}>
                    Scegli il prodotto
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <AppointmentProductField
                      selectedProduct={product!}
                      select={(p) => handleOnChangeProducts(p)}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.productsInnerBox}>
                <Typography className={classes.fieldLabel}>
                  Scegli la tipologia di servizio
                </Typography>
                <Box display="flex" alignItems="center">
                  {productServices(product)
                    .filter((s) => !!s.externalName)
                    .map((storeService) => (
                      <BookingCheckboxButton
                        key={storeService.uuid}
                        name={storeService.externalName!}
                        selected={service?.uuid === storeService?.uuid}
                        onSelected={() => handleSelectService(storeService)}
                      />
                    ))}
                  {productServices(product).length === 0 && (
                    <Typography
                      sx={{
                        display: 'block',
                        height: '32px',
                        lineHeight: '32px',
                      }}
                    >
                      Nessun servizio per il prodotto selezionato
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className={classes.fieldLabel}>
            Scegli data e ora
          </Typography>
          <Box className={classes.contentBox} display="flex">
            <Box className={classes.calendarBox}>
              <div>
                <StaticDatePicker
                  slotProps={{
                    toolbar: {
                      className: classes.pickerToolbar,
                      toolbarFormat: 'ddd, DD MMM',
                    },
                    layout: {
                      sx: {
                        [`.${pickersLayoutClasses.actionBar}`]: {
                          display: 'none',
                        },
                      },
                    },
                  }}
                  value={date}
                  orientation="landscape"
                  minDate={moment()}
                  onChange={(date: any) => handleOnChangeDate(date)}
                />
              </div>
            </Box>
            <Box className={classes.slotsBox}>
              {showWarningSlotLabel && (
                <Box display="flex" className={classes.bottom}>
                  <Box display="flex">
                    <Typography className={classes.overbookingLabel}>
                      Stai eseguendo un overbooking
                    </Typography>
                  </Box>
                </Box>
              )}

              {times.length > 0 && !allDatePassed && (
                <ImageList rowHeight="auto" cols={4}>
                  {times.map((timeSlot, i) => {
                    const date1 = date?.set({
                      h: timeSlot.slot.split(':')[0],
                      m: timeSlot.slot.split(':')[1],
                    });
                    const inPast = moment().isSameOrAfter(date1);
                    return (
                      !inPast && (
                        <Box key={timeSlot.id || timeSlot['@id']}>
                          <TimeSlotButton
                            slot={timeSlot}
                            onSelect={(slot) => {
                              if (slot.occupiedSlots && slot.totalSlots) {
                                setShowWarningSlotLabel(
                                  slot.occupiedSlots >= slot.totalSlots,
                                );
                              }
                              handleTimes(slot);
                            }}
                          />
                        </Box>
                      )
                    );
                  })}
                </ImageList>
              )}
              {(times.length === 0 || allDatePassed) && (
                <Typography>Non ci sono orari disponibili</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </LocalizationProvider>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: 'none !important',
    },
    container: {
      width: '100%',
    },
    gridContainer: {
      width: '100%',
      flexGrow: 1,
    },
    datePicker: {
      width: '100%',
      height: '100%',
    },
    contentBox: {
      width: '100%',
    },
    fieldLabel: {
      fontSize: '20px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      marginBottom: '24px !important',
    },
    overbookingLabel: {
      fontSize: 12,
      fontWeight: 'bold',
      color: 'red',
      textAlign: 'center',
    },
    bottom: {
      marginBottom: 10,
    },
    productsInnerBox: {
      paddingBottom: 40,
    },
    calendarBox: {
      width: '60%',
    },
    slotsBox: {
      width: '40%',
      height: '100%',
      paddingLeft: 10,
      paddingRight: 10,
    },
    pickerToolbar: {
      backgroundColor: '#34303D',
      color: '#FFFDFB',
    },
  }),
);
