import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Checkbox, Autocomplete, TextField } from '@mui/material';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { PRODUCT_VEEV } from '../../../utils/validators';

interface StoreProductsFieldProps {
  onSelectProducts?: (values: string[]) => void;
  defaultValues?: string[];
}

export default function StoreProductsField({
  onSelectProducts,
  defaultValues,
}: StoreProductsFieldProps) {
  const classes = useStyles();
  const catalogProducts = useSelector((state: RootState) =>
    state.ProductsReducer.hydratedProducts?.['hydra:member']
      ? state.ProductsReducer.hydratedProducts?.['hydra:member'].map(
          (obj: any) => {
            return obj.productName;
          },
        )
      : [],
  );

  const [products, setProducts] = useState<string[]>(defaultValues || []);

  const handleChange = (selectedProducts: string[]) => {
    setProducts(selectedProducts);
  };

  useEffect(() => {
    onSelectProducts && onSelectProducts(products);
  }, [products, onSelectProducts]);

  return (
    <Box className={classes.box}>
      <Autocomplete
        noOptionsText="Nessun risultato"
        multiple
        fullWidth
        id="demo-mutiple-checkbox"
        size="small"
        value={products}
        onChange={(e, options) => handleChange(options as any[])}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Seleziona Prodotto"
            size="small"
            color="secondary"
            inputProps={{
              ...params.inputProps,
              style: {
                padding: '6px 10px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
          />
        )}
        options={catalogProducts.filter(p => p !== PRODUCT_VEEV) || []}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option}>
            <Checkbox
              color="secondary"
              style={{ marginRight: 4 }}
              checked={selected}
            />
            {option}
          </li>
        )}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      marginBottom: 24,
    },
  }),
);
