import React from 'react';
import { connect } from 'react-redux';
import ForgotPasswordForm from '../../components/forms/login_form/ForgotPasswordForm';
import { RootState } from '../../redux/reducers';
import { AuthActions } from '../../redux/actions';
import { Box, Typography } from '@mui/material';

interface ForgotPasswordProps {
  passwordRequestError: boolean;
  passwordRequestProgress: boolean;
  passwordRequestSuccess: boolean;
  requestNewPassword: (email: string) => void;
}

interface ForgotPasswordState {
  isSuccess: boolean;
}

export class ForgotPassword extends React.Component<
  ForgotPasswordProps,
  ForgotPasswordState
> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = { isSuccess: false };
  }
  componentDidUpdate(prevProps: ForgotPasswordProps) {
    if (
      prevProps.passwordRequestProgress &&
      this.props.passwordRequestSuccess
    ) {
      this.setState({ isSuccess: true });
    }
  }
  onSubmit(email: string) {
    this.props.requestNewPassword(email);
  }

  render() {
    return (
      <>
        {this.state.isSuccess ? (
          <Box
            style={{
              height: '100vh',
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                width: '60%',
                backgroundColor: 'white',
                padding: 50,
                fontSize: 20,
                fontWeight: 'bold',
                color: 'black',
                textAlign: 'center',
              }}
            >
              Riceverai a breve una email contenete le istruzioni per
              ripristinare la tua password.
            </Typography>
          </Box>
        ) : (
          <ForgotPasswordForm onSubmit={(email) => this.onSubmit(email)} />
        )}
      </>
    );
  }
}

export function mapStateToProps(state: RootState) {
  return {
    passwordRequestError: state.AuthReducer.passwordRequestError,
    passwordRequestProgress: state.AuthReducer.passwordRequestProgress,
    passwordRequestSuccess: state.AuthReducer.passwordRequestSuccess,
  };
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    requestNewPassword: (email: string) =>
      dispatch(AuthActions.requestNewPassword(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
