import { Box, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Appointment, AppointmentRequest } from '../../api/entities';
import { validateEmail, validatePhone } from '../../utils/validators';

interface BookingInfoPageProps {
  event: Appointment | null | undefined;
  onChangedInfoData?: (appointment: AppointmentRequest) => void;
  isActive: boolean;
}

export default function BookingInfoPage({
  event,
  onChangedInfoData,
  isActive,
}: BookingInfoPageProps) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [note, setNote] = useState('');
  const [referral, setReferral] = useState('');
  const [crmClientId, setCrmClientId] = useState<string | undefined>();
  const [clientType, setClientType] = useState<string | undefined>();
  const [nameTouched, setNameTouched] = useState(false);
  const [lastnameTouched, setLastnameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);

  const nameErrorText = () => {
    return nameTouched && name === '' && 'il nome è obbligatorio';
  };

  const lastnameErrorText = () => {
    return lastnameTouched && name === '' && 'il cognome è obbligatorio';
  };

  const emailErrorText = () => {
    if (!emailTouched) return;
    if (!email && !phone) return 'l’email è obbligatoria';
    return !validateEmail(email) && 'l’email non è valida';
  };

  const phoneErrorText = () => {
    if (!phoneTouched) return;
    if (!email && !phone) return 'il numero di telefono è obbligatorio';
    return !validatePhone(phone) && 'il numero di telefono non è valido';
  };

  useEffect(() => {
    if (event && event.clients) {
      let client = event.clients[0];
      setName(client.name ? client.name : '');
      setEmail(client.email ? client.email : '');
      setLastname(client.surname ? client.surname : '');
      setPhone(client.phoneNumber ? client.phoneNumber : '');
      setNote(event.notes ? event.notes : '');
      setReferral(event.referral ? event.referral : '');
      setCrmClientId(client.crmClientId);
      setClientType(client.clientType);
    }
  }, [event]);

  useEffect(() => {
    let appointmentRequest = {} as AppointmentRequest;
    appointmentRequest.notes = note;
    appointmentRequest.referral = referral;
    let client = {
      name,
      surname: lastname,
      phoneNumber: phone,
      email,
      crmClientId,
      clientType,
    };
    appointmentRequest.clients = [client];
    if (event) {
      //data for patch put flow
      appointmentRequest.id = event.uuid;
      if (
        appointmentRequest.clients[0].name === '' &&
        appointmentRequest.clients[0].surname === ''
      ) {
        appointmentRequest.clients[0].name = 'Anonimo';
      }
      onChangedInfoData && onChangedInfoData(appointmentRequest);
    } else {
      //data for post flow
      if (
        appointmentRequest.clients[0].name === '' &&
        appointmentRequest.clients[0].surname === ''
      ) {
        appointmentRequest.clients[0].name = 'Anonimo';
      }
      onChangedInfoData && onChangedInfoData(appointmentRequest);
    }
  }, [
    name,
    email,
    lastname,
    note,
    phone,
    referral,
    clientType,
    crmClientId,
    event,
    onChangedInfoData,
  ]);

  return (
    <Box
      display={isActive ? 'flex' : 'none'}
      flexDirection="column"
      width="100%"
    >
      <Typography className={classes.fieldLabel}>Dati del Cliente</Typography>
      <TextField
        label="Nome *"
        value={name}
        placeholder="Nome"
        color="secondary"
        size="small"
        style={{ marginBottom: '24px' }}
        inputProps={{
          style: {
            padding: '12px 16px',
            borderColor: '#E1E0E2',
          },
        }}
        InputLabelProps={{
          style: {
            top: '4px',
          },
        }}
        onChange={(event) => {
          setName(event.target.value);
          setNameTouched(true);
        }}
        helperText={nameErrorText()}
        error={nameTouched && name === ''}
      />
      <TextField
        value={lastname}
        placeholder="Cognome"
        label="Cognome *"
        color="secondary"
        size="small"
        style={{ marginBottom: '24px' }}
        inputProps={{
          style: {
            padding: '12px 16px',
            borderColor: '#E1E0E2',
          },
        }}
        InputLabelProps={{
          style: {
            top: '4px',
          },
        }}
        onChange={(event) => {
          setLastname(event.target.value);
          setLastnameTouched(true);
        }}
        helperText={lastnameErrorText()}
        error={lastnameTouched && lastname === ''}
      />
      <TextField
        value={email}
        error={emailTouched && ((!phone && !email) || !validateEmail(email))}
        helperText={emailErrorText()}
        placeholder="Email"
        label={phone ? 'Email' : 'Email *'}
        onChange={(event) => {
          setEmail(event.target.value);
          setEmailTouched(true);
        }}
        color="secondary"
        size="small"
        style={{ marginBottom: '24px' }}
        inputProps={{
          style: {
            padding: '12px 16px',
            borderColor: '#E1E0E2',
          },
        }}
        InputLabelProps={{
          style: {
            top: '4px',
          },
        }}
      />
      <TextField
        value={phone}
        error={phoneTouched && ((!phone && !email) || !validatePhone(phone))}
        helperText={phoneErrorText()}
        placeholder="Telefono"
        label={email ? 'Telefono' : 'Telefono *'}
        color="secondary"
        size="small"
        style={{ marginBottom: '24px' }}
        inputProps={{
          style: {
            padding: '12px 16px',
            borderColor: '#E1E0E2',
          },
        }}
        InputLabelProps={{
          style: {
            top: '4px',
          },
        }}
        onChange={(event) => {
          setPhone(event.target.value);
          setPhoneTouched(true);
        }}
      />
      <TextField
        value={referral}
        placeholder="Referral"
        label="Referral"
        color="secondary"
        size="small"
        style={{ marginBottom: '24px' }}
        inputProps={{
          style: {
            padding: '12px 16px',
            borderColor: '#E1E0E2',
          },
        }}
        InputLabelProps={{
          style: {
            top: '4px',
          },
        }}
        onChange={(event) => setReferral(event.target.value)}
      />
      <TextField
        value={note}
        placeholder="Inserisci una nota"
        label="Note"
        color="secondary"
        size="small"
        style={{ marginBottom: '24px' }}
        inputProps={{
          style: {
            padding: '12px 16px',
            borderColor: '#E1E0E2',
          },
        }}
        InputLabelProps={{
          style: {
            top: '4px',
          },
        }}
        onChange={(event) => setNote(event.target.value)}
      />
      <Typography style={{ marginTop: '12px', fontSize: '15px' }}>
        I campi contrassegnati con * sono obbligatori
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: 'none !important',
    },
    fieldLabel: {
      fontSize: '20px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      marginBottom: '24px !important',
    },
  }),
);
