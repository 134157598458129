/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit } from "../nbp";
// @ts-ignore
import { BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite } from "../nbp";
// @ts-ignore
import { BookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead } from "../nbp";
// @ts-ignore
import { HydratedBookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead } from "../nbp";
/**
 * BookAndCollectReservationApi - axios parameter creator
 * @export
 */
export const BookAndCollectReservationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Retrieves All BookAndCollect Reservations for single Store
     * @param {string} uuid
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [referral]
     * @param {string} [userName]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBookAndCollectReservationCollection: async (
      uuid: string,
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      referral?: string,
      userName?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists(
        "getAllBookAndCollectReservationCollection",
        "uuid",
        uuid,
      );
      const localVarPath =
        `/api/stores/{uuid}/book_and_collect_reservations`.replace(
          `{${"uuid"}}`,
          encodeURIComponent(String(uuid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (orderByField !== undefined) {
        localVarQueryParameter["orderByField"] = orderByField;
      }

      if (orderDirection !== undefined) {
        localVarQueryParameter["orderDirection"] = orderDirection;
      }

      if (productName !== undefined) {
        localVarQueryParameter["productName"] = productName;
      }

      if (productVariant !== undefined) {
        localVarQueryParameter["productVariant"] = productVariant;
      }

      if (productCode !== undefined) {
        localVarQueryParameter["productCode"] = productCode;
      }

      if (productQuantity !== undefined) {
        localVarQueryParameter["productQuantity"] = productQuantity;
      }

      if (createdAt !== undefined) {
        localVarQueryParameter["createdAt"] = createdAt;
      }

      if (updatedAt !== undefined) {
        localVarQueryParameter["updatedAt"] = updatedAt;
      }

      if (referral !== undefined) {
        localVarQueryParameter["referral"] = referral;
      }

      if (userName !== undefined) {
        localVarQueryParameter["userName"] = userName;
      }

      if (userSurname !== undefined) {
        localVarQueryParameter["userSurname"] = userSurname;
      }

      if (userEmail !== undefined) {
        localVarQueryParameter["userEmail"] = userEmail;
      }

      if (userPhone !== undefined) {
        localVarQueryParameter["userPhone"] = userPhone;
      }

      if (reservationStatus !== undefined) {
        localVarQueryParameter["reservationStatus"] = reservationStatus;
      }

      if (reservationCode !== undefined) {
        localVarQueryParameter["reservationCode"] = reservationCode;
      }

      if (outcome !== undefined) {
        localVarQueryParameter["outcome"] = outcome;
      }

      if (reservationNotes !== undefined) {
        localVarQueryParameter["reservationNotes"] = reservationNotes;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves All BookAndCollect Reservations
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [userName]
     * @param {string} [referral]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBookAndCollectReservationCollection: async (
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      userName?: string,
      referral?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/book_and_collect_reservations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (orderByField !== undefined) {
        localVarQueryParameter["orderByField"] = orderByField;
      }

      if (orderDirection !== undefined) {
        localVarQueryParameter["orderDirection"] = orderDirection;
      }

      if (productName !== undefined) {
        localVarQueryParameter["productName"] = productName;
      }

      if (productVariant !== undefined) {
        localVarQueryParameter["productVariant"] = productVariant;
      }

      if (productCode !== undefined) {
        localVarQueryParameter["productCode"] = productCode;
      }

      if (productQuantity !== undefined) {
        localVarQueryParameter["productQuantity"] = productQuantity;
      }

      if (createdAt !== undefined) {
        localVarQueryParameter["createdAt"] = createdAt;
      }

      if (updatedAt !== undefined) {
        localVarQueryParameter["updatedAt"] = updatedAt;
      }

      if (userName !== undefined) {
        localVarQueryParameter["userName"] = userName;
      }

      if (referral !== undefined) {
        localVarQueryParameter["referral"] = referral;
      }

      if (userSurname !== undefined) {
        localVarQueryParameter["userSurname"] = userSurname;
      }

      if (userEmail !== undefined) {
        localVarQueryParameter["userEmail"] = userEmail;
      }

      if (userPhone !== undefined) {
        localVarQueryParameter["userPhone"] = userPhone;
      }

      if (reservationStatus !== undefined) {
        localVarQueryParameter["reservationStatus"] = reservationStatus;
      }

      if (reservationCode !== undefined) {
        localVarQueryParameter["reservationCode"] = reservationCode;
      }

      if (outcome !== undefined) {
        localVarQueryParameter["outcome"] = outcome;
      }

      if (reservationNotes !== undefined) {
        localVarQueryParameter["reservationNotes"] = reservationNotes;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a BookAndCollect Reservation
     * @param {string} id
     * @param {string} [reservationCodeOrUuid] If not inserted or set to \&quot;uuid\&quot; the search will be with uuid. Set to \&quot;reservationCode\&quot; the search will be with reservationCode.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBookAndCollectReservationItem: async (
      id: string,
      reservationCodeOrUuid?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getBookAndCollectReservationItem", "id", id);
      const localVarPath = `/api/book_and_collect_reservations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (reservationCodeOrUuid !== undefined) {
        localVarQueryParameter["reservationCodeOrUuid"] = reservationCodeOrUuid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the BookAndCollectReservation resource.
     * @param {string} id
     * @param {BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit} [bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit] The updated BookAndCollectReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchBookAndCollectReservationItem: async (
      id: string,
      bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit?: BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchBookAndCollectReservationItem", "id", id);
      const localVarPath = `/api/book_and_collect_reservations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a BookAndCollectReservation resource
     * @param {BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite} [bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite] The new BookAndCollectReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBookAndCollectReservationBookAndCollectReservationCollection: async (
      bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite?: BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/book_and_collect_reservations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BookAndCollectReservationApi - functional programming interface
 * @export
 */
export const BookAndCollectReservationApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    BookAndCollectReservationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Retrieves All BookAndCollect Reservations for single Store
     * @param {string} uuid
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [referral]
     * @param {string} [userName]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllBookAndCollectReservationCollection(
      uuid: string,
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      referral?: string,
      userName?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedBookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllBookAndCollectReservationCollection(
          uuid,
          orderByField,
          orderDirection,
          productName,
          productVariant,
          productCode,
          productQuantity,
          createdAt,
          updatedAt,
          referral,
          userName,
          userSurname,
          userEmail,
          userPhone,
          reservationStatus,
          reservationCode,
          outcome,
          reservationNotes,
          status,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves All BookAndCollect Reservations
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [userName]
     * @param {string} [referral]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBookAndCollectReservationCollection(
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      userName?: string,
      referral?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedBookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBookAndCollectReservationCollection(
          orderByField,
          orderDirection,
          productName,
          productVariant,
          productCode,
          productQuantity,
          createdAt,
          updatedAt,
          userName,
          referral,
          userSurname,
          userEmail,
          userPhone,
          reservationStatus,
          reservationCode,
          outcome,
          reservationNotes,
          status,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a BookAndCollect Reservation
     * @param {string} id
     * @param {string} [reservationCodeOrUuid] If not inserted or set to \&quot;uuid\&quot; the search will be with uuid. Set to \&quot;reservationCode\&quot; the search will be with reservationCode.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBookAndCollectReservationItem(
      id: string,
      reservationCodeOrUuid?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBookAndCollectReservationItem(
          id,
          reservationCodeOrUuid,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the BookAndCollectReservation resource.
     * @param {string} id
     * @param {BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit} [bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit] The updated BookAndCollectReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchBookAndCollectReservationItem(
      id: string,
      bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit?: BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchBookAndCollectReservationItem(
          id,
          bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a BookAndCollectReservation resource
     * @param {BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite} [bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite] The new BookAndCollectReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postBookAndCollectReservationBookAndCollectReservationCollection(
      bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite?: BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postBookAndCollectReservationBookAndCollectReservationCollection(
          bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * BookAndCollectReservationApi - factory interface
 * @export
 */
export const BookAndCollectReservationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BookAndCollectReservationApiFp(configuration);
  return {
    /**
     *
     * @summary Retrieves All BookAndCollect Reservations for single Store
     * @param {string} uuid
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [referral]
     * @param {string} [userName]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBookAndCollectReservationCollection(
      uuid: string,
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      referral?: string,
      userName?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedBookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead> {
      return localVarFp
        .getAllBookAndCollectReservationCollection(
          uuid,
          orderByField,
          orderDirection,
          productName,
          productVariant,
          productCode,
          productQuantity,
          createdAt,
          updatedAt,
          referral,
          userName,
          userSurname,
          userEmail,
          userPhone,
          reservationStatus,
          reservationCode,
          outcome,
          reservationNotes,
          status,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves All BookAndCollect Reservations
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [userName]
     * @param {string} [referral]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBookAndCollectReservationCollection(
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      userName?: string,
      referral?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedBookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead> {
      return localVarFp
        .getBookAndCollectReservationCollection(
          orderByField,
          orderDirection,
          productName,
          productVariant,
          productCode,
          productQuantity,
          createdAt,
          updatedAt,
          userName,
          referral,
          userSurname,
          userEmail,
          userPhone,
          reservationStatus,
          reservationCode,
          outcome,
          reservationNotes,
          status,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a BookAndCollect Reservation
     * @param {string} id
     * @param {string} [reservationCodeOrUuid] If not inserted or set to \&quot;uuid\&quot; the search will be with uuid. Set to \&quot;reservationCode\&quot; the search will be with reservationCode.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBookAndCollectReservationItem(
      id: string,
      reservationCodeOrUuid?: string,
      options?: any,
    ): AxiosPromise<BookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead> {
      return localVarFp
        .getBookAndCollectReservationItem(id, reservationCodeOrUuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the BookAndCollectReservation resource.
     * @param {string} id
     * @param {BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit} [bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit] The updated BookAndCollectReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchBookAndCollectReservationItem(
      id: string,
      bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit?: BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit,
      options?: any,
    ): AxiosPromise<BookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead> {
      return localVarFp
        .patchBookAndCollectReservationItem(
          id,
          bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a BookAndCollectReservation resource
     * @param {BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite} [bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite] The new BookAndCollectReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBookAndCollectReservationBookAndCollectReservationCollection(
      bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite?: BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite,
      options?: any,
    ): AxiosPromise<BookAndCollectReservationBookAndCollectReservationOutputBookandcollectreservationRead> {
      return localVarFp
        .postBookAndCollectReservationBookAndCollectReservationCollection(
          bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BookAndCollectReservationApi - object-oriented interface
 * @export
 * @class BookAndCollectReservationApi
 * @extends {BaseAPI}
 */
export class BookAndCollectReservationApi extends BaseAPI {
  /**
   *
   * @summary Retrieves All BookAndCollect Reservations for single Store
   * @param {string} uuid
   * @param {string} [orderByField]
   * @param {string} [orderDirection]
   * @param {string} [productName]
   * @param {string} [productVariant]
   * @param {string} [productCode]
   * @param {number} [productQuantity]
   * @param {string} [createdAt]
   * @param {string} [updatedAt]
   * @param {string} [referral]
   * @param {string} [userName]
   * @param {string} [userSurname]
   * @param {string} [userEmail]
   * @param {string} [userPhone]
   * @param {string} [reservationStatus]
   * @param {string} [reservationCode]
   * @param {string} [outcome]
   * @param {string} [reservationNotes]
   * @param {string} [status]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookAndCollectReservationApi
   */
  public getAllBookAndCollectReservationCollection(
    uuid: string,
    orderByField?: string,
    orderDirection?: string,
    productName?: string,
    productVariant?: string,
    productCode?: string,
    productQuantity?: number,
    createdAt?: string,
    updatedAt?: string,
    referral?: string,
    userName?: string,
    userSurname?: string,
    userEmail?: string,
    userPhone?: string,
    reservationStatus?: string,
    reservationCode?: string,
    outcome?: string,
    reservationNotes?: string,
    status?: string,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return BookAndCollectReservationApiFp(this.configuration)
      .getAllBookAndCollectReservationCollection(
        uuid,
        orderByField,
        orderDirection,
        productName,
        productVariant,
        productCode,
        productQuantity,
        createdAt,
        updatedAt,
        referral,
        userName,
        userSurname,
        userEmail,
        userPhone,
        reservationStatus,
        reservationCode,
        outcome,
        reservationNotes,
        status,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves All BookAndCollect Reservations
   * @param {string} [orderByField]
   * @param {string} [orderDirection]
   * @param {string} [productName]
   * @param {string} [productVariant]
   * @param {string} [productCode]
   * @param {number} [productQuantity]
   * @param {string} [createdAt]
   * @param {string} [updatedAt]
   * @param {string} [userName]
   * @param {string} [referral]
   * @param {string} [userSurname]
   * @param {string} [userEmail]
   * @param {string} [userPhone]
   * @param {string} [reservationStatus]
   * @param {string} [reservationCode]
   * @param {string} [outcome]
   * @param {string} [reservationNotes]
   * @param {string} [status]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookAndCollectReservationApi
   */
  public getBookAndCollectReservationCollection(
    orderByField?: string,
    orderDirection?: string,
    productName?: string,
    productVariant?: string,
    productCode?: string,
    productQuantity?: number,
    createdAt?: string,
    updatedAt?: string,
    userName?: string,
    referral?: string,
    userSurname?: string,
    userEmail?: string,
    userPhone?: string,
    reservationStatus?: string,
    reservationCode?: string,
    outcome?: string,
    reservationNotes?: string,
    status?: string,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return BookAndCollectReservationApiFp(this.configuration)
      .getBookAndCollectReservationCollection(
        orderByField,
        orderDirection,
        productName,
        productVariant,
        productCode,
        productQuantity,
        createdAt,
        updatedAt,
        userName,
        referral,
        userSurname,
        userEmail,
        userPhone,
        reservationStatus,
        reservationCode,
        outcome,
        reservationNotes,
        status,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a BookAndCollect Reservation
   * @param {string} id
   * @param {string} [reservationCodeOrUuid] If not inserted or set to \&quot;uuid\&quot; the search will be with uuid. Set to \&quot;reservationCode\&quot; the search will be with reservationCode.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookAndCollectReservationApi
   */
  public getBookAndCollectReservationItem(
    id: string,
    reservationCodeOrUuid?: string,
    options?: any,
  ) {
    return BookAndCollectReservationApiFp(this.configuration)
      .getBookAndCollectReservationItem(id, reservationCodeOrUuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the BookAndCollectReservation resource.
   * @param {string} id
   * @param {BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit} [bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit] The updated BookAndCollectReservation resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookAndCollectReservationApi
   */
  public patchBookAndCollectReservationItem(
    id: string,
    bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit?: BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit,
    options?: any,
  ) {
    return BookAndCollectReservationApiFp(this.configuration)
      .patchBookAndCollectReservationItem(
        id,
        bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationEdit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a BookAndCollectReservation resource
   * @param {BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite} [bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite] The new BookAndCollectReservation resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BookAndCollectReservationApi
   */
  public postBookAndCollectReservationBookAndCollectReservationCollection(
    bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite?: BookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite,
    options?: any,
  ) {
    return BookAndCollectReservationApiFp(this.configuration)
      .postBookAndCollectReservationBookAndCollectReservationCollection(
        bookAndCollectReservationBookAndCollectReservationInputBookandcollectreservationWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
