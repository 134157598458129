import { Box, IconButton, TableCell, TableRow, Theme } from '@mui/material';
import React, { useState } from 'react';
import ActionDialog from '../../dialog/ActionDialog';
import EditServiceModal from '../details/modals/EditServiceModal';
import { Service } from '../../../api/entities';
import { createStyles, makeStyles } from '@mui/styles';
import { EditIcon } from '../../icons/EditIcon';

interface ServiceRowProps {
  row: Service;
  labelId: string;
  onClickRow: (service: Service) => void;
}

export default function ServiceTableRow(props: ServiceRowProps) {
  const classes = useStyles();
  const row = props.row;
  const [openDeleteDialog, setOpenDeletDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const onClickRow = (event: React.MouseEvent<unknown>) => {
    props.onClickRow(row);
  };

  const onEdit = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    setOpenEditModal(true);
  };

  const RowActions = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <IconButton onClick={(e) => onEdit(e)}>
          <EditIcon color="#00D1D2" />
        </IconButton>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TableRow
        hover={false}
        key={row.id}
        onClick={(e) => onClickRow(e)}
        className={classes.row}
      >
        <TableCell className={classes.cellLabel} align="left">
          {row.publicName}
        </TableCell>
        <TableCell className={classes.cellLabel} align="left">
          {row.createdAt?.formatted()}
        </TableCell>
        <TableCell className={classes.cellLabel} align="left">
          {row.updatedAt?.formatted()}
        </TableCell>
        <TableCell className={classes.cellLabel} align="right">
          <RowActions />
        </TableCell>
      </TableRow>
      <ActionDialog
        title="Elimina servizio"
        message="Sei sicuro di voler eliminare questo servizio?"
        onAction={() => {}}
        open={openDeleteDialog}
        onClose={() => setOpenDeletDialog(false)}
        actionTitle="Elimina"
      />
      <EditServiceModal
        service={row}
        isVisible={openEditModal}
        onClose={() => setOpenEditModal(false)}
      />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cellLabel: {
      color: '#34303D !important',
      fontSize: '14px !important',
      padding: '8px 16px !important',
      borderColor: '#E1E0E2',
    },
    row: {
      backgroundColor: 'white',
      cursor: 'pointer',
      '&&:hover': {
        backgroundColor: '#F9F9F9',
      },
    },
  }),
);
