import { SvgIcon } from '@mui/material';
import { useIconColor } from './IconColor';
import React from 'react';
import { IconProps } from './iconProps';

export const EditIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="-2 -2 21 21"
        fill="none"
      >
        <path
          d="M9.68652 3.2926L12.7077 6.31383"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 11.2235L10.4413 1.78214C11.4842 0.739285 13.175 0.739285 14.2179 1.78214C15.2607 2.82501 15.2607 4.51582 14.2179 5.55868L4.77653 15L1 15L1 11.2235Z"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
