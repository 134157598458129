import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { Theme } from '@fullcalendar/core/internal';

const font = "'IQOS Sans'";

export const colors = {
  ocker: '#D6A76F',
  yellow: '#F8F4BC',
  lightBlue: '#BCE9F8',
};

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

export const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: '#34303D' }, //iqos black
      secondary: { main: '#00D1D2' },
      mode: 'light',
    },
    typography: {
      fontFamily: font,
      button: { textTransform: 'none' },
    },
    overrides: {
      MuiStepIcon: {
        root: {
          '&$completed': {
            color: '#00D1D2',
          },
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: `1px solid  rgba(166,166,166,0.5)`,
          },
          '&:after': {
            borderBottom: `2px solid  #00D1D2`,
          },
          '&&&&:hover:before': {
            borderBottom: `1px solid  #00D1D2`,
          },
        },
      },
    },
  }),
);
