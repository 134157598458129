import React from 'react';
import { connect } from 'react-redux';
import { Box, Button, Grid, Typography } from '@mui/material';
import './DetailAppointment.scss';
import AppointmentActions from '../../components/dashboard/AppointmentActions';
import { Appointment } from '../../api/entities';
import SnackBarActions, {
  SnackBarType,
} from '../../redux/actions/UI/SnackBarActions';
import { AppointmentsActions } from '../../redux/actions';
import { UserRoles } from '../../utils/UserRoles';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { ArrowLeftIcon } from '../../components/icons/ArrowLeftIcon';

interface DetailAppointmentProps {
  appointmentDetail?: Appointment;
  deleteAppointmentSuccess: any;
  deleteAppointmentError: boolean;
  deleteAppointmentLoading: boolean;
  deleteAppointment: (id: string, notifEnabled: boolean) => void;
  singleAppointmentLoading: boolean;
  patchAppointmentLoading: boolean;
  getSingleAppointment: (id: string) => void;
  currentRole: any;
}

type LegacyDetailServiceProps = DetailAppointmentProps & {
  navigate: NavigateFunction;
  location: Location;
};

interface DetailAppointmentState {
  event: Appointment;
}

export function DetailAppointment(props: DetailAppointmentProps) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <LegacyDetailAppointment
      {...props}
      navigate={navigate}
      location={location}
    />
  );
}
class LegacyDetailAppointment extends React.Component<
  LegacyDetailServiceProps,
  DetailAppointmentState
> {
  constructor(props: LegacyDetailServiceProps) {
    super(props);
    this.state = {
      event: this.props.location.state as Appointment,
    };
  }
  componentDidMount() {
    let appointmentID = this.props.location.pathname.getIdFromPath();
    if (appointmentID) {
      this.props.getSingleAppointment(appointmentID);
    }
  }
  componentDidUpdate(prevProps: DetailAppointmentProps) {
    if (
      prevProps.deleteAppointmentLoading &&
      this.props.deleteAppointmentSuccess
    ) {
      this.handleBack();
    }
  }

  handleBack = () => {
    this.props.navigate('/dashboard');
  };

  isLoading = () => {
    return (
      this.props.singleAppointmentLoading || this.props.patchAppointmentLoading
    );
  };

  renderKeyLabel = (value: string) => {
    return <Typography className="key-label">{value}</Typography>;
  };

  renderLabel = (value: string | undefined) => {
    return this.isLoading() ? (
      <Skeleton variant="text" width={160} />
    ) : (
      <Typography className="value-label">{value}</Typography>
    );
  };

  serviceColor = () => {
    const service: string | undefined =
      this.props.appointmentDetail?.storeService?.externalName;
    if (service === 'Assistenza') return '#BCE9F8';
    if (service === 'Acquisto') return '#F8F4BC';
    if (service === 'Videocall') return '#00D1D2';
    return '#90ee90';
  };

  render() {
    const { appointmentDetail } = this.props;
    let appointmentClient = appointmentDetail?.clients
      ? appointmentDetail?.clients[0]
      : undefined;
    return (
      <Box display="flex" className="main-container">
        <Grid className="grid-action-container">
          <Button
            disableElevation
            className="back-button"
            onClick={() => this.handleBack()}
            startIcon={<ArrowLeftIcon color="#34303D" />}
          >
            Torna Indietro
          </Button>
          <AppointmentActions
            appointment={this.state.event}
            onDeleteAppointment={(id, notifEnabled) => {
              this.props.deleteAppointment(id, notifEnabled);
            }}
          />
        </Grid>
        <Grid className="grid-info-container">
          <Box display="flex" alignItems="center" className="status-box">
            <div className="title">
              Appuntamento con&nbsp;
              <span className="client">
                {appointmentClient?.name + ' ' + appointmentClient?.surname}
              </span>
            </div>
            <Box
              className="serviceBox"
              style={{
                backgroundColor: this.serviceColor(),
              }}
            >
              <Typography className="service">
                {appointmentDetail?.storeService?.externalName}
              </Typography>
            </Box>
          </Box>
          <Box
            flexDirection="column"
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            className="key-value-box"
          >
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              className="item-box"
            >
              {this.renderKeyLabel('Data:')}
              {this.renderLabel(appointmentDetail?.startTime?.formatted())}
            </Box>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              className="item-box"
            >
              {this.renderKeyLabel('Ora:')}
              {this.renderLabel(
                appointmentDetail?.startTime?.getFormatedTime(),
              )}
            </Box>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              className="item-box"
            >
              {this.renderKeyLabel('Nome:')}
              {this.renderLabel(
                appointmentClient ? appointmentClient.name : '',
              )}
            </Box>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              className="item-box"
            >
              {this.renderKeyLabel('Cognome:')}
              {this.renderLabel(
                appointmentClient ? appointmentClient.surname : '',
              )}
            </Box>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              className="item-box"
            >
              {this.renderKeyLabel('Email:')}
              {this.renderLabel(
                appointmentClient ? appointmentClient.email : '',
              )}
            </Box>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              className="item-box"
            >
              {this.renderKeyLabel('Telefono:')}
              {this.renderLabel(
                appointmentClient ? appointmentClient.phoneNumber : '',
              )}
            </Box>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              className="item-box"
            >
              {this.renderKeyLabel('Referral:')}
              {this.renderLabel(
                appointmentDetail ? appointmentDetail.referral : '/',
              )}
            </Box>
            <Box
              display="flex"
              className="item-box"
              width="100%"
              alignItems={'center'}
            >
              {this.renderKeyLabel('Nota:')}
              <Typography className={'value-label'}>
                {appointmentDetail ? appointmentDetail.notes : ''}
              </Typography>
            </Box>
            {this.props.currentRole === UserRoles.ADMIN && (
              <Box
                display="flex"
                width="100%"
                className="item-box"
                justifyContent="space-between"
              >
                {this.renderKeyLabel('CRM ClientType:')}
                {this.renderLabel(
                  appointmentClient ? appointmentClient.clientType : '',
                )}
              </Box>
            )}
            {this.props.currentRole === UserRoles.ADMIN && (
              <Box
                display="flex"
                width="100%"
                className="item-box"
                justifyContent="space-between"
              >
                {this.renderKeyLabel('CRM ClientID:')}
                {this.renderLabel(
                  appointmentClient ? appointmentClient.crmClientId : '',
                )}
              </Box>
            )}
            {this.props.currentRole === UserRoles.ADMIN && (
              <Box
                display="flex"
                width="100%"
                className="item-box"
                justifyContent="space-between"
              >
                {this.renderKeyLabel('CRM Sync Response:')}
                {this.renderLabel(
                  appointmentDetail?.crmReservation
                    ? appointmentDetail.crmReservation.response
                    : '',
                )}
              </Box>
            )}
            {this.props.currentRole === UserRoles.ADMIN && (
              <Box
                display="flex"
                width="100%"
                className="item-box"
                justifyContent="space-between"
              >
                {this.renderKeyLabel('CRM Task ID:')}
                {this.renderLabel(
                  appointmentDetail?.crmReservation
                    ? appointmentDetail.crmReservation.crmTaskId
                    : '',
                )}
              </Box>
            )}
            {this.props.currentRole === UserRoles.ADMIN && (
              <Box
                display="flex"
                width="100%"
                className="item-box"
                justifyContent="space-between"
              >
                {this.renderKeyLabel('CRM Sync Status:')}
                {this.renderLabel(
                  appointmentDetail?.crmReservation
                    ? appointmentDetail.crmReservation.status
                    : '',
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    appointmentDetail: state.AppointmentsReducer.appointmentDetail,
    deleteAppointmentSuccess:
      state.AppointmentsReducer.deleteAppointmentSuccess,
    deleteAppointmentError: state.AppointmentsReducer.deleteAppointmentError,
    deleteAppointmentLoading:
      state.AppointmentsReducer.deleteAppointmentLoading,
    singleAppointmentLoading:
      state.AppointmentsReducer.singleAppointmentLoading,
    patchAppointmentLoading: state.AppointmentsReducer.patchAppointmentLoading,
    currentRole: state.UsersReducer.currentRole,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showSnackBar: (message: string, type: SnackBarType) =>
      dispatch(SnackBarActions.snackbar(message, type)),
    deleteAppointment: (id: string, notifEnabled: boolean) =>
      dispatch(AppointmentsActions.deleteAppointment(id, notifEnabled)),
    getSingleAppointment: (id: string) =>
      dispatch(AppointmentsActions.getSingleAppointment(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailAppointment);
