import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { Collapse, Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import RowOptionsButton from './RowOptionsButton';
import { RootState } from '../../../redux/reducers';
import ActionDialog from '../../dialog/ActionDialog';
import '../../../utils/extensions';
import { Store } from '../../../api/entities';
import _ from 'lodash';
import { StoreActions } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import { ChevronUpIcon } from '../../icons/ChevronUpIcon';

interface StoreRowProps {
  row: Store;
  labelId: string;
  isItemSelected: boolean;
  onCheckRow: (row: Store) => void;
  onClickRow: (row: Store) => void;
  onArchiveStore: () => void;
}

function RowCollapse(props: { data: any }) {
  const classes = useStyles();
  const timePeriod = props.data;
  return (
    <Grid className={classes.collapsedBoxItem}>
      <Typography className={classes.dayLabel}>
        {timePeriod.openDay.itDay()}
      </Typography>
      <Typography className={classes.hoursLabel}>
        {timePeriod.isClosed ? 'Chiuso' : timePeriod.times.join(' - ')}
      </Typography>
    </Grid>
  );
}

export default function StoreRow(props: StoreRowProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const row = props.row;
  const labelId = props.labelId;
  const [open, setOpen] = useState(false);
  const isItemSelected = props.isItemSelected;
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openActiveStoreDilaog, setOpenActiveStoreDialog] = useState(false);
  const isLoading = useSelector(
    (state: RootState) => state.StoreReducer.loadingStores,
  );
  const currentBusinessHours = props.row.businessHours
    ? props.row.businessHours[0]
    : undefined;
  const timePeriods = currentBusinessHours
    ? currentBusinessHours.timePeriods
      ? currentBusinessHours.timePeriods
      : []
    : [];

  const onCheckRow = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    props.onCheckRow(row);
  };

  const onClickRow = (event: React.MouseEvent<unknown>) => {
    props.onClickRow(row);
  };

  const collapseRow = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const formatTimePeriods = (timePeriods: any) => {
    const newPeriods: any = [];
    const groupedTimePeriods = _.groupBy(timePeriods, 'openDay');
    _.forOwn(groupedTimePeriods, (value, key) => {
      newPeriods.push({
        openDay: key,
        times: value.map((object) => object.openTime + '-' + object.closeTime),
        isClosed: value.map((object) => object.isClosed)[0],
      });
    });
    return newPeriods;
  };

  const onPressOption = (index: number) => {
    switch (index) {
      case 0:
        props.onClickRow(row);
        break;
      case 1:
        dispatch(StoreActions.setCurrentSelectedStore(row));
        let pathname = '/dashboard';
        navigate(pathname);
        break;
      case 2:
        row.status === 'ARCHIVED'
          ? setOpenActiveStoreDialog(true)
          : setOpenArchiveDialog(true);
    }
  };

  const rowTextSkeleton = () => {
    return (
      <Skeleton
        className={classes.skeleton}
        variant="text"
        width="100%"
        height={25}
      />
    );
  };

  const archiveStore = () => {
    if (row.uuid) {
      dispatch(StoreActions.archiveStore(row.uuid, { status: 'ARCHIVED' }));
      props.onArchiveStore();
    }
    setOpenArchiveDialog(false);
  };

  const activateStore = () => {
    if (row.uuid) {
      dispatch(StoreActions.archiveStore(row.uuid, { status: null }));
      props.onArchiveStore();
    }
    setOpenActiveStoreDialog(false);
  };

  return (
    <>
      <TableRow
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        onClick={(e) => onClickRow(e)}
        selected={isItemSelected}
        className={classes.row}
      >
        <TableCell padding="checkbox">
          <Checkbox
            disabled={isLoading}
            checked={isItemSelected}
            color="secondary"
            style={{ marginLeft: 8 }}
            onClick={(e) => onCheckRow(e)}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell className={classes.cellLabel} align="left">
          {isLoading ? rowTextSkeleton() : row.name}
        </TableCell>
        <TableCell className={classes.cellLabel} align="left">
          {isLoading ? rowTextSkeleton() : row.address}
        </TableCell>
        <TableCell className={classes.cellLabel} align="left">
          {isLoading ? rowTextSkeleton() : row.region}
        </TableCell>
        <TableCell className={classes.cellLabel} align="left">
          {isLoading ? rowTextSkeleton() : row.relationsUpdatedAt?.formatted()}
        </TableCell>

        <TableCell align="left" padding="none">
          {isLoading ? (
            rowTextSkeleton()
          ) : (
            <RowOptionsButton
              onSelectAction={(index) => onPressOption(index)}
              storeStatus={row.status ? row.status : ''}
            />
          )}
        </TableCell>
        <TableCell className={classes.cellLabel} align="right">
          {isLoading ? (
            rowTextSkeleton()
          ) : (
            <IconButton
              className={classes.collapseButton}
              onClick={(e) => collapseRow(e)}
            >
              {!open ? (
                <ChevronDownIcon color="#34303D" />
              ) : (
                <ChevronUpIcon color="#34303D" />
              )}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow key={row.id + '_collapse'}>
        <TableCell
          colSpan={7}
          align="center"
          style={{
            padding: 0,
            borderBottom: 'none',
            backgroundColor: '#f3f3f3',
            width: '100%',
          }}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className={classes.collapse}
          >
            <Box
              display="flex"
              className={classes.collapsedBox}
              alignItems="center"
            >
              {formatTimePeriods(timePeriods).map((data: any, i: number) => {
                return <RowCollapse data={data} key={i} />;
              })}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ActionDialog
        open={openArchiveDialog}
        onClose={() => setOpenArchiveDialog(false)}
        title="Archivia Store"
        message="Sei sicuro di voler archiviare questo store?"
        actionTitle="Archivia"
        onAction={() => archiveStore()}
      />
      <ActionDialog
        open={openActiveStoreDilaog}
        onClose={() => setOpenActiveStoreDialog(false)}
        title="Ripristina Store"
        message="Sei sicuro di voler ripristinare questo store?"
        actionTitle="Ripristina"
        onAction={() => activateStore()}
      />
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skeleton: {
      margin: '7.5px 0 !important',
    },
    row: {
      backgroundColor: 'white',
      cursor: 'pointer',
      '&&:hover': {
        backgroundColor: '#F9F9F9',
      },
    },
    cellLabel: {
      color: '#34303D !important',
      fontSize: '14px !important',
      padding: '8px 16px !important',
      borderColor: '#E1E0E2',
    },
    dayLabel: {
      color: '#7D7982',
      fontSize: '12px !important',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '12px !important',
      marginBottom: '10px !important',
    },
    hoursLabel: {
      color: '#1B1C1E',
      fontSize: '14px !important',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '18px !important',
    },
    collapseButton: {
      padding: '8px !important',
    },
    collapse: {
      width: '100%',
      flexDirection: 'row',
    },
    collapsedBox: {
      height: 100,
      backgroundColor: '#f3f3f3',
      paddingLeft: 20,
      paddingRight: 20,
    },
    collapsedBoxItem: {
      padding: 8,
      width: '100%',
    },
  }),
);
