import { createAction } from "@reduxjs/toolkit";
import { TokenApi, ForgotPasswordApi } from "../../api/services";
import { Credentials, Token } from "../../api/services/nbp";
import { AppDispatch } from "../store";
import { AxiosResponse } from "axios";
import SnackBarActions from "./UI/SnackBarActions";

let tokenApi = new TokenApi();
let forgoPassApi = new ForgotPasswordApi();

const authStatus = createAction("auth/authStatus");
const setLoginAction = createAction<boolean>("auth/setLogin");
const requestPasswordProgress = createAction("auth/requestPasswordProgress");
const requestPasswordSuccess = createAction("auth/requestPasswordSuccess");
const requestPasswordError = createAction("auth/requestPasswordError");

const setNewPasswordProgress = createAction("auth/newPasswordProgress");
const setNewPasswordSuccess = createAction("auth/setNewPasswordSuccess");
const setNewPasswordError = createAction("auth/setNewPasswordError");

const setLogin = (isLoggedIn: boolean) => (dispatch: AppDispatch) => {
  dispatch(setLoginAction(isLoggedIn));
};

const loginActionSuccess = createAction<Token>("auth/loginSuccess");
const loginActionLoading = createAction("auth/loginLoading");
const loginActionError = createAction<any>("auth/loginError");

const login = (credentials: Credentials) => (dispatch: AppDispatch) => {
  dispatch(loginActionLoading());
  tokenApi
    .postCredentialsItem(credentials)
    .then((response: AxiosResponse<Token>) => {
      dispatch(loginActionSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(loginActionError(error));
      dispatch(
        SnackBarActions.snackbar(
          error.response.data["hydra:description"]
            ? error.response.data["hydra:description"]
            : "Wrong credentials",
          "error",
        ),
      );
    });
};

const requestNewPassword = (email: string) => (dispatch: AppDispatch) => {
  dispatch(requestPasswordProgress());
  let request = { email };
  forgoPassApi
    .postForgotPassword(request)
    .then((response: AxiosResponse<any>) => {
      dispatch(requestPasswordSuccess());
    })
    .catch((error: any) => {
      dispatch(requestPasswordError());
    });
};

const setNewPassword =
  (token: string, password: string) => (dispatch: AppDispatch) => {
    dispatch(setNewPasswordProgress());
    let request = { password };
    forgoPassApi
      .postForgotPasswordToken(token, request)
      .then((response: AxiosResponse<any>) => {
        dispatch(setNewPasswordSuccess());
      })
      .catch((error: any) => {
        dispatch(setNewPasswordError());
      });
  };

export default {
  setLogin,
  authStatus,
  setLoginAction,
  login,
  loginActionSuccess,
  loginActionLoading,
  loginActionError,
  requestNewPassword,
  requestPasswordError,
  requestPasswordProgress,
  requestPasswordSuccess,
  setNewPassword,
  setNewPasswordProgress,
  setNewPasswordError,
  setNewPasswordSuccess,
};
