import React from 'react';
import { SvgIcon } from '@mui/material';
import { useIconColor } from './IconColor';
import { IconProps } from './iconProps';

export const ComputerIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg viewBox="0 0 24 24" aria-hidden="true" fill="none">
        <path
          fill={iconColor}
          d="M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z"
        ></path>
      </svg>
    </SvgIcon>
  );
};
