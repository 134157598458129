import { Box, Theme } from '@mui/material';
import React, { useState } from 'react';
import TimePeriodRowHours from './TimePeriodRowHours';
import TimePeriodRowInfo from './TimePeriodRowInfo';
import { createStyles, makeStyles } from '@mui/styles';

interface TimePeriodRowProps {
  item: any;
}

export default function TimePeriodRow(props: TimePeriodRowProps) {
  const classes = useStyles();
  const [open] = useState(!props.item.isClosed);

  const item = props.item;

  return (
    <Box
      className={classes.row}
      justifyContent="flex-start"
      alignItems={item.times.length > 1 ? 'flex-start' : 'center'}
      display="flex"
    >
      <TimePeriodRowInfo item={item} />
      <TimePeriodRowHours item={item} hide={open} />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      borderTop: '1px solid #E1E0E2',
      paddingTop: 24,
      paddingBottom: 24,
    },
  }),
);
