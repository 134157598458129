import React, { useCallback, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Autocomplete, Box } from '@mui/material';

const durations = [
  {
    value: '0',
    label: 'Nessuna durata',
  },
  {
    value: '5',
    label: '5 min',
  },
  {
    value: '10',
    label: '10 min',
  },
  {
    value: '15',
    label: '15 min',
  },
  {
    value: '20',
    label: '20 min',
  },
  {
    value: '25',
    label: '25 min',
  },
  {
    value: '30',
    label: '30 min',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      width: '100%',
    },
    box: {
      width: '100%',
      marginBottom: '24px',
    },
  }),
);

interface DurationTextFieldProps {
  onSelectValue: (value: string) => void;
  defaultValue?: string;
}

export default function DurationTextField({
  onSelectValue,
  defaultValue,
}: DurationTextFieldProps) {
  const classes = useStyles();
  const [duration, setDuration] = useState<string>(defaultValue || '0');

  const handleChange = (durarion: string | null) => {
    onSelectValue(durarion || '0');
    setDuration(durarion || '0');
  };

  function getLabel(value: string): string {
    return (
      durations.find((duration) => duration.value === value)?.label ||
      'Nessuna durata'
    );
  }

  return (
    <Box className={classes.box}>
      <Autocomplete
        noOptionsText="Nessun risultato"
        fullWidth
        id="standard-select-duration"
        value={duration || null}
        color="secondary"
        size="small"
        options={durations || []}
        onChange={(e: any, option: any) => handleChange(option?.value || null)}
        isOptionEqualToValue={(option: any, value: any) =>
          option.value === value
        }
        getOptionLabel={(value) => getLabel(value)}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            color="secondary"
            label="Seleziona Durata"
            size="small"
            inputProps={{
              ...params.inputProps,
              style: {
                padding: '6px 10px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
          />
        )}
        renderOption={(props: any, option: any) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
      />
    </Box>
  );
}
