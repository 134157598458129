import { SvgIcon } from '@mui/material';
import { useIconColor } from './IconColor';
import React from 'react';
import { IconProps } from './iconProps';

export const NotificationIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="18"
        viewBox="-2 0 22 18"
        fill="none"
      >
        <path
          d="M5.70801 14V14.709C5.70801 15.974 6.73401 17 8.00001 17C9.26601 17 10.292 15.974 10.292 14.708V14"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13 6.43684V6.40226V6.40226C13 3.41913 10.761 1 8 1V1C5.239 1 3 3.41913 3 6.40226V6.40226V6.43684V9.10771C3 9.45778 2.817 9.77651 2.528 9.93318L2.025 10.2044C1.397 10.5447 1 11.2384 1 11.9968V11.9968C1 13.1032 1.83 14 2.854 14H13.146C14.17 14 15 13.1032 15 11.9968V11.9968C15 11.2384 14.603 10.5447 13.975 10.2055L13.472 9.93426C13.183 9.77651 13 9.45778 13 9.10771V6.43684Z"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
