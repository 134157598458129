import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import Icons from '../../../theme/Icons';
import ServiceTypeLabel from './ServiceProductLabel';
import EditServiceModal from './modals/EditServiceModal';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { EditIcon } from '../../icons/EditIcon';

export default function ServiceInfoCard() {
  const classes = useStyles();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const serviceDetail = useSelector(
    (state: RootState) => state.ServiceReducer.serviceDetail,
  );

  const handleOpenEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };

  return (
    <Box className={classes.infoCard}>
      <Box
        className={classes.titleContainer}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>Informazioni</Typography>
        <IconButton onClick={() => handleOpenEditModal()}>
          <EditIcon color="#00D1D2" />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" className={classes.content}>
        <Typography className={classes.contentLabel}>Nome:</Typography>
        <Typography className={classes.contentValue}>
          {serviceDetail?.publicName}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" className={classes.content}>
        <Typography className={classes.contentLabel}>Icona:</Typography>
        <Typography className={classes.contentValue}>
          <img className={classes.img} src={Icons.Services} />
        </Typography>
      </Box>

      <Typography className={classes.servicesTitle}>
        Servizi previsti
      </Typography>

      <Box
        display="flex"
        gap={'8px'}
        alignItems="center"
        className={classes.servicesContainer}
      >
        <ServiceTypeLabel type="IQOS" />
        <ServiceTypeLabel type="VEEV" />
      </Box>

      <EditServiceModal
        service={serviceDetail}
        isVisible={editModalOpen}
        onClose={() => handleOpenEditModal()}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      marginBottom: '32px !important',
    },
    title: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
    },
    img: {
      marginTop: 4,
    },
    servicesTitle: {
      fontSize: '18px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '18px !important',
      marginTop: '32px !important',
    },
    servicesContainer: {
      marginTop: '16px !important',
    },
    infoCard: {
      padding: '32px 24px 24px 24px',
      width: '500px',
      maxWidth: '35% !important',
    },
    content: {
      padding: '16px 0',
      borderBottom: '1px solid #E1E0E2',
    },
    contentLabel: {
      color: '#34303D',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      minWidth: '150px',
    },
    contentValue: {
      color: '#34303D',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '700 !important',
      lineHeight: '14px !important',
    },
  }),
);
