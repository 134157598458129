import React from 'react';
import { SvgIcon } from '@mui/material';
import { useIconColor } from './IconColor';
import { IconProps } from './iconProps';

export const ArrowRightIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 12H20"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M15 7L20 12.0547L15 17"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
