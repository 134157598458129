import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../../store";

export type SnackBarType = "success" | "error" | "warning" | "info";

export interface SnackBarProps {
  type: SnackBarType;
  message: string;
}

const showSnackbar = createAction<SnackBarProps>("ui/openSnackBar");
const clearSnackBar = createAction("ui/clearSnackBar");

const snackbar =
  (message: string, type: SnackBarType) => (dispatch: AppDispatch) => {
    const payload = { message, type };
    dispatch(showSnackbar(payload));
  };

const clearSnacks = () => (dispatch: AppDispatch) => {
  dispatch(clearSnackBar());
};

export default {
  showSnackbar,
  clearSnackBar,
  snackbar,
  clearSnacks,
};
