import { Box, IconButton, TableCell, TableRow, Theme } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UsersActions } from '../../../redux/actions';
import { RootState } from '../../../redux/reducers';
import ActionDialog from '../../dialog/ActionDialog';
import UserRoleLabel from './UserRoleLabel';
import { EditIcon } from '../../icons/EditIcon';
import UserEditInfoModal from '../profile/UserEditModal';
import { User } from '../../../api/entities';
import Skeleton from '@mui/material/Skeleton';
import { createStyles, makeStyles } from '@mui/styles';
import { DeleteIcon } from '../../icons/DeleteIcon';
interface UsersRowProps {
  row: User;
  labelId: string;
  onClickRow: (user: User) => void;
}

export default function UsersTableRow(props: UsersRowProps) {
  const classes = useStyles();
  const row = props.row;
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeletDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const isLoading = useSelector(
    (state: RootState) => state.UsersReducer.usersLoading,
  );
  const isSearching = useSelector(
    (state: RootState) => state.UsersReducer.searchUserLoading,
  );
  const onClickRow = (event: React.MouseEvent<unknown>) => {
    props.onClickRow(row);
  };
  const onDelete = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    setOpenDeletDialog(true);
  };

  const onEdit = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    setOpenEditModal(true);
  };

  const handleDeleteUser = () => {
    setOpenDeletDialog(false);
    if (row.uuid) {
      dispatch(UsersActions.deleteUser(row.uuid));
    }
  };

  const RowActions = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <IconButton onClick={(e) => onEdit(e)}>
          <EditIcon color="#00d1d2" />
        </IconButton>
        <IconButton onClick={(e) => onDelete(e)}>
          <DeleteIcon color="#00d1d2" />
        </IconButton>
      </Box>
    );
  };

  const rowTextSkeleton = () => {
    return (
      <Skeleton
        className={classes.skeleton}
        variant="text"
        width="100%"
        height={25}
      />
    );
  };

  const rowStore =
    row.stores && row.stores?.length > 1
      ? 'Gestire più negozi'
      : row.stores && row.stores.length
      ? row.stores[0].name
      : '';

  return (
    <React.Fragment>
      <TableRow
        hover={false}
        key={row.username}
        onClick={(e) => onClickRow(e)}
        className={classes.row}
      >
        <TableCell className={classes.cellLabel} align="left">
          {isLoading || isSearching ? rowTextSkeleton() : row.username}
        </TableCell>
        <TableCell className={classes.cellLabel} align="left">
          {isLoading || isSearching ? rowTextSkeleton() : row.email}
        </TableCell>
        <TableCell className={classes.cellLabel} align="left">
          {isLoading || isSearching ? rowTextSkeleton() : rowStore}
        </TableCell>
        <TableCell className={classes.cellLabel} align="left">
          {isLoading || isSearching ? (
            rowTextSkeleton()
          ) : (
            <UserRoleLabel role={row.businessRole} />
          )}
        </TableCell>
        <TableCell className={classes.cellLabel} align="right">
          {isLoading || isSearching ? rowTextSkeleton() : <RowActions />}
        </TableCell>
      </TableRow>
      <ActionDialog
        title="Elimina utente"
        message="Sei sicuro di voler eliminare questo utente?"
        onAction={() => handleDeleteUser()}
        open={openDeleteDialog}
        onClose={() => setOpenDeletDialog(false)}
        actionTitle="Elimina"
      />
      <UserEditInfoModal
        user={row}
        isVisible={openEditModal}
        onClose={() => setOpenEditModal(false)}
      />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skeleton: {
      margin: '7.5px 0 !important',
    },
    cellLabel: {
      color: '#34303D !important',
      fontSize: '14px !important',
      padding: '8px 16px !important',
      borderColor: '#E1E0E2',
    },
    row: {
      backgroundColor: 'white',
      cursor: 'pointer',
      '&&:hover': {
        backgroundColor: '#F9F9F9',
      },
    },
  }),
);
