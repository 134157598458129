import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { StoreServiceActions } from "../actions";
import { HydratedTimeSlots } from "../../api/entities";

export type TimeSlotsState = {
  hydratedTimeSlots?: HydratedTimeSlots;
  getTimeSlotsError: boolean;
  getTimeSlotsLoading: boolean;
};

const INITIAL_BUSINESS_HOURS_STATE: TimeSlotsState = {
  getTimeSlotsError: false,
  getTimeSlotsLoading: false,
  hydratedTimeSlots: undefined,
};

const StoreServiceReducer = createReducer(
  INITIAL_BUSINESS_HOURS_STATE,
  (builder) =>
    builder

      //get time slots
      .addCase(
        StoreServiceActions.getTimeSlotsSuccess,
        (state: TimeSlotsState, action: PayloadAction<HydratedTimeSlots>) => {
          let hydratedTimeSlots = action.payload;
          return {
            ...state,
            hydratedTimeSlots,
            getTimeSlotsError: false,
            getTimeSlotsLoading: false,
          };
        },
      )
      .addCase(
        StoreServiceActions.getTimeSlotsError,
        (state: TimeSlotsState, action: PayloadAction<any>) => {
          let getTimeSlotsError = action.payload;
          return {
            ...state,
            getTimeSlotsError,
            getTimeSlotsLoading: false,
            hydratedTimeSlots: undefined,
          };
        },
      )
      .addCase(
        StoreServiceActions.getTimeSlotsLoading,
        (state: TimeSlotsState) => {
          return {
            ...state,
            getTimeSlotsError: false,
            getTimeSlotsLoading: true,
          };
        },
      ),
);

export default StoreServiceReducer;
