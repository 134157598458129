import React, { useCallback, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ClickAndLending } from '../../api/entities';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ClickAndLendingActions } from '../../redux/actions';
import { RootState } from '../../redux/reducers';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { rowsPerPageOptions } from '../../redux/reducers/ClickAndLendingReducer';
import ClickAndLendingRow from './ClickAndLendingRow';
import ClickAndLendingTableToolbar from './ClickAndLendingTableToolbar';
import TableHead from '@mui/material/TableHead';
import LocalStorageService from '../../services/LocalStorageService';
import { AvailabiltyDialog } from './dialogs/AvailabiltyDialog';
import { NoteDialog } from './dialogs/NoteDialog';
export type Order = 'asc' | 'desc';

const INTERVAL = 1 * 60 * 1000; // 1min refresh data

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  {
    id: 'userSurname',
    label: 'NOME E COGNOME',
  },
  {
    id: 'createdAt',
    label: 'CREATO ALLE',
  },
  {
    id: 'confirmedBy',
    label: 'CONFERMATO ALLE',
  },
  { id: 'code', label: 'CODICE' },
  { id: 'referral', label: 'REFERRAL' },
  { id: 'outcome', label: 'ESITO' },
  { id: 'status', label: 'STATO' },
];

interface TableHeadProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ClickAndLending,
  ) => void;
  orderSurname: Order;
  orderCreatedAt: Order;
  currentOrderField: string;
  orderBySurname: string;
  orderByCreatedAt: string;
  rowCount: number;
}

function ClickAndLendingTableHead(props: TableHeadProps) {
  const {
    classes,
    orderSurname,
    orderCreatedAt,
    orderByCreatedAt,
    onRequestSort,
    currentOrderField,
    orderBySurname,
  } = props;
  const createSortHandler =
    (property: keyof ClickAndLending) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const tableSortItem = (headCell: any) => {
    switch (headCell.id) {
      case 'userSurname':
        return (
          <TableSortLabel
            active={orderBySurname === headCell.id}
            direction={orderBySurname === headCell.id ? orderSurname : 'asc'}
            onClick={createSortHandler(headCell.id)}
            className={classes.headCellLabel}
          >
            {headCell.label}
            {orderBySurname === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {orderSurname === 'asc'
                  ? 'sorted descending'
                  : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        );
      case 'createdAt':
        return (
          <TableSortLabel
            active={
              orderByCreatedAt === headCell.id &&
              currentOrderField === 'createdAt'
            }
            direction={
              orderByCreatedAt === headCell.id ? orderCreatedAt : 'desc'
            }
            onClick={createSortHandler(headCell.id)}
            className={classes.headCellLabel}
          >
            {headCell.label}
            {orderByCreatedAt === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {orderCreatedAt === 'asc'
                  ? 'sorted descending'
                  : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        );

      default:
        return <div className={classes.headCellLabel}>{headCell.label}</div>;
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key="icon"
          style={{ borderBottom: 'none' }}
          align="left"
          padding={'normal'}
          className={classes.headTableCell}
        />

        {headCells.map((headCell) => (
          <TableCell
            className={classes.headTableCell}
            key={headCell.id}
            style={{ borderBottom: 'none' }}
            align={headCell.label === 'icon' ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={
              orderBySurname === headCell.id ? orderSurname : false
            }
          >
            {tableSortItem(headCell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ClickAndLendingTable() {
  const classes = useStyles();

  const [orderSurname, setOrderSurname] = useState<Order>('asc');
  const [orderCreatedAt, setOrderCreateAt] = useState<Order>('desc');

  const [orderBy, setOrderBy] = useState<'userSurname' | 'createdAt'>(
    'userSurname',
  );

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<any>();
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);

  const [selectedRow, setSelectedRow] = useState<ClickAndLending | null>(null);

  const dispatch = useDispatch();

  const getOrder = useCallback(() => {
    if (orderBy === 'userSurname') {
      return orderSurname;
    }
    return orderCreatedAt;
  }, [orderSurname, orderCreatedAt, orderBy]);

  const bookedReservations = useSelector((state: RootState) =>
    state.ClickAndLendingReducer.hydratedClickAndLendingReservations
      ? state.ClickAndLendingReducer.hydratedClickAndLendingReservations[
          'hydra:member'
        ]
      : [],
  );

  const userCurrentStores = useSelector(
    (state: RootState) => state.StoreReducer.userCurrentStores,
  );

  const currentSelectedStore = useSelector((state: RootState) =>
    state.ClickAndLendingReducer.currentSelectedStore
      ? state.ClickAndLendingReducer.currentSelectedStore
      : userCurrentStores[0],
  );

  const perPage = useSelector(
    (state: RootState) => state.ClickAndLendingReducer.rowsPerPage,
  );

  const handleCurrentStore = useCallback(
    (uuid?: string) => {
      if (!uuid) return;
      let selectedStore = _.find(userCurrentStores, { uuid });
      if (selectedStore && selectedStore.uuid !== currentSelectedStore?.uuid) {
        dispatch(ClickAndLendingActions.setCurrentSelectedStore(selectedStore));
      }
    },
    [userCurrentStores, currentSelectedStore, dispatch],
  );

  useEffect(() => {
    const cachedStore = LocalStorageService.getInstance().get(
      LocalStorageService.CURRENT_STORE,
    );

    if (cachedStore) {
      handleCurrentStore(cachedStore.uuid);
      return;
    }

    if (userCurrentStores.length > 0) {
      handleCurrentStore(userCurrentStores[0].uuid);
    }
  }, [userCurrentStores, handleCurrentStore]);

  function openDialog(row: ClickAndLending | null): void {
    if (!row) return;
    if (selectedRow?.uuid !== row.uuid) setSelectedRow(row);
    if (
      row.reservationStatus === 'AWAITINGPICK' ||
      row.reservationStatus === 'VARIANTAWAITINGPICK' ||
      row.reservationStatus === 'ENDED'
    ) {
      setOpenNoteDialog(true);
    } else {
      setOpenVerificationDialog(true);
    }
  }

  useEffect(() => {
    setPage(1);
  }, [query, perPage]);

  useEffect(() => {
    function getClickAndLendingReservations() {
      if (!currentSelectedStore.uuid) return;

      const code =
        query && query.codeReservation !== ''
          ? query.codeReservation
          : undefined;
      const name = query && query.name !== '' ? query.name : undefined;
      const lastName =
        query && query.lastname !== '' ? query.lastname : undefined;

      setTimeout(() => {
        dispatch(
          ClickAndLendingActions.getAllClickAndLendingReservations(
            currentSelectedStore!.uuid!,
            getOrder(),
            page,
            perPage,
            orderBy,
            '', // no status
            code,
            name,
            lastName,
          ),
        );
      }, 300);
    }

    getClickAndLendingReservations();

    const interval = setInterval(() => {
      getClickAndLendingReservations();
    }, INTERVAL);

    return () => clearInterval(interval);
  }, [dispatch, query, currentSelectedStore, getOrder, page, perPage, orderBy]);

  const setRowsPerPage = (value: number) => {
    dispatch(ClickAndLendingActions.setRowsPerPage(value));
  };
  const totalItems =
    useSelector((state: RootState) =>
      state.ClickAndLendingReducer.hydratedClickAndLendingReservations
        ? state.ClickAndLendingReducer.hydratedClickAndLendingReservations[
            'hydra:totalItems'
          ]
        : 0,
    ) ?? 0;

  const handleChangePage = (event: unknown, newPageIndex: number) => {
    setPage(newPageIndex + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newPerPage);
  };

  const onSearch = (query: object) => {
    _.debounce(
      () => {
        setQuery(query);
      },
      500,
      { leading: false },
    )();
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ClickAndLending,
  ) => {
    if (property === 'userSurname') {
      const isAscSurname = orderSurname === 'asc';
      setOrderSurname(isAscSurname ? 'desc' : 'asc');
      setOrderBy('userSurname');
    }

    if (property === 'createdAt') {
      const isAscCAt = orderCreatedAt === 'asc';
      setOrderCreateAt(isAscCAt ? 'desc' : 'asc');
      setOrderBy('createdAt');
    }
  };

  return (
    <div className={classes.table_wrapper}>
      <Paper className={classes.paper}>
        <ClickAndLendingTableToolbar
          onSearch={(queries) => {
            onSearch(queries);
          }}
          onSelectStore={(storedId) => {
            handleCurrentStore(storedId);
          }}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            stickyHeader
          >
            <ClickAndLendingTableHead
              classes={classes}
              orderCreatedAt={orderCreatedAt}
              orderSurname={orderSurname}
              currentOrderField={orderBy}
              orderBySurname={'userSurname'}
              orderByCreatedAt={'createdAt'}
              onRequestSort={handleRequestSort}
              rowCount={bookedReservations.length}
            />
            <TableBody>
              {bookedReservations.map((row, i) => (
                <ClickAndLendingRow
                  key={i}
                  row={row}
                  onRowClick={() => openDialog(row)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalItems}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
        />
      </Paper>
      {selectedRow && (
        <AvailabiltyDialog
          open={openVerificationDialog}
          lending={selectedRow}
          onClose={() => setOpenVerificationDialog(false)}
          onAvailabilityCommunicated={(status: string) => {
            dispatch(
              ClickAndLendingActions.updateReservation(
                selectedRow.uuid ?? '',
                {
                  reservationStatus: status,
                },
                () => setOpenVerificationDialog(false),
              ),
            );
          }}
        />
      )}
      {selectedRow && (
        <NoteDialog
          open={openNoteDialog}
          lending={selectedRow}
          onClose={() => setOpenNoteDialog(false)}
          onNoteSaved={(note: string) => {
            dispatch(
              ClickAndLendingActions.updateReservation(
                selectedRow.uuid ?? '',
                {
                  reservationNotes: note,
                  reservationStatus: 'ENDED',
                },
                () => setOpenNoteDialog(false),
              ),
            );
          }}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table_wrapper: {
      paddingBottom: 20,
      height: '100vh',
    },
    headTableCell: {
      backgroundColor: '#F9F9F9 !important',
      padding: '4px 16px !important',
      textWrap: 'nowrap',
    },
    headCellLabel: {
      color: '#7D7982 !important',
      fontSize: '12px !important',
      letterSpacing: '0.24px',
    },
    paper: {
      width: '100%',
      boxShadow: 'none !important',
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    pagination: {
      backgroundColor: '#F9F9F9',
      color: '#34303D !important',
      fontStyle: 'normal',
      fontWeight: '400',
    },
  }),
);
