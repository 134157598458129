/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { HydratedSpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead } from "../nbp";
// @ts-ignore
import { SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite } from "../nbp";
// @ts-ignore
import { SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead } from "../nbp";
// @ts-ignore
import { SpecialHourPeriodStatusInputSpecialHourPeriodWrite } from "../nbp";
/**
 * SpecialHourPeriodApi - axios parameter creator
 * @export
 */
export const SpecialHourPeriodApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Removes the SpecialHourPeriod resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSpecialHourPeriodItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteSpecialHourPeriodItem", "id", id);
      const localVarPath = `/api/special_hour_periods/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves Archived SpecialHourPeriods
     * @param {boolean} [isClosed]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedSpecialHourPeriodsSpecialHourPeriodCollection: async (
      isClosed?: boolean,
      startDate?: string,
      endDate?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/special_hour_period/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (isClosed !== undefined) {
        localVarQueryParameter["isClosed"] = isClosed;
      }

      if (startDate !== undefined) {
        localVarQueryParameter["startDate"] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter["endDate"] = endDate;
      }

      if (openTime !== undefined) {
        localVarQueryParameter["openTime"] = openTime;
      }

      if (closeTime !== undefined) {
        localVarQueryParameter["closeTime"] = closeTime;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves the collection of SpecialHourPeriodOutput resources.
     * @param {boolean} [isClosed]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpecialHourPeriodCollection: async (
      isClosed?: boolean,
      startDate?: string,
      endDate?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/special_hour_periods`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (isClosed !== undefined) {
        localVarQueryParameter["isClosed"] = isClosed;
      }

      if (startDate !== undefined) {
        localVarQueryParameter["startDate"] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter["endDate"] = endDate;
      }

      if (openTime !== undefined) {
        localVarQueryParameter["openTime"] = openTime;
      }

      if (closeTime !== undefined) {
        localVarQueryParameter["closeTime"] = closeTime;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a SpecialHourPeriodOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpecialHourPeriodItem: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getSpecialHourPeriodItem", "id", id);
      const localVarPath = `/api/special_hour_periods/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves SpecialHourPeriods by Store
     * @param {string} id
     * @param {boolean} [onlyArchived]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {boolean} [isClosed]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpecialHourPeriodsForCurrentStoreSpecialHourPeriodCollection: async (
      id: string,
      onlyArchived?: boolean,
      startDate?: string,
      endDate?: string,
      openTime?: string,
      closeTime?: string,
      isClosed?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        "getSpecialHourPeriodsForCurrentStoreSpecialHourPeriodCollection",
        "id",
        id,
      );
      const localVarPath = `/api/stores/{id}/special_hour_periods`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (onlyArchived !== undefined) {
        localVarQueryParameter["onlyArchived"] = onlyArchived;
      }

      if (startDate !== undefined) {
        localVarQueryParameter["startDate"] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter["endDate"] = endDate;
      }

      if (openTime !== undefined) {
        localVarQueryParameter["openTime"] = openTime;
      }

      if (closeTime !== undefined) {
        localVarQueryParameter["closeTime"] = closeTime;
      }

      if (isClosed !== undefined) {
        localVarQueryParameter["isClosed"] = isClosed;
      }

      if (properties) {
        localVarQueryParameter["properties[]"] = properties;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the SpecialHourPeriod resource.
     * @param {string} id
     * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchSpecialHourPeriodItem: async (
      id: string,
      specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchSpecialHourPeriodItem", "id", id);
      const localVarPath = `/api/special_hour_periods/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Changes status for a Special Hour Period resource
     * @param {string} uuid
     * @param {SpecialHourPeriodStatusInputSpecialHourPeriodWrite} [specialHourPeriodStatusInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusSpecialHourPeriodItem: async (
      uuid: string,
      specialHourPeriodStatusInputSpecialHourPeriodWrite?: SpecialHourPeriodStatusInputSpecialHourPeriodWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("patchStatusSpecialHourPeriodItem", "uuid", uuid);
      const localVarPath = `/api/special_hour_period/{uuid}/status`.replace(
        `{${"uuid"}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        specialHourPeriodStatusInputSpecialHourPeriodWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a SpecialHourPeriod resource.
     * @param {string} id
     * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The new SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSpecialHourPeriodCollection: async (
      id: string,
      specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("postSpecialHourPeriodCollection", "id", id);
      const localVarPath =
        `/api/special_hours/{id}/special_hour_period`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Replaces the SpecialHourPeriod resource.
     * @param {string} id
     * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putSpecialHourPeriodItem: async (
      id: string,
      specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putSpecialHourPeriodItem", "id", id);
      const localVarPath = `/api/special_hour_periods/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SpecialHourPeriodApi - functional programming interface
 * @export
 */
export const SpecialHourPeriodApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SpecialHourPeriodApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Removes the SpecialHourPeriod resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSpecialHourPeriodItem(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSpecialHourPeriodItem(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves Archived SpecialHourPeriods
     * @param {boolean} [isClosed]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArchivedSpecialHourPeriodsSpecialHourPeriodCollection(
      isClosed?: boolean,
      startDate?: string,
      endDate?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedSpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllArchivedSpecialHourPeriodsSpecialHourPeriodCollection(
          isClosed,
          startDate,
          endDate,
          openTime,
          closeTime,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves the collection of SpecialHourPeriodOutput resources.
     * @param {boolean} [isClosed]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSpecialHourPeriodCollection(
      isClosed?: boolean,
      startDate?: string,
      endDate?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedSpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSpecialHourPeriodCollection(
          isClosed,
          startDate,
          endDate,
          openTime,
          closeTime,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a SpecialHourPeriodOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSpecialHourPeriodItem(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSpecialHourPeriodItem(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves SpecialHourPeriods by Store
     * @param {string} id
     * @param {boolean} [onlyArchived]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {boolean} [isClosed]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSpecialHourPeriodsForCurrentStoreSpecialHourPeriodCollection(
      id: string,
      onlyArchived?: boolean,
      startDate?: string,
      endDate?: string,
      openTime?: string,
      closeTime?: string,
      isClosed?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedSpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSpecialHourPeriodsForCurrentStoreSpecialHourPeriodCollection(
          id,
          onlyArchived,
          startDate,
          endDate,
          openTime,
          closeTime,
          isClosed,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the SpecialHourPeriod resource.
     * @param {string} id
     * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchSpecialHourPeriodItem(
      id: string,
      specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchSpecialHourPeriodItem(
          id,
          specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Changes status for a Special Hour Period resource
     * @param {string} uuid
     * @param {SpecialHourPeriodStatusInputSpecialHourPeriodWrite} [specialHourPeriodStatusInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchStatusSpecialHourPeriodItem(
      uuid: string,
      specialHourPeriodStatusInputSpecialHourPeriodWrite?: SpecialHourPeriodStatusInputSpecialHourPeriodWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchStatusSpecialHourPeriodItem(
          uuid,
          specialHourPeriodStatusInputSpecialHourPeriodWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a SpecialHourPeriod resource.
     * @param {string} id
     * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The new SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSpecialHourPeriodCollection(
      id: string,
      specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postSpecialHourPeriodCollection(
          id,
          specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Replaces the SpecialHourPeriod resource.
     * @param {string} id
     * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putSpecialHourPeriodItem(
      id: string,
      specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putSpecialHourPeriodItem(
          id,
          specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SpecialHourPeriodApi - factory interface
 * @export
 */
export const SpecialHourPeriodApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SpecialHourPeriodApiFp(configuration);
  return {
    /**
     *
     * @summary Removes the SpecialHourPeriod resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSpecialHourPeriodItem(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteSpecialHourPeriodItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves Archived SpecialHourPeriods
     * @param {boolean} [isClosed]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArchivedSpecialHourPeriodsSpecialHourPeriodCollection(
      isClosed?: boolean,
      startDate?: string,
      endDate?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedSpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead> {
      return localVarFp
        .getAllArchivedSpecialHourPeriodsSpecialHourPeriodCollection(
          isClosed,
          startDate,
          endDate,
          openTime,
          closeTime,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves the collection of SpecialHourPeriodOutput resources.
     * @param {boolean} [isClosed]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpecialHourPeriodCollection(
      isClosed?: boolean,
      startDate?: string,
      endDate?: string,
      openTime?: string,
      closeTime?: string,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedSpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead> {
      return localVarFp
        .getSpecialHourPeriodCollection(
          isClosed,
          startDate,
          endDate,
          openTime,
          closeTime,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a SpecialHourPeriodOutput resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpecialHourPeriodItem(
      id: string,
      options?: any,
    ): AxiosPromise<SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead> {
      return localVarFp
        .getSpecialHourPeriodItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves SpecialHourPeriods by Store
     * @param {string} id
     * @param {boolean} [onlyArchived]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [openTime]
     * @param {string} [closeTime]
     * @param {boolean} [isClosed]
     * @param {Array<string>} [properties]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpecialHourPeriodsForCurrentStoreSpecialHourPeriodCollection(
      id: string,
      onlyArchived?: boolean,
      startDate?: string,
      endDate?: string,
      openTime?: string,
      closeTime?: string,
      isClosed?: boolean,
      properties?: Array<string>,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedSpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead> {
      return localVarFp
        .getSpecialHourPeriodsForCurrentStoreSpecialHourPeriodCollection(
          id,
          onlyArchived,
          startDate,
          endDate,
          openTime,
          closeTime,
          isClosed,
          properties,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the SpecialHourPeriod resource.
     * @param {string} id
     * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchSpecialHourPeriodItem(
      id: string,
      specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
      options?: any,
    ): AxiosPromise<SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead> {
      return localVarFp
        .patchSpecialHourPeriodItem(
          id,
          specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Changes status for a Special Hour Period resource
     * @param {string} uuid
     * @param {SpecialHourPeriodStatusInputSpecialHourPeriodWrite} [specialHourPeriodStatusInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchStatusSpecialHourPeriodItem(
      uuid: string,
      specialHourPeriodStatusInputSpecialHourPeriodWrite?: SpecialHourPeriodStatusInputSpecialHourPeriodWrite,
      options?: any,
    ): AxiosPromise<SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead> {
      return localVarFp
        .patchStatusSpecialHourPeriodItem(
          uuid,
          specialHourPeriodStatusInputSpecialHourPeriodWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a SpecialHourPeriod resource.
     * @param {string} id
     * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The new SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSpecialHourPeriodCollection(
      id: string,
      specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
      options?: any,
    ): AxiosPromise<SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead> {
      return localVarFp
        .postSpecialHourPeriodCollection(
          id,
          specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Replaces the SpecialHourPeriod resource.
     * @param {string} id
     * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putSpecialHourPeriodItem(
      id: string,
      specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
      options?: any,
    ): AxiosPromise<SpecialHourPeriodSpecialHourPeriodOutputSpecialHourPeriodRead> {
      return localVarFp
        .putSpecialHourPeriodItem(
          id,
          specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SpecialHourPeriodApi - object-oriented interface
 * @export
 * @class SpecialHourPeriodApi
 * @extends {BaseAPI}
 */
export class SpecialHourPeriodApi extends BaseAPI {
  /**
   *
   * @summary Removes the SpecialHourPeriod resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialHourPeriodApi
   */
  public deleteSpecialHourPeriodItem(id: string, options?: any) {
    return SpecialHourPeriodApiFp(this.configuration)
      .deleteSpecialHourPeriodItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves Archived SpecialHourPeriods
   * @param {boolean} [isClosed]
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {string} [openTime]
   * @param {string} [closeTime]
   * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialHourPeriodApi
   */
  public getAllArchivedSpecialHourPeriodsSpecialHourPeriodCollection(
    isClosed?: boolean,
    startDate?: string,
    endDate?: string,
    openTime?: string,
    closeTime?: string,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return SpecialHourPeriodApiFp(this.configuration)
      .getAllArchivedSpecialHourPeriodsSpecialHourPeriodCollection(
        isClosed,
        startDate,
        endDate,
        openTime,
        closeTime,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves the collection of SpecialHourPeriodOutput resources.
   * @param {boolean} [isClosed]
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {string} [openTime]
   * @param {string} [closeTime]
   * @param {Array<string>} [properties] Allows you to reduce the response to contain only the properties you need. If your desired property is nested, you can address it using nested arrays. Example: properties[]&#x3D;{propertyName}&amp;properties[]&#x3D;{anotherPropertyName}&amp;properties[{nestedPropertyParent}][]&#x3D;{nestedProperty}
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialHourPeriodApi
   */
  public getSpecialHourPeriodCollection(
    isClosed?: boolean,
    startDate?: string,
    endDate?: string,
    openTime?: string,
    closeTime?: string,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return SpecialHourPeriodApiFp(this.configuration)
      .getSpecialHourPeriodCollection(
        isClosed,
        startDate,
        endDate,
        openTime,
        closeTime,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a SpecialHourPeriodOutput resource.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialHourPeriodApi
   */
  public getSpecialHourPeriodItem(id: string, options?: any) {
    return SpecialHourPeriodApiFp(this.configuration)
      .getSpecialHourPeriodItem(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves SpecialHourPeriods by Store
   * @param {string} id
   * @param {boolean} [onlyArchived]
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {string} [openTime]
   * @param {string} [closeTime]
   * @param {boolean} [isClosed]
   * @param {Array<string>} [properties]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialHourPeriodApi
   */
  public getSpecialHourPeriodsForCurrentStoreSpecialHourPeriodCollection(
    id: string,
    onlyArchived?: boolean,
    startDate?: string,
    endDate?: string,
    openTime?: string,
    closeTime?: string,
    isClosed?: boolean,
    properties?: Array<string>,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return SpecialHourPeriodApiFp(this.configuration)
      .getSpecialHourPeriodsForCurrentStoreSpecialHourPeriodCollection(
        id,
        onlyArchived,
        startDate,
        endDate,
        openTime,
        closeTime,
        isClosed,
        properties,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the SpecialHourPeriod resource.
   * @param {string} id
   * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialHourPeriodApi
   */
  public patchSpecialHourPeriodItem(
    id: string,
    specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
    options?: any,
  ) {
    return SpecialHourPeriodApiFp(this.configuration)
      .patchSpecialHourPeriodItem(
        id,
        specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Changes status for a Special Hour Period resource
   * @param {string} uuid
   * @param {SpecialHourPeriodStatusInputSpecialHourPeriodWrite} [specialHourPeriodStatusInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialHourPeriodApi
   */
  public patchStatusSpecialHourPeriodItem(
    uuid: string,
    specialHourPeriodStatusInputSpecialHourPeriodWrite?: SpecialHourPeriodStatusInputSpecialHourPeriodWrite,
    options?: any,
  ) {
    return SpecialHourPeriodApiFp(this.configuration)
      .patchStatusSpecialHourPeriodItem(
        uuid,
        specialHourPeriodStatusInputSpecialHourPeriodWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a SpecialHourPeriod resource.
   * @param {string} id
   * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The new SpecialHourPeriod resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialHourPeriodApi
   */
  public postSpecialHourPeriodCollection(
    id: string,
    specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
    options?: any,
  ) {
    return SpecialHourPeriodApiFp(this.configuration)
      .postSpecialHourPeriodCollection(
        id,
        specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Replaces the SpecialHourPeriod resource.
   * @param {string} id
   * @param {SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite} [specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite] The updated SpecialHourPeriod resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpecialHourPeriodApi
   */
  public putSpecialHourPeriodItem(
    id: string,
    specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite?: SpecialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
    options?: any,
  ) {
    return SpecialHourPeriodApiFp(this.configuration)
      .putSpecialHourPeriodItem(
        id,
        specialHourPeriodSpecialHourPeriodInputSpecialHourPeriodWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
