import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Button, Theme, Typography } from '@mui/material';
import { CheckIcon } from '../icons/CheckIcon';

interface Props {
  name: string;
  selected: boolean;
  onSelected: () => void;
  noCheckmark?: boolean;
  minWidth?: string;
  showCloseIcon?: boolean;
}

export default function BookingCheckboxButton({
  name,
  selected,
  onSelected,
  noCheckmark,
  minWidth,
}: Props) {
  const classes = useStyles();

  return (
    <Button
      className={`checkbox-button ${selected && 'selected-checkbox'}`}
      onClick={onSelected}
      style={{ minWidth: minWidth || 'unset' }}
    >
      {!noCheckmark && selected && (
        <div className={classes.icon}>
          <CheckIcon color="#34303d" />
        </div>
      )}

      <Typography className={classes.title}>{name}</Typography>
    </Button>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      height: '14px !important',
      width: '14px !important',
      marginRight: '4px !important',
    },
    title: {
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      color: '#34303d',
    },
  }),
);
