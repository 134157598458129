import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Button, Typography, IconButton } from '@mui/material';
import StoreServiceSettingsDialog from './modals/StoreServiceSettingsDialog';
import { StoreService } from '../../../api/entities';
import { StoreActions } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import { SettingsIcon } from '../../icons/SettingsIcon';
import { ComputerIcon } from '../../icons/ComputerIcon';

interface ServiceButtonProps {
  checked: boolean;
  title: string;
  id?: string;
  storeService?: StoreService;
  onSelect: (id: string, checked: boolean) => void;
}

export default function StoreServiceButton(props: ServiceButtonProps) {
  const classes = useStyles();
  const checked = props.checked;
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const title = props.title;
  const dispatch = useDispatch();
  const handleSelect = () => {
    if (props.id) {
      props.onSelect(props.id, checked);
    }
  };

  const onPressWheel = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setOpenSettingsDialog(true);
  };

  const onEditStoreSettings = (request: any) => {
    dispatch(
      StoreActions.patchStoreService(
        props.storeService?.uuid ? props.storeService?.uuid : '',
        request,
      ),
    );
    setOpenSettingsDialog(false);
  };

  return (
    <>
      <Button
        className={checked ? classes.selectedRoot : classes.root}
        onClick={() => handleSelect()}
      >
        <Box
          className={classes.textWrapper}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <ComputerIcon color={checked ? '#ffffff' : '#34303D'} />
          <Typography
            className={checked ? classes.selectedTitle : classes.title}
          >
            {title}
          </Typography>
        </Box>

        {checked && (
          <div className={classes.wheelIcon}>
            <IconButton
              size="small"
              onClick={(event: any) => onPressWheel(event)}
            >
              <SettingsIcon small={true} />
            </IconButton>
          </div>
        )}
      </Button>
      <StoreServiceSettingsDialog
        isVisible={openSettingsDialog}
        onSave={(request: any) => onEditStoreSettings(request)}
        onClose={() => setOpenSettingsDialog(false)}
        service={props.storeService}
      />
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // @ts-ignore
      position: 'relative !important',
      width: 100,
      height: 100,
      backgroundColor: '#f3f3f3 !important',
      '&:hover': {
        backgroundColor: '#dddddd !important',
      },
    },
    selectedRoot: {
      // @ts-ignore
      position: 'relative !important',
      width: 100,
      height: 100,
      backgroundColor: '#00D1D2 !important',
      '&:hover': {
        backgroundColor: 'rgb(0, 185, 185) !important',
      },
    },
    textWrapper: {
      marginTop: '8px',
    },
    title: {
      marginTop: '4px !important',
      fontSize: '11px !important',
      fontStyle: 'normal',
      fontWeight: '600 !important',
      lineHeight: '11px !important',
      color: '#34303D !important',
      // @ts-ignore
      whiteSpace: 'break-spaces !important',
    },
    selectedTitle: {
      marginTop: '4px !important',
      fontSize: '11px !important',
      fontStyle: 'normal',
      fontWeight: '600 !important',
      lineHeight: '11px !important',
      color: '#f3f3f3 !important',
      // @ts-ignore
      whiteSpace: 'break-spaces !important',
    },
    checkIcon: {
      marginTop: '6px',
    },
    wheelIcon: {
      // @ts-ignore
      position: 'absolute !important',
      top: 0,
      right: 0,
    },
  }),
);
