import React from 'react';
import './AuthForm.scss';
import { Button, Grid, Box } from '@mui/material';
import SnackBarActions, {
  SnackBarType,
} from '../../../redux/actions/UI/SnackBarActions';
import { RootState } from '../../../redux/reducers';
import { connect } from 'react-redux';
import { FormInputPassword } from '../form_input/FormInputPassword';
import { ArrowRightIcon } from '../../icons/ArrowRightIcon';

interface ResetPasswordFormProps {
  onSubmit: (formData: string) => void;
  showSnackBar: (message: string, type: SnackBarType) => void;
}

interface ResetPasswordFormState {
  password: string;
}

export class ResetPasswordForm extends React.Component<
  ResetPasswordFormProps,
  ResetPasswordFormState
> {
  constructor(props: ResetPasswordFormProps) {
    super(props);
    this.state = {
      password: '',
    };
  }
  handleOnSubmit = () => {
    const { password } = this.state;
    if (password !== '') {
      this.props.onSubmit(password);
    }
  };
  setPassword = (value: string) => {
    this.setState({ password: value });
  };

  render() {
    return (
      <Box
        className="container"
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Grid className={'login-wrapper'}>
          <Box className={'login-box'}>
            <h6 className={'h6'}>Per favore inserisci la tua nuova password</h6>
            <FormInputPassword
              onChange={(pass) => this.setPassword(pass)}
              onSubmit={() => this.handleOnSubmit()}
            />

            <Box
              className="form-footer"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                onClick={() => this.handleOnSubmit()}
                className="btn"
                type="submit"
                endIcon={<ArrowRightIcon />}
              >
                Ripristina
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    );
  }
}

export function mapStateToProps(state: RootState) {
  return {};
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showSnackBar: (message: string, type: SnackBarType) =>
      dispatch(SnackBarActions.snackbar(message, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
