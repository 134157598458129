import { Box, Typography, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BusinessHourRequestActions } from '../../../../../redux/actions';
import { IOSSwitch as Switch } from '../../../../forms/Switch';

interface TimePeriodRowInfoProps {
  item: any;
}

export default function TimePeriodRowInfo(props: TimePeriodRowInfoProps) {
  const classes = useStyles();
  const item = props.item;
  const [dayIsClosed, setDayIsClosed] = useState(!item.isClosed);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDayIsClosed(!event.target.checked);
    item.times.forEach((time: any) => {
      if (time.uuid) {
        let newTime = { ...time, isClosed: !event.target.checked };
        dispatch(BusinessHourRequestActions.updateTimePeriod(newTime));
      }
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <Box>
        <Typography className={classes.rowLabel}>
          {item.openDay.itDay()}
        </Typography>
      </Box>
      <Box>
        <Switch
          checked={dayIsClosed}
          onChange={handleChange}
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Box>
      <Box>
        <Typography className={classes.switchLabel}>
          {dayIsClosed ? 'APERTO' : 'CHIUSO'}
        </Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowLabel: {
      color: '#34303D',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '700 !important',
      lineHeight: '14px !important',
      minWidth: '120px',
    },
    switchLabel: {
      color: '#34303D',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      minWidth: '100px',
      marginLeft: '16px !important',
    },
  }),
);
