import React, { createContext, useContext, useEffect, useState } from 'react';
import NotificationsActions, {
  Notification,
} from '../../redux/actions/NotificationsActions';
import { NotificationGroup } from './notificationGroup';
import OneSignal from 'react-onesignal';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import moment from 'moment';

interface GroupedNotifications {
  today: Notification[];
  yesterday: Notification[];
  older: Notification[];
}

interface ContextType {
  today: Notification[];
  yesterday: Notification[];
  older: Notification[];
  toBeReadCount: number;
  setAsRead: (
    notification: Notification,
    group: NotificationGroup,
  ) => Promise<void>;
}

const NotificationContext = createContext<ContextType>({
  today: [],
  yesterday: [],
  older: [],
  toBeReadCount: 0,
  setAsRead: async () => {},
});

export const useNotifications = () =>
  useContext<ContextType>(NotificationContext);

const Provider = NotificationContext.Provider;

interface Props {
  children: React.ReactNode;
}

const ONE_MINUTE_DELAY_IN_MILLISECONDS: number = 1 * 60 * 1000;
const ONE_SIGNAL_APP_ID: string = '66dad2ca-6a2e-45a6-8126-ec494c5750c8';

export const NotificationProvider = ({ children }: Props) => {
  const [today, setToday] = useState<Notification[]>([]);
  const [yesterday, setYesterday] = useState<Notification[]>([]);
  const [older, setOlder] = useState<Notification[]>([]);
  const [toBeReadCount, setToBeReadCount] = useState<number>(0);

  const user = useSelector(
    (state: RootState) => state.UsersReducer.currentUser,
  );

  useEffect(() => {
    const userId = user?.uuid;

    if (userId) runOneSignal(userId);

    getNotifications();

    const interval: NodeJS.Timeout = setInterval(
      () => getNotifications(),
      ONE_MINUTE_DELAY_IN_MILLISECONDS,
    );

    return () => {
      if (userId) stopOneSignal();
      clearInterval(interval);
    };
  }, [user]);

  async function runOneSignal(userId: string): Promise<void> {
    try {
      OneSignal.Debug.setLogLevel('debug');
      await OneSignal.init({ appId: ONE_SIGNAL_APP_ID });
      await OneSignal.login(userId);
      if (!OneSignal.User.PushSubscription.optedIn) {
        await OneSignal.User.PushSubscription.optIn();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function stopOneSignal(): Promise<void> {
    await OneSignal.logout();
    window.location.reload(); // workaround: prevent OneSignal already initialized error after new login
  }

  async function getNotifications(): Promise<void> {
    const notifications: Notification[] =
      await NotificationsActions.getNotifications({ itemsPerPage: 5 });

    const now = moment();
    const yesterday = moment().subtract(1, 'days');

    const groupedNotifications = notifications.reduce(
      (groupedNotifications: GroupedNotifications, noty: Notification) => {
        const creationDate = moment(noty.createdAt);

        if (creationDate.isSame(now, 'day')) {
          return {
            ...groupedNotifications,
            today: [...groupedNotifications.today, noty],
          };
        }

        if (creationDate.isSame(yesterday, 'day')) {
          return {
            ...groupedNotifications,
            yesterday: [...groupedNotifications.yesterday, noty],
          };
        }

        return {
          ...groupedNotifications,
          older: [...groupedNotifications.older, noty],
        };
      },
      { today: [], yesterday: [], older: [] },
    );

    setToday(groupedNotifications.today);
    setYesterday(groupedNotifications.yesterday);
    setOlder(groupedNotifications.older);

    const notificationsToBeRead = notifications.filter(
      (noty: Notification) => !noty.isRead,
    );

    setToBeReadCount(notificationsToBeRead.length);
  }

  async function setAsRead(
    notification: Notification,
    group: NotificationGroup,
  ): Promise<void> {
    if (notification.isRead) return;

    const setNotifications = getStateAndSetterByGroup(group);
    const id = notification.id!;

    await NotificationsActions.markAsRead(id);

    setNotifications((notifications: Notification[]) => {
      return notifications.map((noty: Notification) => {
        if (noty.id === id) return { ...noty, isRead: true };
        return noty;
      });
    });

    await getNotifications();
  }

  function getStateAndSetterByGroup(
    group: NotificationGroup,
  ): (setter: any) => void {
    if (group === 'TODAY') return setToday;
    if (group === 'YESTERDAY') return setYesterday;
    return setOlder;
  }

  return (
    <Provider value={{ today, yesterday, older, toBeReadCount, setAsRead }}>
      {children}
    </Provider>
  );
};
