import React from 'react';
import { Box, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../components/icons/ArrowLeftIcon';
import NotificationList from '../NotificationList/NotificationList';
import './NotificationPage.scss';

export const NotificationPage = () => {
  const navigate = useNavigate();

  return (
    <Box display="flex" className="main-container notifications-container">
      <Grid className="grid-action-container">
        <Button
          disableElevation
          className="sidebar-button"
          onClick={() => navigate(-1)}
          startIcon={<ArrowLeftIcon color="#34303D" />}
        >
          Torna Indietro
        </Button>
      </Grid>
      <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
        <Grid className="grid-info-container">
          <div className="title">Notifiche</div>
          <NotificationList />
        </Grid>
      </Box>
    </Box>
  );
};
