import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { ClickAndLending } from '../../../api/entities';
import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';

interface AvailabiltyDialogProps {
  open: boolean;
  lending: ClickAndLending;
  onClose: () => void;
  onAvailabilityCommunicated: (status: string) => void;
}

export const AvailabiltyDialog = ({
  open,
  lending,
  onClose,
  onAvailabilityCommunicated,
}: AvailabiltyDialogProps) => {
  const classes = useAvailabiltyStyles();

  const uiDate = (date: string | undefined) => {
    return moment(date).format('LLLL');
  };

  const statusLabel = useMemo<string | undefined>(() => {
    if (lending.reservationStatus === 'ENDED') {
      return 'Concluso';
    }
    if (lending.reservationStatus === 'NOTAVAILABLE') {
      return 'Nessun prodotto disponibile';
    }
    if (lending.reservationStatus === 'EXPIRED') {
      return 'Richiesta scaduta';
    }
    if (lending.reservationStatus === 'CANCELLED') {
      return 'Il cliente ha annullato la richiesta';
    }
  }, [lending]);

  return (
    <Dialog
      onClose={() => onClose()}
      PaperProps={{ sx: { borderRadius: '10px' } }}
      className={classes.modal}
      open={open}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        {lending.userName + ' ' + lending.userSurname}
      </DialogTitle>
      <Grid className={classes.summaryBox}>
        <div className={classes.summaryRow}>
          <Typography className={classes.subHeaderLabel}>
            Email:&nbsp;
          </Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {lending.userEmail}
          </Typography>
        </div>
        <div className={classes.summaryRow}>
          <Typography className={classes.subHeaderLabel}>
            Telefono:&nbsp;
          </Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {lending.userPhone}
          </Typography>
        </div>
        <div className={classes.summaryRow}>
          <Typography className={classes.subHeaderLabel}>
            Richiesta inoltrata:&nbsp;
          </Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {uiDate(lending.createdAt)}
          </Typography>
        </div>

        <div className={classes.summaryRow}>
          <Typography className={classes.subHeaderLabel}>
            Codice prenotazione:&nbsp;
          </Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {lending.reservationCode}
          </Typography>
        </div>
        <div className={classes.summaryRow}>
          <Typography className={classes.subHeaderLabel}>
            Prodotto richiesto dal cliente:&nbsp;
          </Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {lending.productName}
          </Typography>
        </div>

        {lending.reservationStatus === 'CANCELLED' && (
          <div className={classes.summaryRow}>
            <Typography className={classes.subHeaderLabel}>
              Prodotto alternativo:&nbsp;
            </Typography>
            <Typography className={classes.subHeaderSubtitleLabel}>
              {lending.alternativeProductName || '-'}
            </Typography>
          </div>
        )}

        {statusLabel && (
          <div
            style={{
              border:
                lending.reservationStatus === 'NOTAVAILABLE' ||
                lending.reservationStatus === 'CANCELLED'
                  ? 0
                  : 1,
            }}
            className={classes.summaryRow}
          >
            <Typography className={classes.subHeaderLabel}>Stato:</Typography>
            <Typography className={classes.subHeaderSubtitleLabel}>
              {statusLabel}
            </Typography>
          </div>
        )}

        {lending.reservationStatus !== 'NOTAVAILABLE' &&
          lending.reservationStatus !== 'CANCELLED' && (
            <div>
              <div>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ marginTop: '24px', paddingBottom: 8 }}
                >
                  <Typography>Prodotto disponibile</Typography>
                </Box>
                <Grid justifyContent="center" alignItems="center">
                  <Button
                    className={classes.continueButton}
                    disableElevation
                    disabled={lending.reservationStatus !== 'CREATED'}
                    onClick={() => onAvailabilityCommunicated('AWAITINGPICK')}
                  >
                    Comunica Disponibilità
                  </Button>
                </Grid>
              </div>
              <div>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ paddingBottom: 8 }}
                >
                  <Typography>Nessun prodotto disponibile</Typography>
                </Box>

                <Grid justifyContent="center" alignItems="center">
                  <Button
                    className={classes.continueButton}
                    disableElevation
                    disabled={lending.reservationStatus !== 'CREATED'}
                    onClick={() => onAvailabilityCommunicated('NOTAVAILABLE')}
                  >
                    Comunica Indisponibilità
                  </Button>
                </Grid>
              </div>
            </div>
          )}
      </Grid>
    </Dialog>
  );
};

const useAvailabiltyStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '12px !important',
    },
    continueButton: {
      backgroundColor: '#34303d !important',
      padding: '12px 24px !important',
      borderRadius: '50px !important',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      color: '#fff !important',
      border: '1px solid #34303d !important',
    },
    summaryBox: {
      backgroundColor: 'white',
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 4,
      flexWrap: 'nowrap',
    },
    summaryRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '11px 0',
      borderBottom: '1px solid #E1E0E2',
      width: '100%',
    },
    subHeaderLabel: {
      color: '#34303D',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      minWidth: '250px',
    },
    subHeaderSubtitleLabel: {
      color: '#34303D',
      fontfFamily: 'IQOS Sans',
      fontSize: '14px !important',
      fontWeight: '600 !important',
    },
  }),
);
