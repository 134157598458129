import { Box, IconButton, TextField, Theme, Toolbar } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { Autocomplete } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

enum SearchType {
  name,
  lastname,
  code,
}

interface ClickAndLendingTableToolbarProps {
  onSelectStore: (storeId: string) => void;
  onSearch: (queries: {
    name: string;
    lastname: string;
    codeReservation: string;
  }) => void;
}

export default function ClickAndLendingTableToolbar({
  onSelectStore,
  onSearch,
}: ClickAndLendingTableToolbarProps) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [codeReservation, setCodeReservation] = useState('');

  const queries = useMemo(
    () => ({
      name,
      lastname,
      codeReservation,
    }),
    [name, lastname, codeReservation],
  );

  useEffect(() => {
    onSearch(queries);
  }, [queries, onSearch]);

  const search = (term: string, type: SearchType) => {
    switch (type) {
      case SearchType.name:
        setName(term);
        break;
      case SearchType.lastname:
        setLastname(term);
        break;
      case SearchType.code:
        setCodeReservation(term);
        break;
    }
  };

  const userCurrentStores = useSelector(
    (state: RootState) => state.StoreReducer.userCurrentStores,
  );

  const currentSelectedStore = useSelector((state: RootState) =>
    state.ClickAndLendingReducer.currentSelectedStore
      ? state.ClickAndLendingReducer.currentSelectedStore
      : userCurrentStores[0],
  );

  const handleCurrentStore = (value: any) => {
    onSelectStore(value);
  };

  const clearTextFields = () => {
    setName('');
    setLastname('');
    setCodeReservation('');
  };

  return (
    <Toolbar className={classes.toolbar}>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        className={classes.barWrapper}
      >
        <Box
          display="flex"
          alignItems="center"
          className="click-and-lending-search"
        >
          <div className={classes.toolbarTitle}>Prenota & Prova</div>
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.searchFieldName}
            label="Nome"
            value={name}
            onChange={(event) => search(event.target.value, SearchType.name)}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
            InputProps={{
              style: {
                padding: '4px 2px',
              },
              endAdornment: name && (
                <IconButton
                  className={classes.clearIcon}
                  aria-label="toggle password visibility"
                  onClick={() => search('', SearchType.name)}
                ></IconButton>
              ),
            }}
          />
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.searchFieldLastname}
            label="Cognome"
            value={lastname}
            onChange={(event) =>
              search(event.target.value, SearchType.lastname)
            }
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
            InputProps={{
              style: {
                padding: '4px 2px',
              },
              endAdornment: lastname && (
                <IconButton
                  className={classes.clearIcon}
                  aria-label="toggle password visibility"
                  onClick={() => search('', SearchType.lastname)}
                ></IconButton>
              ),
            }}
          />
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.searchField}
            label="Codice prenotazione"
            value={codeReservation}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
            InputProps={{
              style: {
                padding: '4px 2px',
              },
              endAdornment: codeReservation && (
                <IconButton
                  className={classes.clearIcon}
                  aria-label="toggle password visibility"
                  onClick={() => search('', SearchType.code)}
                ></IconButton>
              ),
            }}
            onChange={(event) => search(event.target.value, SearchType.code)}
          />
        </Box>

        <Autocomplete
          id="open-on-focus"
          className={classes.storeField}
          openOnFocus
          disableClearable
          options={userCurrentStores}
          noOptionsText="Nessuna opzione"
          value={currentSelectedStore}
          size="small"
          onChange={(event, newValue) => {
            handleCurrentStore(newValue.uuid);
            clearTextFields();
          }}
          getOptionLabel={(option) => (option.name ? option.name : '')}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.uuid}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className="text-field"
              color="secondary"
              size="small"
              inputProps={{
                ...params.inputProps,
                style: {
                  padding: '6px 2px',
                },
              }}
              InputLabelProps={{
                ...params.InputLabelProps,
                style: {
                  top: '4px',
                },
              }}
            />
          )}
        />
      </Box>
    </Toolbar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      width: '100%',
      padding: '20px 20px 0 20px !important',
      marginBottom: '32px',
      boxSizing: 'border-box',
    },
    barWrapper: {
      width: '100%',
    },
    toolBarButton: {
      backgroundColor: '#00d1d2 !important',
      padding: '14px 24px !important',
      borderRadius: '50px !important',
      color: '#34303d !important',
      lineHeight: '16px !important',
      fontSize: '16px !important',
    },
    toolbarTitle: {
      color: '#34303D',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    searchField: {
      maxWidth: window.innerWidth > 1024 ? 200 : 150,
      alignSelf: 'center',
    },
    searchFieldName: {
      maxWidth: window.innerWidth > 1024 ? 200 : 150,
      alignSelf: 'center',
      marginLeft: '24px !important',
    },
    searchFieldLastname: {
      maxWidth: window.innerWidth > 1024 ? 200 : 150,
      alignSelf: 'center',
    },
    toolbarButtonTitle: {
      color: '#ffffff',
      fontSize: 10,
      fontWeight: 'bold',
    },
    storeField: {
      alignSelf: 'center',
      minWidth: window.innerWidth > 1024 ? 225 : 175,
      maxWidth: window.innerWidth > 1024 ? 225 : 175,
    },
    clearIcon: {
      width: 20,
      height: 20,
      marginRight: -4,
    },
  }),
);
