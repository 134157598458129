import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { UserRoles } from '../../../utils/UserRoles';

interface RowOptionProps {
  onSelectAction: (index: number) => void;
  storeStatus: string;
}

export default function RowOptionsButton(props: RowOptionProps) {
  const classes = useStyles();
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    event.stopPropagation();
    props.onSelectAction(index);
  };
  const currentRole = useSelector(
    (state: RootState) => state.UsersReducer.currentRole,
  );

  const renderArchive = () => {
    return (
      (currentRole === UserRoles.ADMIN ||
        currentRole === UserRoles.SUPER_STORE_MANAGER) && (
        <Button className={classes.button} onClick={(e) => handleClick(e, 2)}>
          Archivia
        </Button>
      )
    );
  };

  const renderActivate = () => {
    return (
      (currentRole === UserRoles.ADMIN ||
        currentRole === UserRoles.SUPER_STORE_MANAGER) && (
        <Button className={classes.button} onClick={(e) => handleClick(e, 2)}>
          Ripristina
        </Button>
      )
    );
  };

  const renderEditHours = () => {
    return (
      (currentRole === UserRoles.ADMIN ||
        currentRole === UserRoles.SUPER_STORE_MANAGER ||
        currentRole === UserRoles.STORE_MANAGER) && (
        <Button className={classes.button} onClick={(e) => handleClick(e, 0)}>
          Modifica
        </Button>
      )
    );
  };
  return (
    <div className={classes.root}>
      <ButtonGroup
        className={classes.group}
        size="small"
        variant="outlined"
        color="secondary"
        aria-label="outlined secondary button group"
        disableElevation
      >
        {renderEditHours()}
        <Button className={classes.button} onClick={(e) => handleClick(e, 1)}>
          Calendario
        </Button>
        {props.storeStatus === 'ARCHIVED' ? renderActivate() : renderArchive()}
      </ButtonGroup>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    group: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    button: {
      borderColor: '#E1E0E2 !important',
      fontSize: '12px !important',
      '&&:hover': {
        backgroundColor: '#dcf2f2',
      },
    },
  }),
);
