import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ProductsActions } from "../actions";
import { HydratedProducts } from "../../api/entities";

export interface ProductsState {
  hydratedProducts?: HydratedProducts;
  getProductsError: boolean;
  getProductsLoading: boolean;
}

const INITIAL_SERVICE_STATE: ProductsState = {
  getProductsError: false,
  getProductsLoading: false,
};

const ProductsReducer = createReducer(INITIAL_SERVICE_STATE, (builder) => {
  builder
    .addCase(
      ProductsActions.getProductsSuccess,
      (state: ProductsState, action: PayloadAction<HydratedProducts>) => {
        let hydratedProducts = action.payload;
        return {
          ...state,
          hydratedProducts,
          getProductsLoading: false,
          getProductsError: false,
        };
      },
    )
    .addCase(ProductsActions.getProductsLoading, (state: ProductsState) => {
      return { ...state, getProductsLoading: true, getProductsError: false };
    })
    .addCase(
      ProductsActions.getProductsError,
      (state: ProductsState, action: PayloadAction<any>) => {
        return {
          ...state,
          getProductsLoading: false,
          getProductsError: action.payload,
        };
      },
    );
});

export default ProductsReducer;
