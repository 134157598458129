import { SvgIcon } from '@mui/material';
import { useIconColor } from './IconColor';
import React, { useMemo } from 'react';

import { IconProps } from './iconProps';

export const SettingsIcon = ({ color, className, small }: IconProps) => {
  const iconColor = useIconColor(color);

  const size = useMemo<number>(() => (small ? 24 : 18), [small]);
  const position = useMemo<string>(() => (small ? '-1 -1' : '2.25 3'), [small]);

  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={`${position} ${size} ${size}`}
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.96129 15.2692L6.17597 15.4594C7.07264 15.5998 7.713 16.4211 7.64697 17.3463L7.55747 18.6004C7.53141 18.9657 7.72603 19.3097 8.04838 19.4688L8.94679 19.9114C9.26915 20.0705 9.65405 20.011 9.91732 19.763L10.8201 18.9097C11.4856 18.2804 12.5126 18.2804 13.1791 18.9097L14.0818 19.763C14.3451 20.0118 14.7291 20.0705 15.0523 19.9114L15.9525 19.4679C16.274 19.3097 16.4677 18.9666 16.4417 18.6022L16.3522 17.3463C16.2861 16.4211 16.9265 15.5998 17.8232 15.4594L19.0378 15.2692C19.3915 15.2141 19.6765 14.943 19.7564 14.5857L19.978 13.592C20.0579 13.2347 19.9163 12.8641 19.6209 12.6579L18.6069 11.9477C17.8588 11.4233 17.6303 10.3994 18.0821 9.59503L18.6946 8.50535C18.8728 8.18804 18.8432 7.79075 18.6199 7.50455L17.9987 6.70729C17.7754 6.42109 17.4035 6.30288 17.0612 6.41043L15.8865 6.7784C15.0185 7.05037 14.0931 6.59441 13.7595 5.73138L13.3094 4.56438C13.1773 4.22307 12.855 3.99909 12.4961 3.99998L11.5004 4.00265C11.1416 4.00354 10.8201 4.2293 10.6897 4.57149L10.251 5.72516C9.9208 6.59352 8.99111 7.05304 8.1205 6.77928L6.89713 6.39532C6.55393 6.28688 6.18032 6.40598 5.95702 6.69396L5.34012 7.49211C5.11682 7.78097 5.08989 8.17916 5.27148 8.49646L5.89793 9.5888C6.3593 10.3941 6.1334 11.4277 5.38096 11.9548L4.37915 12.657C4.08373 12.8641 3.94211 13.2347 4.02204 13.5911L4.24361 14.5848C4.32267 14.943 4.60766 15.2141 4.96129 15.2692V15.2692Z"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.1214 9.87855C15.2929 11.05 15.2929 12.95 14.1214 14.1214C12.95 15.2928 11.05 15.2928 9.87858 14.1214C8.70714 12.95 8.70714 11.05 9.87858 9.87855C11.05 8.70712 12.95 8.70712 14.1214 9.87855"
          stroke={iconColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
