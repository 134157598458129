import React, { useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import ActionDialog from '../dialog/ActionDialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogActionFooter from '../dialog/DialogActionFooter';
import { Appointment, ClientEdit } from '../../api/entities';
import { useDispatch, useSelector } from 'react-redux';
import { AppointmentsActions } from '../../redux/actions';
import { RootState } from '../../redux/reducers';
import ConfirmCustomerArrivedDialog from './ConfirmCustomerArrivedDialog';
import { EditIcon } from '../icons/EditIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditNoteIcon } from '../icons/EditNoteIcon';
import BookingCheckboxButton from './BookingCheckboxButton';
import EditBookingModal from './EditBookingModal';

interface AppointmentActionsProps {
  appointment?: Appointment;
  onDeleteAppointment?: (id: string, notifEnabled: boolean) => void;
}

export default function AppointmentActions(props: AppointmentActionsProps) {
  const classes = useStyles();
  const appointment = useSelector(
    (state: RootState) => state.AppointmentsReducer.appointmentDetail,
  );
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [tmpNote, setTmpNote] = useState('');
  const [clientArrived, setClientArrived] = useState<boolean | undefined>(
    undefined,
  );
  const [openClientArriveYESdDialog, setOpenClientYESArrivedDialog] =
    useState(false);
  const [openClientArriveNOdDialog, setOpenClientNOArrivedDialog] =
    useState(false);
  const currentUser = useSelector(
    (state: RootState) => state.UsersReducer.currentUser,
  );
  const [notifyForDelete, setNotifyForDelete] = useState(false);

  useEffect(() => {
    if (appointment) {
      setClientArrived(appointment.hasArrived);
    }
  }, [appointment]);

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
  };

  // PATCH CRM DATA
  const clientWithCrmValues = () => {
    if (appointment && appointment.clients) {
      let client = appointment.clients[0];
      return [
        { crmClientId: client.crmClientId, clientType: client.clientType },
      ] as ClientEdit[];
    } else {
      return [
        { crmClientId: undefined, clientType: undefined },
      ] as ClientEdit[];
    }
  };

  const handleClientIsArrived = (isArrived: boolean) => {
    setOpenClientNOArrivedDialog(false);
    setOpenClientYESArrivedDialog(false);
    setClientArrived(isArrived);
    if (appointment && appointment.uuid) {
      dispatch(
        AppointmentsActions.patchAppointment(appointment.uuid, {
          hasArrived: isArrived,
          clients: clientWithCrmValues(),
        }),
      );
    }
  };

  const handleDeleteAppointment = () => {
    if (appointment?.uuid) {
      setOpenDeleteDialog(false);
      props.onDeleteAppointment &&
        props.onDeleteAppointment(appointment.uuid, notifyForDelete);
    }
  };

  const notificationEnabled = useMemo<boolean>(() => {
    return (
      currentUser?.businessRole === 'SHOP ASSISTANT' ||
      currentUser?.businessRole === 'CALL CENTER'
    );
  }, [currentUser]);

  const deletAlert = () => {
    return (
      <ActionDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title="Elimina un appuntamento"
        message="Sei sicuro di voler eliminare questo appuntamento?"
        actionTitle="Elimina"
        hasNotificationOption={true}
        notificationEnabled={notificationEnabled}
        onCheckedNotificationEnabled={(value) => setNotifyForDelete(value)}
        onAction={() => handleDeleteAppointment()}
      />
    );
  };

  const onSaveNotes = () => {
    if (appointment && appointment.uuid) {
      dispatch(
        AppointmentsActions.patchAppointment(appointment.uuid, {
          notes: tmpNote,
          clients: clientWithCrmValues(),
        }),
      );
      handleCloseNoteDialog();
    }
  };

  const noteDialog = () => {
    return (
      <Dialog
        open={openNoteDialog}
        onClose={handleCloseNoteDialog}
        aria-labelledby="form-dialog-title"
        maxWidth={'xs'}
        fullWidth
        PaperProps={{ sx: { borderRadius: '10px' } }}
      >
        <DialogTitle className={classes.contentTitle} id="form-dialog-title">
          Aggiungi Nota
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            label="Nota sull'appuntamento"
            color="secondary"
            multiline
            onChange={(event) => setTmpNote(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <DialogActionFooter
            onCancel={handleCloseNoteDialog}
            onConfirm={() => onSaveNotes()}
          />
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Typography className={classes.label}>Il cliente è arrivato?</Typography>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <BookingCheckboxButton
          name="Sì"
          minWidth="calc(50% - 7px)"
          selected={!!clientArrived}
          onSelected={() => {
            if (!!clientArrived) return;
            setOpenClientYESArrivedDialog(true);
          }}
        />
        <BookingCheckboxButton
          name="No"
          minWidth="calc(50% - 7px)"
          selected={!clientArrived}
          onSelected={() => {
            if (!clientArrived) return;
            setOpenClientNOArrivedDialog(true);
          }}
        />
      </Box>
      <Typography className={classes.label}>Gestisci prenotazione</Typography>
      <Box
        flexDirection="column"
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Button
          fullWidth
          className={classes.editButton}
          startIcon={<DeleteIcon color="#34303D" />}
          onClick={() => {
            setOpenDeleteDialog(true);
          }}
        >
          Elimina
        </Button>
        <Button
          fullWidth
          className={classes.editButton}
          startIcon={<EditIcon color="#34303D" />}
          onClick={() => setOpenEditDialog(true)}
        >
          Modifica
        </Button>
        <Button
          fullWidth
          className={classes.editButton}
          startIcon={<EditNoteIcon color="#34303D" />}
          onClick={() => {
            setOpenNoteDialog(true);
          }}
        >
          Aggiungi Nota
        </Button>
      </Box>
      {deletAlert()}
      {noteDialog()}
      <EditBookingModal
        event={props.appointment}
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      />
      <ConfirmCustomerArrivedDialog
        open={openClientArriveYESdDialog}
        onPressConfirm={() => handleClientIsArrived(!clientArrived)}
        onPressCancel={() => setOpenClientYESArrivedDialog(false)}
        onClose={() => setOpenClientYESArrivedDialog(false)}
      />
      <ConfirmCustomerArrivedDialog
        open={openClientArriveNOdDialog}
        onPressConfirm={() => handleClientIsArrived(!clientArrived)}
        onPressCancel={() => setOpenClientNOArrivedDialog(false)}
        onClose={() => setOpenClientNOArrivedDialog(false)}
      />
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#34303D',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      marginBottom: '16px !important',
      marginTop: '40px !important',
    },
    editButton: {
      marginBottom: '8px !important',
      textAlign: 'center',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      color: '#34303D',
      backgroundColor: '#EFEFEF !important',
      padding: '8px !important',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '12px !important',
    },
  }),
);
