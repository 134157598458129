import React, { useMemo } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

interface ServiceProductLabelProps {
  type: string;
}

export default function ServiceProductLabel({
  type,
}: ServiceProductLabelProps) {
  const classes = useStyles();

  const title = useMemo<string>(() => {
    if (type === 'IQOS') return 'IQOS';
    if (type === 'VEEV') return 'VEEV';
    return '';
  }, [type]);

  const color = useMemo<string>(() => {
    if (type === 'IQOS') return '#F8F4BC';
    if (type === 'VEEV') return '#BCE9F8';
    return '#00D1D2';
  }, [type]);

  return (
    <Box
      className={classes.box}
      sx={{ backgroundColor: color }}
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      padding: '7px 16px',
      borderRadius: 4,
    },
    title: {
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      color: '#34303d',
    },
  }),
);
