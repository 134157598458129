import { useMemo } from "react";

const DEFAULT_COLOR: string = "#FFFDFB";

export function useIconColor(color?: string) {
  return useMemo<string>(() => {
    if (color) return color;
    return DEFAULT_COLOR;
  }, [color]);
}
