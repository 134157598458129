import React, { createRef } from 'react';
import { connect } from 'react-redux';
import {
  MenuItem,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Dashboard.scss';
import { RootState } from '../../redux/reducers';
import {
  AppointmentsActions,
  SnackBarActions,
  StoreActions,
} from '../../redux/actions';
import { SnackBarType } from '../../redux/actions/UI/SnackBarActions';
import BookingEventModal from '../../components/dashboard/BookingEventModal';
import {
  Appointment,
  HydratedAppointment,
  HydratedStore,
  Store,
} from '../../api/entities';
import _ from 'lodash';
import LocalStorageService from '../../services/LocalStorageService';
import OverlaySpinner from '../../components/OverlaySpinner';
import moment, { DurationInputArg2 } from 'moment';
import { EventApi, EventClickArg } from '@fullcalendar/core';
import { Autocomplete } from '@mui/material';
import { ChevronLeftIcon } from '../../components/icons/ChevronLeftIcon';
import { ChevronRightIcon } from '../../components/icons/ChevronRightIcon';
import { AddIcon } from '../../components/icons/AddIcon';
import EditBookingModal from '../../components/dashboard/EditBookingModal';
import BookingModal from '../../components/dashboard/BookingModal';

const CALENDAR_VIEW_OPTIONS = [
  { id: 'dayGridMonth', label: 'Mese' },
  { id: 'timeGridWeek', label: 'Settimana' },
  { id: 'timeGridDay', label: 'Giorno' },
];

interface DashboardProps {
  hydratedStore?: HydratedStore;
  hydratedAppointment?: HydratedAppointment;
  loggedIn: boolean;
  calendarAppointments: any[];
  showSnackBar: (message: string, type: SnackBarType) => void;
  getAppointments: (storeId: string, startDay: string, endDay: string) => void;
  getUserCurrentStores: () => void;
  setCurrentSelectedStore: (store: Store) => void;
  userCurrentStores: Store[];
  getUserCurrentStoresLoading: boolean;
  currentSelectedStore?: Store;
  loadingAppointments: boolean;
  patchAppointmentLoading: boolean;
  postAppointmentLoading: boolean;
  postAppointmentSuccess?: Appointment;
  deleteAppointmentLoading: boolean;
  viewName?: string;
}
interface DashboardState {
  weekendsVisible: boolean;
  currentCalendarView: string;
  anchorEl: HTMLElement | null;
  selectedEvent: EventApi | null;
  openBookingModal: boolean;
  openEditBookingModal: boolean;
  currentSelectedStore: Store | undefined;
  didMount: boolean;
  givenCalendarDate: Date | undefined;
  currentEventNumber: number;
  viewName: string;
}
export class Dashboard extends React.Component<DashboardProps, DashboardState> {
  calendarRef: React.RefObject<FullCalendar> = createRef();

  constructor(props: DashboardProps) {
    super(props);
    const cachedCalendarView = LocalStorageService.getInstance().get(
      LocalStorageService.CALENDAR_VIEW,
    );
    const cachedStore = LocalStorageService.getInstance().get(
      LocalStorageService.CURRENT_STORE,
    );
    this.state = {
      anchorEl: null,
      weekendsVisible: true,
      selectedEvent: null,
      currentCalendarView: cachedCalendarView
        ? cachedCalendarView
        : 'dayGridMonth',
      currentSelectedStore: cachedStore,
      openBookingModal: false,
      openEditBookingModal: false,
      didMount: false, // for show progress only first time
      givenCalendarDate: new Date(),
      currentEventNumber: 0,
      viewName: '',
    };
  }

  componentDidMount() {
    this.updateViewName();
    this.props.getUserCurrentStores();
  }

  componentDidUpdate(prevProps: DashboardProps) {
    if (
      prevProps.getUserCurrentStoresLoading &&
      !this.props.getUserCurrentStoresLoading
    ) {
      const cachedStore = LocalStorageService.getInstance().get(
        LocalStorageService.CURRENT_STORE,
      );
      if (cachedStore === undefined || cachedStore === null) {
        if (this.props.userCurrentStores.length > 0) {
          const storeId = this.props.userCurrentStores[0].uuid;
          if (storeId) {
            this.props.setCurrentSelectedStore(this.props.userCurrentStores[0]);
            this.setState({
              currentSelectedStore: this.props.userCurrentStores[0],
            });
            this.props.getAppointments(
              storeId,
              this.getGivenRange().startDay,
              this.getGivenRange().endDay,
            );
          }
        }
      } else {
        const currentStoreId = cachedStore.uuid;
        if (currentStoreId) {
          this.props.setCurrentSelectedStore(cachedStore);
          this.setState({ currentSelectedStore: cachedStore });
          this.props.getAppointments(
            currentStoreId,
            this.getGivenRange().startDay,
            this.getGivenRange().endDay,
          );
        }
      }
    }

    if (prevProps.loadingAppointments && !this.props.loadingAppointments) {
      this.calculateEventsNumber();
      if (!this.interval) this.pingAppointments();
    }
    if (
      prevProps.postAppointmentLoading &&
      !this.props.postAppointmentLoading
    ) {
      if (this.props.postAppointmentSuccess) {
        this.calculateEventsNumber();
      }
    }
    if (
      prevProps.deleteAppointmentLoading &&
      !this.props.deleteAppointmentLoading
    ) {
      this.calculateEventsNumber();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = false;
  }

  interval: any = false;

  pingAppointments = () => {
    this.interval = setInterval(() => {
      this.setState({ didMount: true });
      const cachedStore = LocalStorageService.getInstance().get(
        LocalStorageService.CURRENT_STORE,
      );
      if (cachedStore?.uuid) {
        this.props.getAppointments(
          cachedStore.uuid,
          this.getGivenRange().startDay,
          this.getGivenRange().endDay,
        );
      }
    }, 60000);
  };

  getNextDate = () => {
    let givenDate = this.state.givenCalendarDate!;
    const unit = this.getMomentUnitByCurrentView();
    return moment(givenDate).add(1, unit).toDate();
  };

  getPreviousDate = () => {
    let givenDate = this.state.givenCalendarDate;
    const unit = this.getMomentUnitByCurrentView();
    return moment(givenDate).subtract(1, unit).toDate();
  };

  private getMomentUnitByCurrentView(): DurationInputArg2 {
    switch (this.state.currentCalendarView) {
      case 'dayGridMonth':
        return 'months';
      case 'timeGridWeek':
        return 'weeks';
      default:
        return 'days';
    }
  }

  getGivenRange = () => {
    let month = moment(this.state.givenCalendarDate).month();
    let year = moment(this.state.givenCalendarDate).year();
    let givenDate = this.state.givenCalendarDate;

    if (this.state.currentCalendarView === 'dayGridMonth') {
      const startDate = moment([year, month]).format('YYYY-MM-DD');
      const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
      return { startDay: startDate, endDay: endDate };
    } else if (this.state.currentCalendarView === 'timeGridWeek') {
      const startDate = moment(givenDate).startOf('week').format('YYYY-MM-DD');
      const endDate = moment(givenDate).endOf('week').format('YYYY-MM-DD');
      return { startDay: startDate, endDay: endDate };
    } else {
      const startDate = moment(givenDate).format('YYYY-MM-DD');
      const endDate = moment(givenDate).format('YYYY-MM-DD');
      return { startDay: startDate, endDay: endDate };
    }
  };

  handleEventClick = (clickInfo: EventClickArg) => {
    clearInterval(this.interval);
    this.interval = false;
    this.setState({ anchorEl: clickInfo.el, selectedEvent: clickInfo.event });
  };

  handleClose = () => {
    if (!this.state.openEditBookingModal) {
      this.pingAppointments();
    }
    this.setState({ anchorEl: null });
  };

  calculateEventsNumber = () => {
    this.setState({
      currentEventNumber: this.props.calendarAppointments.length,
    });
  };

  handleChangeCalendarView = (event: any) => {
    LocalStorageService.getInstance().set(
      LocalStorageService.CALENDAR_VIEW,
      event.target.value,
    );
    this.setState({ currentCalendarView: event.target.value }, () => {
      this.calendarRef.current
        ?.getApi()
        .changeView(
          this.state.currentCalendarView,
          this.state.givenCalendarDate,
        );
      this.getAppointmentsByDates(this.state.givenCalendarDate);
    });
  };

  getAppointmentsByDates = (calendarDate: Date | undefined) => {
    clearInterval(this.interval);
    this.interval = false;
    //call appi only when prev and next button change month
    this.setState({ givenCalendarDate: calendarDate }, () => {
      // this.calculateEventsNumber();
      const cachedStore = LocalStorageService.getInstance().get(
        LocalStorageService.CURRENT_STORE,
      );
      const currentStoreId = cachedStore.uuid;
      if (currentStoreId) {
        this.props.getAppointments(
          currentStoreId,
          this.getGivenRange().startDay,
          this.getGivenRange().endDay,
        );
      }
    });
  };

  handleCurrentStore = (storeId?: string) => {
    clearInterval(this.interval);
    this.interval = false;
    let selectedStore = _.find(this.props.userCurrentStores, {
      uuid: storeId,
    });

    this.setState({ didMount: true });
    if (selectedStore?.uuid) {
      this.props.setCurrentSelectedStore(selectedStore);
      this.setState({ currentSelectedStore: selectedStore });
      this.props.getAppointments(
        selectedStore.uuid,
        this.getGivenRange().startDay,
        this.getGivenRange().endDay,
      );
    }
  };

  getAppointmentFromEvents = (id: string | undefined) => {
    if (this.props.hydratedAppointment && id) {
      let appointments = this.props.hydratedAppointment['hydra:member'];
      let selectedAppointment = _.find(appointments, { uuid: id });
      return selectedAppointment;
    }
    return null;
  };

  handlePressInstantBooking = () => {
    clearInterval(this.interval);
    this.interval = false;
    const cachedStore = LocalStorageService.getInstance().get(
      LocalStorageService.CURRENT_STORE,
    );

    if (cachedStore?.storeServices.length > 0) {
      this.setState({ openBookingModal: true });
    } else {
      this.props.showSnackBar(
        'Questo negozio non ha alcun servizio per prenotare un appuntamento',
        'warning',
      );
    }
  };

  arrivedInfolabel = (hasArrived?: boolean) => {
    if (hasArrived === undefined) {
      return (
        <Typography
          style={{
            color: 'gray',
            fontSize: '0.76rem',
            fontWeight: 'bold',
            marginLeft: 4,
          }}
        >
          {'\u2015'}
        </Typography>
      );
    }

    if (hasArrived) {
      return (
        <Typography
          style={{
            color: 'green',
            fontSize: '0.76rem',
            fontWeight: 'bold',
            marginLeft: 4,
          }}
        >
          {'\u2713'}
        </Typography>
      );
    }

    return (
      <Typography
        style={{
          color: 'red',
          fontSize: '0.76rem',
          fontWeight: 'bold',
          marginLeft: 4,
        }}
      >
        {'\u2717'}
      </Typography>
    );
  };

  renderEventContent = (obj: any) => {
    let hasArrived = obj.event._def.extendedProps.hasArrived;
    return (
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <Typography
          noWrap
          style={{
            fontSize: '0.76rem',
            marginLeft: 2,
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
          }}
        >
          {obj.timeText + ' ' + obj.event._def.title}
        </Typography>
        {this.arrivedInfolabel(hasArrived)}
      </div>
    );
  };

  getServiceColor = (serviceName: string | undefined) => {
    switch (serviceName) {
      case 'Assistenza':
        return '#BCF4F4';
      case 'Prova e acquista':
        return '#FFE4CA';
      case 'Videocall':
        return '#CEF1BB';
      default:
        return '#00D1D2';
    }
  };

  renderLegend = () => {
    if (!this.props.currentSelectedStore) return;
    const currentServices = this.props.currentSelectedStore.storeServices
      ? this.props.currentSelectedStore.storeServices
      : [];
    return currentServices.map((service, index) => {
      return (
        <div
          key={index}
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              width: 16,
              height: 16,
              borderRadius: 8,
              backgroundColor: this.getServiceColor(service.externalName),
              marginLeft: index !== 0 ? 10 : 0,
            }}
          />
          <h2 className="service-label">{service.externalName}</h2>
        </div>
      );
    });
  };

  next(): void {
    if (!this.calendarRef.current) return;
    this.calendarRef.current?.getApi().next();
    const nextDate = this.getNextDate();
    this.setState({ givenCalendarDate: nextDate }, () =>
      this.getAppointmentsByDates(this.state.givenCalendarDate),
    );
    this.updateViewName();
  }

  previous(): void {
    if (!this.calendarRef.current) return;
    this.calendarRef.current?.getApi().prev();
    const previousDate = this.getPreviousDate();
    this.setState({ givenCalendarDate: previousDate }, () =>
      this.getAppointmentsByDates(this.state.givenCalendarDate),
    );
    this.updateViewName();
  }

  goToToday(): void {
    if (!this.calendarRef.current) return;
    this.calendarRef.current?.getApi().today();
    this.setState({ givenCalendarDate: new Date() }, () =>
      this.getAppointmentsByDates(this.state.givenCalendarDate),
    );
    this.updateViewName();
  }

  updateViewName(): void {
    const name: string | undefined =
      this.calendarRef.current?.getApi().view.title;
    if (name) this.setState({ viewName: name });
  }

  render() {
    const open = Boolean(this.state.anchorEl);
    const { userCurrentStores } = this.props;

    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          className="week-container"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <h1 className={'page-title'}>{this.state.viewName}</h1>
            <IconButton
              className="calendar-nav-button"
              disabled={
                this.props.getUserCurrentStoresLoading ||
                this.props.loadingAppointments
              }
              onClick={() => this.previous()}
            >
              <ChevronLeftIcon color="#34303D" />
            </IconButton>
            <IconButton
              disabled={
                this.props.getUserCurrentStoresLoading ||
                this.props.loadingAppointments
              }
              onClick={() => this.next()}
            >
              <ChevronRightIcon color="#34303D" />
            </IconButton>
            <Button
              variant="contained"
              disableElevation
              className="today-button"
              disabled={
                this.props.getUserCurrentStoresLoading ||
                this.props.loadingAppointments
              }
              onClick={() => this.goToToday()}
            >
              Oggi
            </Button>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Autocomplete
                disabled={
                  this.props.getUserCurrentStoresLoading ||
                  this.props.loadingAppointments
                }
                id="open-on-focus"
                openOnFocus
                disableClearable
                options={userCurrentStores}
                noOptionsText="Nessuna opzione"
                value={this.state.currentSelectedStore}
                onChange={(event, newValue) =>
                  this.handleCurrentStore(newValue.uuid)
                }
                getOptionLabel={(option) => (option.name ? option.name : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="secondary"
                    size="small"
                    className="text-field"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        padding: '6.5px 16px',
                        borderColor: '#E1E0E2',
                      },
                    }}
                  />
                )}
              />

              <TextField
                id="standard-select-data"
                disabled={
                  this.props.getUserCurrentStoresLoading ||
                  this.props.loadingAppointments
                }
                select
                className="text-field select"
                value={this.state.currentCalendarView}
                onChange={this.handleChangeCalendarView}
                color="secondary"
                size="small"
              >
                {CALENDAR_VIEW_OPTIONS.map((item, i) => (
                  <MenuItem key={i} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                disabled={
                  this.props.getUserCurrentStoresLoading ||
                  this.props.loadingAppointments
                }
                className="instant-booking-button"
                startIcon={<AddIcon color="#34303D" />}
                onClick={() => this.handlePressInstantBooking()}
              >
                <Typography className="title">Instant Booking</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          className="legend-box"
        >
          <div className={'appointments-number'}>
            Tot. Prenotazioni:&nbsp;
            {!(
              this.props.getUserCurrentStoresLoading ||
              this.props.loadingAppointments
            )
              ? this.state.currentEventNumber
              : 0}
          </div>
          <Box display="flex" alignItems="center">
            {this.renderLegend()}
          </Box>
        </Box>
        <Box className="calendar-container" id="calendar-container">
          <FullCalendar
            eventStartEditable={false}
            height={'parent'}
            stickyHeaderDates
            firstDay={1}
            contentHeight="auto"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={false}
            eventContent={(arg: any) => {
              return this.renderEventContent(arg);
            }}
            buttonText={{
              today: 'Oggi',
              month: 'Mese',
              week: 'Settimana',
              day: 'Giorno',
            }}
            ref={this.calendarRef}
            locale="it"
            titleFormat={{
              year: 'numeric',
              day: '2-digit',
              month: 'long',
            }}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: false,
              omitZeroMinute: false,
            }}
            slotDuration="01:00:00"
            dayHeaderFormat={
              this.state.currentCalendarView === 'timeGridWeek'
                ? { weekday: 'long', day: 'numeric' }
                : { weekday: 'long' }
            }
            initialDate={this.getGivenRange().startDay}
            allDaySlot={false}
            slotMaxTime={'23:00:00'}
            slotMinTime={'07:00:00'}
            eventTextColor={'white'}
            eventColor={'transparent'}
            nowIndicator
            initialView={this.state.currentCalendarView}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={2}
            weekends={this.state.weekendsVisible}
            events={this.props.calendarAppointments}
            eventClick={this.handleEventClick}
          />
        </Box>
        <BookingEventModal
          event={this.getAppointmentFromEvents(this.state.selectedEvent?.id)}
          open={open}
          anchor={this.state.anchorEl}
          onClose={() => this.handleClose()}
          onDelete={() => {
            this.state.selectedEvent?.remove();
            this.handleClose();
          }}
          onEdit={() =>
            this.setState({ openEditBookingModal: true }, () =>
              this.handleClose(),
            )
          }
        />
        <BookingModal
          open={this.state.openBookingModal}
          onClose={() =>
            this.setState({ openBookingModal: false }, () =>
              this.pingAppointments(),
            )
          }
        />
        <EditBookingModal
          open={this.state.openEditBookingModal}
          event={this.getAppointmentFromEvents(this.state.selectedEvent?.id)}
          onClose={() =>
            this.setState({ openEditBookingModal: false }, () =>
              this.pingAppointments(),
            )
          }
        />
        {this.props.patchAppointmentLoading ||
          (this.props.postAppointmentLoading && <OverlaySpinner />)}
      </>
    );
  }
}

export function mapStateToProps(state: RootState) {
  return {
    userCurrentStores: state.StoreReducer.userCurrentStores,
    currentSelectedStore: state.StoreReducer.currentSelectedStore,
    getUserCurrentStoresLoading: state.StoreReducer.getUserCurrentStoresLoading,
    userCurrentStoresError: state.StoreReducer.getUserCurrentStoresError,
    loggedIn: state.AuthReducer.loggedIn,
    calendarAppointments: state.AppointmentsReducer.calendarAppointments,
    hydratedAppointment: state.AppointmentsReducer.hydratedAppointment,
    loadingAppointments: state.AppointmentsReducer.loadingAppointments,
    patchAppointmentLoading: state.AppointmentsReducer.patchAppointmentLoading,
    postAppointmentLoading: state.AppointmentsReducer.postAppointmentLoading,
    postAppointmentSuccess: state.AppointmentsReducer.postAppointmentSuccess,
    deleteAppointmentLoading:
      state.AppointmentsReducer.deleteAppointmentLoading,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showSnackBar: (message: string, type: SnackBarType) =>
      dispatch(SnackBarActions.snackbar(message, type)),
    getAppointments: (storeId: string, startDay: string, endDay: string) =>
      dispatch(AppointmentsActions.getAppointments(storeId, startDay, endDay)),
    getUserCurrentStores: () => dispatch(StoreActions.getUserCurrentStores()),
    setCurrentSelectedStore: (store: Store) =>
      dispatch(StoreActions.setCurrentSelectedStore(store)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
