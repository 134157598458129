/* tslint:disable */
/* eslint-disable */
/**
 * IQOS Booking Platform
 * IQOS Booking Platform backend API
 *
 * The version of the OpenAPI document: 1.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI } from "../base";
// @ts-ignore
import { ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit } from "../nbp";
// @ts-ignore
import { ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite } from "../nbp";
// @ts-ignore
import { ClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead } from "../nbp";
// @ts-ignore
import { HydratedClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead } from "../nbp";
/**
 * ClickAndLendingReservationApi - axios parameter creator
 * @export
 */
export const ClickAndLendingReservationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Retrieves All ClickAndLending Reservations for single Store
     * @param {string} uuid
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [referral]
     * @param {string} [userName]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClickAndLendingReservationCollection: async (
      uuid: string,
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      referral?: string,
      userName?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists(
        "getAllClickAndLendingReservationCollection",
        "uuid",
        uuid,
      );
      const localVarPath =
        `/api/stores/{uuid}/click_and_lending_reservations`.replace(
          `{${"uuid"}}`,
          encodeURIComponent(String(uuid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (orderByField !== undefined) {
        localVarQueryParameter["orderByField"] = orderByField;
      }

      if (orderDirection !== undefined) {
        localVarQueryParameter["orderDirection"] = orderDirection;
      }

      if (productName !== undefined) {
        localVarQueryParameter["productName"] = productName;
      }

      if (productVariant !== undefined) {
        localVarQueryParameter["productVariant"] = productVariant;
      }

      if (productCode !== undefined) {
        localVarQueryParameter["productCode"] = productCode;
      }

      if (productQuantity !== undefined) {
        localVarQueryParameter["productQuantity"] = productQuantity;
      }

      if (createdAt !== undefined) {
        localVarQueryParameter["createdAt"] = createdAt;
      }

      if (updatedAt !== undefined) {
        localVarQueryParameter["updatedAt"] = updatedAt;
      }

      if (referral !== undefined) {
        localVarQueryParameter["referral"] = referral;
      }

      if (userName !== undefined) {
        localVarQueryParameter["userName"] = userName;
      }

      if (userSurname !== undefined) {
        localVarQueryParameter["userSurname"] = userSurname;
      }

      if (userEmail !== undefined) {
        localVarQueryParameter["userEmail"] = userEmail;
      }

      if (userPhone !== undefined) {
        localVarQueryParameter["userPhone"] = userPhone;
      }

      if (reservationStatus !== undefined) {
        localVarQueryParameter["reservationStatus"] = reservationStatus;
      }

      if (reservationCode !== undefined) {
        localVarQueryParameter["reservationCode"] = reservationCode;
      }

      if (outcome !== undefined) {
        localVarQueryParameter["outcome"] = outcome;
      }

      if (reservationNotes !== undefined) {
        localVarQueryParameter["reservationNotes"] = reservationNotes;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves All ClickAndLending Reservations
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [userName]
     * @param {string} [referral]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClickAndLendingReservationCollection: async (
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      userName?: string,
      referral?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/click_and_lending_reservations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (orderByField !== undefined) {
        localVarQueryParameter["orderByField"] = orderByField;
      }

      if (orderDirection !== undefined) {
        localVarQueryParameter["orderDirection"] = orderDirection;
      }

      if (productName !== undefined) {
        localVarQueryParameter["productName"] = productName;
      }

      if (productVariant !== undefined) {
        localVarQueryParameter["productVariant"] = productVariant;
      }

      if (productCode !== undefined) {
        localVarQueryParameter["productCode"] = productCode;
      }

      if (productQuantity !== undefined) {
        localVarQueryParameter["productQuantity"] = productQuantity;
      }

      if (createdAt !== undefined) {
        localVarQueryParameter["createdAt"] = createdAt;
      }

      if (updatedAt !== undefined) {
        localVarQueryParameter["updatedAt"] = updatedAt;
      }

      if (userName !== undefined) {
        localVarQueryParameter["userName"] = userName;
      }

      if (referral !== undefined) {
        localVarQueryParameter["referral"] = referral;
      }

      if (userSurname !== undefined) {
        localVarQueryParameter["userSurname"] = userSurname;
      }

      if (userEmail !== undefined) {
        localVarQueryParameter["userEmail"] = userEmail;
      }

      if (userPhone !== undefined) {
        localVarQueryParameter["userPhone"] = userPhone;
      }

      if (reservationStatus !== undefined) {
        localVarQueryParameter["reservationStatus"] = reservationStatus;
      }

      if (reservationCode !== undefined) {
        localVarQueryParameter["reservationCode"] = reservationCode;
      }

      if (outcome !== undefined) {
        localVarQueryParameter["outcome"] = outcome;
      }

      if (reservationNotes !== undefined) {
        localVarQueryParameter["reservationNotes"] = reservationNotes;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (itemsPerPage !== undefined) {
        localVarQueryParameter["itemsPerPage"] = itemsPerPage;
      }

      if (pagination !== undefined) {
        localVarQueryParameter["pagination"] = pagination;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves a ClickAndLending Reservation
     * @param {string} id
     * @param {string} [reservationCodeOrUuid] If not inserted or set to \&quot;uuid\&quot; the search will be with uuid. Set to \&quot;reservationCode\&quot; the search will be with reservationCode.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClickAndLendingReservationItem: async (
      id: string,
      reservationCodeOrUuid?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getClickAndLendingReservationItem", "id", id);
      const localVarPath = `/api/click_and_lending_reservations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (reservationCodeOrUuid !== undefined) {
        localVarQueryParameter["reservationCodeOrUuid"] = reservationCodeOrUuid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the ClickAndLendingReservation resource.
     * @param {string} id
     * @param {ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit} [clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit] The updated ClickAndLendingReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchClickAndLendingReservationItem: async (
      id: string,
      clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit?: ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchClickAndLendingReservationItem", "id", id);
      const localVarPath = `/api/click_and_lending_reservations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a ClickAndLendingReservation resource
     * @param {ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite} [clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite] The new ClickAndLendingReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postClickAndLendingReservationClickAndLendingReservationCollection: async (
      clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite?: ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/click_and_lending_reservations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication authenticationToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClickAndLendingReservationApi - functional programming interface
 * @export
 */
export const ClickAndLendingReservationApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ClickAndLendingReservationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Retrieves All ClickAndLending Reservations for single Store
     * @param {string} uuid
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [referral]
     * @param {string} [userName]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClickAndLendingReservationCollection(
      uuid: string,
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      referral?: string,
      userName?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClickAndLendingReservationCollection(
          uuid,
          orderByField,
          orderDirection,
          productName,
          productVariant,
          productCode,
          productQuantity,
          createdAt,
          updatedAt,
          referral,
          userName,
          userSurname,
          userEmail,
          userPhone,
          reservationStatus,
          reservationCode,
          outcome,
          reservationNotes,
          status,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves All ClickAndLending Reservations
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [userName]
     * @param {string} [referral]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClickAndLendingReservationCollection(
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      userName?: string,
      referral?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClickAndLendingReservationCollection(
          orderByField,
          orderDirection,
          productName,
          productVariant,
          productCode,
          productQuantity,
          createdAt,
          updatedAt,
          userName,
          referral,
          userSurname,
          userEmail,
          userPhone,
          reservationStatus,
          reservationCode,
          outcome,
          reservationNotes,
          status,
          page,
          itemsPerPage,
          pagination,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Retrieves a ClickAndLending Reservation
     * @param {string} id
     * @param {string} [reservationCodeOrUuid] If not inserted or set to \&quot;uuid\&quot; the search will be with uuid. Set to \&quot;reservationCode\&quot; the search will be with reservationCode.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClickAndLendingReservationItem(
      id: string,
      reservationCodeOrUuid?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClickAndLendingReservationItem(
          id,
          reservationCodeOrUuid,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates the ClickAndLendingReservation resource.
     * @param {string} id
     * @param {ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit} [clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit] The updated ClickAndLendingReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchClickAndLendingReservationItem(
      id: string,
      clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit?: ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchClickAndLendingReservationItem(
          id,
          clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a ClickAndLendingReservation resource
     * @param {ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite} [clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite] The new ClickAndLendingReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postClickAndLendingReservationClickAndLendingReservationCollection(
      clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite?: ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postClickAndLendingReservationClickAndLendingReservationCollection(
          clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ClickAndLendingReservationApi - factory interface
 * @export
 */
export const ClickAndLendingReservationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ClickAndLendingReservationApiFp(configuration);
  return {
    /**
     *
     * @summary Retrieves All ClickAndLending Reservations for single Store
     * @param {string} uuid
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [referral]
     * @param {string} [userName]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClickAndLendingReservationCollection(
      uuid: string,
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      referral?: string,
      userName?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead> {
      return localVarFp
        .getAllClickAndLendingReservationCollection(
          uuid,
          orderByField,
          orderDirection,
          productName,
          productVariant,
          productCode,
          productQuantity,
          createdAt,
          updatedAt,
          referral,
          userName,
          userSurname,
          userEmail,
          userPhone,
          reservationStatus,
          reservationCode,
          outcome,
          reservationNotes,
          status,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves All ClickAndLending Reservations
     * @param {string} [orderByField]
     * @param {string} [orderDirection]
     * @param {string} [productName]
     * @param {string} [productVariant]
     * @param {string} [productCode]
     * @param {number} [productQuantity]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {string} [userName]
     * @param {string} [referral]
     * @param {string} [userSurname]
     * @param {string} [userEmail]
     * @param {string} [userPhone]
     * @param {string} [reservationStatus]
     * @param {string} [reservationCode]
     * @param {string} [outcome]
     * @param {string} [reservationNotes]
     * @param {string} [status]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [pagination] Enable or disable pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClickAndLendingReservationCollection(
      orderByField?: string,
      orderDirection?: string,
      productName?: string,
      productVariant?: string,
      productCode?: string,
      productQuantity?: number,
      createdAt?: string,
      updatedAt?: string,
      userName?: string,
      referral?: string,
      userSurname?: string,
      userEmail?: string,
      userPhone?: string,
      reservationStatus?: string,
      reservationCode?: string,
      outcome?: string,
      reservationNotes?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number,
      pagination?: boolean,
      options?: any,
    ): AxiosPromise<HydratedClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead> {
      return localVarFp
        .getClickAndLendingReservationCollection(
          orderByField,
          orderDirection,
          productName,
          productVariant,
          productCode,
          productQuantity,
          createdAt,
          updatedAt,
          userName,
          referral,
          userSurname,
          userEmail,
          userPhone,
          reservationStatus,
          reservationCode,
          outcome,
          reservationNotes,
          status,
          page,
          itemsPerPage,
          pagination,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves a ClickAndLending Reservation
     * @param {string} id
     * @param {string} [reservationCodeOrUuid] If not inserted or set to \&quot;uuid\&quot; the search will be with uuid. Set to \&quot;reservationCode\&quot; the search will be with reservationCode.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClickAndLendingReservationItem(
      id: string,
      reservationCodeOrUuid?: string,
      options?: any,
    ): AxiosPromise<ClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead> {
      return localVarFp
        .getClickAndLendingReservationItem(id, reservationCodeOrUuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the ClickAndLendingReservation resource.
     * @param {string} id
     * @param {ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit} [clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit] The updated ClickAndLendingReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchClickAndLendingReservationItem(
      id: string,
      clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit?: ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit,
      options?: any,
    ): AxiosPromise<ClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead> {
      return localVarFp
        .patchClickAndLendingReservationItem(
          id,
          clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a ClickAndLendingReservation resource
     * @param {ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite} [clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite] The new ClickAndLendingReservation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postClickAndLendingReservationClickAndLendingReservationCollection(
      clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite?: ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite,
      options?: any,
    ): AxiosPromise<ClickAndLendingReservationClickAndLendingReservationOutputClickandlendingreservationRead> {
      return localVarFp
        .postClickAndLendingReservationClickAndLendingReservationCollection(
          clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ClickAndLendingReservationApi - object-oriented interface
 * @export
 * @class ClickAndLendingReservationApi
 * @extends {BaseAPI}
 */
export class ClickAndLendingReservationApi extends BaseAPI {
  /**
   *
   * @summary Retrieves All ClickAndLending Reservations for single Store
   * @param {string} uuid
   * @param {string} [orderByField]
   * @param {string} [orderDirection]
   * @param {string} [productName]
   * @param {string} [productVariant]
   * @param {string} [productCode]
   * @param {number} [productQuantity]
   * @param {string} [createdAt]
   * @param {string} [updatedAt]
   * @param {string} [referral]
   * @param {string} [userName]
   * @param {string} [userSurname]
   * @param {string} [userEmail]
   * @param {string} [userPhone]
   * @param {string} [reservationStatus]
   * @param {string} [reservationCode]
   * @param {string} [outcome]
   * @param {string} [reservationNotes]
   * @param {string} [status]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClickAndLendingReservationApi
   */
  public getAllClickAndLendingReservationCollection(
    uuid: string,
    orderByField?: string,
    orderDirection?: string,
    productName?: string,
    productVariant?: string,
    productCode?: string,
    productQuantity?: number,
    createdAt?: string,
    updatedAt?: string,
    referral?: string,
    userName?: string,
    userSurname?: string,
    userEmail?: string,
    userPhone?: string,
    reservationStatus?: string,
    reservationCode?: string,
    outcome?: string,
    reservationNotes?: string,
    status?: string,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return ClickAndLendingReservationApiFp(this.configuration)
      .getAllClickAndLendingReservationCollection(
        uuid,
        orderByField,
        orderDirection,
        productName,
        productVariant,
        productCode,
        productQuantity,
        createdAt,
        updatedAt,
        referral,
        userName,
        userSurname,
        userEmail,
        userPhone,
        reservationStatus,
        reservationCode,
        outcome,
        reservationNotes,
        status,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves All ClickAndLending Reservations
   * @param {string} [orderByField]
   * @param {string} [orderDirection]
   * @param {string} [productName]
   * @param {string} [productVariant]
   * @param {string} [productCode]
   * @param {number} [productQuantity]
   * @param {string} [createdAt]
   * @param {string} [updatedAt]
   * @param {string} [userName]
   * @param {string} [referral]
   * @param {string} [userSurname]
   * @param {string} [userEmail]
   * @param {string} [userPhone]
   * @param {string} [reservationStatus]
   * @param {string} [reservationCode]
   * @param {string} [outcome]
   * @param {string} [reservationNotes]
   * @param {string} [status]
   * @param {number} [page] The collection page number
   * @param {number} [itemsPerPage] The number of items per page
   * @param {boolean} [pagination] Enable or disable pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClickAndLendingReservationApi
   */
  public getClickAndLendingReservationCollection(
    orderByField?: string,
    orderDirection?: string,
    productName?: string,
    productVariant?: string,
    productCode?: string,
    productQuantity?: number,
    createdAt?: string,
    updatedAt?: string,
    userName?: string,
    referral?: string,
    userSurname?: string,
    userEmail?: string,
    userPhone?: string,
    reservationStatus?: string,
    reservationCode?: string,
    outcome?: string,
    reservationNotes?: string,
    status?: string,
    page?: number,
    itemsPerPage?: number,
    pagination?: boolean,
    options?: any,
  ) {
    return ClickAndLendingReservationApiFp(this.configuration)
      .getClickAndLendingReservationCollection(
        orderByField,
        orderDirection,
        productName,
        productVariant,
        productCode,
        productQuantity,
        createdAt,
        updatedAt,
        userName,
        referral,
        userSurname,
        userEmail,
        userPhone,
        reservationStatus,
        reservationCode,
        outcome,
        reservationNotes,
        status,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves a ClickAndLending Reservation
   * @param {string} id
   * @param {string} [reservationCodeOrUuid] If not inserted or set to \&quot;uuid\&quot; the search will be with uuid. Set to \&quot;reservationCode\&quot; the search will be with reservationCode.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClickAndLendingReservationApi
   */
  public getClickAndLendingReservationItem(
    id: string,
    reservationCodeOrUuid?: string,
    options?: any,
  ) {
    return ClickAndLendingReservationApiFp(this.configuration)
      .getClickAndLendingReservationItem(id, reservationCodeOrUuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the ClickAndLendingReservation resource.
   * @param {string} id
   * @param {ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit} [clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit] The updated ClickAndLendingReservation resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClickAndLendingReservationApi
   */
  public patchClickAndLendingReservationItem(
    id: string,
    clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit?: ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit,
    options?: any,
  ) {
    return ClickAndLendingReservationApiFp(this.configuration)
      .patchClickAndLendingReservationItem(
        id,
        clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationEdit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a ClickAndLendingReservation resource
   * @param {ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite} [clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite] The new ClickAndLendingReservation resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClickAndLendingReservationApi
   */
  public postClickAndLendingReservationClickAndLendingReservationCollection(
    clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite?: ClickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite,
    options?: any,
  ) {
    return ClickAndLendingReservationApiFp(this.configuration)
      .postClickAndLendingReservationClickAndLendingReservationCollection(
        clickAndLendingReservationClickAndLendingReservationInputClickandlendingreservationWrite,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
