import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import moment from "moment";
import { StoreApi } from "../../api/services/nbp/store-api";
import { AxiosResponse } from "axios";
import {
  HydratedStore,
  RequestStore,
  Service,
  Store,
  StoreDetail,
  StoreService,
} from "../../api/entities";
import { StoreServiceApi } from "../../api/services";
import SnackBarActions from "./UI/SnackBarActions";
import _ from "lodash";

//Api Store instance from open api generator
let api = new StoreApi();
let storeServiceApi = new StoreServiceApi();

//CURRENT SELECTED STORE
const currentSelectedStore = createAction<Store | undefined>(
  "store/currentSelectedStore",
);
const setCurrentSelectedStore = (store?: Store) => (dispatch: AppDispatch) => {
  dispatch(currentSelectedStore(store));
};

//GET STORES
const getStoresSuccess = createAction<HydratedStore>("store/getStoreSuccess");
const getStoresLoading = createAction("store/getStoresLoading");
const getStoresError = createAction<any>("store/getStoresError");

const getStores =
  (
    sortField: string = "name",
    sortDirection: string = "asc",
    _page: number = 1,
    _itemsPerPage: number = 10,
    _onlyArchived?: boolean,
    _names?: string[],
  ) =>
  (dispatch: AppDispatch) => {
    let all = true;
    let names = _names;
    let products = undefined;
    let city = undefined;
    let orderbyfield = sortField;
    let orderdirection = sortDirection;
    let address = undefined;
    let region = undefined;
    let longitude = undefined;
    let latitude = undefined;
    let phoneNumber = undefined;
    let zipCode = undefined;
    let province = undefined;
    let storeType = undefined;
    let visibilityStartDate = undefined;
    let visibilityEndDate = undefined;
    let alsoArchived = undefined;
    let onlyArchived = _onlyArchived;
    let properties = undefined;
    let slug = undefined;
    let page = _page;
    let itemsPerPage = _itemsPerPage;
    let pagination = undefined;
    let options = undefined;

    dispatch(getStoresLoading());
    api
      .getAllAssociatedStoresStoreCollection(
        all,
        undefined,
        names,
        products,
        city,
        orderbyfield,
        orderdirection,
        address,
        region,
        longitude,
        latitude,
        phoneNumber,
        zipCode,
        province,
        storeType,
        visibilityStartDate,
        visibilityEndDate,
        onlyArchived,
        alsoArchived,
        properties,
        slug,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((response: AxiosResponse<HydratedStore>) => {
        dispatch(getStoresSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(getStoresError(error));
      });
  };

//* get all current stores for calendar*/
const getUserCurrentStoresSuccess = createAction<HydratedStore>(
  "store/getUSerCurrentStoresSuccess",
);
const getUserCurrentStoresLoading = createAction(
  "store/getUserCurrentStoresLoading",
);
const getUserCurrentStoresError = createAction<any>(
  "store/getUserCurrentStoresError",
);

const getUserCurrentStores = () => (dispatch: AppDispatch) => {
  let all = true;
  let name = undefined;
  let names = undefined;
  let products = undefined;
  let city = undefined;
  let orderbyfield = undefined;
  let orderdirection = undefined;
  let address = undefined;
  let region = undefined;
  let longitude = undefined;
  let latitude = undefined;
  let phoneNumber = undefined;
  let zipCode = undefined;
  let province = undefined;
  let storeType = undefined;
  let visibilityStartDate = undefined;
  let visibilityEndDate = undefined;
  let alsoArchived = undefined;
  let onlyArchived = undefined;
  let properties = undefined;
  let slug = undefined;
  let page = undefined;
  let itemsPerPage = undefined;
  let pagination = false;
  let options = undefined;

  dispatch(getUserCurrentStoresLoading());
  api
    .getAllAssociatedStoresStoreCollection(
      all,
      name,
      names,
      products,
      city,
      orderbyfield,
      orderdirection,
      address,
      region,
      longitude,
      latitude,
      phoneNumber,
      zipCode,
      province,
      storeType,
      visibilityStartDate,
      visibilityEndDate,
      onlyArchived,
      alsoArchived,
      properties,
      slug,
      page,
      itemsPerPage,
      pagination,
      options,
    )
    .then((response: AxiosResponse<HydratedStore>) => {
      dispatch(getUserCurrentStoresSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(getUserCurrentStoresError(error));
    });
};

// GET STORES BY REGION
const sortStoresByField =
  (
    sortField: string,
    sortType: string,
    _page: number = 1,
    _itemsPerPage: number = 10,
  ) =>
  (dispatch: AppDispatch) => {
    let all = true;
    let name = undefined;
    let names = undefined;
    let products = undefined;
    let city = undefined;
    let orderbyfield = sortField;
    let orderdirection = sortType;
    let address = undefined;
    let region = undefined;
    let longitude = undefined;
    let latitude = undefined;
    let phoneNumber = undefined;
    let zipCode = undefined;
    let province = undefined;
    let storeType = undefined;
    let visibilityStartDate = undefined;
    let visibilityEndDate = undefined;
    let alsoArchived = undefined;
    let onlyArchived = undefined;
    let properties = undefined;
    let slug = undefined;
    let page = _page;
    let itemsPerPage = _itemsPerPage;
    let pagination = undefined;
    let options = undefined;

    dispatch(getStoresLoading());
    api
      .getAllAssociatedStoresStoreCollection(
        all,
        name,
        names,
        products,
        city,
        orderbyfield,
        orderdirection,
        address,
        region,
        longitude,
        latitude,
        phoneNumber,
        zipCode,
        province,
        storeType,
        visibilityStartDate,
        visibilityEndDate,
        onlyArchived,
        alsoArchived,
        properties,
        slug,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((response: AxiosResponse<HydratedStore>) => {
        dispatch(getStoresSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(getStoresError(error));
      });
  };

//GET SINGLE STORE
const getSingleStoreSuccess = createAction<StoreDetail>(
  "store/getSingleStoreSuccess",
);
const getSingleStoreLoading = createAction("store/getSingleStoreLoading");
const getSingleStoreError = createAction<any>("store/getSingleStoreError");

const getSingleStore = (id: string) => (dispatch: AppDispatch) => {
  dispatch(getSingleStoreLoading());
  api
    .getStoreUserReadStoreItem(id)
    .then((response: AxiosResponse<StoreDetail>) => {
      dispatch(getSingleStoreSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(getSingleStoreError(error));
    });
};

//EDIT STORE
const editStoreSuccess = createAction<Store>("store/editStoreSuccess");
const editStoreLoading = createAction("stores/editStoreLoading");
const editStoreError = createAction<any>("store/editStoreError");

const editStore =
  (id: string, store?: RequestStore) => (dispatch: AppDispatch) => {
    dispatch(editStoreLoading());
    api
      .patchStoreItem(id, store)
      .then((response: AxiosResponse<Store>) => {
        dispatch(editStoreSuccess(response.data));
        dispatch(
          SnackBarActions.snackbar("Store modificato correttamente", "success"),
        );
      })
      .catch((error: any) => {
        dispatch(editStoreError(error));
        dispatch(
          SnackBarActions.snackbar(
            error.response.data["hydra:description"]
              ? error.response.data["hydra:description"]
              : "Qualcosa è andato storto",
            "error",
          ),
        );
      });
  };

//ARCHIVE STORE
const archiveStore = (id: string, data: any) => (dispatch: AppDispatch) => {
  dispatch(editStoreLoading());
  api
    .patchStatusStoreItem(id, data)
    .then((response: AxiosResponse<any>) => {
      dispatch(editStoreSuccess(response.data));
      dispatch(
        SnackBarActions.snackbar("Store modificato correttamente", "success"),
      );
    })
    .catch((error: any) => {
      dispatch(editStoreError(error));
    });
};

// CREATE STORE
const createStoreSuccess = createAction<Store>("store/createStoreSuccess");
const createStoreLoading = createAction("stores/createStoreLoading");
const createStoreError = createAction<any>("store/createStoreError");

const createStore =
  (store?: RequestStore, catalogServices?: Service[]) =>
  (dispatch: AppDispatch) => {
    dispatch(createStoreLoading());
    api
      .postStoresStoreCollection(store)
      .then((response: AxiosResponse<Store>) => {
        dispatch(createStoreSuccess(response.data));
        dispatch(SnackBarActions.snackbar("Nuovo store creato", "success"));
        if (catalogServices && response.data.uuid) {
          for (let catalogService of catalogServices) {
            let catalogServiceProducts = _.map(
              catalogService.products,
              function (o) {
                return o.uuid;
              },
            ) as string[];
            let productsIds: string[] = [];
            if (catalogServiceProducts) {
              productsIds = catalogServiceProducts;
            }
            // dispatch(createStoreService(response.data.uuid, {service:catalogService.uuid, products: productsIds }))
            storeServiceApi
              .postStoreServiceCollection(response.data.uuid, {
                service: catalogService.uuid,
                products: productsIds,
              })
              .then((response: AxiosResponse<StoreService>) => {})
              .catch((error: any) => {});
          }
        }
      })
      .catch((error: any) => {
        dispatch(createStoreError(error));
        dispatch(
          SnackBarActions.snackbar(
            error.response.data["hydra:description"]
              ? error.response.data["hydra:description"]
              : "Qualcosa è andato storto",
            "error",
          ),
        );
      });
  };

//CREATE STORE SERVICES
const createStoreServicesSuccess = createAction<StoreService>(
  "store/createStoreServicesSuccess",
);
const createStoreServicesLoading = createAction(
  "stores/createStoreStoreServicesLoading",
);
const createStoreServicesError = createAction<any>(
  "store/createStoreServicesError",
);

const createStoreService =
  (storeID: string, storeServiceId: string, productId: string) =>
  (dispatch: AppDispatch) => {
    dispatch(createStoreServicesLoading());
    storeServiceApi
      .createProductWithServiceStoreServiceItem(
        storeID,
        storeServiceId,
        productId,
      )
      .then((response: AxiosResponse<StoreService>) => {
        dispatch(createStoreServicesSuccess(response.data));
        dispatch(getStoreServicesByStore(storeID));
        dispatch(
          SnackBarActions.snackbar("Servizio attivato sullo store", "success"),
        );
      })
      .catch((error: any) => {
        dispatch(createStoreServicesError(error));
        dispatch(
          SnackBarActions.snackbar(
            error.response.data["hydra:description"]
              ? error.response.data["hydra:description"]
              : "Qualcosa è andato storto",
            "error",
          ),
        );
      });
  };

//DELETE STORE SERVICE
const deleteStoreServiceSuccess = createAction<string>(
  "store/deleteStoreServiceSuccess",
);
const deleteStoreServiceLoading = createAction(
  "stores/deleteStoreStoreServiceLoading",
);
const deleteStoreServiceError = createAction<any>(
  "store/deleteStoreServiceError",
);

const deleteStoreService =
  (storeID: string, storeServiceId: string, productId: string) =>
  (dispatch: AppDispatch) => {
    dispatch(deleteStoreServiceLoading());
    storeServiceApi
      .removeProductFromServiceStoreServiceItem(
        storeID,
        storeServiceId,
        productId,
      )
      .then((response: AxiosResponse<any>) => {
        dispatch(deleteStoreServiceSuccess(productId));
        dispatch(getStoreServicesByStore(storeID));
        dispatch(
          SnackBarActions.snackbar("Servizio rimosso dallo store", "success"),
        );
      })
      .catch((error: any) => {
        dispatch(deleteStoreServiceError(error));
        dispatch(
          SnackBarActions.snackbar(
            error.response.data["hydra:description"]
              ? error.response.data["hydra:description"]
              : "Qualcosa è andato storto",
            "error",
          ),
        );
      });
  };

const patchStoreServiceSuccess = createAction<StoreService>(
  "store/patchStoreServiceSuccess",
);
const patchStoreServiceLoading = createAction("store/patchStoreServiceLoading");
const patchStoreServiceError = createAction<any>(
  "store/patchStoreServiceError",
);

const patchStoreService =
  (id: string, request: any) => (dispatch: AppDispatch) => {
    dispatch(patchStoreServiceLoading());
    storeServiceApi
      .patchStoreServiceItem(id, request)
      .then((response: AxiosResponse) => {
        dispatch(patchStoreServiceSuccess(response.data));
        //dispatch(getStoreServicesByStore(id))
        dispatch(SnackBarActions.snackbar("Fatto!", "success"));
      })
      .catch((error: any) => {
        dispatch(patchStoreServiceError(error));
        dispatch(
          SnackBarActions.snackbar(
            error.response.data["hydra:description"]
              ? error.response.data["hydra:description"]
              : "Qualcosa è andato storto",
            "error",
          ),
        );
      });
  };

//STORE SERVICES BY STORE
const getStoreServiceByStoreSuccess = createAction<Service[]>(
  "store/getStoreServiceByStoreSuccess",
);
const getStoreServiceByStoreLoading = createAction(
  "store/getStoreServiceByStoreLoading",
);
const getStoreServiceByStoreError = createAction<any>(
  "store/getStoreServiceByStoreError",
);

const getStoreServicesByStore =
  (storeId: string) => (dispatch: AppDispatch) => {
    dispatch(getStoreServiceByStoreLoading());
    storeServiceApi
      .getStoreservicesForCurrentStoreStoreServiceCollection(storeId)
      .then((response: AxiosResponse<any>) => {
        dispatch(getStoreServiceByStoreSuccess(response.data["hydra:member"]));
      })
      .catch((error: any) => {
        dispatch(getStoreServiceByStoreError(error));
      });
  };

const filterStoresAction = createAction<string>("store/search");

const searchStores = (term: string) => (dispatch: AppDispatch) => {
  dispatch(filterStoresAction(term));
};

const removeStoreOpenScheduleAction = createAction<string>(
  "store/removeSchedule",
);

const removeStoreOpenSchedule = (id: string) => (dispatch: AppDispatch) => {
  dispatch(removeStoreOpenScheduleAction(id));
};

const addHourRangeAction = createAction<{ day: string; hourRange: string }>(
  "store/addHourRange",
);

const addHourRange = (day: string) => (dispatch: AppDispatch) => {
  let hourRange =
    moment().format("HH:mm") + " - " + moment().add(2, "hour").format("HH:mm");
  dispatch(addHourRangeAction({ day, hourRange }));
};

const deleteHourRangeAction = createAction<{ day: string; hour: string }>(
  "store/removeHourRange",
);

const deleteHourRange =
  (day: string, hour: string) => (dispatch: AppDispatch) => {
    dispatch(deleteHourRangeAction({ day, hour }));
  };

const updateSingleHourAction = createAction<{
  day: string;
  oldHour: string;
  newHour: string;
}>("store/removeHourRange");

const updateSingleHour =
  (day: string, oldHour: string, newHour: string) =>
  (dispatch: AppDispatch) => {
    dispatch(updateSingleHourAction({ day, oldHour, newHour }));
  };

const setRowsPerPageAction = createAction<number>("store/setPerPage");

const setRowsPerPage = (value: number) => (dispatch: AppDispatch) => {
  dispatch(setRowsPerPageAction(value));
};

const setPageIndexAction = createAction<number>("store/setPageIndex");

const setPageIndex = (value: number) => (dispatch: AppDispatch) => {
  dispatch(setPageIndexAction(value));
};

const sortStoresAction = createAction<"asc" | "desc">("store/order");

const sortStores = (order: "asc" | "desc") => (dispatch: AppDispatch) => {
  dispatch(sortStoresAction(order));
};

export default {
  getStores,
  getStoresLoading,
  getStoresError,
  getStoresSuccess,
  getSingleStore,
  getSingleStoreSuccess,
  getSingleStoreError,
  getSingleStoreLoading,
  editStore,
  editStoreSuccess,
  editStoreLoading,
  editStoreError,
  createStore,
  createStoreSuccess,
  createStoreLoading,
  createStoreError,
  getUserCurrentStores,
  getUserCurrentStoresError,
  getUserCurrentStoresLoading,
  getUserCurrentStoresSuccess,
  setCurrentSelectedStore,
  currentSelectedStore,
  archiveStore,
  createStoreService,
  createStoreServicesLoading,
  createStoreServicesError,
  createStoreServicesSuccess,
  deleteStoreService,
  deleteStoreServiceError,
  deleteStoreServiceLoading,
  deleteStoreServiceSuccess,
  getStoreServicesByStore,
  getStoreServiceByStoreSuccess,
  getStoreServiceByStoreLoading,
  getStoreServiceByStoreError,
  patchStoreService,
  patchStoreServiceError,
  patchStoreServiceLoading,
  patchStoreServiceSuccess,
  searchStores,
  filterStoresAction,
  sortStores,
  sortStoresAction,
  // addStoreOpenSchedule,
  // addStoreOpenScheduleAction,
  removeStoreOpenScheduleAction,
  removeStoreOpenSchedule,
  addHourRange,
  addHourRangeAction,
  deleteHourRange,
  deleteHourRangeAction,
  updateSingleHour,
  updateSingleHourAction,
  setRowsPerPage,
  setRowsPerPageAction,
  sortStoresByField,
  setPageIndexAction,
  setPageIndex,
};
