import { SvgIcon } from '@mui/material';
import React from 'react';
import { useIconColor } from './IconColor';
import { IconProps } from './iconProps';
export const EditNoteIcon = ({ color, className }: IconProps) => {
  const iconColor = useIconColor(color);

  return (
    <SvgIcon className={className}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 7.14192V12.6593C14.5 13.3999 13.9193 13.9999 13.2027 13.9999H3.79733C3.08067 13.9999 2.5 13.3999 2.5 12.6599V4.67325C2.5 3.93325 3.08067 3.33325 3.79733 3.33325H7.36467"
          stroke={iconColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.83333 7.99984H4.5"
          stroke={iconColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.5 10.6666H12.5"
          stroke={iconColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.2227 2.98242L13.5175 4.27723"
          stroke={iconColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.5 6.38149L12.5463 2.3352C12.9932 1.88827 13.7179 1.88827 14.1648 2.3352C14.6117 2.78214 14.6117 3.50678 14.1648 3.95372L10.1185 8L8.5 8L8.5 6.38149Z"
          stroke={iconColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
