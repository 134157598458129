import { combineReducers } from "@reduxjs/toolkit";
import AuthReducer from "./AuthReducer";
import StoreReducer from "./StoreReducer";
import UsersReducer from "./UsersReducer";
import UiReducer from "./UI/UiReducer";
import ServiceReducer from "./ServiceReducer";
import AppointmentsReducer from "./AppointmentsReducer";
import BusinessHoursReducer from "./BusinessHoursReducer";
import StoreServiceReducer from "./StoreServiceReducer";
import ProductsReducer from "./ProductsReducer";
import BusinessHourRequestReducer from "./BusinessHourRequestReducer";
import BookAndCollectReducer from "./BookAndCollectReducer";
import ClickAndLendingReducer from "./ClickAndLendingReducer";
import { reducer as formReducer } from "redux-form";

const appReducer = combineReducers({
  form: formReducer,
  AuthReducer,
  StoreReducer,
  UiReducer,
  UsersReducer,
  ServiceReducer,
  AppointmentsReducer,
  BusinessHoursReducer,
  StoreServiceReducer,
  ProductsReducer,
  BusinessHourRequestReducer,
  BookAndCollectReducer,
  ClickAndLendingReducer,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
