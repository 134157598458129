import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { AxiosResponse } from "axios";
import { ClickAndLendingReservationApi } from "../../api/services";
import {
  ClickAndLending,
  ClickAndLendingPathOptions,
  HydratedClickAndLending,
  Store,
} from "../../api/entities";

let api = new ClickAndLendingReservationApi();

//GET CLICK_LENDING RESERVATIONS
const getReservationsSuccess = createAction<HydratedClickAndLending>(
  "click_and_lending/getReservationsSuccess",
);
const getReservationsLoading = createAction(
  "click_and_lending/getReservationsLoading",
);
const getReservationsError = createAction<any>(
  "click_and_lending/getReservationsError",
);

const getAllClickAndLendingReservations =
  (
    _uuid: string,
    _orderDirection: string = "desc",
    _page: number = 1,
    _itemsPerPage: number = 10,
    _orderByField: string = "createdAt",
    _status?: string,
    _reservationCode?: string,
    _userName?: string,
    _userSurname?: string,
  ) =>
  (dispatch: AppDispatch) => {
    let uuid = _uuid;
    let orderByField = _orderByField;
    let orderDirection = _orderDirection;
    let productName = undefined;
    let productVariant = undefined;
    let productCode = undefined;
    let productQuantity = undefined;
    let createdAt = undefined;
    let updatedAt = undefined;
    let referral = undefined;
    let userName = _userName;
    let userSurname = _userSurname;
    let userEmail = undefined;
    let userPhone = undefined;
    let reservationStatus = _status === "" ? undefined : _status;
    let reservationCode = _reservationCode;
    let outcome = undefined;
    let reservationNotes = undefined;
    let status = undefined;
    let page = _page;
    let itemsPerPage = _itemsPerPage;
    let pagination = undefined;
    let options = undefined;

    dispatch(getReservationsLoading());
    api
      .getAllClickAndLendingReservationCollection(
        uuid,
        orderByField,
        orderDirection,
        productName,
        productVariant,
        productCode,
        productQuantity,
        createdAt,
        updatedAt,
        referral,
        userName,
        userSurname,
        userEmail,
        userPhone,
        reservationStatus,
        reservationCode,
        outcome,
        reservationNotes,
        status,
        page,
        itemsPerPage,
        pagination,
        options,
      )
      .then((response: AxiosResponse<HydratedClickAndLending>) => {
        dispatch(getReservationsSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(getReservationsError(error));
      });
  };

//CURRENT SELECTED STORE
const currentSelectedStore = createAction<Store>(
  "click_and_lending/currentSelectedStore",
);
const setCurrentSelectedStore = (store: Store) => (dispatch: AppDispatch) => {
  dispatch(currentSelectedStore(store));
};

const setRowsPerPage = (value: number) => (dispatch: AppDispatch) => {
  dispatch(setRowsPerPageAction(value));
};
const setRowsPerPageAction = createAction<number>(
  "click_and_lending/setPerPage",
);

const patchSuccess = createAction<ClickAndLending>(
  "click_and_lending/patchSuccess",
);
const patchLoading = createAction("click_and_lending/patchLoading");
const patchError = createAction<any>("click_and_lending/patchError");

const updateReservation =
  (id: string, patchRequest: ClickAndLendingPathOptions, action: any) =>
  (dispatch: AppDispatch) => {
    dispatch(patchLoading());
    api
      .patchClickAndLendingReservationItem(id, patchRequest)
      .then((response: AxiosResponse<ClickAndLending>) => {
        dispatch(patchSuccess(response.data));
        action();
      })
      .catch((error: any) => {
        dispatch(patchError(error));
        action();
      });
  };

const productsSuccess = createAction<any>("click_and_lending/productsSuccess");

const getProducts = () => (dispatch: AppDispatch) => {
  fetch("https://s3.eu-west-1.amazonaws.com/it.iqos-bp-web/products.json")
    .then((response) => response.json())
    .then((json) => dispatch(productsSuccess(json)));
};

export default {
  getProducts,
  productsSuccess,
  getReservationsSuccess,
  getReservationsLoading,
  getReservationsError,
  getAllClickAndLendingReservations,
  currentSelectedStore,
  setCurrentSelectedStore,
  updateReservation,
  setRowsPerPage,
  setRowsPerPageAction,
  patchSuccess,
  patchLoading,
  patchError,
};
