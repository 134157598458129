import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { BookAndCollectActions } from "../actions";
import _ from "lodash";
import {
  BookAndCollect,
  HydratedBookAndCollect,
  Store,
} from "../../api/entities";
import LocalStorageService from "../../services/LocalStorageService";

export const rowsPerPageOptions = [10, 30, 100];

export type BookAndCollectState = {
  hydratedBookAndCollectReservations?: HydratedBookAndCollect;
  loadingReservations: boolean;
  reservationsError: boolean;
  patchInProgress: boolean;
  rowsPerPage: number;
  currentSelectedStore: Store;
};

const INITIAL_BOOK_AND_COLLECT_STATE: BookAndCollectState = {
  loadingReservations: false,
  patchInProgress: false,
  reservationsError: false,
  rowsPerPage: rowsPerPageOptions[1],
  currentSelectedStore: {},
};

const updateBookAndCollect = (
  hydratedBookAndCollectReservations: HydratedBookAndCollect | undefined,
  request: BookAndCollect,
) => {
  if (hydratedBookAndCollectReservations) {
    let index = _.findIndex(
      hydratedBookAndCollectReservations["hydra:member"],
      (member) => member.uuid === request.uuid,
    );
    let newArray = [
      ...hydratedBookAndCollectReservations["hydra:member"].slice(0, index),
      request,
      ...hydratedBookAndCollectReservations["hydra:member"].slice(index + 1),
    ];
    return { ...hydratedBookAndCollectReservations, "hydra:member": newArray };
  }
  return hydratedBookAndCollectReservations;
};

const BookAndCollectReducer = createReducer(
  INITIAL_BOOK_AND_COLLECT_STATE,
  (builder) =>
    builder
      //get book and collect reservations
      .addCase(
        BookAndCollectActions.getReservationsLoading,
        (state: BookAndCollectState) => {
          return { ...state, loadingReservations: true };
        },
      )
      .addCase(
        BookAndCollectActions.getReservationsSuccess,
        (
          state: BookAndCollectState,
          action: PayloadAction<HydratedBookAndCollect>,
        ) => {
          let hydratedBookAndCollectReservations = action.payload;
          return {
            ...state,
            hydratedBookAndCollectReservations,
            loadingReservations: false,
            reservationsError: false,
          };
        },
      )
      .addCase(
        BookAndCollectActions.getReservationsError,
        (state: BookAndCollectState, action: any) => {
          return {
            ...state,
            reservationsError: action.payload,
            loadingReservations: false,
          };
        },
      )

      // set current selected store
      .addCase(
        BookAndCollectActions.currentSelectedStore,
        (state: BookAndCollectState, action: PayloadAction<Store>) => {
          let currentSelectedStore = action.payload;
          LocalStorageService.getInstance().set(
            LocalStorageService.CURRENT_STORE,
            currentSelectedStore,
          );
          return { ...state, currentSelectedStore };
        },
      )

      // set store per page
      .addCase(
        BookAndCollectActions.setRowsPerPageAction,
        (state: BookAndCollectState, action: PayloadAction<number>) => {
          return { ...state, rowsPerPage: action.payload };
        },
      )

      // patch one book and collect
      .addCase(
        BookAndCollectActions.patchLoading,
        (state: BookAndCollectState) => {
          return { ...state, patchInProgress: true };
        },
      )
      .addCase(
        BookAndCollectActions.patchSuccess,
        (state: BookAndCollectState, action: PayloadAction<BookAndCollect>) => {
          const patchedBookAndCollect = action.payload;
          return {
            ...state,
            patchInProgress: false,
            hydratedBookAndCollectReservations: updateBookAndCollect(
              state.hydratedBookAndCollectReservations,
              patchedBookAndCollect,
            ),
          };
        },
      )
      .addCase(
        BookAndCollectActions.patchError,
        (state: BookAndCollectState, action: any) => {
          return { ...state, patchInProgress: false };
        },
      ),
);

export default BookAndCollectReducer;
