import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
interface UserRoleLabelProps {
  role?: string;
  menu?: boolean;
}

export default function UserRoleLabel(props: UserRoleLabelProps) {
  const classes = useStyles();

  const title = () => {
    switch (props.role?.toLowerCase()) {
      case 'admin':
        return 'Admin';
      case 'shopassistant':
      case 'shop assistant':
        return 'Shop Assistant';
      case 'super store manager':
        return 'Super Store Manager';
      case 'store manager':
        return 'Store Manager';
      case 'manager':
        return 'Shop Manager';
      case 'call center':
        return 'Call Center';
      default:
        return props.role;
    }
  };

  return (
    <Box
      className={!props.menu ? classes.box : classes.menuBox}
      border={!props.menu ? 1 : 0}
      width={'unset'}
      display={'inline-block'}
    >
      <Typography className={!props.menu ? classes.title : classes.menuTitle}>
        {title()}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      padding: '2px 10px',
      borderColor: '#E1E0E2 !important',
      borderRadius: 4,
    },
    menuBox: {
      padding: 6,
    },
    title: {
      fontSize: '14px !important',
      fontWeight: 'bold',
      lineHeight: '14px',
      color: '#34303D',
    },
    menuTitle: {
      fontSize: '14px !important',
      padding: '0 !important',
      fontWeight: 'bold',
      color: 'white',
    },
  }),
);
