import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Grid, Typography, Box } from '@mui/material';
import StoreServiceButton from './StoreServiceButton';
import { Product } from '../../../api/entities';
import _ from 'lodash';
import { StoreActions } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import ActionDialog from '../../dialog/ActionDialog';
import { UserRoles } from '../../../utils/UserRoles';
import { SnackBarActions } from '../../../redux/actions';
import { PRODUCT_VEEV } from '../../../utils/validators';

export default function StoreServiceCard() {
  const classes = useStyles();
  const [openActionDialog, setOpenActionDialog] = useState(false);
  const dispatch = useDispatch();
  const storeDetail = useSelector(
    (state: RootState) => state.StoreReducer.storeDetail,
  );
  const currentRole = useSelector(
    (state: RootState) => state.UsersReducer.currentRole,
  );
  const [serviceForDelete, setServiceForDelete] = useState('');
  const [productId, setProductId] = useState('');
  const allServices = useSelector((state: RootState) =>
    state.ServiceReducer.hydratedService?.['hydra:member']
      ? state.ServiceReducer.hydratedService?.['hydra:member']
      : [],
  );

  const getStoreServices = (productId: string | undefined) => {
    if (allServices && productId) {
      const storeServices = storeDetail ? storeDetail.storeServices : [];
      return _.map(allServices, function (obj) {
        return {
          ...obj,
          selected: storeServices
            ? storeServices.some(
                (storeService) =>
                  storeService.service?.uuid === obj.uuid &&
                  storeService.products?.some(
                    (product) => product.uuid === productId,
                  ),
              )
            : false,
        };
      });
    }
    return [];
  };

  const handleServiceSelection = (
    selectedCatalogServiceId: string,
    productId: string,
  ) => {
    addService(selectedCatalogServiceId, productId);
  };

  const addService = (selectedCatalogServiceId: string, productId: string) => {
    if (storeDetail?.uuid) {
      let catalogService = _.find(allServices, function (o) {
        return o.uuid === selectedCatalogServiceId;
      });
      if (catalogService && catalogService.uuid) {
        dispatch(
          StoreActions.createStoreService(
            storeDetail.uuid,
            catalogService.uuid,
            productId,
          ),
        );
      }
    }
  };

  const deleteStoreService = () => {
    setOpenActionDialog(false);
    let serviceForRemove = _.find(storeDetail?.storeServices, function (o) {
      return o.service?.uuid === serviceForDelete;
    });

    let service = serviceForRemove?.service;
    if (service && service.uuid && storeDetail && storeDetail.uuid) {
      dispatch(
        StoreActions.deleteStoreService(
          storeDetail.uuid,
          service.uuid,
          productId,
        ),
      );
    }
  };

  const handleOpenActionDialog = (id: string, productId: string) => {
    setProductId(productId);
    setServiceForDelete(id);
    setOpenActionDialog(true);
  };

  const isAdminOrStoreManager = () => {
    return (
      currentRole === UserRoles.ADMIN ||
      currentRole === UserRoles.SUPER_STORE_MANAGER ||
      currentRole === UserRoles.STORE_MANAGER
    );
  };

  return (
    <Grid className={classes.root}>
      {storeDetail?.products &&
        storeDetail.products
        .filter((p: Product) => p.productName !== PRODUCT_VEEV)
        .map((product: Product) => {
          return (
            <>
              <Typography className={classes.title}>
                {'Servizi ' + product.productName}
              </Typography>
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexWrap="wrap"
                width="100%"
                sx={{ marginTop: '16px' }}
                gap="12px"
              >
                {getStoreServices(product.uuid).map((service) => (
                  <StoreServiceButton
                    key={service.uuid}
                    id={service.uuid}
                    storeService={storeDetail?.storeServices?.find(
                      (o) => o.service?.uuid === service.uuid,
                    )}
                    checked={service.selected}
                    title={service.publicName ?? ''}
                    onSelect={(id: string, checked) => {
                      if (isAdminOrStoreManager()) {
                        checked
                          ? handleOpenActionDialog(
                              id,
                              product.uuid ? product.uuid : '',
                            )
                          : handleServiceSelection(
                              id,
                              product.uuid ? product.uuid : '',
                            );
                      } else {
                        dispatch(SnackBarActions.snackbar('Cont', 'warning'));
                      }
                    }}
                  />
                ))}
              </Box>
            </>
          );
        })}

      <ActionDialog
        title="Servizio"
        message="Sei sicuro di voler rimuovere questo servizio dallo store?"
        onAction={() => {
          deleteStoreService();
        }}
        open={openActionDialog}
        onClose={() => setOpenActionDialog(false)}
        actionTitle="Rimuovi"
      />
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      fontSize: '20px !important',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px !important',
      marginTop: '32px !important',
    },
    gridList: {
      padding: 8,
    },
  }),
);
