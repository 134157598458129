import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';

export default function OverlaySpinner() {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={0} direction="column">
      <CircularProgress />
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      width: '100wv',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 1000,
    },
  }),
);
