import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { AxiosResponse } from "axios";
import { UserApi } from "../../api/services/nbp/user-api";
import { HydratedUser, Roles, User } from "../../api/entities";
import { UserRoles } from "../../utils/UserRoles";
import SnackBarActions from "./UI/SnackBarActions";

let api = new UserApi();

const getCurrentUserSuccess = createAction<User>("users/getCurrentUserSuccess");
const getCurrentUserError = createAction<any>("users/getCurrentUserError");
const getCurrentUserLoading = createAction("users/getCurrentUserLoading");

const setCurrentRole = createAction<Roles>("users/currentRole");

//GET CURRENT USER
const getCurrent = () => (dispatch: AppDispatch) => {
  dispatch(getCurrentUserLoading());
  api
    .getCurrentUserUserCollection()
    .then((response: AxiosResponse<HydratedUser>) => {
      // set role from response, and map inside Roles type in ./entities

      const role: Roles = response.data["hydra:member"][0].businessRole
        ? (response.data["hydra:member"][0].businessRole as Roles)
        : UserRoles.ADMIN;

      dispatch(setCurrentRole(role));
      dispatch(getCurrentUserSuccess(response.data["hydra:member"][0]));
    })
    .catch((error: any) => {
      dispatch(getCurrentUserError(error));
    });
};

const getUsersSuccess = createAction<HydratedUser>("users/getUsersSuccess");
const getUsersError = createAction<any>("users/getUsersError");
const getUsersLoading = createAction("users/getUsersLoading");

//GET USERS
const getUsers =
  (_page: number = 1, _itemsPerPage: number = 10, term?: string) =>
  (dispatch: AppDispatch) => {
    let page = _page;
    let itemsPerPage = _itemsPerPage;
    dispatch(getUsersLoading());
    api
      .getUserCollection(term, page, itemsPerPage)
      .then((response: AxiosResponse<HydratedUser>) => {
        dispatch(getUsersSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(getUsersError(error));
      });
  };

//GET SINGLE USER
const getSingleUserSuccess = createAction<User>("users/getSingleUserSuccess");
const getSingleUserError = createAction<any>("users/getSingleUserError");
const getSingleUserLoading = createAction("users/getSingleUserLoading");

const getSingleUser = (id: string) => (dispatch: AppDispatch) => {
  dispatch(getSingleUserLoading());
  api
    .getUserItem(id)
    .then((response: AxiosResponse<User>) => {
      dispatch(getSingleUserSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(getSingleUserError(error));
    });
};

const patchUserSuccess = createAction<User>("users/patchUserSuccess");
const patchUserError = createAction<any>("users/patchUserError");
const patchUserLoading = createAction("users/patchUserLoading");

// UPDATE USER
const patchUser = (id: string, request: any) => (dispatch: AppDispatch) => {
  dispatch(patchUserLoading());
  api
    .patchUserItem(id, request)
    .then((response: AxiosResponse<User>) => {
      dispatch(patchUserSuccess(response.data));
      dispatch(SnackBarActions.snackbar("Utente modificato", "success"));
      dispatch(getUsers());
    })
    .catch((error: any) => {
      dispatch(patchUserError(error));
      dispatch(
        SnackBarActions.snackbar(
          error.response.data["hydra:description"]
            ? error.response.data["hydra:description"]
            : "Qualcosa è andato storto",
          "error",
        ),
      );
    });
};

// SUSPEND USER
const patchUserStatus =
  (userId: string, status?: string) => (dispatch: AppDispatch) => {
    dispatch(patchUserLoading());
    let request = { status };
    api
      .patchStatusUserItem(userId, request)
      .then((response: AxiosResponse<User>) => {
        dispatch(patchUserSuccess(response.data));
        dispatch(
          SnackBarActions.snackbar(
            `Lo stato dell'utente è stato modificato`,
            "success",
          ),
        );
      })
      .catch((error: any) => {
        dispatch(patchUserError(error));
        dispatch(
          SnackBarActions.snackbar(
            error.response.data["hydra:description"]
              ? error.response.data["hydra:description"]
              : "Qualcosa è andato storto",
            "error",
          ),
        );
      });
  };

//DELETE USER
const deleteUserSuccess = createAction<string>("users/deleteSuccess");
const deleteUserError = createAction<any>("users/deleteUserError");
const deleteUserLoading = createAction("users/deleteUserLoading");

const deleteUser = (id: string) => (dispatch: AppDispatch) => {
  dispatch(deleteUserLoading());
  api
    .deleteUserItem(id)
    .then((response: AxiosResponse<any>) => {
      dispatch(deleteUserSuccess(id));
      dispatch(SnackBarActions.snackbar("Utente eliminato", "success"));
      dispatch(getUsers());
    })
    .catch((error: any) => {
      dispatch(deleteUserError(error));
      dispatch(
        SnackBarActions.snackbar(
          error.response.data["hydra:description"]
            ? error.response.data["hydra:description"]
            : "Qualcosa è andato storto",
          "error",
        ),
      );
    });
};

const searchUsersSuccess = createAction<HydratedUser>(
  "users/searchUserSuccess",
);
const searchUsersError = createAction<any>("users/searchUserError");
const searchUsersLoading = createAction("users/searchUserLoading");

// POST NEW USER
const postUserSuccess = createAction<User>("users/postUserSuccess");
const postUserError = createAction<any>("users/postUserError");
const postUserLoading = createAction("users/postUserLoading");

const createUser = (request: any) => (dispatch: AppDispatch) => {
  dispatch(postUserLoading());
  api
    .postUserCollection(request)
    .then((response: AxiosResponse<User>) => {
      dispatch(postUserSuccess(response.data));
      dispatch(SnackBarActions.snackbar("Creato un nuovo utente", "success"));
    })
    .catch((error) => {
      dispatch(postUserError(error));
      dispatch(
        SnackBarActions.snackbar(
          error.response.data["hydra:description"]
            ? error.response.data["hydra:description"]
            : "Qualcosa è andato storto",
          "error",
        ),
      );
    });
};

export default {
  getUsers,
  getUsersLoading,
  getUsersSuccess,
  getUsersError,
  getCurrent,
  getCurrentUserSuccess,
  getCurrentUserError,
  getCurrentUserLoading,
  setCurrentRole,
  getSingleUser,
  getSingleUserError,
  getSingleUserLoading,
  getSingleUserSuccess,
  patchUser,
  patchUserError,
  patchUserLoading,
  patchUserSuccess,
  searchUsersSuccess,
  searchUsersError,
  searchUsersLoading,
  patchUserStatus,
  deleteUser,
  deleteUserSuccess,
  deleteUserLoading,
  deleteUserError,
  createUser,
  postUserSuccess,
  postUserLoading,
  postUserError,
};
