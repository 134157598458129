import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Theme,
  Toolbar,
} from '@mui/material';
import React, { useState } from 'react';
import _ from 'lodash';
import UserCreateModal from '../profile/UserCreateModal';
import { createStyles, makeStyles } from '@mui/styles';
import { AddIcon } from '../../icons/AddIcon';
import { SearchIcon } from '../../icons/SearchIcon';

interface UsersTableToolbarProps {
  onSearch: (term: string) => void;
}

export default function UsersTableToolbar({
  onSearch,
}: UsersTableToolbarProps) {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);

  let debounced = _.debounce(onSearch, 400, { leading: false });

  const handleOpenModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Toolbar className={classes.toolbar}>
      <Box
        className={classes.barWrapper}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <div className={classes.toolbarTitle}>Utenti</div>
          <TextField
            type="search"
            className={classes.searchField}
            placeholder="Cerca utente"
            color="secondary"
            size="small"
            inputProps={{
              style: {
                padding: '12px 16px 12px 0px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
            onChange={(event) => debounced(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon small={true} color="#7D7982" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          className={classes.toolBarButton}
          onClick={() => handleOpenModal()}
          startIcon={<AddIcon color="#34303D" />}
        >
          Nuovo Utente
        </Button>
      </Box>
      <UserCreateModal
        isVisible={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Toolbar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      width: '100%',
      padding: '20px 20px 0 20px !important',
      marginBottom: '32px',
    },
    barWrapper: {
      width: '100%',
    },
    toolBarButton: {
      padding: '14px 24px !important',
      borderRadius: '50px !important',
      color: '#34303d !important',
      lineHeight: '16px !important',
      fontSize: '16px !important',
    },
    toolbarTitle: {
      color: '#34303D',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    searchField: {
      width: 350,
      alignSelf: 'center',
      marginRight: '10px !important',
      marginLeft: '24px !important',
    },
  }),
);
