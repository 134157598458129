import React, { useMemo } from 'react';
import { TextField, Box, Autocomplete } from '@mui/material';
import _ from 'lodash';

interface Role {
  id: number;
  value: string;
  label: string;
}

const roles: Role[] = [
  {
    id: 0,
    value: 'ADMIN',
    label: 'Admin',
  },
  {
    id: 1,
    value: 'SHOP ASSISTANT',
    label: 'Shop Assistant',
  },
  {
    id: 2,
    value: 'STORE MANAGER',
    label: 'Store Manager',
  },
  {
    id: 3,
    value: 'CALL CENTER',
    label: 'Call Center',
  },
  {
    id: 4,
    value: 'SUPER STORE MANAGER',
    label: 'Super Store Manager',
  },
];

interface RoleTextFieldProps {
  onSelect: (region: string) => void;
  value: string;
}

export default function RoleTextField({ value, onSelect }: RoleTextFieldProps) {
  const handleChange = (role: Role | null) => {
    if (!role) return;
    onSelect(role?.value);
  };

  const role = useMemo(() => {
    return roles.find((r) => r.value === value) || null;
  }, [value]);

  return (
    <Box>
      <Autocomplete
        noOptionsText="Nessun risultato"
        fullWidth
        id="standard-select-role"
        value={role}
        color="secondary"
        size="small"
        options={roles}
        onChange={(e: any, option: Role | null) => handleChange(option)}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            color="secondary"
            label="Seleziona tipo di utente"
            size="small"
            inputProps={{
              ...params.inputProps,
              style: {
                padding: '6px 10px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
          />
        )}
      />
    </Box>
  );
}
