import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { IOSSwitch as Switch } from '../../../forms/Switch';
import DialogActionFooter from '../../../dialog/DialogActionFooter';
import PlaceTextField from '../../../PlaceTextField';
import '../../../../utils/extensions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { SnackBarActions, StoreActions } from '../../../../redux/actions';
import RegionTextField from '../../../RegionTextField';
import StoreProductsField from '../StoreProductsField';
import _ from 'lodash';
import CapacityTextField from '../../../services/CapacityTextField';
import { validatePhone } from '../../../../utils/validators';
import { UserRoles } from '../../../../utils/UserRoles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { CloudUploadIcon } from '../../../icons/CloudUploadIcon';

interface StoreEditInfoModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export default function StoreEditInfoModal(props: StoreEditInfoModalProps) {
  const classes = useStyles();
  const storeDetail = useSelector(
    (state: RootState) => state.StoreReducer.storeDetail,
  );
  const [name, setName] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [phoneNumber, setPhone] = useState<string>('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>('');
  const [slug, setSlug] = useState<string>('');
  const [crmStoreId, setCrmId] = useState<string>('');
  const [secondCrmStoreId, setSecondCrmStoreId] = useState<string>('');
  const [placeId, setPlaceId] = useState<string>('');
  const [visibilityStartDate, setStartDate] = useState<Moment | null>(null);
  const [visibilityEndDate, setEndDate] = useState<Moment | null>(null);
  const [storeIsVisible, setStoreIsVisible] = useState(true);
  const [currentPhoto, setCurrentPhoto] = useState('');
  const [products, setStoreProducts] = useState<any[]>([]);
  const [capacity, setCapacity] = useState<number>(0);
  const [capacityIsVisible, setCapacityIsVisible] = useState(false);
  const dispatch = useDispatch();

  const phoneErrorText = () => {
    return !validatePhone(phoneNumber) && 'numero di telefono non valido';
  };

  const catalogProducts = useSelector((state: RootState) =>
    state.ProductsReducer.hydratedProducts?.['hydra:member']
      ? state.ProductsReducer.hydratedProducts?.['hydra:member']
      : [],
  );
  const currentRole = useSelector(
    (state: RootState) => state.UsersReducer.currentRole,
  );

  useEffect(() => {
    setName(storeDetail?.name || '');
    setRegion(storeDetail?.region || '');
    setAddress(storeDetail?.address || '');
    setPhone(storeDetail?.phoneNumber || '');
    setCellPhoneNumber(storeDetail?.cellPhoneNumber || '');
    setCrmId(storeDetail?.crmStoreId || '');
    setSecondCrmStoreId(storeDetail?.secondCrmStoreId || '');
    setSlug(storeDetail?.slug || '');

    const startDate: Moment = storeDetail?.visibilityStartDate
      ? moment(storeDetail.visibilityStartDate)
      : moment(new Date());

    const endDate: Moment = storeDetail?.visibilityEndDate
      ? moment(storeDetail.visibilityEndDate)
      : moment(new Date());

    setStartDate(startDate);
    setEndDate(endDate);

    setCapacity(storeDetail?.capacity || 0);

    if (storeDetail?.capacity) {
      const isCapacityVisible =
        storeDetail.capacity !== undefined || storeDetail.capacity !== 0;
      setCapacityIsVisible(isCapacityVisible);
    }

    setStoreProducts(
      storeDetail?.products
        ? _.map(storeDetail.products, function (o) {
            return o.productName;
          })
        : [],
    );

    setStoreIsVisible(
      Boolean(
        storeDetail?.visibilityStartDate && storeDetail?.visibilityEndDate,
      ),
    );
  }, [storeDetail]);

  useEffect(() => {
    if (!capacityIsVisible) {
      setCapacity(0);
    }
  }, [capacityIsVisible]);

  const handleClose = () => {
    props.onClose();
  };

  const handleChangePhoto = (event: any) => {
    setCurrentPhoto(URL.createObjectURL(event.target.files[0]));
  };

  const checkAllDataSet = () => {
    return name !== '' && address !== '' && region !== '';
  };

  const withLocation = (location: any, currentRequest: any) => {
    let newRequest = {};
    if (location && location.lat && location.lng) {
      newRequest = {
        ...currentRequest,
        latitude: location.lat,
        longitude: location.lng,
      };
    } else {
      newRequest = { ...currentRequest };
    }
    return newRequest;
  };

  const productsIDS = (products: any[]) => {
    var filtered = catalogProducts.filter(function (obj) {
      return products.includes(obj.productName);
    });
    return _.map(filtered, function (o) {
      return o.uuid;
    });
  };

  const checkPhoneField = () => {
    if (!validatePhone(phoneNumber) && phoneNumber !== '') {
      dispatch(
        SnackBarActions.snackbar('Numero di telefono non valido', 'warning'),
      );
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    let location;
    let city;
    let storeID = storeDetail?.uuid ?? '';
    if (placeId !== '') {
      let results = await fetch(
        'https://maps.googleapis.com/maps/api/geocode/json?place_id=' +
          placeId +
          '&key=AIzaSyCgHbEyU7C1wS6O0sOrzBG8swoF86o3-us',
        { method: 'GET' },
      );
      let jsonResults = await results.json();
      location = jsonResults.results[0].geometry.location;
      let _city = _.find(
        jsonResults.results[0].address_components,
        function (o) {
          if (_.includes(o.types, 'locality')) {
            return o;
          }
        },
      );

      city = _city ? _city.long_name : '';
    }

    let baseStore = {
      name,
      address,
      city,
      phoneNumber,
      cellPhoneNumber,
      slug,
      crmStoreId,
      secondCrmStoreId,
      region,
      capacity,
      products: productsIDS(products),
      visibilityStartDate: '',
      visibilityEndDate: '',
    };

    if (checkAllDataSet()) {
      if (storeIsVisible) {
        let newStore = {
          ...baseStore,
          visibilityStartDate: storeIsVisible
            ? visibilityStartDate?.toISOString()
            : '',
          visibilityEndDate: storeIsVisible
            ? visibilityEndDate?.toISOString()
            : '',
        };
        if (currentPhoto !== '') {
          let withImageStore = {
            ...newStore,
            image: currentPhoto.replace('blob:', ''),
          };

          dispatch(
            StoreActions.editStore(
              storeID,
              withLocation(location, withImageStore),
            ),
          );
        } else {
          dispatch(
            StoreActions.editStore(storeID, withLocation(location, newStore)),
          );
        }
      } else {
        if (currentPhoto !== '') {
          let withImageStore = {
            ...baseStore,
            image: currentPhoto.replace('blob:', ''),
          };
          dispatch(
            StoreActions.editStore(
              storeID,
              withLocation(location, withImageStore),
            ),
          );
        } else {
          dispatch(
            StoreActions.editStore(storeID, withLocation(location, baseStore)),
          );
        }
      }
      handleClose();
    } else {
      dispatch(
        SnackBarActions.snackbar(
          'Per favore compila tutti i campi!',
          'warning',
        ),
      );
    }
  };

  const checkAndSave = () => {
    if (checkPhoneField()) {
      handleSave();
    }
  };

  return (
    <Dialog
      className={classes.modal}
      open={props.isVisible}
      onClose={() => handleClose()}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        Modifica Store
      </DialogTitle>
      <Grid className={classes.gridContainer}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box justifyContent="space-between">
            <TextField
              className={classes.contentField}
              autoFocus
              label="Nome"
              id="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              color="secondary"
              size="small"
              style={{ marginBottom: '24px' }}
              inputProps={{
                style: {
                  padding: '12px 16px',
                  borderColor: '#E1E0E2',
                },
              }}
              InputLabelProps={{
                style: {
                  top: '4px',
                },
              }}
            />
            <RegionTextField
              regionName={region}
              onSelect={(region) => setRegion(region)}
            />
            <PlaceTextField
              value={address}
              onSelect={(placeObject) => {
                setPlaceId(placeObject?.place_id ?? '');
                setAddress(placeObject?.description ?? '');
              }}
            />
            <TextField
              className={classes.contentField}
              label="Telefono"
              id="phone"
              error={phoneNumber !== '' && !validatePhone(phoneNumber)}
              helperText={phoneErrorText()}
              value={phoneNumber}
              onChange={(event) => setPhone(event.target.value)}
              color="secondary"
              size="small"
              style={{ marginBottom: '24px' }}
              inputProps={{
                style: {
                  padding: '12px 16px',
                  borderColor: '#E1E0E2',
                },
              }}
              InputLabelProps={{
                style: {
                  top: '4px',
                },
              }}
            />
            <TextField
              className={classes.contentField}
              label="Cellulare"
              id="phone"
              error={cellPhoneNumber !== '' && !validatePhone(phoneNumber)}
              helperText={phoneErrorText()}
              value={cellPhoneNumber}
              onChange={(event) => setCellPhoneNumber(event.target.value)}
              color="secondary"
              size="small"
              style={{ marginBottom: '24px' }}
              inputProps={{
                style: {
                  padding: '12px 16px',
                  borderColor: '#E1E0E2',
                },
              }}
              InputLabelProps={{
                style: {
                  top: '4px',
                },
              }}
            />
            <TextField
              className={classes.contentField}
              label="Identificativo"
              id="slug"
              value={slug}
              onChange={(event) => setSlug(event.target.value)}
              color="secondary"
              size="small"
              style={{ marginBottom: '24px' }}
              inputProps={{
                style: {
                  padding: '12px 16px',
                  borderColor: '#E1E0E2',
                },
              }}
              InputLabelProps={{
                style: {
                  top: '4px',
                },
              }}
            />
            {currentRole === UserRoles.ADMIN && (
              <TextField
                className={classes.contentField}
                label="CRM Id"
                id="crm_id"
                value={crmStoreId}
                onChange={(event) => setCrmId(event.target.value)}
                color="secondary"
                size="small"
                style={{ marginBottom: '24px' }}
                inputProps={{
                  style: {
                    padding: '12px 16px',
                    borderColor: '#E1E0E2',
                  },
                }}
                InputLabelProps={{
                  style: {
                    top: '4px',
                  },
                }}
              />
            )}
            {currentRole === UserRoles.ADMIN && (
              <TextField
                className={classes.contentField}
                label="Store Code Id"
                id="crm_id"
                value={secondCrmStoreId}
                onChange={(event) => setSecondCrmStoreId(event.target.value)}
                color="secondary"
                size="small"
                style={{ marginBottom: '24px' }}
                inputProps={{
                  style: {
                    padding: '12px 16px',
                    borderColor: '#E1E0E2',
                  },
                }}
                InputLabelProps={{
                  style: {
                    top: '4px',
                  },
                }}
              />
            )}
            <StoreProductsField
              defaultValues={products}
              onSelectProducts={(values) => setStoreProducts(values)}
            />
            <Box
              display="flex"
              width="100%"
              alignItems={'center'}
              justifyContent="space-between"
              sx={{ marginBottom: capacityIsVisible ? '24px' : 0 }}
            >
              <Typography className={classes.label}>
                Coda unica per i servizi
              </Typography>
              <Switch
                checked={capacityIsVisible}
                onChange={() => {
                  setCapacityIsVisible(!capacityIsVisible);
                }}
              />
            </Box>
            {capacityIsVisible && (
              <CapacityTextField
                capacity={capacity?.toString()}
                onSelectValue={(value) =>
                  setCapacity(value === '' ? 0 : parseInt(value))
                }
              />
            )}
            <Box
              display="flex"
              width="100%"
              alignItems={'center'}
              justifyContent="space-between"
              sx={{ marginBottom: storeIsVisible ? '24px' : 0 }}
            >
              <Typography className={classes.label}>
                Pianifica visibilità
              </Typography>
              <Switch
                checked={storeIsVisible}
                onChange={() => {
                  setStoreIsVisible(!storeIsVisible);
                }}
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
            {storeIsVisible && (
              <Box
                sx={{ marginBottom: storeIsVisible ? '24px' : 0 }}
                display="flex"
                width="100%"
                alignItems={'center'}
              >
                <Box display="flex" alignItems="center" width={'50%'}>
                  <Typography className={classes.visibleDateLabel}>
                    Data di inizio
                  </Typography>
                  <div>
                    <DatePicker
                      className={classes.date}
                      format="DD/MM/YYYY"
                      value={visibilityStartDate}
                      onChange={(event: any) =>
                        setStartDate(
                          moment(event ? event.toString() : new Date()),
                        )
                      }
                      slotProps={{
                        textField: {
                          color: 'secondary',
                          label: 'Data di inizio',
                          inputProps: {
                            style: {
                              padding: '12px 16px',
                              borderColor: '#E1E0E2',
                            },
                          },
                          InputLabelProps: {
                            style: {
                              top: '-4px',
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </Box>
                <Box display="flex" alignItems="center" width={'50%'}>
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.visibleDateLabel}>
                      Data di fine
                    </Typography>
                    <div>
                      <DatePicker
                        className={classes.date}
                        format="DD/MM/YYYY"
                        value={visibilityEndDate}
                        onChange={(event: any) =>
                          setEndDate(
                            moment(event ? event.toString() : new Date()),
                          )
                        }
                        slotProps={{
                          textField: {
                            color: 'secondary',
                            label: 'Data di fine',
                            inputProps: {
                              style: {
                                padding: '12px 16px',
                                borderColor: '#E1E0E2',
                              },
                            },
                            InputLabelProps: {
                              style: {
                                top: '-4px',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </Box>
                </Box>
              </Box>
            )}
            <Box>
              <Typography className={classes.label}>
                Foto dello store
              </Typography>
              <Box display="flex">
                <div className={classes.uploadRoot}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleChangePhoto}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      disableElevation
                      color="inherit"
                      className={classes.uploadButton}
                      startIcon={<CloudUploadIcon color="#34303D7" />}
                      component="span"
                    >
                      UPLOAD
                    </Button>
                  </label>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                  />
                </div>
                {storeDetail?.image && (
                  <img
                    src={currentPhoto}
                    className={classes.img}
                    alt="No image uploaded"
                  />
                )}
              </Box>
            </Box>
          </Box>
        </LocalizationProvider>
      </Grid>
      <Box
        className={classes.footerWrapper}
        justifyContent="space-between"
        alignItems="center"
        display="flex"
      >
        <DialogActionFooter
          onCancel={() => handleClose()}
          onConfirm={() => checkAndSave()}
          actionTitle="Modifica"
        />
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '16px !important',
    },
    gridContainer: {
      backgroundColor: 'white',
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 4,
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
    input: {
      display: 'none',
    },
    uploadRoot: {
      alignItems: 'center',
    },
    uploadButton: {
      marginTop: 6,
      fontWeight: 'bold',
      fontFamily: 'IQOS Sans',
      borderRadius: '40px !important',
    },
    label: {
      fontWeight: 'bold',
      fontSize: '14px !important',
      marginTop: '10px !important',
    },
    contentField: {
      width: '100%',
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
    img: { marginLeft: 16 },
    visibilityBox: {
      width: '80%',
    },
    visibleDateLabel: {
      fontSize: '14px !important',
      color: '#34303D',
      marginRight: '12px !important',
    },
    date: {
      fontSize: '14px',
      marginLeft: 16,
    },
    uploadBoxLabel: {
      marginTop: 4,
      fontWeight: 'bold',
      fontSize: 14,
    },
    imgIcon: {
      marginLeft: 8,
      marginTop: 6,
    },
  }),
);
