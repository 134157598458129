import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import DialogActionFooter from '../../../dialog/DialogActionFooter';
import Icons from '../../../../theme/Icons';
import { useDispatch } from 'react-redux';
import StoreProductsField from '../../../stores/details/StoreProductsField';
import CapacityTextField from '../../CapacityTextField';
import DurationTextField from '../../DurationTextField';
import { ServiceActions, SnackBarActions } from '../../../../redux/actions';
import { CloudUploadIcon } from '../../../icons/CloudUploadIcon';

interface EditServiceModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export default function CreateServiceModal({
  isVisible,
  onClose,
}: EditServiceModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [products, setProducts] = useState<string[]>([]);
  const [duration, setDuration] = useState<number>(1);
  const [capacity, setCapacity] = useState<number>(0);

  const handleSave = () => {
    if (name !== '' && duration > 0 && products.length > 0) {
      const newService = {
        publicName: name,
        defaultDuration: duration,
        defaultCapacity: capacity,
        products,
      };
      dispatch(ServiceActions.postCatalogService(newService));
      onClose();
    } else {
      dispatch(
        SnackBarActions.snackbar(
          'Alcuni campi non sono corretti, per favore ricontrolla.',
          'warning',
        ),
      );
    }
  };

  const handleChangeProducts = (values: any[]) => {
    setProducts(values);
  };

  return (
    <Dialog
      className={classes.modal}
      open={isVisible}
      onClose={onClose}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        Crea Servizio
      </DialogTitle>
      <Grid className={classes.gridContainer}>
        <Box justifyContent="space-between">
          <TextField
            className={classes.contentField}
            autoFocus
            color="secondary"
            label="Nome del servizio"
            id="name"
            size="small"
            style={{ marginBottom: '24px' }}
            inputProps={{
              style: {
                padding: '12px 16px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <CapacityTextField
            onSelectValue={(value: string) => setCapacity(parseInt(value))}
          />
          <DurationTextField
            onSelectValue={(value: string) => setDuration(parseInt(value))}
          />
          <StoreProductsField
            onSelectProducts={(values) => handleChangeProducts(values)}
          />
          <Typography className={classes.label}>Icona del servizio</Typography>
          <Box display="flex" alignItems="center">
            <div className={classes.uploadRoot}>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  disableElevation
                  color="inherit"
                  className={classes.uploadButton}
                  startIcon={<CloudUploadIcon color="#34303D" />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
              <input
                accept="image/*"
                className={classes.input}
                id="icon-button-file"
                type="file"
              />
            </div>
            <img src={Icons.EditService} className={classes.img} />
          </Box>
        </Box>
      </Grid>
      <Box className={classes.footerWrapper}>
        <DialogActionFooter
          onCancel={onClose}
          onConfirm={() => handleSave()}
          actionTitle="Crea"
        />
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '16px !important',
    },
    gridContainer: {
      backgroundColor: 'white',
      paddingBottom: 32,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 4,
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
    input: {
      display: 'none',
    },
    uploadRoot: {
      alignItems: 'center',
    },
    uploadButton: {
      marginTop: 6,
      fontWeight: 'bold',
      fontFamily: 'IQOS Sans',
      borderRadius: '40px !important',
    },
    label: {
      fontWeight: 'bold',
      fontSize: '14px !important',
      marginTop: '10px !important',
    },
    contentField: {
      width: '100%',
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
    img: { marginLeft: 16 },
  }),
);
