import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { AxiosResponse } from "axios";
import { StoreServiceApi } from "../../api/services/nbp/store-service-api";
import { HydratedTimeSlots } from "../../api/entities";

//Api Service instance from open api generator
let api = new StoreServiceApi();

//GET BUSINESS HOURS BY STORE
const getTimeSlotsSuccess = createAction<HydratedTimeSlots>(
  "time_slots/getTimeSlotsSuccess",
);
const getTimeSlotsLoading = createAction("time_slots/getTimeSlotsLoading");
const getTimeSlotsError = createAction<any>("time_slots/getTimeSlotsError");

const getTimeSlots =
  (_id: string, _date: string) => (dispatch: AppDispatch) => {
    let id = _id;
    let date = _date;

    dispatch(getTimeSlotsLoading());
    api
      .getTimeslotsStoreServiceCollection(id, date)
      .then((response: AxiosResponse<HydratedTimeSlots>) => {
        dispatch(getTimeSlotsSuccess(response.data));
      })
      .catch((error: any) => {
        dispatch(getTimeSlotsError(error));
      });
  };

// const postStoreServicesSuccess = createAction<StoreService>('store_service/postStoreServicesSuccess');
// const postStoreServicesLoading = createAction('store_service/postStoreStoreServicesLoading')
// const postStoreServicesError = createAction<any>('store_service/postStoreServicesError')

// const postStoreService = (storeID:string,storeService:RequestStoreService)  => (dispatch:AppDispatch) => {
// 	dispatch(postStoreServicesLoading())
// 	api.postStoreServiceCollection(storeID, storeService).then((response:AxiosResponse<StoreService>) => {
//         dispatch(postStoreServicesSuccess(response.data))
// 	}).catch((error:any) => {
// 		dispatch(postStoreServicesError(error))
// 	})
// }

export default {
  getTimeSlots,
  getTimeSlotsError,
  getTimeSlotsLoading,
  getTimeSlotsSuccess,
};
