import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { AxiosResponse } from "axios";
import { HydratedProducts } from "../../api/entities";
import { ProductApi } from "../../api/services";

//Api Service instance from open api generator
let api = new ProductApi();

//GET PRODUCTS
const getProductsSuccess = createAction<HydratedProducts>(
  "products/getProductsSuccess",
);
const getProductsLoading = createAction("products/getProductsLoading");
const getProductsError = createAction<any>("products/getProductsError");

const getProducts = () => (dispatch: AppDispatch) => {
  dispatch(getProductsLoading());
  api
    .getProductCollection()
    .then((response: AxiosResponse<HydratedProducts>) => {
      dispatch(getProductsSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(getProductsError(error));
    });
};

// //GET PRODUCTS BY STORE
// const getProductsByStoreSuccess = createAction<HydratedProducts>('products/getProductsByStoreSuccess')
// const getProductsByStoreLoading = createAction('products/getProductsByStoreLoading')
// const getProductsByStoreError = createAction<any>('products/getProductsByStoreError')

// const getProductsByStore = (storeId:string) => (dispatch:AppDispatch) => {
//    dispatch(getProductsByStoreLoading())

// }

export default {
  getProducts,
  getProductsError,
  getProductsLoading,
  getProductsSuccess,
};
