import React, { useCallback } from 'react';
import { UserRoles } from '../../utils/UserRoles';
import { LogoutIcon } from '../icons/LogoutIcon';
import { PeopleIcon } from '../icons/PeopleIcon';
import { StoreIcon } from '../icons/StoreIcon';
import { SettingsIcon } from '../icons/SettingsIcon';
import { ClockIcon } from '../icons/ClockIcon';
import { CalendarIcon } from '../icons/CalendarIcon';
import { makeStyles, createStyles } from '@mui/styles';
import {
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { ModalsActions } from '../../redux/actions';
import { Theme } from '@fullcalendar/core/internal';

interface Props {
  closeDrawer: () => void;
}

export const SideNavigation = ({ closeDrawer }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  const currentRole = useSelector(
    (state: RootState) => state.UsersReducer.currentRole,
  );

  const onLogout = () => {
    dispatch(ModalsActions.openLogoutDialog());
  };

  const isActive = useCallback(
    (path: string) => location.pathname.includes(path),
    [location],
  );

  return (
    <div style={{ paddingLeft: 0 }}>
      <Link
        to="/dashboard"
        style={{ textDecoration: 'none', color: '#696969' }}
      >
        <ListItem
          className={classes.listItem}
          onClick={closeDrawer}
          key="Calendario"
        >
          <ListItemButton className={classes.listItemButton}>
            {isActive('/dashboard') && (
              <div className={classes.listItemIndicator} />
            )}
            <ListItemIcon>
              <CalendarIcon
                color={isActive('/dashboard') ? '#00D1D2' : '#ffffff'}
              />
            </ListItemIcon>
            <ListItemText
              className={
                isActive('/dashboard')
                  ? classes.listItemTextActive
                  : classes.listItemText
              }
              disableTypography={true}
              primary="Calendario"
            />
          </ListItemButton>
        </ListItem>
      </Link>
      <Link
        to="/click-lending"
        style={{ textDecoration: 'none', color: '#696969' }}
      >
        <ListItem
          className={classes.listItem}
          onClick={closeDrawer}
          key="Click & Lending"
        >
          <ListItemButton className={classes.listItemButton}>
            {isActive('/click-lending') && (
              <div className={classes.listItemIndicator} />
            )}

            <ListItemIcon>
              <ClockIcon
                color={isActive('/click-lending') ? '#00D1D2' : '#ffffff'}
              />
            </ListItemIcon>
            <ListItemText
              primary="Prenota e prova"
              disableTypography={true}
              className={
                isActive('/click-lending')
                  ? classes.listItemTextActive
                  : classes.listItemText
              }
            />
          </ListItemButton>
        </ListItem>
      </Link>
      {currentRole !== UserRoles.SHOP_ASSISTANT && (
        <Link to="/stores" style={{ textDecoration: 'none', color: '#696969' }}>
          <ListItem
            className={classes.listItem}
            onClick={closeDrawer}
            key="Store"
          >
            <ListItemButton className={classes.listItemButton}>
              {isActive('/stores') && (
                <div className={classes.listItemIndicator} />
              )}
              <ListItemIcon>
                <StoreIcon
                  color={isActive('/stores') ? '#00D1D2' : '#ffffff'}
                />
              </ListItemIcon>
              <ListItemText
                className={
                  isActive('/stores')
                    ? classes.listItemTextActive
                    : classes.listItemText
                }
                disableTypography={true}
                primary="Store"
              />
            </ListItemButton>
          </ListItem>
        </Link>
      )}
      {currentRole === UserRoles.ADMIN && (
        <Link
          to="/services"
          style={{ textDecoration: 'none', color: '#696969' }}
        >
          <ListItem
            className={classes.listItem}
            onClick={closeDrawer}
            key="Servizi"
          >
            <ListItemButton className={classes.listItemButton}>
              {isActive('/services') && (
                <div className={classes.listItemIndicator} />
              )}
              <ListItemIcon>
                <SettingsIcon
                  color={isActive('/services') ? '#00D1D2' : '#ffffff'}
                />
              </ListItemIcon>
              <ListItemText
                className={
                  isActive('/services')
                    ? classes.listItemTextActive
                    : classes.listItemText
                }
                disableTypography={true}
                primary="Servizi"
              />
            </ListItemButton>
          </ListItem>
        </Link>
      )}
      {(currentRole === UserRoles.ADMIN ||
        currentRole === UserRoles.SUPER_STORE_MANAGER) && (
        <Link to="/users" style={{ textDecoration: 'none', color: '#696969' }}>
          <ListItem
            className={classes.listItem}
            onClick={closeDrawer}
            key="Utenti"
          >
            <ListItemButton className={classes.listItemButton}>
              {isActive('/users') && (
                <div className={classes.listItemIndicator} />
              )}
              <ListItemIcon>
                <PeopleIcon
                  color={isActive('/users') ? '#00D1D2' : '#ffffff'}
                />
              </ListItemIcon>
              <ListItemText
                className={
                  isActive('/users')
                    ? classes.listItemTextActive
                    : classes.listItemText
                }
                disableTypography={true}
                primary="Utenti"
              />
            </ListItemButton>
          </ListItem>
        </Link>
      )}
      <ListItem
        className={classes.listItem}
        onClick={() => {
          closeDrawer();
          onLogout();
        }}
        key="Esci"
        style={{ color: '#696969' }}
      >
        <ListItemButton className={classes.listItemButton}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            disableTypography={true}
            primary="Esci"
          />
        </ListItemButton>
      </ListItem>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIndicator: {
      backgroundColor: '#00D1D2',
      height: '40px',
      borderRadius: '2px',
      width: '4px',
      position: 'absolute',
      left: 0,
    },
    listItem: {
      padding: '4px 0px !important',
      position: 'relative',
    },
    listItemButton: {
      paddingTop: '10px !important',
      paddingBottom: '10px !important',
      paddingLeft: '24px !important',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.05) !important',
      },
    },
    listItemText: {
      color: '#ffffff',
      fontSize: '14px !important',
    },
    listItemTextActive: {
      fontWeight: 'bold',
      color: '#ffffff',
      fontSize: '14px !important',
    },
  }),
);
