export default class LocalStorageService {
  private static instance: LocalStorageService;

  private constructor() {}

  static getInstance() {
    if (!LocalStorageService.instance) {
      LocalStorageService.instance = new LocalStorageService();
    }
    return LocalStorageService.instance;
  }
  public get(key: string): any {
    const value: string | null = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }
  public set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
  public remove(key: string): any {
    localStorage.removeItem(key);
  }

  public static CURRENT_STORE = "currentStore";
  public static CALENDAR_VIEW = "dayGridMonth";
}
