import React from 'react';
import { connect } from 'react-redux';
import StoreTable from '../../components/stores/table/StoresTable';
import { RootState } from '../../redux/reducers';
import {
  ServiceActions,
  SnackBarActions,
  StoreActions,
} from '../../redux/actions';
import { SnackBarType } from '../../redux/actions/UI/SnackBarActions';
import CreateStoreModal from '../../components/stores/details/modals/CreateStoreModal';

interface StoresProps {
  showSnackBar: (message: string, type: SnackBarType) => void;
  getStores: (
    sortField?: string,
    sortDir?: string,
    page?: number,
    itemsPerPage?: number,
    onlyArchived?: boolean,
  ) => void;
  perPage: number;
  getServices: () => void;
}
interface StoresState {
  createStoreModalVisible: boolean;
}
export class Stores extends React.Component<StoresProps, StoresState> {
  constructor(props: StoresProps) {
    super(props);
    this.state = {
      createStoreModalVisible: false,
    };
  }
  componentDidMount() {
    this.props.getServices();
    this.props.getStores('name', 'asc', 1, this.props.perPage, false);
  }
  toggleCreateStoreModal = () => {
    this.setState({
      createStoreModalVisible: !this.state.createStoreModalVisible,
    });
  };

  render() {
    const { createStoreModalVisible } = this.state;
    return (
      <div>
        <StoreTable
          onCreateStore={() => this.toggleCreateStoreModal()}
          onArchiveSelected={(value) =>
            this.props.getStores('name', 'asc', 1, this.props.perPage, value)
          }
        />
        <CreateStoreModal
          isVisible={createStoreModalVisible}
          onClose={() => this.toggleCreateStoreModal()}
        />
      </div>
    );
  }
}

export function mapStateToProps(state: RootState) {
  return {
    perPage: state.StoreReducer.rowsPerPage,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showSnackBar: (message: string, type: SnackBarType) =>
      dispatch(SnackBarActions.snackbar(message, type)),
    getStores: (
      sortField?: string,
      sortDir?: string,
      page?: number,
      itemsPerPage?: number,
      onlyArchived?: boolean,
    ) =>
      dispatch(
        StoreActions.getStores(
          sortField,
          sortDir,
          page,
          itemsPerPage,
          onlyArchived,
        ),
      ),
    getServices: () => dispatch(ServiceActions.getServices()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stores);
