import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Dialog, DialogTitle, TableBody, Table } from '@mui/material';
import DialogActionFooter from '../../../dialog/DialogActionFooter';
import _ from 'lodash';
import TimePeriodRow from './time_periods/TimePeriodRow';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import {
  BusinessHourRequestActions,
  BusinessHoursActions,
  ModalsActions,
} from '../../../../redux/actions';
import { TimePeriod } from '../../../../api/entities';

export default function StoreHoursModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const businessHoursRequest = useSelector(
    (state: RootState) => state.BusinessHourRequestReducer.businessHoursRequest,
  );
  const storeDetail = useSelector(
    (state: RootState) => state.StoreReducer.storeDetail,
  );
  const { showTimePeriodsModalSuccess } = useSelector(
    (state: RootState) => state.UiReducer,
  );

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      dispatch(ModalsActions.closeModal());
      return;
    }
    dispatch(ModalsActions.closeModal());
  };

  const handleSave = () => {
    // console.log('BUSINESS HORS FINAL REQUEST', businessHoursRequest);
    let id = businessHoursRequest?.uuid;
    let timePeriods: any[] = [];

    businessHoursRequest?.timePeriods?.forEach((timePeriod: any) => {
      if (timePeriod) {
        timePeriod.times.forEach((time: any) => {
          let requestObj = {
            closeDay: time.closeDay,
            openDay: time.openDay,
            openTime: time.openTime,
            closeTime: time.closeTime,
            isClosed: time.isClosed ? time.isClosed : false,
            storeServices: _.map(time.storeServices, function (o) {
              return o.uuid;
            }),
          };
          timePeriods.push(requestObj);
        });
      }
    });

    let request = {
      timePeriods,
      isDefault: businessHoursRequest?.isDefault
        ? businessHoursRequest.isDefault
        : false,
    };
    dispatch(BusinessHoursActions.updateBusinessHours(id ?? '', request));
    dispatch(ModalsActions.closeModal());
    dispatch(BusinessHourRequestActions.setBusinessHourRequest(undefined));
    if (storeDetail && storeDetail.uuid) {
      dispatch(BusinessHoursActions.getBusinessHoursByStore(storeDetail.uuid));
    }
  };

  return (
    <Dialog
      className={classes.modal}
      open={showTimePeriodsModalSuccess}
      onClose={() => handleClose()}
      maxWidth="md"
      fullWidth
      scroll="body"
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        Gestione Orari
      </DialogTitle>
      <div className={classes.gridContainer}>
        <Table>
          <TableBody>
            {businessHoursRequest?.timePeriods &&
              businessHoursRequest?.timePeriods.map((item: TimePeriod) => (
                <TimePeriodRow item={item} key={_.uniqueId()} />
              ))}
          </TableBody>
        </Table>
      </div>

      <Box className={classes.footerWrapper}>
        <DialogActionFooter
          actionTitle={'Salva'}
          onCancel={() => handleClose()}
          onConfirm={() => handleSave()}
        />
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '24px !important',
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    gridContainer: {
      paddingLeft: '24px !important',
      paddingRight: '24px !important',
      marginBottom: '24px !important',
    },
  }),
);
