import React, { useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { ClickAndLending } from '../../../api/entities';
import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';
import DialogActionFooter from '../../dialog/DialogActionFooter';

interface NoteDialogProps {
  open: boolean;
  lending: ClickAndLending;
  onClose: () => void;
  onNoteSaved: (note: string) => void;
}

export const NoteDialog = ({
  open,
  lending,
  onClose,
  onNoteSaved,
}: NoteDialogProps) => {
  const classes = useStyles();

  const [note, setNote] = useState<string>();

  const uiDate = (date: string | undefined) => {
    return moment(date).format('LLLL');
  };

  const statusLabel = useMemo<string | undefined>(() => {
    if (lending.reservationStatus === 'ENDED') {
      return 'Concluso';
    }
    if (lending.reservationStatus === 'NOTAVAILABLE') {
      return 'Nessun prodotto disponibile';
    }
    if (lending.reservationStatus === 'EXPIRED') {
      return 'Richiesta scaduta';
    }
    if (lending.reservationStatus === 'CANCELLED') {
      return 'Il cliente ha annullato la richiesta';
    }
  }, [lending]);

  const shouldShowNote = useMemo<boolean>(() => {
    return lending.outcome !== '' && lending.reservationStatus !== 'ENDED';
  }, [lending]);
  return (
    <Dialog
      onClose={() => onClose()}
      PaperProps={{ sx: { borderRadius: '10px' } }}
      className={classes.modal}
      open={open}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        {lending.userName + ' ' + lending.userSurname}
      </DialogTitle>
      <Grid className={classes.summaryBox}>
        <div className={classes.summaryRow}>
          <Typography className={classes.subHeaderLabel}>Email:</Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {lending.userEmail}
          </Typography>
        </div>
        <div className={classes.summaryRow}>
          <Typography className={classes.subHeaderLabel}>Telefono:</Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {lending.userPhone}
          </Typography>
        </div>
        <div className={classes.summaryRow}>
          <Typography className={classes.subHeaderLabel}>
            Richiesta inoltrata:
          </Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {uiDate(lending.createdAt)}
          </Typography>
        </div>
        <div className={classes.summaryRow}>
          <Typography className={classes.subHeaderLabel}>
            Codice prenotazione:
          </Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {lending.reservationCode}
          </Typography>
        </div>

        <div className={classes.summaryRow}>
          <Typography className={classes.subHeaderLabel}>
            Prodotto richiesta dal cliente:
          </Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {lending.productName}
          </Typography>
        </div>
        {lending.alternativeProductName && (
          <div className={classes.summaryRow}>
            <Typography className={classes.subHeaderLabel}>
              Prodotto alternativo proposto:
            </Typography>
            <Typography className={classes.subHeaderSubtitleLabel}>
              {lending.alternativeProductName}
            </Typography>
          </div>
        )}
        {statusLabel && (
          <div className={classes.summaryRow}>
            <Typography className={classes.subHeaderLabel}>Stato:</Typography>
            <Typography className={classes.subHeaderSubtitleLabel}>
              {statusLabel}
            </Typography>
          </div>
        )}
        <div className={classes.summaryRow} style={{ border: 0 }}>
          <Typography className={classes.subHeaderLabel}>
            Disponibilità confermata:
          </Typography>
          <Typography className={classes.subHeaderSubtitleLabel}>
            {uiDate(lending.confirmedAt)}
          </Typography>
        </div>
        {shouldShowNote && (
          <TextField
            fullWidth
            label="Nota"
            color="secondary"
            value={note}
            onChange={(event: any) => setNote(event.target.value)}
            style={{ marginTop: '24px' }}
          />
        )}
      </Grid>
      {shouldShowNote && (
        <Box className={classes.footerWrapper}>
          <DialogActionFooter
            disableConfirm={note === lending.reservationNotes && !!note}
            onCancel={() => onClose()}
            onConfirm={() => onNoteSaved(note!)}
            actionTitle="Salva Nota"
          />
        </Box>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '12px !important',
    },
    summaryBox: {
      backgroundColor: 'white',
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 4,
      flexWrap: 'nowrap',
    },
    summaryRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '11px 0',
      borderBottom: '1px solid #E1E0E2',
      width: '100%',
    },
    endSummaryRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '11px 0',
    },
    subHeaderLabel: {
      color: '#34303D',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '14px !important',
      minWidth: '250px',
    },
    subHeaderSubtitleLabel: {
      color: '#34303D',
      fontfFamily: 'IQOS Sans',
      fontSize: '14px !important',
      fontWeight: '600 !important',
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
  }),
);
