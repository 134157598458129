export function validateEmail(email: string): boolean {
  if (email === "") {
    return true;
  } else {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}

export function isEmpty(value: string): boolean {
  return value === "";
}

export function validatePhone(phone: string): boolean {
  if (phone === "") {
    return true;
  } else {
    const re = new RegExp(
      "^(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$",
    );
    return re.test(phone);
  }
}

export const PRODUCT_VEEV = "VEEV"
