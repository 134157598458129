import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import {
  AuthActions,
  ProductsActions,
  ServiceActions,
  StoreActions,
  UsersActions,
} from './redux/actions';
import { GuardedRoute } from './components/guards/GuardRoute';
import Login from './screens/login/Login';
import './App.scss';
import Dashboard from './screens/dashboard/Dashboard';
import DetailAppointment from './screens/appointment/DetailAppointment';
import { theme } from './theme/AppTheme';
import { ThemeProvider } from '@mui/material';
import Stores from './screens/stores/Stores';
import DetailStore from './screens/stores/DetailStore';
import SnackBar from './components/snackbars/SnackBar';
import UsersScreen from './screens/users/UsersScreen';
import UserProfile from './screens/users/UserProfile';
import DetailServiceScreen from './screens/service/DetailServiceScreen';
import ServicesScreen from './screens/service/ServicesScreen';
import ClickLending from './screens/click_lending/';
import ResetPassword from './screens/login/ResetPassword';
import ForgotPassword from './screens/login/ForgotPassword';
import ApiInterceptor from './services/ApiInterceptor';
import { RootState } from './redux/reducers';
import { Store, User } from './api/entities';
import Scaffold from './components/Scaffold/Scaffold';
import { UserRoles } from './utils/UserRoles';
import { NotificationPage } from './components/notifications/NotificationPage/NotificationPage';

interface IAppProps {
  authStatus: () => void;
  getUser: () => void;
  getServices: () => void;
  getProducts: () => void;
  setCurrentSelectedStore: (store: Store) => void;
  currentRole: any;
  loggedIn: boolean;
  currentUser?: User;
}
interface IAppState {}

export class App extends React.Component<IAppProps, IAppState> {
  componentDidUpdate(prevProps: IAppProps) {
    if (prevProps.loggedIn === false && this.props.loggedIn) {
      this.props.getUser();
      this.props.getServices();
      this.props.getProducts();
    }
  }

  componentDidMount() {
    this.props.authStatus();
    new ApiInterceptor();
  }

  getHomePage(): string {
    if (this.props.currentRole === UserRoles.SUPER_STORE_MANAGER) {
      return '/stores';
    }
    return '/dashboard';
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="app-wrapper">
          <SnackBar />
          <Router>
            <Scaffold>
              <Routes>
                <Route
                  path="/"
                  element={
                    <GuardedRoute authRoute redirectTo={this.getHomePage()} />
                  }
                >
                  <Route path="/" element={<Login />} />
                </Route>

                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />

                {!!this.props.currentUser && (
                  <>
                    <Route path="/dashboard" element={<GuardedRoute />}>
                      <Route path="/dashboard" element={<Dashboard />} />
                    </Route>

                    <Route path="/notifications" element={<GuardedRoute />}>
                      <Route
                        path="/notifications"
                        element={<NotificationPage />}
                      />
                    </Route>

                    <Route path="/click-lending" element={<GuardedRoute />}>
                      <Route
                        path="/click-lending"
                        element={
                          // @ts-ignore
                          <ClickLending />
                        }
                      />
                    </Route>

                    <Route path="/appointment/:id" element={<GuardedRoute />}>
                      <Route
                        path="/appointment/:id"
                        element={<DetailAppointment />}
                      />
                    </Route>

                    {this.props.currentRole !== UserRoles.SHOP_ASSISTANT && (
                      <>
                        <Route path="/stores" element={<GuardedRoute />}>
                          <Route path="/stores" element={<Stores />} />
                        </Route>
                        <Route path="/stores/:id" element={<GuardedRoute />}>
                          <Route
                            path="/stores/:id"
                            element={
                              // @ts-ignore
                              <DetailStore />
                            }
                          />
                        </Route>
                      </>
                    )}

                    {this.props.currentRole === UserRoles.ADMIN && (
                      <>
                        <Route path="/services" element={<GuardedRoute />}>
                          <Route
                            path="/services"
                            element={<ServicesScreen />}
                          />
                        </Route>

                        <Route path="/services/:id" element={<GuardedRoute />}>
                          <Route
                            path="/services/:id"
                            element={<DetailServiceScreen />}
                          />
                        </Route>
                      </>
                    )}

                    {(this.props.currentRole === UserRoles.ADMIN ||
                      this.props.currentRole ===
                        UserRoles.SUPER_STORE_MANAGER) && (
                      <>
                        <Route path="/users" element={<GuardedRoute />}>
                          <Route path="/users" element={<UsersScreen />} />
                        </Route>

                        <Route path="/users/:id" element={<GuardedRoute />}>
                          <Route path="/users/:id" element={<UserProfile />} />
                        </Route>
                      </>
                    )}
                  </>
                )}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Scaffold>
          </Router>
        </div>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = {
  authStatus: () => AuthActions.authStatus(),
  getUser: () => UsersActions.getCurrent(),
  getServices: () => ServiceActions.getServices(),
  getProducts: () => ProductsActions.getProducts(),
  setCurrentSelectedStore: (store: Store) =>
    StoreActions.setCurrentSelectedStore(store),
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.AuthReducer.loggedIn,
    currentUser: state.UsersReducer.currentUser,
    currentRole: state.UsersReducer.currentRole,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
