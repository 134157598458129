import React from 'react';
import { connect } from 'react-redux';
import ResetPasswordForm from '../../components/forms/login_form/ResetPasswordForm';
import { RootState } from '../../redux/reducers';
import { AuthActions } from '../../redux/actions';
import SnackBarActions, {
  SnackBarType,
} from '../../redux/actions/UI/SnackBarActions';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';

interface SetNewPasswordProps {
  setNewPasswordError: boolean;
  setNewPasswordProgress: boolean;
  setNewPasswordSuccess: boolean;
  setNewPassword: (token: string, pass: string) => void;
  showSnackBar: (message: string, type: SnackBarType) => void;
  goToLogin: () => void;
  logout: () => void;
}

type LegacySetNewPasswordProps = SetNewPasswordProps & {
  navigate: NavigateFunction;
  location: Location;
};

interface SetNewPasswordState {
  token: string;
}

export function ResetPassword(props: SetNewPasswordProps) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <LegacyResetPassword {...props} navigate={navigate} location={location} />
  );
}

class LegacyResetPassword extends React.Component<
  LegacySetNewPasswordProps,
  SetNewPasswordState
> {
  constructor(props: LegacySetNewPasswordProps) {
    super(props);
    this.props.logout();
    this.state = {
      token: new URLSearchParams(this.props.location.search).get('token') ?? '',
    };
  }

  componentDidUpdate(prevProps: SetNewPasswordProps) {
    if (prevProps.setNewPasswordProgress && this.props.setNewPasswordSuccess) {
      this.props.showSnackBar('Fatto!', 'success');
      this.props.navigate('/');
    }
    if (prevProps.setNewPasswordProgress && this.props.setNewPasswordError) {
      // server should handle this error message, now is empty
      this.props.showSnackBar(
        'Il link non è più valido, inserisci nuovamente la tua email.',
        'error',
      );
      this.props.navigate('/forgot-password');
    }
  }
  onSubmit(password: string) {
    if (this.state.token !== '') {
      this.props.setNewPassword(this.state.token, password);
    } else {
      this.props.showSnackBar(
        'Token non valido, controlla di nuovo la tua posta',
        'warning',
      );
    }
  }

  render() {
    return (
      <ResetPasswordForm onSubmit={(password) => this.onSubmit(password)} />
    );
  }
}

export function mapStateToProps(state: RootState) {
  return {
    setNewPasswordError: state.AuthReducer.setNewPasswordError,
    setNewPasswordProgress: state.AuthReducer.setNewPasswordProgress,
    setNewPasswordSuccess: state.AuthReducer.setNewPasswordSuccess,
  };
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    setNewPassword: (token: string, pass: string) =>
      dispatch(AuthActions.setNewPassword(token, pass)),
    showSnackBar: (message: string, type: SnackBarType) =>
      dispatch(SnackBarActions.snackbar(message, type)),
    goToLogin: () => dispatch(AuthActions.setLogin(false)),
    logout: () => dispatch(AuthActions.setLogin(false)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
