import Authorization from "../../domain/Authorization";
import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import AuthActions from "../actions/AuthActions";
import CookieService from "../../services/CookieService";
import { Token } from "../../api/services/nbp";
import LocalStorageService from "../../services/LocalStorageService";

export type AuthState = {
  loggedIn: boolean;
  auth?: Authorization;
  loginLoading: boolean;
  loginError: boolean;
  passwordRequestProgress: boolean;
  passwordRequestError: boolean;
  passwordRequestSuccess: boolean;
  setNewPasswordProgress: boolean;
  setNewPasswordError: boolean;
  setNewPasswordSuccess: boolean;
};

const INITIAL_AUTH_STATE: AuthState = {
  loggedIn: false,
  loginError: false,
  loginLoading: false,
  passwordRequestProgress: false,
  passwordRequestError: false,
  passwordRequestSuccess: false,
  setNewPasswordProgress: false,
  setNewPasswordError: false,
  setNewPasswordSuccess: false,
};

const AuthReducer = createReducer(INITIAL_AUTH_STATE, (builder) =>
  builder
    .addCase(AuthActions.authStatus, (state: AuthState) => {
      const auth = CookieService.getInstance().isAuthenticated("token");
      if (auth) {
        return {
          ...state,
          loggedIn: true,
          loginLoading: false,
          loginError: false,
        };
      } else {
        return {
          ...state,
          loggedIn: false,
          loginLoading: false,
          loginError: false,
        };
      }
    })
    .addCase(
      AuthActions.setLoginAction,
      (state: AuthState, action: PayloadAction<boolean>) => {
        if (action.payload === false) {
          CookieService.getInstance().remove("token");
          LocalStorageService.getInstance().remove(
            LocalStorageService.CURRENT_STORE,
          );
          LocalStorageService.getInstance().remove(
            LocalStorageService.CALENDAR_VIEW,
          );
        }
        return {
          ...state,
          loggedIn: action.payload,
          loginLoading: false,
          loginError: false,
        };
      },
    )
    .addCase(AuthActions.loginActionLoading, (state: AuthState) => {
      return {
        ...state,
        loginLoading: true,
        loginError: false,
        loggedIn: false,
      };
    })
    .addCase(
      AuthActions.loginActionError,
      (state: AuthState, action: PayloadAction<any>) => {
        let message = action.payload.response.data.message;
        return {
          ...state,
          loginLoading: false,
          loginError: message,
          loggedIn: false,
        };
      },
    )
    .addCase(
      AuthActions.loginActionSuccess,
      (state: AuthState, action: PayloadAction<Token>) => {
        let token = action.payload;
        CookieService.getInstance().set("token", token);
        return {
          ...state,
          loginLoading: false,
          loginError: false,
          loggedIn: true,
        };
      },
    )
    .addCase(
      AuthActions.requestPasswordError,
      (state: AuthState, action: PayloadAction<any>) => {
        return {
          ...state,
          passwordRequestError: true,
          passwordRequestProgress: false,
          passwordRequestSuccess: false,
        };
      },
    )
    .addCase(
      AuthActions.requestPasswordProgress,
      (state: AuthState, action: PayloadAction<any>) => {
        return {
          ...state,
          passwordRequestError: false,
          passwordRequestProgress: true,
          passwordRequestSuccess: false,
        };
      },
    )
    .addCase(
      AuthActions.requestPasswordSuccess,
      (state: AuthState, action: PayloadAction<any>) => {
        return {
          ...state,
          passwordRequestError: false,
          passwordRequestProgress: false,
          passwordRequestSuccess: true,
        };
      },
    )
    .addCase(
      AuthActions.setNewPasswordError,
      (state: AuthState, action: PayloadAction<any>) => {
        return {
          ...state,
          setNewPasswordProgress: false,
          setNewPasswordError: true,
          setNewPasswordSuccess: false,
        };
      },
    )
    .addCase(
      AuthActions.setNewPasswordProgress,
      (state: AuthState, action: PayloadAction<any>) => {
        return {
          ...state,
          setNewPasswordProgress: true,
          setNewPasswordError: false,
          setNewPasswordSuccess: false,
        };
      },
    )
    .addCase(
      AuthActions.setNewPasswordSuccess,
      (state: AuthState, action: PayloadAction<any>) => {
        return {
          ...state,
          setNewPasswordProgress: false,
          setNewPasswordError: false,
          setNewPasswordSuccess: true,
        };
      },
    ),
);

export default AuthReducer;
