import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Dialog, Grid, DialogTitle, TextField } from '@mui/material';
import DialogActionFooter from '../../dialog/DialogActionFooter';
import { useDispatch } from 'react-redux';
import { SnackBarActions, UsersActions } from '../../../redux/actions';
import RoleTextField from '../RoleTextField';
import { validateEmail } from '../../../utils/validators';

interface UserCreateModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export default function UserCreateModal({
  isVisible,
  onClose,
}: UserCreateModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [businessRole, setBusinessRole] = useState('');
  const errorText = () => {
    return email !== '' && !validateEmail(email) && 'email non valida';
  };

  const handleClose = () => {
    setName('');
    setSurname('');
    setPassword('');
    setBusinessRole('');
    setEmail('');
    onClose();
  };
  const handleSave = () => {
    if (
      email !== '' &&
      businessRole !== '' &&
      validateEmail(email) &&
      password !== ''
    ) {
      var request = {
        name,
        businessRole,
        surname,
        email,
        username: email,
        password,
      };
      dispatch(UsersActions.createUser(request));
      handleClose();
    } else {
      dispatch(
        SnackBarActions.snackbar(
          'Per favore compila tutti i campi!',
          'warning',
        ),
      );
    }
  };

  return (
    <Dialog
      className={classes.modal}
      open={isVisible}
      onClose={() => handleClose()}
      scroll="body"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        Nuovo Utente
      </DialogTitle>
      <Grid className={classes.gridContainer}>
        <Box justifyContent="space-between">
          <TextField
            className={classes.contentField}
            autoFocus
            label="Nome"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            color="secondary"
            size="small"
            style={{ marginBottom: '24px' }}
            inputProps={{
              style: {
                padding: '12px 16px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
          />
          <TextField
            className={classes.contentField}
            label="Cognome"
            id="cognome"
            value={surname}
            onChange={(event) => setSurname(event.target.value)}
            color="secondary"
            size="small"
            style={{ marginBottom: '24px' }}
            inputProps={{
              style: {
                padding: '12px 16px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
          />
          <TextField
            className={classes.contentField}
            error={email !== '' && !validateEmail(email)}
            helperText={errorText()}
            label="Email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            color="secondary"
            size="small"
            style={{ marginBottom: '24px' }}
            inputProps={{
              style: {
                padding: '12px 16px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
          />
          <TextField
            className={classes.contentField}
            label="Password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            color="secondary"
            size="small"
            style={{ marginBottom: '24px' }}
            inputProps={{
              style: {
                padding: '12px 16px',
              },
            }}
            InputLabelProps={{
              style: {
                top: '4px',
              },
            }}
          />
          <RoleTextField
            value={businessRole}
            onSelect={(role) => setBusinessRole(role)}
          />
        </Box>
      </Grid>
      <Box className={classes.footerWrapper}>
        <DialogActionFooter
          onCancel={() => handleClose()}
          onConfirm={() => handleSave()}
          actionTitle="Crea"
        />
      </Box>
    </Dialog>
  );
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '16px !important',
    },
    gridContainer: {
      backgroundColor: 'white',
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 4,
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
    contentField: {
      width: '100%',
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
  }),
);
