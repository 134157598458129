import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { StoreActions } from "../actions";
import {
  HydratedStore,
  Service,
  Store,
  StoreDetail,
  StoreService,
} from "../../api/entities";
import LocalStorageService from "../../services/LocalStorageService";

export const rowsPerPageOptions = [10, 30, 100];

export type StoreState = {
  hydratedStore?: HydratedStore;
  userCurrentStores: Store[];
  loadingStores: boolean;
  loadingSingleStore: boolean;
  storesError: boolean;
  singleStoreError: boolean;
  editStoreLoading: boolean;
  editStoreError: boolean;
  searchedStores: any[];
  storeDetail?: StoreDetail;
  getUserCurrentStoresLoading: boolean;
  getUserCurrentStoresError: boolean;
  createStoreLoading: boolean;
  createStoreError: boolean;
  currentSelectedStore: Store | undefined;
  createStoreServicesLoading: boolean;
  createStoreServicesError: boolean;
  deleteStoreServiceLoading: boolean;
  deleteStoreServiceError: boolean;
  getStoreServiceByStoreLoading: boolean;
  getStoreServiceByStoreError: boolean;
  patchStoreServiceError: boolean;
  patchStoreServiceLoading: boolean;
  rowsPerPage: number;
  pageIndex: number;
};

const INITIAL_STORE_STATE: StoreState = {
  loadingStores: false,
  storesError: false,
  loadingSingleStore: false,
  singleStoreError: false,
  editStoreLoading: false,
  editStoreError: false,
  searchedStores: [],
  userCurrentStores: [],
  getUserCurrentStoresLoading: false,
  getUserCurrentStoresError: false,
  createStoreLoading: false,
  createStoreError: false,
  createStoreServicesLoading: false,
  createStoreServicesError: false,
  deleteStoreServiceLoading: false,
  deleteStoreServiceError: false,
  currentSelectedStore: undefined,
  getStoreServiceByStoreLoading: false,
  getStoreServiceByStoreError: false,
  patchStoreServiceError: false,
  patchStoreServiceLoading: false,
  rowsPerPage: rowsPerPageOptions[0],
  pageIndex: 0,
};

const addStore = (
  hydratedStore: HydratedStore | undefined,
  newStore: Store,
) => {
  if (hydratedStore) {
    return {
      ...hydratedStore,
      "hydra:member": [newStore, ...hydratedStore?.["hydra:member"]],
    };
  }
  return hydratedStore;
};

const replaceStoreServices = (
  storeDetail: StoreDetail | undefined,
  services: Service[],
) => {
  if (storeDetail) {
    return { ...storeDetail, storeServices: services };
  }
  return storeDetail;
};

const StoreReducer = createReducer(INITIAL_STORE_STATE, (builder) =>
  builder

    .addCase(
      StoreActions.currentSelectedStore,
      (state: StoreState, action: PayloadAction<Store | undefined>) => {
        let currentSelectedStore = action.payload;
        LocalStorageService.getInstance().set(
          LocalStorageService.CURRENT_STORE,
          currentSelectedStore,
        );
        return {
          ...state,
          currentSelectedStore,
          getUserCurrentStoresLoading: false,
        };
      },
    )

    .addCase(
      StoreActions.getStoresSuccess,
      (state: StoreState, action: PayloadAction<HydratedStore>) => {
        let hydratedStore = action.payload;
        return {
          ...state,
          hydratedStore,
          loadingStores: false,
          storesError: false,
        };
      },
    )

    .addCase(StoreActions.getStoresError, (state: StoreState, action: any) => {
      return { ...state, storesError: action.payload, loadingStores: false };
    })

    .addCase(StoreActions.getStoresLoading, (state: StoreState) => {
      return { ...state, loadingStores: true };
    })

    .addCase(
      StoreActions.setRowsPerPageAction,
      (state: StoreState, action: PayloadAction<number>) => {
        return { ...state, rowsPerPage: action.payload };
      },
    )

    .addCase(
      StoreActions.setPageIndexAction,
      (state: StoreState, action: PayloadAction<number>) => {
        return { ...state, pageIndex: action.payload };
      },
    )
    //get logged user stores
    .addCase(
      StoreActions.getUserCurrentStoresSuccess,
      (state: StoreState, action: PayloadAction<HydratedStore>) => {
        let userCurrentStores = action.payload
          ? action.payload["hydra:member"]
          : [];
        return {
          ...state,
          userCurrentStores,
          getUserCurrentStoresLoading: false,
          getUserCurrentStoresError: false,
        };
      },
    )
    .addCase(
      StoreActions.getUserCurrentStoresError,
      (state: StoreState, action: any) => {
        return {
          ...state,
          getUserCurrentStoresError: action.payload,
          getUserCurrentStoresLoading: false,
        };
      },
    )
    .addCase(StoreActions.getUserCurrentStoresLoading, (state: StoreState) => {
      return {
        ...state,
        getUserCurrentStoresLoading: true,
        getUserCurrentStoresError: false,
      };
    })

    //get single store
    .addCase(
      StoreActions.getSingleStoreSuccess,
      (state: StoreState, action: PayloadAction<StoreDetail>) => {
        let store = action.payload;
        return {
          ...state,
          storeDetail: store,
          loadingSingleStore: false,
          singleStoreError: false,
          editStoreError: false,
        };
      },
    )
    .addCase(
      StoreActions.getSingleStoreError,
      (state: StoreState, action: any) => {
        return {
          ...state,
          singleStoreError: action.payload,
          loadingSingleStore: false,
        };
      },
    )
    .addCase(StoreActions.getSingleStoreLoading, (state: StoreState) => {
      return {
        ...state,
        loadingSingleStore: true,
        singleStoreError: false,
        editStoreError: false,
      };
    })
    //edit store
    .addCase(StoreActions.editStoreLoading, (state: StoreState) => {
      return { ...state, editStoreLoading: true, editStoreError: false };
    })
    .addCase(StoreActions.editStoreError, (state: StoreState, action: any) => {
      return {
        ...state,
        editStoreError: action.payload,
        editStoreLoading: false,
      };
    })
    .addCase(
      StoreActions.editStoreSuccess,
      (state: StoreState, action: PayloadAction<StoreDetail>) => {
        let store = action.payload;
        if (store.uuid === state.currentSelectedStore?.uuid) {
          LocalStorageService.getInstance().set(
            LocalStorageService.CURRENT_STORE,
            store,
          );
        }
        return {
          ...state,
          storeDetail: store,
          editStoreLoading: false,
          editStoreError: false,
        };
      },
    )

    //create store
    .addCase(StoreActions.createStoreLoading, (state: StoreState) => {
      return { ...state, createStoreLoading: true, createStoreError: false };
    })
    .addCase(
      StoreActions.createStoreError,
      (state: StoreState, action: any) => {
        return {
          ...state,
          createStoreError: action.payload,
          createStoreLoading: false,
        };
      },
    )
    .addCase(
      StoreActions.createStoreSuccess,
      (state: StoreState, action: PayloadAction<StoreDetail>) => {
        let newStore = action.payload;
        return {
          ...state,
          hydratedStore: addStore(state.hydratedStore, newStore),
          createStoreLoading: false,
          createStoreError: false,
        };
      },
    )

    //create store service
    .addCase(StoreActions.createStoreServicesLoading, (state: StoreState) => {
      return {
        ...state,
        createStoreServicesLoading: true,
        createStoreServicesError: false,
      };
    })
    .addCase(
      StoreActions.createStoreServicesError,
      (state: StoreState, action: any) => {
        return {
          ...state,
          createStoreServicesError: action.payload,
          createStoreServicesLoading: false,
        };
      },
    )
    .addCase(
      StoreActions.createStoreServicesSuccess,
      (state: StoreState, action: PayloadAction<StoreService>) => {
        // let newStoreService = action.payload
        // let newStoreDetail = updateStoreService(state.storeDetail, newStoreService)
        // if ( newStoreDetail &&  newStoreDetail.uuid === state.currentSelectedStore?.uuid) {
        // 	LocalStorageService.getInstance().set(LocalStorageService.CURRENT_STORE, newStoreDetail)
        // }
        return {
          ...state,
          createStoreServicesLoading: false,
          createStoreServicesError: false,
        };
      },
    )

    //patch store service
    .addCase(StoreActions.patchStoreServiceLoading, (state: StoreState) => {
      return {
        ...state,
        patchStoreServiceLoading: true,
        patchStoreServiceError: false,
      };
    })
    .addCase(
      StoreActions.patchStoreServiceError,
      (state: StoreState, action: any) => {
        return {
          ...state,
          patchStoreServiceError: action.payload,
          patchStoreServiceLoading: false,
        };
      },
    )
    .addCase(
      StoreActions.patchStoreServiceSuccess,
      (state: StoreState, action: PayloadAction<StoreService>) => {
        // let newStoreService = action.payload
        // let newStoreDetail = updateStoreService(state.storeDetail, newStoreService)
        // if ( newStoreDetail &&  newStoreDetail.uuid === state.currentSelectedStore?.uuid) {
        // 	LocalStorageService.getInstance().set(LocalStorageService.CURRENT_STORE, newStoreDetail)
        // }
        return {
          ...state,
          patchStoreServiceLoading: false,
          patchStoreServiceError: false,
        };
      },
    )

    //delete store service
    .addCase(StoreActions.deleteStoreServiceLoading, (state: StoreState) => {
      return {
        ...state,
        deleteStoreServiceLoading: true,
        deleteStoreServiceError: false,
      };
    })
    .addCase(
      StoreActions.deleteStoreServiceError,
      (state: StoreState, action: any) => {
        return {
          ...state,
          deleteStoreServiceError: action.payload,
          deleteStoreServiceLoading: false,
        };
      },
    )
    .addCase(
      StoreActions.deleteStoreServiceSuccess,
      (state: StoreState, action: PayloadAction<string>) => {
        let deletedId = action.payload;
        //remove store services from store detail this id
        return {
          ...state,
          deleteStoreServiceLoading: false,
          deleteStoreServiceError: false,
        };
      },
    )

    //get store services by store
    .addCase(
      StoreActions.getStoreServiceByStoreLoading,
      (state: StoreState) => {
        return {
          ...state,
          getStoreServiceByStoreLoading: true,
          getStoreServiceByStoreError: false,
        };
      },
    )
    .addCase(
      StoreActions.getStoreServiceByStoreError,
      (state: StoreState, action: any) => {
        return {
          ...state,
          getStoreServiceByStoreError: action.payload,
          getStoreServiceByStoreLoading: false,
        };
      },
    )
    .addCase(
      StoreActions.getStoreServiceByStoreSuccess,
      (state: StoreState, action: PayloadAction<any>) => {
        let storeServices = action.payload;
        //remove store services from store detail this id
        let newStoreDetail = replaceStoreServices(
          state.storeDetail,
          storeServices,
        );
        if (
          newStoreDetail &&
          newStoreDetail.uuid === state.currentSelectedStore?.uuid
        ) {
          LocalStorageService.getInstance().set(
            LocalStorageService.CURRENT_STORE,
            newStoreDetail,
          );
        }
        return { ...state, storeDetail: newStoreDetail };
      },
    ),
);

export default StoreReducer;
