import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import { CloseIcon } from './icons/CloseIcon';

interface ModalHeaderProps {
  title: string;
  onClose: () => void;
}

export default function ModalHeader(props: ModalHeaderProps) {
  const classes = useStyles();

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Box
      display="flex"
      className={classes.headerView}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography className={classes.title}>{props.title}</Typography>
      <IconButton onClick={() => handleClose()}>
        <CloseIcon color="#34303D" />
      </IconButton>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerView: {
      width: '100%',
      height: 40,
      backgroundColor: 'white',
      paddingTop: 20,
      paddingBottom: 8,
    },
    title: {
      fontSize: 20,
      color: '#34303D',
    },
  }),
);
