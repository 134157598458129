import React from 'react';
import './AuthForm.scss';
import FormInput from '../form_input/FormInput';
import { FormInputPassword } from '../form_input/FormInputPassword';
import { Typography, Button, Grid, Box } from '@mui/material';
import { Credentials } from '../../../api/services/nbp';
import SnackBarActions, {
  SnackBarType,
} from '../../../redux/actions/UI/SnackBarActions';
import { RootState } from '../../../redux/reducers';
import { connect } from 'react-redux';
import { ArrowRightIcon } from '../../icons/ArrowRightIcon';

interface LoginFormProps {
  onSubmit: (formData: Credentials) => void;
  onForgotPasswordPress: () => void;
  showSnackBar: (message: string, type: SnackBarType) => void;
  loading: boolean;
}

interface LoginFormState {
  username: string;
  password: string;
}

export class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  constructor(props: LoginFormProps) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }
  handleOnSubmit = () => {
    const { username, password } = this.state;
    if (username !== '' && password !== '') {
      let credentials = { username, password };
      this.props.onSubmit(credentials);
    } else {
      this.props.showSnackBar(
        'Per favore controlla il tuo nome utente e password',
        'warning',
      );
    }
  };

  setUsername = (value: string) => {
    this.setState({ username: value });
  };

  setPassword = (value: string) => {
    this.setState({ password: value });
  };

  render() {
    return (
      <Box
        className="container"
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Grid className="login-wrapper">
          <Box className="login-box">
            <h4 className="h4">Booking Platform - Accedi</h4>
            <h6 className="h6">Inserisci le tue credenziali per accedere</h6>
            <FormInput
              onChange={(username) => this.setUsername(username)}
              onSubmit={() => this.handleOnSubmit()}
            />
            <br />
            <FormInputPassword
              onChange={(password) => this.setPassword(password)}
              onSubmit={() => this.handleOnSubmit()}
            />
            <br />

            <Box
              className="form-footer"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                disabled={this.props.loading}
                onClick={() => this.handleOnSubmit()}
                className="btn"
                type="submit"
                endIcon={<ArrowRightIcon />}
              >
                Accedi
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography className="forgot-title">
              Hai dimenticato la password? &nbsp;
            </Typography>
            <Typography
              onClick={() => this.props.onForgotPasswordPress()}
              className="forgot-link"
            >
              Clicca qui
            </Typography>
          </Box>
        </Grid>
      </Box>
    );
  }
}

export function mapStateToProps(state: RootState) {
  return {};
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    showSnackBar: (message: string, type: SnackBarType) =>
      dispatch(SnackBarActions.snackbar(message, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
