import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Button, Grid, IconButton, List, Typography } from '@mui/material';
import { EditIcon } from '../../icons/EditIcon';
import _ from 'lodash';
import { BusinessHours } from '../../../api/entities';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  BusinessHourRequestActions,
  BusinessHoursActions,
  ModalsActions,
} from '../../../redux/actions';
import ActionDialog from '../../dialog/ActionDialog';
import { GroupedTimePeriods } from '../../../redux/reducers/BusinessHoursReducer';
import { UserRoles } from '../../../utils/UserRoles';
import { RootState } from '../../../redux/reducers';
import { AddIcon } from '../../icons/AddIcon';

interface StoreHoursCardProps {
  storeOpenSchedule: BusinessHours;
  activeWorkingTime: boolean;
  onDeleteCard: (id: string) => void;
}

function Row(props: { businessHour: GroupedTimePeriods }) {
  const classes = useStyles();
  const item = props.businessHour;
  const storeServices = useSelector((state: RootState) =>
    state.StoreReducer.storeDetail?.storeServices
      ? state.StoreReducer.storeDetail.storeServices
      : [],
  );

  const renderService = (timeService: any | undefined) => {
    if (timeService && timeService.length > 0) {
      let service = _.find(storeServices, { uuid: timeService[0].uuid });
      if (service) return service.externalName;
    }
    return 'Tutti i Servizi';
  };

  const presentationTimes = () => {
    return item.times.map((time) => {
      return (
        <p style={{ margin: 0 }}>
          {time.openTime +
            ' - ' +
            time.closeTime +
            ' ' +
            renderService(time ? time.storeServices : undefined)}
        </p>
      );
    });
  };

  return (
    <Box
      className={classes.row}
      key={item.openDay}
      justifyContent="space-between"
      alignItems="flex-start"
      display="flex"
    >
      <Typography className={classes.rowLabelDay}>
        {item.openDay.itDay()}
      </Typography>

      <Typography className={classes.rowLabel}>
        {item.isClosed === true ? 'CHIUSO' : presentationTimes()}
      </Typography>
    </Box>
  );
}

export default function StoreHoursCard(props: StoreHoursCardProps) {
  const classes = useStyles();
  const specialTimePeriods = useSelector(
    (state: RootState) => state.BusinessHoursReducer.hydratedSpecialHours,
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const currentRole = useSelector(
    (state: RootState) => state.UsersReducer.currentRole,
  );
  const dispatch = useDispatch();

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const isAdminOrStoreManager = () => {
    return (
      currentRole === UserRoles.ADMIN ||
      currentRole === UserRoles.SUPER_STORE_MANAGER ||
      currentRole === UserRoles.STORE_MANAGER
    );
  };

  const onPressEditRange = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    dispatch(
      BusinessHourRequestActions.setBusinessHourRequest(
        props.storeOpenSchedule,
      ),
    );
    dispatch(ModalsActions.openDateRangeModal());
  };

  const onPressEditSpecialTimePeriod = () => {
    dispatch(ModalsActions.openSpecialHoursModal());
  };

  const onPressEditTimePeriods = () => {
    dispatch(
      BusinessHourRequestActions.setBusinessHourRequest(
        props.storeOpenSchedule,
      ),
    );
    dispatch(ModalsActions.openTimePeriodsModal());
  };

  const onPressCreateSpecialTimePeriod = () => {
    dispatch(ModalsActions.openCreateSpecialHoursModal());
  };

  const removeCard = () => {
    dispatch(
      BusinessHoursActions.deleteBusinessHour(
        props.storeOpenSchedule.uuid ?? '',
        props.storeOpenSchedule.store?.uuid,
      ),
    );
  };

  const getSpecialTimePeriods = (businessHour: BusinessHours) => {
    const newSpecialTimePeriods: GroupedTimePeriods[] = [];
    specialTimePeriods?.['hydra:member'].forEach((period) => {
      let acceptableCriteria =
        (moment(period.startDate).isAfter(moment(businessHour.startDate)) &&
          moment(period.startDate).isBefore(moment(businessHour.endDate))) ||
        (moment(period.startDate).isAfter(moment(businessHour.startDate)) &&
          (businessHour.endDate === null ||
            businessHour.endDate === undefined));

      if (acceptableCriteria) {
        newSpecialTimePeriods.push({
          openDay: period.startDate?.formatted() ?? '',
          isClosed: period.isClosed ? period.isClosed : false,
          times: new Array(period),
        });
      } else {
        if (businessHour.isDefault) {
          newSpecialTimePeriods.push({
            openDay: period.startDate?.formatted() ?? '',
            isClosed: period.isClosed ? period.isClosed : false,
            times: new Array(period),
          });
        }
      }
    });

    return newSpecialTimePeriods;
  };

  const formattedStartDate = props.storeOpenSchedule.startDate?.formatted();
  const formattedEndDate = props.storeOpenSchedule.endDate?.formatted() ?? '';

  const renderDateRangeTitle = () => {
    return formattedStartDate + ' - ' + formattedEndDate;
  };

  const renderEditBusinessHoursRange = () => {
    return (
      isAdminOrStoreManager() &&
      !props.storeOpenSchedule.isDefault && (
        <IconButton onClick={(event) => onPressEditRange(event)}>
          <EditIcon color="#00D1D2" />
        </IconButton>
      )
    );
  };

  const renderEditHours = () => {
    return (
      isAdminOrStoreManager() && (
        <Box justifyContent="space-between" alignItems="center" display="flex">
          <Typography className={classes.headerTitle}>
            Gestione Orari
          </Typography>
          <IconButton onClick={() => onPressEditTimePeriods()}>
            <EditIcon color="#00D1D2" />
          </IconButton>
        </Box>
      )
    );
  };

  const renderEditOtherHours = () => {
    return (
      isAdminOrStoreManager() && (
        <Box justifyContent="space-between" alignItems="center" display="flex">
          <Typography className={classes.headerTitle}>
            Gestione altri speciali orari
          </Typography>
          <Box>
            <IconButton onClick={() => onPressCreateSpecialTimePeriod()}>
              <AddIcon color="#00D1D2" />
            </IconButton>
            {getSpecialTimePeriods(props.storeOpenSchedule).length > 0 && (
              <IconButton onClick={() => onPressEditSpecialTimePeriod()}>
                <EditIcon color="#00D1D2" />
              </IconButton>
            )}
          </Box>
        </Box>
      )
    );
  };

  const renderDeleteRange = () => {
    return (
      isAdminOrStoreManager() && (
        <Box justifyContent="flex-end" alignItems="center" display="flex">
          <Button
            className={classes.deleteButton}
            onClick={() => handleDelete()}
          >
            ELIMINA
          </Button>
        </Box>
      )
    );
  };

  return (
    <div
      style={{
        marginTop: props.storeOpenSchedule.isDefault ? 0 : '40px',
      }}
      className={classes.cardContainer}
    >
      <Box className={classes.container}>
        <Typography className={classes.title}>
          {props.storeOpenSchedule.isDefault
            ? 'Orario base'
            : renderDateRangeTitle()}
          {renderEditBusinessHoursRange()}
        </Typography>
        {props.activeWorkingTime && (
          <Box
            display="flex"
            className={classes.currentHours}
            alignItems="center"
            justifyContent="center"
          >
            <Typography className={classes.currentHoursTitle}>
              Orario Corrente
            </Typography>
          </Box>
        )}
      </Box>

      <Grid>
        {renderEditHours()}
        <List>
          {props.storeOpenSchedule.timePeriods?.map((item: any) => (
            <Row key={_.uniqueId()} businessHour={item} />
          ))}
        </List>
        {renderEditOtherHours()}
        <List>
          {getSpecialTimePeriods(props.storeOpenSchedule).map(
            (extendedHour: GroupedTimePeriods) => (
              <Row key={_.uniqueId()} businessHour={extendedHour} />
            ),
          )}
        </List>
        {!props.storeOpenSchedule.isDefault && renderDeleteRange()}
      </Grid>

      <ActionDialog
        title="Elimina programmazioni"
        message="Sei sicuro di elimina questi programmazioni?"
        onAction={() => removeCard()}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        actionTitle="Elimina"
      />
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      borderRadius: '4px',
      border: '1px solid #e1e0e2',
      padding: '24px',
    },
    currentHours: {
      backgroundColor: '#00D1D2',
      borderRadius: 8,
      padding: '3px 8px',
    },
    currentHoursTitle: {
      color: '#ffffff',
      fontSize: '14px !important',
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    deleteButton: {
      width: 80,
      height: 30,
      backgroundColor: '#00D1D2',
      '&:hover': {
        backgroundColor: '#00D1D2',
      },
      color: '#ffffff',
      fontSize: 14,
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
    },
    title: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    headerTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#00D1D2',
    },
    row: {
      padding: '10px 0',
    },
    rowLabel: {
      color: '#34303D',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      lineHeight: '16px !important',
    },
    rowLabelDay: {
      color: '#34303D',
      fontSize: '14px !important',
      fontStyle: 'normal !important',
      fontWeight: '700 !important',
      lineHeight: '16px !important',
    },
  }),
);
