import { createAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { AxiosResponse } from "axios";
import { ServiceApi } from "../../api/services/nbp/service-api";
import {
  HydratedServices,
  Service,
  TransNotifications,
  TransNotification,
  TransNotificationRequest,
} from "../../api/entities";
import {
  StoreApi,
  StoreServiceApi,
  TransactionalNotificationApi,
} from "../../api/services";
import SnackBarActions from "./UI/SnackBarActions";

//Api Service instance from open api generator
let api = new ServiceApi();
let tnApi = new TransactionalNotificationApi();
let storeApi = new StoreServiceApi();

//GET SERVICES
const getServicesSuccess = createAction<HydratedServices>(
  "service/getServicesSuccess",
);
const getServicesLoading = createAction("service/getServicesLoading");
const getServicesError = createAction<any>("service/getServicesError");

const getServices = () => (dispatch: AppDispatch) => {
  dispatch(getServicesLoading());
  api
    .getServiceCollection()
    .then((response: AxiosResponse<HydratedServices>) => {
      dispatch(getServicesSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(getServicesError(error));
    });
};

//GET SINGLE SERVICE
const getSingleServiceSuccess = createAction<Service>(
  "services/getSingleServiceSuccess",
);
const getSingleServiceError = createAction<any>(
  "services/getSingleServiceError",
);
const getSingleServiceLoading = createAction(
  "services/getSingleServiceLoading",
);

const getSingleService = (id: string) => (dispatch: AppDispatch) => {
  dispatch(getSingleServiceLoading());
  api
    .getServiceItem(id)
    .then((response: AxiosResponse<Service>) => {
      dispatch(getSingleServiceSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(getSingleServiceError(error));
    });
};

//CREATE SERVICE
const postCatServicesSuccess = createAction<Service>(
  "services/postCatServicesSuccess",
);
const postCatServicesLoading = createAction("services/postCatServicesLoading");
const postCatServicesError = createAction<any>("services/postCatServicesError");

const postCatalogService = (request: any) => (dispatch: AppDispatch) => {
  dispatch(postCatServicesLoading());
  api
    .postServiceServiceCollection(request)
    .then((response: AxiosResponse<Service>) => {
      dispatch(postCatServicesSuccess(response.data));
      dispatch(getServices());
    })
    .catch((error) => {
      dispatch(postCatServicesError(error));
    });
};

//EDIT SERVICE
const editServiceSuccess = createAction<Service>("services/editServiceSuccess");
const editServiceError = createAction<any>("services/editServiceError");
const editServiceLoading = createAction("services/editServiceLoading");

const editService = (id: string, request: any) => (dispatch: AppDispatch) => {
  //edit serice put or patch
  dispatch(editServiceLoading());
  api
    .patchServiceItem(id, request)
    .then((response: AxiosResponse<Service>) => {
      dispatch(editServiceSuccess(response.data));
      dispatch(SnackBarActions.snackbar("Servizio modificato.", "success"));
      dispatch(getServices());
    })
    .catch((error) => {
      dispatch(editServiceError(error));
    });
};

// GET TRANSACTIONAL NOTIFICATIONS SERVICE
const getTransactionalNotificationsSuccess = createAction<TransNotifications>(
  "services/getTransactionalNotificationsSuccess",
);
const getTransactionalNotificationsError = createAction<any>(
  "services/getTransactionalNotificationsError",
);
const getTransactionalNotificationsLoading = createAction(
  "services/getTransactionalNotificationsLoading",
);

const getTransactionalNotifications =
  (serviceId: string, productId: string) => (dispatch: AppDispatch) => {
    dispatch(getTransactionalNotificationsLoading());
    tnApi
      .getTransactionalNotificationCollection(serviceId, productId)
      .then((response: AxiosResponse<TransNotifications>) => {
        dispatch(getTransactionalNotificationsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getTransactionalNotificationsError(error));
      });
  };

// PATCH TRANSACTIONAL NOTIFICATION SERVICE
const patchTransactionalNotificationsSuccess = createAction<TransNotification>(
  "services/patchTransactionalNotificationsSuccess",
);
const patchTransactionalNotificationsError = createAction<any>(
  "services/patchTransactionalNotificationsError",
);
const patchTransactionalNotificationsLoading = createAction(
  "services/patchTransactionalNotificationsLoading",
);

const editTransactionalNotifications =
  (type: "email" | "sms", id: string, request: any) =>
  (dispatch: AppDispatch) => {
    dispatch(patchTransactionalNotificationsLoading());
    if (type === "email") {
      tnApi
        .patchForEmailNotificationTransactionalNotificationItem(id, request)
        .then((response: AxiosResponse<TransNotification>) => {
          dispatch(patchTransactionalNotificationsSuccess(response.data));
          dispatch(
            SnackBarActions.snackbar(
              "Email modificato correttamente",
              "success",
            ),
          );
        })
        .catch((error) => {
          dispatch(patchTransactionalNotificationsError(error));
          dispatch(
            SnackBarActions.snackbar("Qualcosa è andato storto", "error"),
          );
        });
    } else {
      tnApi
        .patchForSmsNotificationTransactionalNotificationItem(id, request)
        .then((response: AxiosResponse<TransNotification>) => {
          dispatch(patchTransactionalNotificationsSuccess(response.data));
          dispatch(
            SnackBarActions.snackbar("Sms modificato correttamente", "success"),
          );
        })
        .catch((error) => {
          dispatch(patchTransactionalNotificationsError(error));
          dispatch(
            SnackBarActions.snackbar("Qualcosa è andato storto", "error"),
          );
        });
    }
  };

export default {
  getServices,
  getServicesLoading,
  getServicesError,
  getServicesSuccess,
  getSingleService,
  getSingleServiceError,
  getSingleServiceLoading,
  getSingleServiceSuccess,
  editService,
  editServiceError,
  editServiceLoading,
  editServiceSuccess,
  postCatalogService,
  postCatServicesError,
  postCatServicesLoading,
  postCatServicesSuccess,
  getTransactionalNotificationsError,
  getTransactionalNotificationsLoading,
  getTransactionalNotificationsSuccess,
  getTransactionalNotifications,
  patchTransactionalNotificationsSuccess,
  patchTransactionalNotificationsError,
  patchTransactionalNotificationsLoading,
  editTransactionalNotifications,
};
