import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { IOSSwitch as Switch } from '../../../forms/Switch';
import DialogActionFooter from '../../../dialog/DialogActionFooter';
import PlaceTextField from '../../../PlaceTextField';
import '../../../../utils/extensions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { SnackBarActions, StoreActions } from '../../../../redux/actions';
import RegionTextField from '../../../RegionTextField';
import StoreProductsField from '../StoreProductsField';
import _ from 'lodash';
import CapacityTextField from '../../../services/CapacityTextField';
import { validatePhone } from '../../../../utils/validators';
import { UserRoles } from '../../../../utils/UserRoles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import { CloudUploadIcon } from '../../../icons/CloudUploadIcon';
interface CreateStoreModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export default function CreateStoreModal({
  isVisible,
  onClose,
}: CreateStoreModalProps) {
  const classes = useStyles();
  const [name, setName] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [phoneNumber, setPhone] = useState<string>('');
  const [placeId, setPlaceId] = useState<string>('');
  const [slug, setSlug] = useState<string>('');
  const [crmStoreId, setCrmId] = useState<string>('');
  const [secondCrmStoreId, setSecondCrmStoreId] = useState<string>('');
  const [capacity, setCapacity] = useState<number>(0);
  const [visibilityStartDate, setStartDate] = useState<Moment | null>();
  const [visibilityEndDate, setEndDate] = useState<Moment | null>();
  const [storeIsVisible, setStoreIsVisible] = useState(true);
  const [capacityIsVisible, setCapacityIsVisible] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState('');
  const [products, setStoreProducts] = useState<any[]>([]);
  const phoneErrorText = () => {
    return !validatePhone(phoneNumber) && 'numero di telefono non valido';
  };

  const catalogProducts = useSelector((state: RootState) =>
    state.ProductsReducer.hydratedProducts?.['hydra:member']
      ? state.ProductsReducer.hydratedProducts?.['hydra:member']
      : [],
  );

  const currentRole = useSelector(
    (state: RootState) => state.UsersReducer.currentRole,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!capacityIsVisible) {
      setCapacity(0);
    }
  }, [capacityIsVisible]);

  const handleChangePhoto = (event: any) => {
    setCurrentPhoto(URL.createObjectURL(event.target.files[0]));
  };

  const checkAllDataSet = () => {
    return name !== '' && address !== '' && region !== '';
  };

  const handleClose = () => {
    setName('');
    setRegion('');
    setAddress('');
    setCapacity(0);
    setStoreProducts([]);
    setPlaceId('');
    setPhone('');
    setSlug('');
    onClose();
  };

  const productsIDS = (products: any[]) => {
    var filtered = catalogProducts.filter(function (obj) {
      return products.includes(obj.productName);
    });
    return _.map(filtered, function (o) {
      return o.uuid;
    });
  };
  const checkPhoneField = () => {
    if (!validatePhone(phoneNumber) && phoneNumber !== '') {
      dispatch(
        SnackBarActions.snackbar('Numero di telefono non valido', 'warning'),
      );
      return false;
    }
    return true;
  };
  const handleSave = async () => {
    let location;
    let city;
    if (placeId !== '') {
      let results = await fetch(
        'https://maps.googleapis.com/maps/api/geocode/json?place_id=' +
          placeId +
          '&key=AIzaSyCgHbEyU7C1wS6O0sOrzBG8swoF86o3-us',
        { method: 'GET' },
      );
      let jsonResults = await results.json();

      location = jsonResults.results[0].geometry.location;
      let _city = _.find(
        jsonResults.results[0].address_components,
        function (o) {
          if (_.includes(o.types, 'locality')) {
            return o;
          }
        },
      );
      city = _city ? _city.long_name : '';
    } else {
      dispatch(
        SnackBarActions.snackbar(
          'Si prega di controllare tutti i campi',
          'warning',
        ),
      );
    }

    let baseStore = {
      name,
      address,
      city,
      phoneNumber,
      capacity,
      slug,
      crmStoreId,
      secondCrmStoreId,
      region,
      latitude: location && location.lat ? location.lat : 0,
      longitude: location && location.lng ? location.lng : 0,
      products: productsIDS(products) as string[],
    };

    if (checkAllDataSet()) {
      if (storeIsVisible) {
        let newStore = {
          ...baseStore,
          visibilityStartDate: storeIsVisible
            ? visibilityStartDate?.toISOString()
            : undefined,
          visibilityEndDate: storeIsVisible
            ? visibilityEndDate?.toISOString()
            : undefined,
        };
        if (currentPhoto !== '') {
          let withImageStore = {
            ...newStore,
            image: currentPhoto.replace('blob:', ''),
          };
          dispatch(StoreActions.createStore(withImageStore));
        } else {
          dispatch(StoreActions.createStore(newStore));
        }
      } else {
        if (currentPhoto !== '') {
          let withImageStore = {
            ...baseStore,
            image: currentPhoto.replace('blob:', ''),
          };
          dispatch(StoreActions.createStore(withImageStore));
        } else {
          dispatch(StoreActions.createStore(baseStore));
        }
      }
      handleClose();
    } else {
      dispatch(
        SnackBarActions.snackbar(
          'Per favore compila tutti i campi!',
          'warning',
        ),
      );
    }
  };

  const checkAndSave = () => {
    if (checkPhoneField()) {
      handleSave();
    }
  };

  return (
    <Dialog
      className={classes.modal}
      open={isVisible}
      scroll="body"
      maxWidth="md"
      fullWidth
      onClose={() => handleClose()}
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <div>
        <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
          Crea Store
        </DialogTitle>
        <Grid className={classes.gridContainer}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
            <Box justifyContent="space-between">
              <TextField
                className={classes.contentField}
                autoFocus
                label="Nome"
                id="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                color="secondary"
                size="small"
                style={{ marginBottom: '24px' }}
                inputProps={{
                  style: {
                    padding: '12px 16px',
                    borderColor: '#E1E0E2',
                  },
                }}
                InputLabelProps={{
                  style: {
                    top: '4px',
                  },
                }}
              />
              <RegionTextField
                regionName={region}
                onSelect={(region) => setRegion(region)}
              />
              <PlaceTextField
                value={address}
                onSelect={(placeObject) => {
                  setPlaceId(placeObject?.place_id ?? '');
                  setAddress(placeObject?.description ?? '');
                }}
              />
              <TextField
                className={classes.contentField}
                label="Telefono"
                error={phoneNumber !== '' && !validatePhone(phoneNumber)}
                helperText={phoneErrorText()}
                id="phone"
                value={phoneNumber}
                onChange={(event) => setPhone(event.target.value)}
                color="secondary"
                size="small"
                style={{ marginBottom: '24px' }}
                inputProps={{
                  style: {
                    padding: '12px 16px',
                    borderColor: '#E1E0E2',
                  },
                }}
                InputLabelProps={{
                  style: {
                    top: '4px',
                  },
                }}
              />
              <TextField
                className={classes.contentField}
                label="Identificativo"
                id="slug"
                value={slug}
                onChange={(event) => setSlug(event.target.value)}
                color="secondary"
                size="small"
                style={{ marginBottom: '24px' }}
                inputProps={{
                  style: {
                    padding: '12px 16px',
                    borderColor: '#E1E0E2',
                  },
                }}
                InputLabelProps={{
                  style: {
                    top: '4px',
                  },
                }}
              />
              {currentRole === UserRoles.ADMIN && (
                <TextField
                  className={classes.contentField}
                  label="CRM Id"
                  id="crm_id"
                  value={crmStoreId}
                  onChange={(event) => setCrmId(event.target.value)}
                  color="secondary"
                  size="small"
                  style={{ marginBottom: '24px' }}
                  inputProps={{
                    style: {
                      padding: '12px 16px',
                      borderColor: '#E1E0E2',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      top: '4px',
                    },
                  }}
                />
              )}
              {currentRole === UserRoles.ADMIN && (
                <TextField
                  className={classes.contentField}
                  label="Store Code Id"
                  id="crm_id"
                  value={secondCrmStoreId}
                  onChange={(event) => setSecondCrmStoreId(event.target.value)}
                  color="secondary"
                  size="small"
                  style={{ marginBottom: '24px' }}
                  inputProps={{
                    style: {
                      padding: '12px 16px',
                      borderColor: '#E1E0E2',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      top: '4px',
                    },
                  }}
                />
              )}
              <StoreProductsField
                onSelectProducts={(values) => setStoreProducts(values)}
              />
              <Box
                display="flex"
                width="100%"
                alignItems={'center'}
                justifyContent="space-between"
                sx={{ marginBottom: capacityIsVisible ? '24px' : 0 }}
              >
                <Typography className={classes.label}>
                  Coda unica per i servizi
                </Typography>
                <Switch
                  checked={capacityIsVisible}
                  onChange={() => {
                    setCapacityIsVisible(!capacityIsVisible);
                  }}
                />
              </Box>
              {capacityIsVisible && (
                <CapacityTextField
                  onSelectValue={(value) =>
                    setCapacity(value === '' ? 0 : parseInt(value))
                  }
                />
              )}
              <Box
                display="flex"
                width="100%"
                alignItems={'center'}
                justifyContent="space-between"
                sx={{ marginBottom: storeIsVisible ? '24px' : 0 }}
              >
                <Typography className={classes.label}>
                  Pianifica visibilità
                </Typography>
                <Switch
                  checked={storeIsVisible}
                  onChange={() => {
                    setStoreIsVisible(!storeIsVisible);
                  }}
                  name="checkedB"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Box>
              {storeIsVisible && (
                <Box
                  sx={{ marginBottom: storeIsVisible ? '24px' : 0 }}
                  display="flex"
                  width="100%"
                  alignItems={'center'}
                >
                  <Box display="flex" alignItems="center" width={'50%'}>
                    <Typography className={classes.visibleDateLabel}>
                      Data di inizio
                    </Typography>
                    <div>
                      <DatePicker
                        className={classes.date}
                        format="DD/MM/YYYY"
                        slotProps={{
                          textField: {
                            color: 'secondary',
                            label: 'Data di inizio',
                            inputProps: {
                              style: {
                                padding: '12px 16px',
                                borderColor: '#E1E0E2',
                              },
                            },
                            InputLabelProps: {
                              style: {
                                top: '-4px',
                              },
                            },
                          },
                        }}
                        value={visibilityStartDate}
                        onChange={(event: any) => setStartDate(event || null)}
                      />
                    </div>
                  </Box>
                  <Box display="flex" alignItems="center" width={'50%'}>
                    <Typography className={classes.visibleDateLabel}>
                      Data di fine
                    </Typography>
                    <div>
                      <DatePicker
                        className={classes.date}
                        format="DD/MM/YYYY"
                        value={visibilityEndDate}
                        slotProps={{
                          textField: {
                            color: 'secondary',
                            label: 'Data di fine',
                            inputProps: {
                              style: {
                                padding: '12px 16px',
                                borderColor: '#E1E0E2',
                              },
                            },
                            InputLabelProps: {
                              style: {
                                top: '-4px',
                              },
                            },
                          },
                        }}
                        onChange={(event: any) => setEndDate(event || null)}
                      />
                    </div>
                  </Box>
                </Box>
              )}
              <Box>
                <Typography className={classes.uploadBoxLabel}>
                  Foto dello store
                </Typography>
                <Box display="flex">
                  <div className={classes.uploadRoot}>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleChangePhoto}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        disableElevation
                        color="inherit"
                        className={classes.uploadButton}
                        startIcon={<CloudUploadIcon color="#34303D7" />}
                        component="span"
                      >
                        UPLOAD
                      </Button>
                    </label>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="icon-button-file"
                      type="file"
                    />
                  </div>

                  <img src={currentPhoto} className={classes.img} />
                </Box>
              </Box>
            </Box>
          </LocalizationProvider>
        </Grid>
        <Box
          className={classes.footerWrapper}
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <DialogActionFooter
            onCancel={() => handleClose()}
            onConfirm={() => checkAndSave()}
            actionTitle="Crea"
          />
        </Box>
      </div>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      marginBottom: '16px !important',
    },
    gridContainer: {
      backgroundColor: 'white',
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 4,
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
    input: {
      display: 'none',
    },
    uploadRoot: {
      alignItems: 'center',
    },
    uploadButton: {
      marginTop: 6,
      fontWeight: 'bold',
      fontFamily: 'IQOS Sans',
      borderRadius: '40px !important',
    },
    label: {
      fontWeight: 'bold',
      fontSize: '14px !important',
      marginTop: '10px !important',
    },
    contentField: {
      width: '100%',
      fontFamily: 'IQOS Sans',
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 16,
    },
    img: { marginLeft: 16 },
    visibilityBox: {
      width: '80%',
    },
    visibleDateLabel: {
      fontSize: '14px !important',
      color: '#34303D',
      marginRight: '12px !important',
    },
    date: {
      fontSize: '14px',
      marginLeft: 16,
    },
    uploadBoxLabel: {
      marginTop: 4,
      fontWeight: 'bold',
      fontSize: 14,
    },
    imgIcon: {
      marginLeft: 8,
      marginTop: 6,
    },
  }),
);
