import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { ClickAndLendingActions, StoreActions } from '../../redux/actions';
import { Store } from '../../api/entities';
import ClickAndLendingTable from '../../components/clickAndLending/ClickAndLendingTable';

interface ClickLendingProps {
  getAllClickAndLendingReservations: (
    uuid: string,
    _orderDirection: string,
    _page: number,
    _itemsPerPage: number,
    _orderBy: string,
    _status: string,
    _reservationCode?: string,
    _userName?: string,
    _userSurname?: string,
  ) => void;
  perPage: number;
  setCurrentSelectedStore: (store: Store) => void;
  getProducts: () => void;
  currentSelectedStore: Store;
  userCurrentStores: Store[];
  getUserCurrentStores: () => void;
  getUserCurrentStoresLoading: boolean;
  loadingReservations: boolean;
}
interface ClickAndLendingState {
  currentStatus: any;
  currentOrderField: string;
}

class ClickAndLending extends React.Component<
  ClickLendingProps,
  ClickAndLendingState
> {
  componentDidMount() {
    this.props.getUserCurrentStores();
    this.props.getProducts();
  }

  render() {
    return (
      <div>
        <ClickAndLendingTable />
      </div>
    );
  }
}

export function mapStateToProps(state: RootState) {
  return {
    perPage: state.ClickAndLendingReducer.rowsPerPage,
    userCurrentStores: state.StoreReducer.userCurrentStores,
    loadingReservations: state.ClickAndLendingReducer.loadingReservations,
    getUserCurrentStoresLoading: state.StoreReducer.getUserCurrentStoresLoading,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getProducts: () => dispatch(ClickAndLendingActions.getProducts()),
    getAllClickAndLendingReservations: (
      uuid: string,
      _orderDirection: string,
      _page: number,
      _itemsPerPage: number,
      _orderBy: string,
      _status: string,
      _reservationCode?: string,
      _userName?: string,
      _userSurname?: string,
    ) =>
      dispatch(
        ClickAndLendingActions.getAllClickAndLendingReservations(
          uuid,
          _orderDirection,
          _page,
          _itemsPerPage,
          _orderBy,
          _status,
          _reservationCode,
          _userName,
          _userSurname,
        ),
      ),
    setCurrentSelectedStore: (store: Store) =>
      dispatch(ClickAndLendingActions.setCurrentSelectedStore(store)),
    getUserCurrentStores: () => dispatch(StoreActions.getUserCurrentStores()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClickAndLending);
