import moment from "moment";
import { TimeSlot } from "../api/entities";

declare global {
  interface String {
    formatted(): string;
    getIdFromPath(): string;
    isoFormat(): string;
    getFormatedTime(): string;
    itDay(): string;
  }
}

String.prototype.itDay = function (): string {
  var s = String(this);
  switch (s) {
    case "MONDAY":
      return "LUNEDÌ";
    case "TUESDAY":
      return "MARTEDÌ";
    case "WEDNESDAY":
      return "MERCOLEDÌ";
    case "THURSDAY":
      return "GIOVEDÌ";
    case "FRIDAY":
      return "VENERDÌ";
    case "SATURDAY":
      return "SABATO";
    case "SUNDAY":
      return "DOMENICA";
    default:
      return s;
  }
};

String.prototype.formatted = function (): string {
  var s = String(this);
  return s ? moment(s).format("DD/MM/YYYY") : "non valid";
};

String.prototype.getFormatedTime = function (): string {
  var s = String(this);
  return s ? moment(s).format("HH:mm") : "00:00";
};

String.prototype.getIdFromPath = function (): string {
  var s = String(this);
  let pathArguments = s?.split("/");
  let storeID = pathArguments && pathArguments[pathArguments?.length - 1];
  return storeID;
};

String.prototype.isoFormat = function (): string {
  var s = String(this);
  return moment(s).toISOString();
};

export function addTimeForDate(
  date: moment.Moment | null | undefined,
  slot: TimeSlot | undefined | null,
) {
  if (date && slot) {
    let getDate = date.format("DD/MM/YYYY");
    let dateTime = moment(`${getDate} ${slot.slot}`, "DD-MM-YYYY HH:mm");
    return dateTime;
  }
  return undefined;
}
