import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Box,
  Dialog,
  Grid,
  TextField,
  Typography,
  DialogTitle,
  Autocomplete,
} from '@mui/material';
import { IOSSwitch as Switch } from '../../../../forms/Switch';

import DialogActionFooter from '../../../../dialog/DialogActionFooter';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/reducers';
import {
  BusinessHoursActions,
  ModalsActions,
  SnackBarActions,
} from '../../../../../redux/actions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export default function CreateSpecialTimePeriodModal() {
  const classes = useStyles();
  const storeDetail = useSelector(
    (state: RootState) => state.StoreReducer.storeDetail,
  );
  const [openTime, setOpenTime] = useState('09:00');
  const [closeTime, setCloseTime] = useState('17:00');
  const [day, setDay] = useState('');
  const dispatch = useDispatch();
  const storeServices = useSelector((state: RootState) =>
    state.StoreReducer.storeDetail?.storeServices
      ? state.StoreReducer.storeDetail.storeServices
      : [],
  );
  const [rowServices, setRowServices] = useState<any[]>([]);
  const [selectedServiceID, setSelectedServiceID] = useState<string>('all');
  const { showCreateSpecialHoursModalSuccess } = useSelector(
    (state: RootState) => state.UiReducer,
  );
  const [dayIsClosed, setDayIsClosed] = useState(false);

  useEffect(() => {
    if (storeServices) {
      setRowServices(
        storeServices.concat({ uuid: 'all', externalName: 'Tutti i Servizi' }),
      );
    }
  }, [storeServices]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      dispatch(ModalsActions.closeModal());
      return;
    }
    dispatch(ModalsActions.closeModal());
  };
  const handleCloseTime = (event: any) => {
    setCloseTime(event.target.value);
  };

  const handleOpenTime = (event: any) => {
    setOpenTime(event.target.value);
  };

  const handleDay = (event: any) => {
    let dayISO = moment(event).toISOString();
    setDay(dayISO);
  };

  const handleServices = (option: any) => {
    const storeService = storeServices.find((s) => s.uuid === option?.uuid);
    setSelectedServiceID(storeService?.uuid ? storeService.uuid : 'all');
  };

  const renderServiceValue = (serviceUuid: string) => {
    const storeService = storeServices.find((s) => s.uuid === serviceUuid);
    if (storeService?.externalName) return storeService.externalName;
    return 'Tutti i Servizi';
  };

  const hadleChangedIsClosed = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDayIsClosed(event.target.checked);
  };

  const handleSave = () => {
    if (storeDetail && storeDetail.specialHours) {
      if (day && closeTime && openTime) {
        let specialHourID = storeDetail?.specialHours[0].uuid;
        let request = {
          startDate: day,
          endDate: day,
          openTime,
          closeTime,
          isClosed: !dayIsClosed,
          storeServices: selectedServiceID !== 'all' ? [selectedServiceID] : [],
        };
        dispatch(
          BusinessHoursActions.createSpecialHourPeriod(
            specialHourID ? specialHourID : '',
            request,
          ),
        );
        handleClose();
      } else {
        dispatch(
          SnackBarActions.snackbar(
            'Alcuni campi non sono corretti, per favore ricontrolla.',
            'warning',
          ),
        );
      }
    }
  };

  return (
    <Dialog
      className={classes.modal}
      open={showCreateSpecialHoursModalSuccess}
      onClose={() => handleClose()}
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: '10px' } }}
    >
      <DialogTitle className={classes.contentTitle} id="alert-dialog-title">
        Aggiungi altri orari
      </DialogTitle>
      <Grid className={classes.gridContainer}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
          <Box
            sx={{ margin: 0 }}
            key={_.uniqueId()}
            justifyContent="flex-start"
            alignItems="center"
            display="flex"
          >
            <div>
              <DatePicker
                slotProps={{
                  textField: {
                    color: 'secondary',
                    size: 'small',
                    inputProps: {
                      style: {
                        padding: '12px 16px',
                      },
                    },
                  },
                }}
                className={classes.date}
                value={moment(day)}
                onChange={(event: any) => {
                  handleDay(event);
                }}
              />
            </div>

            <Switch
              checked={dayIsClosed}
              onChange={hadleChangedIsClosed}
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            <Typography className={classes.switchLabel}>
              {dayIsClosed ? 'APERTO' : 'CHIUSO'}
            </Typography>

            <TextField
              id="time"
              type="time"
              defaultValue={'09:00'}
              value={openTime}
              className={classes.timeStart}
              onChange={(value) => handleOpenTime(value)}
              color="secondary"
              size="small"
              sx={{ marginRight: '16px', minWidth: '110px' }}
              inputProps={{
                style: {
                  padding: '12px 16px',
                },
                step: 300, // 5 min
              }}
              InputLabelProps={{
                style: {
                  top: '4px',
                },
                shrink: true,
              }}
            />

            <TextField
              id="time"
              type="time"
              defaultValue={'17:00'}
              value={closeTime}
              onChange={(value) => handleCloseTime(value)}
              className={classes.timeEnd}
              color="secondary"
              size="small"
              sx={{ marginRight: '16px', minWidth: '110px' }}
              inputProps={{
                style: {
                  padding: '12px 16px',
                },
                step: 300, // 5 min
              }}
              InputLabelProps={{
                style: {
                  top: '4px',
                },
                shrink: true,
              }}
            />

            <Autocomplete
              noOptionsText="Nessun risultato"
              fullWidth
              id="standard-select-service"
              value={selectedServiceID}
              color="secondary"
              size="small"
              options={rowServices || []}
              onChange={(e: any, option: any) => handleServices(option)}
              getOptionLabel={(option: any) => renderServiceValue(option)!}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  color="secondary"
                  label="Seleziona Servizio"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      padding: '6px 10px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      top: '4px',
                    },
                  }}
                />
              )}
              renderOption={(props: any, option: any) => (
                <li {...props} key={option.value}>
                  {option.externalName}
                </li>
              )}
            />
          </Box>
        </LocalizationProvider>
      </Grid>
      <Box className={classes.footerWrapper}>
        <DialogActionFooter
          onCancel={() => handleClose()}
          onConfirm={() => handleSave()}
        />
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    gridContainer: {
      padding: '24px',
    },
    footerWrapper: {
      padding: '0px 8px 8px 8px',
    },
    contentTitle: {
      fontSize: '24px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
    },
    switchLabel: {
      color: '#34303D',
      fontWeight: 'bold',
      marginLeft: '16px !important',
      marginRight: '16px !important',
    },
    date: {
      width: 175,
      marginRight: '16px !important',
      fontSize: '14px',
    },
    timeStart: {
      marginLeft: 20,
      marginRight: 8,
      width: 100,
      fontSize: '14px',
    },
    timeEnd: {
      marginLeft: 8,
      marginRight: 20,
      width: 100,
      fontSize: '14px',
    },
    endRowLabel: {
      color: '#00D1D2',
      fontSize: 12,
      fontWeight: 'bold',
    },
  }),
);
