import { Box, Grid, IconButton, List, Theme, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import EditNotificationDialog from './modals/EditNotificationDialog';
import { ServiceActions } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { CustomTab, CustomTabs } from '../../CustomTabs';
import { SettingsIcon } from '../../icons/SettingsIcon';

interface ServiceTabsProps {
  serviceId: string;
}

export default function ServiceTabs(props: ServiceTabsProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [openEditNotificationDialog, setOpenEditNotificationDialog] =
    useState(false);
  const [editObject, setEditObject] = useState(null);
  const [type, setType] = useState('');
  const serviceDetail = useSelector(
    (state: RootState) => state.ServiceReducer.serviceDetail,
  );

  const transNotifications = useSelector(
    (state: RootState) => state.ServiceReducer.transactionalNotifications,
  );

  useEffect(() => {
    setOpenEditNotificationDialog(!!editObject);
  }, [editObject]);

  const switchTab = () => {
    if (index === 0) {
      const veevId: string | undefined = serviceDetail?.products?.find(
        (item) => item.productName === 'VEEV',
      )?.uuid;

      dispatch(
        ServiceActions.getTransactionalNotifications(
          props.serviceId,
          veevId || '',
        ),
      );

      setIndex(1);
      return;
    }

    const iqosId: string | undefined = serviceDetail?.products?.find(
      (item) => item.productName === 'IQOS',
    )?.uuid;

    dispatch(
      ServiceActions.getTransactionalNotifications(
        props.serviceId,
        iqosId || '',
      ),
    );

    setIndex(0);
  };

  const clickOnEdit = (object: any, type: string) => {
    setType(type);
    setEditObject(object);
  };

  const renderEmailData = () => {
    if (transNotifications?.['hydra:member'].length === 0) {
      return <Typography style={{ color: 'black' }}>No data</Typography>;
    }
    return transNotifications?.['hydra:member'].reduce(
      (rows: any[], obj, i) => {
        if (obj.emailNotification === null) return rows;
        return [
          ...rows,
          <EmailRow
            key={i}
            row={obj}
            onEditNotification={() => clickOnEdit(obj, 'email')}
          />,
        ];
      },
      [],
    );
  };

  const renderSmsData = () => {
    if (transNotifications?.['hydra:member'].length === 0) {
      return <Typography style={{ color: 'black' }}>No data</Typography>;
    }
    return transNotifications?.['hydra:member'].reduce(
      (rows: any[], obj, i) => {
        if (obj.smsNotification === null) return rows;
        return [
          ...rows,
          <SmsRow
            key={i}
            row={obj}
            onEditNotification={() => clickOnEdit(obj, 'sms')}
          />,
        ];
      },
      [],
    );
  };

  const renderTabContent = () => {
    return (
      <>
        <Box className={classes.accordionBox} justifyContent="space-between">
          <Typography className={classes.accordionTitle}>EMAIL</Typography>
          <List>{renderEmailData()}</List>
        </Box>
        <Box className={classes.accordionBox} justifyContent="space-between">
          <Typography className={classes.accordionTitle}>SMS</Typography>
          <List>{renderSmsData()}</List>
        </Box>
      </>
    );
  };

  const onEditTemplate = () => {
    setEditObject(null);
    setType('');
    if (index === 0) {
      let iqos = serviceDetail?.products?.filter((item) => {
        if (item.productName === 'IQOS') {
          return item.uuid;
        }
        return '';
      })[0].uuid;
      dispatch(
        ServiceActions.getTransactionalNotifications(
          props.serviceId,
          iqos ? iqos : '',
        ),
      );
    } else {
      let veev = serviceDetail?.products?.filter((item) => {
        if (item.productName === 'VEEV') {
          return item;
        }
      })[0].uuid;
      dispatch(
        ServiceActions.getTransactionalNotifications(
          props.serviceId,
          veev ? veev : '',
        ),
      );
    }
  };

  return (
    <Grid className={classes.gridContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.title}>Notifiche</Typography>
      </Box>
      <CustomTabs value={index} onChange={switchTab} centered>
        <CustomTab label="IQOS" />
        <CustomTab label="VEEV" />
      </CustomTabs>

      {renderTabContent()}

      <EditNotificationDialog
        type={type}
        object={editObject}
        isVisible={openEditNotificationDialog}
        onClose={() => setEditObject(null)}
        onSave={() => onEditTemplate()}
      />
    </Grid>
  );
}
interface SmsRowProps {
  row: any;
  onEditNotification: () => void;
}
function SmsRow(props: SmsRowProps) {
  const classes = useStyles();
  return (
    <Box
      className={classes.notificationBox}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography className={classes.notification}>
        {props.row.smsNotification?.externalName ?? ''}
      </Typography>
      <IconButton onClick={() => props.onEditNotification()}>
        <SettingsIcon color="#00D1D2" />
      </IconButton>
    </Box>
  );
}

function EmailRow(props: SmsRowProps) {
  const classes = useStyles();

  return (
    <Box
      className={classes.notificationBox}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography className={classes.notification}>
        {props.row.emailNotification?.subject ?? ''}
      </Typography>
      <IconButton onClick={() => props.onEditNotification()}>
        <SettingsIcon color="#00D1D2" />
      </IconButton>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      maxWidth: '35% !important',
      paddingTop: '32px',
      paddingRight: '24px',
      paddingLeft: '24px',
    },
    accordionBox: {
      marginTop: 16,
      border: '1px solid #E5E5E5',
      padding: '16px',
      borderRadius: '8px',
    },
    accordionTitle: {
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '700 !important',
      lineHeight: '16px !important',
    },
    title: {
      fontSize: '18px !important',
      fontStyle: 'normal !important',
      fontWeight: '700 !important',
      lineHeight: '18px !important',
    },
    tabLabel: {
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: 'IQOS Sans',
      color: '#9F9F9F',
    },
    notificationBox: {
      marginTop: '6px !important',
    },
    notification: {
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      lineHeight: '20px !important',
    },
  }),
);
